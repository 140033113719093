import React, { Fragment, useEffect, useState } from "react";
import { Switch, useHistory } from "react-router";
import { Route } from "react-router-dom";
import DebitFrequencyStep from "./steps/DebitFrequencyStep";
import DebitDetailsStep from "./steps/DebitDetailsStep";
import DebitAccountStep from "./steps/DebitAccountStep";
import DebitDeliveryMethodStep from "./steps/DebitDeliveryMethodStep";
import DebitReviewStep from "./steps/DebitReviewStep";
import DebitSuccessStep from "./steps/DebitSuccessStep";
import { useSimpleContext } from "../../../../contexts/SimpleContext";
import DebitScheduleStep from "./steps/DebitScheduleStep";
import { BulkDebit } from "./bulk_debits/create_bulk_debit/BulkDebit";

function CreateDebit() {
  const history = useHistory();
  const [state] = useSimpleContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!state) {
      history.push("frequency", { steps: 8, currStep: 1 });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null;
  } else {
    return (
      <Fragment>
        <Switch>
          <Route
            path="/new-payment/debit/frequency"
            render={() => <DebitFrequencyStep />}
          />
          <Route
            path="/new-payment/debit/details"
            render={() => <DebitDetailsStep />}
          />
          <Route
            path="/new-payment/debit/deposit-account"
            render={() => <DebitAccountStep />}
          />
          <Route
            path="/new-payment/debit/delivery-method"
            render={() => <DebitDeliveryMethodStep />}
          />
          <Route
            path="/new-payment/debit/review"
            render={() => <DebitReviewStep />}
          />
          <Route
            path="/new-payment/debit/success"
            render={() => <DebitSuccessStep />}
          />
          <Route
            path="/new-payment/debit/schedule"
            render={() => <DebitScheduleStep />}
          />
          <Route path="/new-payment/debit/bulk" render={() => <BulkDebit />} />
        </Switch>
      </Fragment>
    );
  }
}

export default CreateDebit;
