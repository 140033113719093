/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import { useHistory } from "react-router-dom";
import useGQL from "../../../../api_client/UseGQL";
import { upcomingBill } from "../../../../api_client/queries/bills";
import BillingDetails from "./BillingDetails";
import BillingPricingDetails from "./BillingPricingDetails";
import BillingTable from "../../../../components/tables/BillingTable";
import { CustomLoader } from "../../../../components/loaders/CustomLoader";
import PopOutOverlay from "../../../../components/overlays/PopOutOverlay";
import Bill from "./Bill";
import { useToasts } from "react-toast-notifications";
import { Button, UncontrolledCollapse } from "reactstrap";
import FilterIcon from "../../../../assets/icons/FilterIcon";
import BillingFilters from "components/modals/BillingFilters";

function Billing(props) {
  const [filters, setFilters] = useState({
    startMonth: 1,
    startYear: new Date().getFullYear(),
    endMonth: 12,
    endYear: new Date().getFullYear(),
  });
  const [loading, setLoading] = useState(true);
  const [bill, setBill] = useState({});
  const gqlHooks = useGQL();
  let history = useHistory();
  const [overlayDetails, setOverlayDetails] = useState(false);
  const { addToast } = useToasts();
  const [exportTransactionsModal, setExportTransactionsModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (filters.states) {
      count++;
    }
    if (filters.contactTag) {
      count++;
    }
    if (filters.importSource) {
      count++;
    }
    setFilterCount(count);
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    let output = {
      numTransactions: true,
      subtotal: true,
      availableCredit: true,
      tax: true,
      total: true,
      month: true,
      year: true,
      numDebits: true,
      costDebits: true,
      numCredits: true,
      costCredits: true,
      numInternals: true,
      costInternals: true,
      numNsfs: true,
      costNsfs: true,
      amountTransacted: true,
      transactions: {
        amount: true,
        direction: true,
        states: {
          createdAt: true,
          updatedAt: true,
          state: true,
        },
      },
      owner: {
        company: {
          billingAccount: {
            identifier: true,
            accountNumber: true,
            institution: true,
            title: true,
          },
        },
      },
    };
    upcomingBill({}, output, gqlHooks).then((response) => {
      if (
        response &&
        response?.data?.owner?.company?.billingAccount?.identifier
      ) {
        setBill(response.data);
      } else {
        addToast("Please specify a billing account", {
          appearance: "warning",
          autoDismiss: true,
        });
        history.push("#banks");
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="content">
        <CustomLoader size={30} general={true} />
      </div>
    );
  }

  return (
    <Fragment>
      <PopOutOverlay
        fullscreen
        isOpen={overlayDetails}
        toggle={() => setOverlayDetails(!overlayDetails)}
      >
        <Bill toggle={() => setOverlayDetails(!overlayDetails)} {...props} />
      </PopOutOverlay>
      <div>
        <Row className="mt-3 mb-0 mr-0 ml-0">
          <Col md="7" sm="12" className="mb-4">
            <div
              className="border border-radius p-4 shadow-sm"
              style={{ height: "100%" }}
            >
              <BillingDetails
                bill={bill}
                action={() => history.push("/admin/settings#banks")}
                actionTitle="Update Billing Account"
              />
            </div>
          </Col>
          <Col md="5" sm="12" className="mb-4">
            <div
              className="border border-radius p-4 shadow-sm"
              style={{ height: "100%" }}
            >
              <BillingPricingDetails />
            </div>
          </Col>
        </Row>

        <div className="flex-row">
          <Button
            className="mr-3 btn-simple btn-primary sp-button-simple pull-right"
            id="buttonToggler"
          >
            <FilterIcon width={15} height={15} strokewidth={3} />{" "}
            <span className="filter-btn-label">Filter</span>
          </Button>

          <h4 className="mt-2 mb-0 ml-4 mb-sm-2 d-inline-block">History</h4>
        </div>

        <div className="flex-row ml-4">
          <UncontrolledCollapse toggler="#buttonToggler">
            <BillingFilters filters={filters} setFilters={setFilters} />
          </UncontrolledCollapse>
        </div>
        <BillingTable {...props} filters={filters} />
      </div>
    </Fragment>
  );
}

export default Billing;

Billing.defaultProps = {};
