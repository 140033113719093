import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";

function NotificationActionsDropdown(props) {
  return (
    <>
      <UncontrolledDropdown className="pull-right">
        <div className="details-action-wrapper">
          <DropdownToggle className="details-more-btn" nav>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
        </div>
        <DropdownMenu right className="custom-dropdown-menu">
          <DropdownItem
            onClick={props.readAllNotifications}
            className="btn-link"
          >
            <FontAwesomeIcon icon={faEnvelopeOpen} />
            <span className="d-inline-block ml-3 font-weight-600">
              Mark all notifications as read
            </span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default NotificationActionsDropdown;
NotificationActionsDropdown.propTypes = {
  readAllNotifications: PropTypes.func,
};
