import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faEllipsisH,
  faLink,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../modules/Helpers";

function RecurringPlanActionsMobileDropdown(props) {
  return (
    <>
      <UncontrolledDropdown className="pull-right">
        <div className="details-action-wrapper">
          <DropdownToggle className="details-more-btn" nav>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
        </div>
        <DropdownMenu
          right
          className="custom-dropdown-menu details-actions-dropdown"
        >
          <DropdownItem onClick={props.ticket}>
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span className="d-inline-block ml-3 font-weight-600">
              Get help
            </span>
          </DropdownItem>
          <hr />
          <DropdownItem>
            <div>
              {document.queryCommandSupported("copy") && (
                <CopyToClipboard
                  text={`${baseUrl}/payment/recurring/accept/${props.recurringPlan.identifier}`}
                  onCopy={props.copyPlanLink}
                >
                  <span className="d-inline-block font-weight-600">
                    <FontAwesomeIcon className="mr-3" icon={faLink} />
                    Copy payment plan link
                  </span>
                </CopyToClipboard>
              )}
            </div>
          </DropdownItem>
          {props.recurringPlan.active && (
            <>
              <hr />
              <DropdownItem onClick={props.cancelPlan} className="text-danger">
                <div>
                  <FontAwesomeIcon icon={faBan} />
                  <span className="d-inline-block ml-3 font-weight-600">
                    Cancel payment plan
                  </span>
                </div>
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default RecurringPlanActionsMobileDropdown;
RecurringPlanActionsMobileDropdown.propTypes = {
  recurringPlan: PropTypes.object,
  copyPlanLink: PropTypes.func,
  cancelPlan: PropTypes.func,
  ticket: PropTypes.func,
};
