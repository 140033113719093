import React, { Fragment, useEffect, useRef, useState } from "react";
import useForm from "../../../hooks/UseForms";
import { OwnersValidation } from "../../../modules/form_validation/OwnersValidation";
// import Select from "react-select";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";

function OnboardingSigningAuthoritiesForm(props) {
  const { values, setValues, errors, handleChange } = useForm(
    null,
    OwnersValidation
  );

  const refs = {
    legalName: useRef(),
    emailAddress: useRef(),
  };

  useEffect(() => {
    refs.legalName.current.focus();
    setValues({
      legalName: props.legalName,
      emailAddress: props.emailAddress,
    });
  }, []);

  useEffect(() => {
    props.setSigningAuthority(values.legalName, values.emailAddress);
  }, [values.legalName, values.emailAddress]);

  return (
    <Fragment>
      <div className="position-relative">
        <Row>
          <Col>
            <Label>Legal Name</Label>
            <FormGroup className="mb-3">
              <Input
                name="legalName"
                type="text"
                innerRef={refs.legalName}
                value={values.legalName || ""}
                onChange={handleChange}
                invalid={!!errors.legalName}
                required
              />
              <FormFeedback>{errors.legalName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <Label>Email Address</Label>
            <FormGroup className="mb-3">
              <Input
                name="emailAddress"
                type="email"
                innerRef={refs.emailAddress}
                value={values.emailAddress || ""}
                onChange={handleChange}
                invalid={!!errors.emailAddress}
                required
              />
              <FormFeedback>{errors.emailAddress}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Button
          size="sm"
          className="position-absolute btn btn-link"
          style={{ top: -8, right: -5 }}
          onClick={() => props.removeSigningAuthorities(props.id)}
        >
          <i className="fas fa-times-circle text-muted" />
        </Button>
      </div>
    </Fragment>
  );
}

export default OnboardingSigningAuthoritiesForm;

OnboardingSigningAuthoritiesForm.propTypes = {
  id: PropTypes.number,
  legalName: PropTypes.string,
  emailAddress: PropTypes.string,
  setSigningAuthority: PropTypes.func,
  removeSigningAuthorities: PropTypes.func,
};
