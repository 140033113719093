import React, { Fragment } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faEllipsisH,
  faEnvelope,
  faLink,
  faQuestionCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

function TransactionActionsMobileDropdown(props) {
  const cancelButton =
    props.transaction.states[0].state === 7 ||
    props.transaction.states[0].state === 15 ||
    props.transaction.states[0].state === 16 ||
    props.transaction.states[0].state === 8;
  return (
    <>
      <UncontrolledDropdown className="pull-right">
        <div className="details-action-wrapper">
          <DropdownToggle className="details-more-btn" nav>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
        </div>
        <DropdownMenu
          right
          className="custom-dropdown-menu details-actions-dropdown"
        >
          <DropdownItem onClick={props.ticket}>
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span className="d-inline-block ml-3 font-weight-600">
              Get help
            </span>
          </DropdownItem>
          <hr />
          <DropdownItem>
            {document.queryCommandSupported("copy") && (
              <CopyToClipboard
                text={props.transaction.link}
                onCopy={() => props.copyPaymentLink()}
              >
                <span className="d-inline-block font-weight-600">
                  <FontAwesomeIcon className="mr-3" icon={faLink} />
                  Copy payment link
                </span>
              </CopyToClipboard>
            )}
          </DropdownItem>
          {props.transaction.states[0].state === 7 && (
            <Fragment>
              <hr />
              {/* This is a hack, method should be email by default for credit transactions */}
              {(props.transaction.method === "EMAIL" ||
                props.transaction.direction === "CREDIT") && (
                <DropdownItem onClick={props.resendPaymentEmail}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span className="d-inline-block ml-3 font-weight-600">
                    Resend payment request
                  </span>
                </DropdownItem>
              )}
            </Fragment>
          )}
          {cancelButton && (
            <>
              <hr />
              <DropdownItem
                onClick={props.cancelPayment}
                className="text-danger"
              >
                <div>
                  {props.transaction.states[0].state === 8 ? (
                    <FontAwesomeIcon icon={faTrashAlt} />
                  ) : (
                    <FontAwesomeIcon icon={faBan} />
                  )}
                  <span className="d-inline-block ml-3 font-weight-600">
                    {props.transaction.states[0].state === 8
                      ? "Delete payment request"
                      : "Cancel payment request"}
                  </span>
                </div>
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default TransactionActionsMobileDropdown;
TransactionActionsMobileDropdown.propTypes = {
  transaction: PropTypes.object,
  copyPaymentLink: PropTypes.func,
  resendPaymentEmail: PropTypes.func,
  cancelPayment: PropTypes.func,
  ticket: PropTypes.func,
};
