import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    top: "auto",
    left: 20,
    bottom: 25,
    right: "auto",
    position: "fixed",
    zIndex: 999,
  },
  fab: {
    height: 60,
    width: 60,
    backgroundColor: "#8125D2",
    "&:hover": {
      backgroundColor: "#8125D2",
    },
  },
}));

function FabUpButton() {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <ArrowUpwardIcon />
        </Fab>
      </div>
    </Fragment>
  );
}

export default FabUpButton;

FabUpButton.propTypes = {};
