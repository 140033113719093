import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { getBankIcon } from "../../modules/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";

function BankDetail(props) {
  return (
    <Fragment>
      <div className="bank-logo ">
        {props.institution && getBankIcon(props.institution) ? (
          <img src={getBankIcon(props.institution)} alt="Bank Logo" />
        ) : (
          <FontAwesomeIcon icon={faUniversity} />
        )}
      </div>
      <span>{props.description}</span>
      <h5>
        {props.label ? (
          props.label
        ) : (
          <span className="text-muted font-italic">Pending authorization</span>
        )}
      </h5>
    </Fragment>
  );
}

export default BankDetail;

BankDetail.propTypes = {
  institution: PropTypes.string,
  label: PropTypes.elementType,
  description: PropTypes.string.isRequired,
};
