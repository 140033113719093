import React, { Fragment } from "react";
import ImportBillsTable from "../../../../../../components/tables/ImportBillsTable";

function CreditImportBillsStep() {
  return (
    <Fragment>
      <div className="wrapper">
        <div className="main-panel bg-white">
          <div className="content pt-0">
            <ImportBillsTable />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CreditImportBillsStep;
