import React, { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useGQL from "../../../api_client/UseGQL";
import { verifyEmail } from "../../../api_client/mutations/auth";
import EmailVerificationIcon from "../../../assets/img/Email-Verification_Icon.png";
import useApiValue from "../../../hooks/useApiValue";

function EmailConfirmed(props) {
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();
  const { update } = useApiValue();

  useEffect(() => {
    const { token, uid } = props.match.params;
    let output = {
      ok: true,
    };
    verifyEmail({ userId: uid, emailToken: token }, output, gqlHooks).then(
      (response) => {
        if (!response) {
          history.push("/auth/login/");
          return;
        }
        if (response.fieldError) {
          addToast(response.fieldError.message, {
            appearance: "warning",
            autoDismiss: true,
          });
        } else {
          addToast("Email address confirmed!", {
            appearance: "success",
            autoDismiss: true,
          });
          update({ emails: [{ verified: true }] });
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="text-center ">
        <div className="mr-auto ml-auto mb-3 auth-logo">
          <img src={EmailVerificationIcon} width={80} alt="Email Confirmed" />
        </div>
        <h2 className="font-weight-bold register-header mb-2">
          Email Confirmed!
        </h2>
        <p className="text-muted">
          Please close this tab and continue with your previous session.
        </p>
      </div>
    </>
  );
}

export default EmailConfirmed;

EmailConfirmed.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
      uid: PropTypes.string,
    }),
  }).isRequired,
};
