/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";

function ExportIcon(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || "100%";
  const height = props.height || "100%";
  const title = props.title || "export icon";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <polygon points="16,0 10,0 12.293,2.293 7.293,7.293 8.707,8.707 13.707,3.707 16,6 " />
        <path
          d="M15,16H1c-0.552,0-1-0.448-1-1V1c0-0.552,0.448-1,1-1h7v2H2v12h12V8h2v7C16,15.552,15.552,16,15,16z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default ExportIcon;
