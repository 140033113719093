import { withApiValue } from "modules/WithApiValue";
import React, { useRef, useState } from "react";
import { Button, Form } from "reactstrap";
import Cheque from "../../../assets/img/Cheque.png";
import ManualContactBankForm from "../settings/banks/manual/ManualContactBankForm";
import PropTypes from "prop-types";
import useForm from "hooks/UseForms";
import { AddBankAccountValidation } from "modules/form_validation/AddBankAccountValidation";
import useGQL from "api_client/UseGQL";
import { useToasts } from "react-toast-notifications";
import loadingAlert from "components/sweet_alert/LoadingAlert";
import { formatPostalCode, appName } from "modules/Helpers";
import { createBankAccountManual } from "api_client/mutations/bankAccount";
import { trackEvent } from "modules/analytics";
import confirmRoutingAlert from "components/modals/ConfirmRouting";

const config = {
  company: {
    kyc: {
      companyAttestation: true,
      submitted: true,
    },
    validated: true,
  },
  kyc: { submitted: true, validated: true },
};

function NewContactBankAccount(props) {
  const { values, setValues, handleChange, handleSubmit, errors, setErrors } =
    useForm(confirm, AddBankAccountValidation);
  const [confirmModal, setConfirmModal] = useState(null);
  const [hideAccountNumber, setHideAccountNumber] = useState(false);
  const gqlHooks = useGQL();
  const { addToast } = useToasts();

  const refs = {
    institutionNumber: useRef(""),
    transitNumber: useRef(""),
    accountNumber: useRef(""),
    institution: useRef(""),
    holderName: useRef(""),
    holderEmail: useRef(""),
    holderAddress: useRef(""),
    holderAddressPostalCode: useRef(""),
    holderAddressCity: useRef(""),
  };

  async function submit() {
    loadingAlert(setConfirmModal, "Authorizing...");
    const data = { ...values, holderAddressCountry: "CA", title: "Chequing" };
    data.holderAddressPostalCode = formatPostalCode(
      data.holderAddressPostalCode
    );
    let output = {
      bankAccount: {
        identifier: true,
      },
    };
    let response = await createBankAccountManual(
      { bankAccount: data, contactId: props.contact.identifier },
      output,
      gqlHooks
    );
    setHideAccountNumber(false);
    setConfirmModal(null);
    if (response.bankAccount) {
      finish(response.bankAccount);
    }
  }

  function finish(account) {
    props.done(account);

    addToast("Bank account added successfully", {
      appearance: "success",
      autoDismiss: true,
    });
    trackEvent("addBankComplete", {}, gqlHooks);
  }

  const ChangeConfirmModal = (modal = null, hide = false) => {
    setHideAccountNumber(hide);
    setConfirmModal(modal);
  };

  function confirm() {
    let errs = {};
    Object.keys(refs)
      .reverse()
      .forEach(function (key) {
        if (!values[key] || values[key] === "") {
          errs[key] = "This field is required";
          refs[key].current.focus();
        }
      });
    setErrors(errs);
    if (Object.entries(errs).length == 0) {
      confirmRoutingAlert(
        ChangeConfirmModal,
        submit,
        values.accountNumber,
        setErrors,
        refs.accountNumber
      );
    }
  }

  return (
    <>
      {confirmModal}
      <div>
        Ensure you enter bank details correctly. {appName} cannot guarantee
        recovery of funds if bank details are entered incorrectly.
      </div>
      <div className="manual-bank-form-wrapper">
        <img src={Cheque} alt="Cheque image" className="manual-bank-cheque" />
        <Form>
          <ManualContactBankForm
            values={values}
            setValues={setValues}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            errors={errors}
            refs={refs}
            hideAccountNumber={hideAccountNumber}
          />
          <div className="manual-bank-form-button-wrapper">
            <Button block color="primary" type="button" onClick={confirm}>
              Add Account
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default withApiValue(NewContactBankAccount, config, () => null);

NewContactBankAccount.propTypes = {
  done: PropTypes.func,
  company: PropTypes.object,
  kyc: PropTypes.object,
  contact: PropTypes.shape({
    identifier: PropTypes.string,
  }),
};
