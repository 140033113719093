import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import PaymentDetailsForm from "../../../../../components/forms/PaymentDetailsForm";

function DebitDetailsStep() {
  const history = useHistory();
  const [state, setState] = useSimpleContext();

  function submit(values, contactAccount) {
    setState({
      ...state,
      ...values,
      contactAccount,
    });
    history.push("deposit-account", {
      ...history.location.state,
      currStep: 4,
    });
  }

  return (
    <Fragment>
      <PaymentDetailsForm contactType="customer" submit={submit} />
    </Fragment>
  );
}

export default DebitDetailsStep;
