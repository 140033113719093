import React, { Fragment, useEffect, useState } from "react";
// import BankError from "../../../assets/img/checkout/Bank-Error.png";
import useForm from "../../../hooks/UseForms";
import { AddBankAccountValidation } from "../../../modules/form_validation/AddBankAccountValidation";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { Button, Col, Container, Form, Row } from "reactstrap";
import FieldGenerator from "../../../components/forms/FieldGenerator";
import { updateBankAccountKyc } from "../../../api_client/mutations/bankAccount";
import useGQL from "../../../api_client/UseGQL";
import PropTypes from "prop-types";
import { trackEvent } from "../../../modules/analytics";

function UpdateKyc(props) {
  const { values, handleChange, handleSubmit, errors } = useForm(
    submit,
    AddBankAccountValidation
  );
  const [state, setState] = useSimpleContext();
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();

  useEffect(() => {
    if (state.transaction) {
      if (state.transaction.direction === "CREDIT") {
        trackEvent(
          "creditUpdateKyc",
          {
            transactionId: state.transaction.identifier,
            companyId: state.transaction.company?.identifier,
            contactId: state.transaction.contact.identifier,
          },
          gqlHooks
        );
      } else {
        trackEvent(
          "debitUpdateKyc",
          {
            transactionId: state.transaction.identifier,
            companyId: state.transaction.company?.identifier,
            contactId: state.transaction.contact.identifier,
          },
          gqlHooks
        );
      }
    } else {
      trackEvent(
        "recurringUpdateKyc",
        {
          recurringId: state.recurringPlan.identifier,
          companyId: state.recurringPlan.company.identifier,
          contactId: state.recurringPlan.contact.identifier,
        },
        gqlHooks
      );
    }
  }, []);

  async function submit() {
    setLoading(true);
    let input = {
      kyc: { ...values },
      bankAccountId: state.bankAccount.identifier,
    };
    let output = {
      bankAccount: {
        ["... on PublicBankAccountObject"]: {
          identifier: true,
          missingKyc: true,
          state: true,
          institutionNumber: true,
          institution: true,
          accountNumber: true,
          title: true,
          accountType: true,
        },
      },
    };

    const response = await updateBankAccountKyc(input, output, gqlHooks);
    if (response && response.bankAccount) {
      setState({
        ...state,
        bankAccount: { ...state.bankAccount, ...response.bankAccount },
      });
      props.setBankMethod("connected");
    }
    setLoading(false);
  }

  return (
    <Fragment>
      <Container className="bg-default rounded-top">
        <Row>
          <Col md="8" lg="8" className="m-auto pt-5">
            {/* <img
              className="ml-auto mr-auto d-block"
              style={{ maxWidth: "270px" }}
              src={BankError}
              alt="Bank Connection Error"
            /> */}
          </Col>
        </Row>
      </Container>
      <Container className="payment-secondary-action bg-default">
        <Row>
          <Col md="8" lg="8" className="m-auto">
            <h3 className="text-white pb-0 mb-2">Almost there!</h3>
            <p className="text-white text-center text-lg mb-3">
              We need a few more details to authorize your account.
            </p>
            <div
              style={{ background: "#535353" }}
              className="p-3 rounded justify-content-between d-flex mb-4 text-white"
            >
              <span>{state?.bankAccount?.institution}</span>
              <span>{state?.bankAccount?.accountNumber}</span>
            </div>
            <h3 className="text-white pb-0 mb-1 pt-3">Required details</h3>
            <Form onSubmit={handleSubmit}>
              <FieldGenerator
                fields={state.bankAccount.missingKyc}
                handleChange={handleChange}
                errors={errors}
                values={values}
                white
              />
              <Button
                type="submit"
                disabled={loading}
                block
                className="btn-primary btn-lg mb-2"
              >
                Submit info
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default UpdateKyc;

UpdateKyc.propTypes = {
  setBankMethod: PropTypes.func,
};
