import {
  MAX_LENGTH_SHORT_NAME,
  PASSWORD_LENGTH,
  PHONE_ERROR,
  verifyLength,
  verifyPhone,
  verifyStatementNames,
} from "../Helpers";

export function registerFormValidation(values, errors) {
  if (values.companyName) {
    if (
      verifyStatementNames(values.companyName) ||
      verifyLength(values.companyName, MAX_LENGTH_SHORT_NAME)
    ) {
      errors.companyName =
        "Company name must be less than 15 characters and only use valid characters";
    } else {
      delete errors.companyName;
    }
  }

  if (values.password) {
    if (!verifyLength(values.password, PASSWORD_LENGTH)) {
      errors.password = "Password must be greater than 8 Characters";
    } else {
      delete errors.password;
    }
  }

  if (values.phone) {
    if (!verifyPhone(values.phone)) {
      errors.phone = PHONE_ERROR;
    } else {
      delete errors.phone;
    }
  }

  if (values.phone === "") {
    delete errors.phone;
  }

  if (values.password === "") {
    delete errors.password;
  }

  delete errors.accessCode;
  delete errors.email;
  return errors;
}
