import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import PropTypes from "prop-types";
import {
  generalMessages,
  loadingMessages,
  loadingMessagesNoBlank,
  loadingURLS,
} from "../../modules/Helpers";

export function CustomLoader(props) {
  const messages = props.general
    ? generalMessages
    : props.noBlankMessage
    ? loadingMessagesNoBlank
    : loadingMessages;
  const [loadingMessage, setLoadingMessage] = useState(messages[0]);
  const [index, setIndex] = useState(1);
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    if (index == 5) {
      setFailed(true);
      return;
    }
    if (index == 4) {
      const timer = setTimeout(() => {
        setLoadingMessage(messages[index % messages.length]);
      }, 180000);
      setIndex(index + 1);
      return () => clearTimeout(timer);
    }
    const timer = setTimeout(() => {
      setLoadingMessage(messages[index % messages.length]);
    }, props.timoutTime);
    setIndex(index + 1);
    return () => clearTimeout(timer);
    // eslint-disable-next-line no-unused-vars
  }, [loadingMessage]);
  return (
    <div className="d-flex align-items-center justify-content-center flex-column mt-3 ml-auto mr-auto">
      {failed ? (
        ""
      ) : (
        <ClipLoader
          size={props.size ? props.size : 60}
          loading
          color="#8125D2"
        />
      )}
      <br />
      <h4 className={`text-default text-center ${props.customClass}`}>
        {loadingMessage}
      </h4>
      {props.general ? (
        ""
      ) : index == 5 ? (
        <i>
          Check connectivity status{" "}
          <a href={loadingURLS[0]} target="_blank" rel="noopener noreferrer">
            here
          </a>{" "}
        </i>
      ) : (
        <i>
          We use Flinks to connect bank accounts. For more on Flinks, click{" "}
          <a href={loadingURLS[1]} target="_blank" rel="noopener noreferrer">
            here
          </a>
        </i>
      )}
    </div>
  );
}
CustomLoader.propTypes = {
  size: PropTypes.number,
  message: PropTypes.string,
  customClass: PropTypes.string,
  noBlankMessage: PropTypes.bool,
  timoutTime: PropTypes.number,
};

CustomLoader.defaultProps = {
  noBlankMessage: false,
  timoutTime: 15000,
};
