import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import defaultImage from "../../assets/img/Company-Placeholder.png";
import { useHistory } from "react-router";
import { useGQLContext } from "../../api_client/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { withApiValue } from "../../modules/WithApiValue";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const config = {
  company: { displayImage: true, name: true, fullName: true },
};

function MobileNavOverlay(props) {
  const history = useHistory();
  const [, gqlDispatch] = useGQLContext();

  function changeRoute(route) {
    history.push(route);
    props.toggle();
  }

  return (
    <Fragment>
      <Dialog
        fullScreen
        className="modal-overlay"
        open={props.isOpen}
        onClose={props.toggle}
        TransitionComponent={Transition}
      >
        <div className="overlay-header">
          <div>
            <img
              width={25}
              height={25}
              src={
                props.company?.displayImage
                  ? props.company.displayImage
                  : defaultImage
              }
              alt="Company Logo"
            />
            <span
              style={{ lineHeight: "30px" }}
              className="ml-2 font-weight-600"
            >
              {props.company?.fullName ? (
                <>
                  {props.company?.fullName.length > 30 ? (
                    <span>{`${props.company?.fullName.substring(
                      0,
                      30
                    )}...`}</span>
                  ) : (
                    props.company?.fullName
                  )}
                </>
              ) : (
                <span>{`${props.company?.name.substring(0, 30)}...`}</span>
              )}
            </span>
          </div>
          <div>
            <Button
              aria-hidden={true}
              className="btn-link details-close-btn mt-0"
              data-dismiss="modal"
              type="button"
              onClick={props.toggle}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </div>
        <Nav vertical className="border-bottom pb-4 mb-4">
          <NavItem>
            <NavLink onClick={() => changeRoute("/admin/payments")}>
              Payments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => changeRoute("/admin/plans")}>Plans</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => changeRoute("/admin/contacts")}>
              Contacts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => changeRoute("/admin/settings")}>
              Settings
            </NavLink>
          </NavItem>
        </Nav>
        <Nav vertical>
          <NavItem>
            <NavLink onClick={() => changeRoute("/admin/settings#company")}>
              Edit company
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => changeRoute("/admin/settings#profile")}>
              Edit profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="text-danger"
              onClick={() => gqlDispatch({ type: "logout" })}
            >
              Sign out
            </NavLink>
          </NavItem>
        </Nav>
      </Dialog>
    </Fragment>
  );
}

export default withApiValue(MobileNavOverlay, config, () => null);

MobileNavOverlay.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  company: PropTypes.object,
};
