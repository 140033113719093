/* eslint-disable react/prop-types */
import React from "react";

function ProfileIcon(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || "100%";
  const height = props.height || "100%";
  const title = props.title || "single 02";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <path d="M19,19h-6C7.486,19,3,23.486,3,29v0.65l0.594,0.264C3.786,29.999,8.375,32,16,32 s12.214-2.001,12.406-2.086L29,29.65V29C29,23.486,24.514,19,19,19z" />
        <path
          d="M16,17c4.579,0,8-4.751,8-9c0-4.411-3.589-8-8-8S8,3.589,8,8C8,12.249,11.421,17,16,17z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default ProfileIcon;
