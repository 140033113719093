import React from "react";
import {
  Badge,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import { convertToDate, getEmailStatus } from "../../modules/Helpers";

export function statusPillEmail(value, transaction) {
  // need to remove number and dashes from identifier in order for the dom call to work
  let identifier = transaction?.identifier;
  let statusDate = transaction?.states[0].createdAt;
  let width = 80;
  let status = getEmailStatus(value, transaction?.direction);
  identifier = identifier?.replace(/-/g, "");
  identifier = identifier?.replace(/[0-9]/g, "hh");

  // API spelling error "initalized"
  if (value === "initalized") {
    // Email status - initialized
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="info"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "open") {
    // Email status - open
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="info"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "click") {
    // Email status - click
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="info"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "processed") {
    // Email status - processed
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="info"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "dropped") {
    // Email status - dropped
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="warning"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "delivered") {
    // Email status - delivered (but displayed as "Email sent" to minimize user confusion)
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="info"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "deferred") {
    // Email status - deferred
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="info"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "bounce") {
    // Email status - bounce
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="warning"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "blocked") {
    // Email status - blocked
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="warning"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === "spamreport") {
    // Email status - spamreport
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
        >
          <PopoverHeader>
            {status?.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status?.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="warning"
          className="mb-0"
          style={{ minWidth: width, cursor: "default" }}
          pill
        >
          {status?.value}
        </Badge>
      </React.Fragment>
    );
  }
}
