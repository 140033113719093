import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function login(input, output, hooks) {
  const query = buildMutation(input, output, "login");
  return callMutation(query, hooks);
}

login.propTypes = {
  input: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function signup(input, output, hooks) {
  const query = buildMutation(input, output, "signup");
  return callMutation(query, hooks);
}

signup.propTypes = {
  input: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string.isRequired,
    accessCode: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function signupFromInvite(input, output, hooks) {
  const query = buildMutation(input, output, "signupFromInvite");
  return callMutation(query, hooks);
}

signupFromInvite.propTypes = {
  input: PropTypes.shape({
    authToken: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    securityPin: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function confirmLogin(input, output, hooks) {
  const query = buildMutation(input, output, "exchangeMfaForJwt");
  return callMutation(query, hooks);
}

confirmLogin.propTypes = {
  input: PropTypes.shape({
    mfaCode: PropTypes.string.isRequired,
    loginToken: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function resendMFACode(input, output, hooks) {
  const query = buildMutation(input, output, "resendMfa");
  return callMutation(query, hooks);
}

resendMFACode.propTypes = {
  input: PropTypes.shape({
    loginToken: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function verifyEmail(input, output, hooks) {
  const query = buildMutation(input, output, "verifyEmail");
  return callMutation(query, hooks);
}

verifyEmail.propTypes = {
  input: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    emailId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function resendVerificationEmail(input, output, hooks) {
  const query = buildMutation(input, output, "resendVerificationEmail");
  return callMutation(query, hooks);
}

resendVerificationEmail.propTypes = {
  input: PropTypes.shape({
    emailId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function resetPassword(input, output, hooks) {
  const query = buildMutation(input, output, "resetPassword");
  return callMutation(query, hooks);
}

resetPassword.propTypes = {
  input: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function confirmPasswordReset(input, output, hooks) {
  const query = buildMutation(input, output, "confirmPasswordReset");
  return callMutation(query, hooks);
}

confirmPasswordReset.propTypes = {
  input: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    passwordToken: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
