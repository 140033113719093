import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import TokenModalBodyCreate from "./add_api_token/TokenModalBodyCreate";
import TokenModalBodyShowValue from "./add_api_token/TokenModalBodyShowValue";

function AddApiTokenModal(props) {
  const [state, setState] = useState({
    token: undefined,
    loading: false,
  });

  // this ugly hack is needed until we
  //  update to react 18 since async
  //  state updates not batched in < 18
  let curState = state;
  function updateState(updated) {
    curState = { ...curState, ...updated };
    setState(curState);
  }

  return (
    <>
      {!state.token ? (
        <Modal isOpen={true} toggle={props.toggle}>
          <TokenModalBodyCreate
            isLoading={state.loading}
            setLoading={(loading) => updateState({ loading: loading })}
            setToken={(identifier, value) =>
              updateState({ token: { identifier, value } })
            }
            toggle={props.toggle}
          />
        </Modal>
      ) : (
        <Modal isOpen={true} backdrop="static" keyboard={false}>
          <TokenModalBodyShowValue
            token={state.token.value}
            toggle={props.toggle}
            identifier={state.token.identifier}
          />
        </Modal>
      )}
    </>
  );
}

export default AddApiTokenModal;

AddApiTokenModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};
