import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import SearchIcon from "../../assets/icons/SearchIcon";
import useForm from "../../hooks/UseForms";
import { amountParser, amountPattern } from "modules/Helpers";

function CustomTableSearch({ setFilters, filters, disableAmount }) {
  const { values, setValues, handleChange } = useForm(
    null,
    (values, errors) => {
      return errors;
    }
  );

  const [searchValue, setSearchValue] = useState(null);
  const [gteValue, setGteValue] = useState(null);
  const [lteValue, setLteValue] = useState(null);

  useEffect(() => {
    if (values.search && !disableAmount) {
      const { gte, lte } = amountParser(values.search);
      setGteValue(gte);
      setLteValue(lte);
    } else if (gteValue || lteValue) {
      setGteValue(undefined);
      setLteValue(undefined);
    }
  }, [values]);

  useEffect(() => {
    if (values.search) {
      let search = values.search;
      if (!disableAmount) {
        search = search.replace(amountPattern, "");
      }
      search = search.replace(/[<>]/, "").trim();
      if (search) {
        setSearchValue(search);
      } else {
        setSearchValue(undefined);
      }
    } else {
      setSearchValue(undefined);
    }
  }, [values]);

  useEffect(() => {
    if (searchValue) {
      const timeoutId = setTimeout(
        () =>
          setFilters({
            ...filters,
            search: searchValue,
          }),
        500
      );
      return () => clearTimeout(timeoutId);
    } else {
      setFilters({
        ...filters,
        search: undefined,
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (lteValue || gteValue) {
      const timeoutId = setTimeout(
        () =>
          setFilters({
            ...filters,
            amountLte: lteValue?.toString(),
            amountGte: gteValue?.toString(),
          }),
        500
      );
      return () => clearTimeout(timeoutId);
    } else {
      setFilters({
        ...filters,
        amountLte: undefined,
        amountGte: undefined,
      });
    }
  }, [lteValue, gteValue]);

  return (
    <Fragment>
      <div className="d-flex flex-grow-1 custom-search-container">
        <InputGroup className={`input-group-focus custom-search-input rounded`}>
          <InputGroupAddon
            addonType="prepend"
            className="custom-search-input mt-0 rounded"
          >
            <InputGroupText className="custom-search-input">
              <SearchIcon
                fill={"gray"}
                width={15}
                height={15}
                strokewidth={6}
              />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            className="custom-search-input mt-0 shadow-none"
            type="text"
            name="search"
            onChange={handleChange}
            value={values.search || ""}
            placeholder="Search fields"
          />
          <InputGroupAddon
            onClick={() => setValues({ search: "" })}
            addonType="append"
            className={`custom-search-input mt-0 append-clear-container ${
              (values.search === "" || !values.search) && "hide-close"
            }`}
          >
            <i className="tim-icons icon-simple-remove" />
          </InputGroupAddon>
        </InputGroup>
      </div>
    </Fragment>
  );
}

export default CustomTableSearch;

CustomTableSearch.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  disableAmount: PropTypes.bool,
};
