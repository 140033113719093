import React, { useContext, useReducer, useState } from "react";
import Cookies from "js-cookie";
import TokenStateGateway from "../gateways/TokenStateGateway";
import {
  DEMO,
  DEVELOPMENT,
  ENV,
  GRAPHQL,
  LOCAL,
  PRODUCTION,
  STAGING,
} from "../modules/Helpers";

export const setToken = (token) => {
  let time = 8 / 24;
  if (ENV === PRODUCTION || ENV === DEVELOPMENT) {
    Cookies.set("token", token, { expires: time, secure: true });
  } else {
    Cookies.set("token", token, { expires: 8 });
  }
};

export const getToken = () => {
  if (Cookies.get("token")) {
    return Cookies.get("token");
  } else {
    return "";
  }
};

export const deleteToken = () => {
  if (Cookies.get("token")) {
    Cookies.remove("token");
  }
  if (Cookies.get("user")) {
    Cookies.remove("user");
  }
};

export const gqlReducer = (state, action) => {
  if (action.type === "setToken") {
    setToken(action.token);
    return {
      ...state,
      token: action.token,
    };
  } else if (action.type === "logout") {
    deleteToken();
    return { ...state, token: "", fieldErrors: {} };
  } else if (action.type === "setFieldErrors") {
    return {
      ...state,
      fieldErrors: action.fieldErrors,
    };
  } else if (action.type === "refresh") {
    return {
      ...state,
      refresh: action.refresh,
    };
  } else {
    return state;
  }
};

const GQLContext = React.createContext();

export const useGQLContext = () => useContext(GQLContext);

const resolveHost = () => {
  switch (ENV) {
    case PRODUCTION:
      return "https://api.jetpay.baselinepayments.com/graphql/";
    case LOCAL:
      return "http://localhost:8000/graphql/";
    case GRAPHQL:
      return "https://api.dev2.mazumago.com/graphql/";
    case STAGING:
      return "https://api.staging.mazumago.com/graphql/";
    case DEMO:
      return "https://api.demo.jetpay.baselinepayments.com/graphql/";
    case DEVELOPMENT:
    default:
      return "https://api.dev.jetpay.baselinepayments.com/graphql/";
  }
};

export const host = resolveHost();

// eslint-disable-next-line react/prop-types
function GQLProvider({ children }) {
  let token = getToken();
  const [alert, setAlert] = useState(null);
  return (
    <GQLContext.Provider
      value={useReducer(gqlReducer, {
        token: token,
        fieldErrors: {},
        setAlert: setAlert,
        refresh: false,
      })}
    >
      <TokenStateGateway>
        {children}
        {alert}
      </TokenStateGateway>
    </GQLContext.Provider>
  );
}

export default GQLProvider;
