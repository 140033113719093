import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import React, { Fragment, useState } from "react";
import useGQL from "../../../../../../api_client/UseGQL";
import { Button, Col, Row } from "reactstrap";
import { calculateTotalAmount } from "../bulk_approval/ReviewBulkApproval";
import pickBy from "lodash.pickby";
import { createBatchCredit } from "../../../../../../api_client/mutations/accountingIntegration";
import BankDetail from "../../../../../../components/generic_details/BankDetail";
import { PulseLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import {
  formattedDollarValue,
  pluralize,
} from "../../../../../../modules/Helpers";
import { useAppValue } from "../../../../../../contexts/Context";

export const BulkCreditReview = () => {
  const [context] = useAppValue();
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const totalAmount = calculateTotalAmount(state.bulkCredits || []);
  const numTransactions = state.bulkCredits?.length || 0;
  const requiresApproval = !context.user?.isOwnerOrAdmin;
  const many = numTransactions > 1;

  async function submit() {
    setLoading(true);
    const creditBatch = state.bulkCredits.map((rawCredit) => {
      const credit = {
        amount: rawCredit.amount,
        statement: rawCredit.statement,
        note: rawCredit.note,
        contactName: rawCredit.contact.name,
        contactEmail: rawCredit.contact.email,
        toBankAccountId: rawCredit.toBankAccount,
      };
      // remove entries with undefined values
      return pickBy(credit, (creditProp) => creditProp);
    });
    const input = {
      creditBatch: creditBatch,
      fromBankAccountId: state.fromBankAccount.value,
    };
    const output = {
      numPaymentsCreated: true,
      numPaymentsFailed: true,
      validationErrors: {
        index: true,
        message: true,
      },
      totalAmountSent: true,
      transactions: {
        identifier: true,
      },
    };
    const response = await createBatchCredit(input, output, gqlHooks);
    setLoading(false);
    if (response?.validationErrors?.length) {
      /*const mockCreditErrors = [
        {
          index: "1",
          message: "Failed to post to bank",
        },
      ];
      */
      setState({
        ...state,
        creditErrors: [...response.validationErrors],
        //creditErrors: [...response.validationErrors, ...mockCreditErrors],
        totalAmountSent: response?.totalAmountSent,
        numPaymentsFailed: response?.numPaymentsFailed,
        numPaymentsCreated: response?.numPaymentsCreated,
        transactions: response?.transactions,
      });
      history.push("/bulk-payment/credit/create/failed-payments", {
        ...history.location.state,
        currStep: 4,
      });
      return;
    }
    if (response) {
      setState({
        ...state,
        totalAmountSent: response.totalAmountSent,
        transactions: response.transactions,
      });
      history.push("/bulk-payment/credit/create/complete", {
        ...history.location.state,
        currStep: 4,
      });
    }
  }

  return (
    <Fragment>
      <h2 className="text-center">
        Review {requiresApproval && `draft `}
        {pluralize("payment", many)}
      </h2>
      {requiresApproval && (
        <div className="new-payment-inner-content text-center pt-0">
          <span className="text-center">
            You&apos;re {requiresApproval ? "creating" : "sending"}{" "}
            {numTransactions} {pluralize("payment", many)} totalling{" "}
            <b>{formattedDollarValue(totalAmount)}</b>.{" "}
            {requiresApproval && (
              <>
                {pluralize(
                  <>
                    This is a <b>draft payment</b>
                  </>,
                  many,
                  <>
                    These are <b>draft payments</b>
                  </>
                )}{" "}
                and will require an additional approval.
              </>
            )}
          </span>
        </div>
      )}
      <div className="new-payment-inner-content">
        <h5 className="mb-2">Pay to</h5>
        <div className="review-payment">
          <Row>
            <Col>
              <span>Amount</span>
              <h3>{formattedDollarValue(totalAmount)}</h3>
            </Col>
            <Col>
              <span>Number of payments</span>
              <h3>{numTransactions}</h3>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Delivery method</span>
              <h5>Email</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Frequency</span>
              <h5>One time</h5>
            </Col>
          </Row>
          {/*TODO Can change the wording of this section to make it make sense?*/}
          {/*<hr />*/}
          {/*<Row>*/}
          {/*  <InitiateDateInfo*/}
          {/*    type={INITIATE}*/}
          {/*    isOpen={tooltipInitiatedIsOpen}*/}
          {/*    setOpen={setTooltipInitiatedIsOpen}*/}
          {/*  />*/}
          {/*  <DepositDateInfo*/}
          {/*    isOpen={tooltipDepositIsOpen}*/}
          {/*    setOpen={setTooltipDepositIsOpen}*/}
          {/*  />*/}
          {/*</Row>*/}
        </div>
        <h5 className="mb-2 mt-4">Pay from</h5>
        <div className="review-payment">
          <BankDetail
            description="Your bank account"
            institution={state.fromBankAccount.institution}
            label={state.fromBankAccount.label}
          />
        </div>
        <Button
          disabled={loading}
          onClick={submit}
          block
          type="button"
          className="btn-primary text-black mt-4"
        >
          {loading ? (
            <PulseLoader color="white" size={10} />
          ) : (
            <>
              {requiresApproval
                ? `Create draft ${pluralize("payment", many)}`
                : `Send ${pluralize("payment", many)}`}
            </>
          )}
        </Button>
      </div>
    </Fragment>
  );
};
