import React, { Fragment } from "react";
import PropTypes from "prop-types";

function PaymentDetailsHeader(props) {
  return (
    <Fragment>
      <div className={`payment-details-header ${props.customClass}`}>
        <div
          style={{
            backgroundImage: `url(${props.transaction.company?.displayImage})`,
          }}
          className={`${
            !props.transaction.company?.displayImage && "d-none"
          } payment-details-header-logo`}
        />
        <h4 className="ml-3 mb-0 font-weight-600">
          {props.transaction.company.fullName || props.transaction.company.name}
        </h4>
        <h4 className="ml-auto font-weight-400 mb-0 desktop">
          {props.transaction.direction === "CREDIT"
            ? "Payment"
            : "Payment Request"}
        </h4>
      </div>
    </Fragment>
  );
}

export default PaymentDetailsHeader;

PaymentDetailsHeader.propTypes = {
  transaction: PropTypes.object.isRequired,
  customClass: PropTypes.string,
};
