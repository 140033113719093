import React from "react";
// reactstrap components
import { Card, CardBody, CardTitle, Col, Container } from "reactstrap";
import PropTypes from "prop-types";
import CardText from "reactstrap/es/CardText";

export const PageNotAvailableError = ({ errorTitle, errorBodyText }) => (
  <>
    <div className="content">
      <Container>
        <Col className="ml-auto mr-auto" lg="4" md="6">
          <Card className="card-lock card-white text-center">
            <CardBody>
              <CardTitle tag="h2">
                {errorTitle || "Page not available"}
              </CardTitle>
              {errorBodyText && <CardText>{errorBodyText}</CardText>}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  </>
);

PageNotAvailableError.propTypes = {
  errorTitle: PropTypes.string,
  errorBodyText: PropTypes.string,
};
