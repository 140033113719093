import React, { Fragment, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { calcBillsPaymentTotal } from "../../../../../../modules/Helpers";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { createBatchCredit } from "../../../../../../api_client/mutations/accountingIntegration";
import useGQL from "../../../../../../api_client/UseGQL";
import { useHistory, useLocation } from "react-router";
import BankDetail from "../../../../../../components/generic_details/BankDetail";
import { withApiValue } from "../../../../../../modules/WithApiValue";
import PropTypes from "prop-types";
import ReviewBillsOverlay from "../../../../../../components/overlays/ReviewBillsOverlay";
import pickBy from "lodash.pickby";
import FailedBatchCreditModal from "../../../../../../components/modals/FailedBatchCreditModal";
import { PulseLoader } from "react-spinners";
import InitiateDateInfo, {
  INITIATE,
} from "../../../../../../components/generic_details/InitiateDateInfo";
import DepositDateInfo from "../../../../../../components/generic_details/DepositDateInfo";

const config = {
  company: {
    railzConnection: {
      serviceName: true,
    },
  },
};

function CreditReviewBillPaymentStep(props) {
  const location = useLocation();
  const history = useHistory();
  const gqlHooks = useGQL();
  const [state, setState] = useSimpleContext();
  const [loading, setLoading] = useState(false);
  const [tooltipInitiatedIsOpen, setTooltipInitiatedIsOpen] = useState(false);
  const [tooltipDepositIsOpen, setTooltipDepositIsOpen] = useState(false);
  const [reviewBillsOverlay, setReviewBillsOverlay] = useState(false);
  const [failedBatchCreditModal, setFailedBatchCreditModal] = useState(false);

  async function submit() {
    setLoading(true);
    const creditBatch = state.bills.map((bill) => {
      const dueDate = new Date(bill.dueDate?.value).toISOString();
      const credit = {
        railzBillId: bill.billId?.value,
        railzVendorId: bill.vendorId?.value,
        amount: bill.amount?.value,
        statement: bill.statement?.value,
        note: bill.note?.value,
        contactName: bill.contactName?.value,
        contactEmail: bill.contactEmail?.value,
        dueDate: dueDate,
        totalAmount: bill.totalAmount?.value,
        amountDue: bill.amountDue?.value,
        toBankAccountId: bill.toBankAccountId?.value,
      };
      // remove entries with undefined values
      return pickBy(credit, (creditProp) => creditProp);
    });

    const input = {
      creditBatch: creditBatch,
      fromBankAccountId: state.fromBankAccount.value,
      importedFromRailz: true,
    };
    const output = {
      batchTransactionId: true,
      totalAmountSent: true,
      failedTransactions: {
        identifier: true,
        railzBillId: true,
        railzVendorId: true,
        amount: true,
        statement: true,
        note: true,
        contactName: true,
        contactEmail: true,
        toBankAccountId: true,
      },
    };
    const response = await createBatchCredit(input, output, gqlHooks);
    setLoading(false);
    if (response?.failedTransactions.length === state.bills.length) {
      setState({
        failedTransactions: response.failedTransactions,
        ...state,
      });
      setFailedBatchCreditModal(!failedBatchCreditModal);
    } else if (
      response?.failedTransactions.length > 0 &&
      response?.failedTransactions.length < state.bills.length
    ) {
      setState({
        failedTransactions: response.failedTransactions,
        totalAmountSent: response.totalAmountSent,
        ...state,
      });
      history.push("payment-warning-success", {
        ...location.state,
        currStep: 6,
      });
    } else if (response) {
      history.push("payment-success", {
        ...location.state,
        currStep: 6,
      });
    }
  }

  return (
    <Fragment>
      <ReviewBillsOverlay
        isOpen={reviewBillsOverlay}
        toggle={() => setReviewBillsOverlay(!reviewBillsOverlay)}
      />
      {failedBatchCreditModal && (
        <FailedBatchCreditModal
          failedTransactions={state.failedTransactions}
          toggle={() => setFailedBatchCreditModal(!failedBatchCreditModal)}
          isOpen={true}
        />
      )}
      <h2 className="text-center">Review payment</h2>
      <div className="new-payment-inner-content">
        <h5 className="mb-2">Pay to</h5>
        <div className="review-payment">
          <Row>
            <Col>
              <span>Amount</span>
              <h3>{calcBillsPaymentTotal(state.bills)}</h3>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Bills</span>
              <h5>
                <Button
                  className="btn-link btn-primary p-0 m-0"
                  onClick={() => setReviewBillsOverlay(!reviewBillsOverlay)}
                >
                  View {state.bills.length} bill
                  {state.bills.length > 1 && "s"}
                </Button>
              </h5>
            </Col>
            <Col>
              <span>Imported from</span>
              <h5>{props.company.railzConnection.serviceName.capitalize()}</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Delivery method</span>
              <h5>Email</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Frequency</span>
              <h5>One time</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <InitiateDateInfo
              type={INITIATE}
              isOpen={tooltipInitiatedIsOpen}
              setOpen={setTooltipInitiatedIsOpen}
            />
            <DepositDateInfo
              isOpen={tooltipDepositIsOpen}
              setOpen={setTooltipDepositIsOpen}
            />
          </Row>
          {state.note && (
            <Fragment>
              <hr />
              <Row>
                <Col>
                  <span>Note to contact</span>
                  <h5>{state.note}</h5>
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
        <h5 className="mb-2 mt-4">Pay from</h5>
        <div className="review-payment">
          <BankDetail
            description="Your bank account"
            institution={state.fromBankAccount.institution}
            label={state.fromBankAccount.label}
          />
        </div>
        <Button
          disabled={loading}
          onClick={submit}
          block
          type="button"
          className="btn-success mt-4"
        >
          {loading ? <PulseLoader color="white" size={10} /> : "Send payment"}
        </Button>
      </div>
    </Fragment>
  );
}

export default withApiValue(CreditReviewBillPaymentStep, config);

CreditReviewBillPaymentStep.propTypes = {
  company: PropTypes.object,
};
