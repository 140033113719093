import { callGQL } from "./utils";
import { jsonToGraphQLQuery } from "json-to-graphql-query";

export function buildQuery(input, output, queryName, paginated = false) {
  let query = {
    query: {},
  };
  query.query[queryName] = {
    data: output,
    authorizationError: {
      message: true,
    },
    authenticationError: {
      message: true,
    },
    count: paginated,
  };
  Object.keys(input).forEach(
    (key) => input[key] === undefined && delete input[key]
  );
  if (Object.entries(input).length > 0) {
    query.query[queryName].__args = input;
  }
  return jsonToGraphQLQuery(query, { pretty: true })
    .replace(/"</g, "")
    .replace(/>"/g, "");
}

export async function callQuery(query, { context, errorHandler }) {
  let data = await callGQL(query, context.token ? `JWT ${context.token}` : "");
  return errorHandler(data);
}
