import React, { Fragment } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import LinkIcon from "../../../../../assets/icons/LinkIcon";
import MailIcon from "../../../../../assets/icons/MailIcon";

function DebitDeliveryMethodStep() {
  const history = useHistory();
  const [state, setState] = useSimpleContext();

  return (
    <Fragment>
      {" "}
      <h2 className="text-center">Select payment request delivery method</h2>
      <ButtonGroup
        style={{ maxWidth: "450px", marginTop: "11px" }}
        className="business-type-btn-wrapper flex-column"
      >
        <Button
          className="business-type-btn-custom mb-2"
          onClick={() => {
            history.push("review", {
              ...history.location.state,
              currStep: 6,
            });
            setState({ ...state, deliveryMethod: "Email" });
          }}
        >
          <div className="icon-text-wrapper">
            <div className="btn-custom-icon">
              <MailIcon width={40} height={40} fill="#8125D2" />
            </div>
            <div className="btn-custom-text">
              <h4>Email</h4>
              <span>Your contact will receive an email</span>
            </div>
          </div>
        </Button>
        <Button
          className="business-type-btn-custom"
          onClick={() => {
            history.push("review", {
              ...history.location.state,
              currStep: 6,
            });
            setState({ ...state, deliveryMethod: "Link" });
          }}
        >
          <div className="icon-text-wrapper">
            <div className="btn-custom-icon">
              <LinkIcon width={40} height={40} fill="#8125D2" />
            </div>
            <div className="btn-custom-text">
              <h4>Link</h4>
              <span>Include a link in an invoice or email</span>
            </div>
          </div>
        </Button>
      </ButtonGroup>
    </Fragment>
  );
}

export default DebitDeliveryMethodStep;

DebitDeliveryMethodStep.propTypes = {};
