/* eslint-disable react/prop-types */
import React from "react";
import debitRecurring from "assets/img/banks/debitRecurring.svg";
function RecurringIcon(props) {
  //const fill = props.fill || "currentColor";
  //const secondaryfill = props.secondaryfill || fill;
  // eslint-disable-next-line no-unused-vars
  //const strokewidth = props.strokewidth || 1;
  const width = props.width || "100%";
  const height = props.height || "100%";
  //const title = props.title || "event confirm";

  return (
    // <svg
    //   height={height}
    //   width={width}
    //   viewBox="0 0 64 64"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <title>{title}</title>
    //   <g fill={secondaryfill}>
    //     <path
    //       d="M32,12a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0v9A1,1,0,0,1,32,12Z"
    //       fill={fill}
    //     />
    //     <path
    //       d="M15,12a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0v9A1,1,0,0,1,15,12Z"
    //       fill={fill}
    //     />
    //     <path
    //       d="M49,12a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0v9A1,1,0,0,1,49,12Z"
    //       fill={fill}
    //     />
    //     <path
    //       d="M35.587,55H6a1,1,0,0,1-1-1V21a1,1,0,0,1,1-1H58a1,1,0,0,1,1,1V41.211a14.065,14.065,0,0,1,2,2.605V9a2,2,0,0,0-2-2H52v4a3,3,0,0,1-6,0V7H35v4a3,3,0,0,1-6,0V7H18v4a3,3,0,0,1-6,0V7H5A2,2,0,0,0,3,9V55a2,2,0,0,0,2,2H36.365A13.972,13.972,0,0,1,35.587,55Z"
    //       fill={fill}
    //     />
    //     <path d="M37,51A12,12,0,1,0,49,39,12,12,0,0,0,37,51Zm18.707-4.707a1,1,0,0,1,0,1.414l-8,8a1,1,0,0,1-1.414,0l-4-4a1,1,0,0,1,1.414-1.414L47,53.586l7.293-7.293A1,1,0,0,1,55.707,46.293Z" />
    //   </g>
    // </svg>
    <img src={debitRecurring} height={height} width={width} />
  );
}

export default RecurringIcon;
