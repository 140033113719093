import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import CreateDebit from "./debit/CreateDebit";
import CustomStepper from "../../../components/CustomStepper";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBan } from "@fortawesome/free-solid-svg-icons";
import WarningAlert from "../../../components/sweet_alert/WarningAlert";
import { useHistory } from "react-router";
import { SimpleContextProvider } from "../../../contexts/SimpleContext";
import CreateCredit from "./credit/CreateCredit";
import CreateInternal from "./internal/CreateInternal";
import * as Sentry from "@sentry/browser";
import { BulkCredit } from "./credit/bulk_payments/create_bulk_credit/BulkCredit";

function NewPayment() {
  const history = useHistory();
  const location = useLocation();
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(true);
  const lastStep =
    location.pathname === "/new-payment/debit/success" ||
    location.pathname === "/new-payment/credit/success" ||
    location.pathname === "/new-payment/credit/payment-success" ||
    location.pathname === "/new-payment/credit/payment-warning-success" ||
    location.pathname === "/new-payment/internal/success" ||
    location.pathname === "/bulk-payment/credit/create/complete" ||
    location.pathname === "/bulk-payment/credit/create/failed-payments";

  const tableLayout =
    location.pathname.includes("/bulk-payment/credit/create/details") ||
    location.pathname.includes("/bulk-payment/credit/create/failed-payments") ||
    location.pathname.includes("/new-payment/debit/bulk/details") ||
    location.pathname.includes("/new-payment/debit/bulk/failed-payments");

  useEffect(() => {
    if (!location.state) {
      history.push("/admin/payments");
    } else {
      setLoading(false);
    }
    try {
      Sentry.configureScope((scope) => scope.setTransactionName("New Payment"));
    } catch (e) {
      console.log("sentry not loaded");
    }
  }, []);

  function cancel() {
    WarningAlert(
      () => setAlert(null),
      setAlert,
      <Fragment>
        <span>You will lose all information entered.</span>
        <Button
          onClick={() => setAlert(null)}
          className="btn-simple btn-primary mt-3"
        >
          Continue payment
        </Button>
        <Button
          onClick={() => history.push("/admin/payments")}
          color="warning"
          className="btn-primary mt-3 ml-2"
        >
          Yes, cancel
        </Button>
      </Fragment>,
      "Are you sure?"
    );
  }

  if (loading) {
    return null;
  } else {
    return (
      <Fragment>
        <div className="new-payment-wrapper bg-white">
          <CustomStepper />
          {alert}
          <div className="d-flex justify-content-between">
            <div
              className={
                location?.state && (location?.state?.currStep === 1 || lastStep)
                  ? "invisible"
                  : undefined
              }
            >
              <Button
                type="button"
                className="btn btn-link font-weight-600 btn-links d-flex mt-2"
                onClick={() => history.goBack()}
                color={"primary"}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "8px", fontSize: "18px" }}
                  icon={faAngleLeft}
                />
                Back
              </Button>
            </div>
            <p className="mt-3 font-weight-400 text-muted">
              {location.pathname.includes("bulk") && "Bulk "}
              {location.pathname.includes("credit") && "Payable"}
              {location.pathname.includes("debit") && "Receivable"}
              {location.pathname.includes("internal") && "Internal Transfer"}
            </p>
            <div
              className={
                location.pathname.includes("success") ? "invisible" : ""
              }
            >
              <Button
                type="button"
                className="btn btn-link font-weight-600 btn-primary d-flex mt-2"
                onClick={cancel}
                color={"danger"}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "8px", fontSize: "18px" }}
                  icon={faBan}
                />
                Cancel
              </Button>
            </div>
          </div>
          <div
            className={
              tableLayout ? "bulk-payment-table-wrapper" : "new-payment-content"
            }
          >
            <SimpleContextProvider>
              <Switch>
                <Route
                  path="/new-payment/debit"
                  render={() => <CreateDebit />}
                />
                <Route
                  path="/new-payment/credit"
                  render={() => <CreateCredit />}
                />
                <Route
                  path="/new-payment/internal"
                  render={() => <CreateInternal />}
                />
                <Route
                  path="/bulk-payment/credit/create"
                  render={() => <BulkCredit />}
                />
              </Switch>
            </SimpleContextProvider>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default NewPayment;
