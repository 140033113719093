import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, FormFeedback, FormGroup, Input } from "reactstrap";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { CustomTooltip } from "../../../modules/Helpers";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import { useHistory } from "react-router";
import { checkSecurityAnswer } from "../../../api_client/mutations/contacts";
import useGQL from "../../../api_client/UseGQL";
import useForm from "../../../hooks/UseForms";
import { securityQuestionFormValidation } from "../../../modules/form_validation/SecurityQuestionFormValidation";
import { PulseLoader } from "react-spinners";

function CreditSecurityQuestion() {
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  let gqlHooks = useGQL();
  const [loading, setLoading] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const answerRef = useRef(null);
  const { values, handleChange, errors, handleSubmit } = useForm(
    confirm,
    securityQuestionFormValidation
  );

  useEffect(() => {
    if (state.transaction.company.settings.useSecurityQuestion) {
      answerRef.current.focus();
    }
  }, []);

  function confirm() {
    if (state.transaction.company.settings.useSecurityQuestion) {
      handleNext();
    } else {
      history.push("bank-transfer");
    }
  }

  function handleNext() {
    setLoading(true);
    let input = {
      supplierId: state.transaction.contact.identifier,
      securityAnswer: values.securityAnswer,
    };
    let output = {
      ok: true,
    };
    checkSecurityAnswer(input, output, gqlHooks).then((response) => {
      if (response) {
        if (response.ok) {
          setState({ ...state, securityAnswer: values.securityAnswer });
          history.push("bank-transfer");
        }
      }
      setLoading(false);
    });
  }

  return (
    <Fragment>
      {state.transaction.company.settings.useSecurityQuestion ? (
        <div className="text-center">
          <h4 className="mt-4 mb-2">
            Please answer the security question
            <CustomTooltip
              title={`Get started by answering this security question. Contact ${state.transaction.company.name} if you don't know the answer.`}
              placement="top"
              arrow
              open={tooltipIsOpen}
              onOpen={() => setTooltipIsOpen(true)}
              onClose={() => setTooltipIsOpen(false)}
              leaveTouchDelay={10000}
            >
              <Button
                onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                variant="contained"
                className="btn-link tooltip-button"
              >
                <i className="fas fa-info-circle" />
              </Button>
            </CustomTooltip>
          </h4>
          <p className="text-muted mb-3">
            &quot;{state.transaction.company.settings.securityQuestion}
            &quot;
          </p>
          <div className="security-question-input-wrapper">
            <form
              className="security-question-input-wrapper flex-grow-1"
              onSubmit={handleSubmit}
            >
              <div className="security-question-input">
                <FormGroup>
                  <Input
                    aria-label="Security question answer"
                    name="securityAnswer"
                    type="text"
                    className="bg-white"
                    innerRef={answerRef}
                    placeholder="Enter answer"
                    value={values.securityAnswer || ""}
                    onChange={handleChange}
                    invalid={!!errors.securityAnswer}
                    required
                  />
                  <FormFeedback className="mobile">
                    {errors.securityAnswer}
                  </FormFeedback>
                </FormGroup>
              </div>
              <Button
                className="btn-lg btn-primary"
                type="submit"
                disabled={
                  !values.securityAnswer &&
                  state.transaction.company.settings.useSecurityQuestion
                }
              >
                {loading ? (
                  <PulseLoader color="white" size={10} />
                ) : (
                  <>
                    <span className="mr-3 d-inline-block">Accept payment</span>
                    <div className="d-inline-block">
                      <ArrowRightIcon height={20} width={20} />
                    </div>
                  </>
                )}
              </Button>
            </form>
          </div>
          <div
            style={{ marginTop: "0.25rem", fontSize: "11px" }}
            className="text-danger text-left pl-4 desktop"
          >
            {errors.securityAnswer}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <Button
            className="btn-lg btn-primary mb-4"
            onClick={() => history.push("bank-transfer")}
          >
            <div className="d-flex align-items-center flex-grow-1">
              <span className="mr-3">Accept payment</span>
              <div>
                <ArrowRightIcon height={20} width={20} />
              </div>
            </div>
          </Button>
        </div>
      )}
    </Fragment>
  );
}

export default CreditSecurityQuestion;

CreditSecurityQuestion.propTypes = {};
