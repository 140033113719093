/* eslint-disable react/prop-types */
import React from "react";

function SecurityIcon(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || "100%";
  const height = props.height || "100%";
  const title = props.title || "privacy";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap="round"
        strokeWidth="2"
      >
        <rect height="7" width="10" fill="none" x="11" y="16" />
        <path d="M13,16V13a3,3,0,0,1,3-3h0a3,3,0,0,1,3,3v3" fill="none" />
        <path
          d="M28,19A12,12,0,0,1,4,19V5L16,2,28,5Z"
          fill="none"
          stroke={fill}
        />
      </g>
    </svg>
  );
}

export default SecurityIcon;
