import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";

function ConfirmModal(props) {
  return (
    <>
      <Modal
        autoFocus
        backdrop
        fade
        centered
        isOpen={props.isOpen}
        toggle={props.toggleModal}
      >
        <ModalBody className="pb-0">
          <Row>
            <Col sm="12">
              <Button close onClick={props.toggleModal} />
            </Col>
          </Row>
          <h4 className="text-center">{props.title}</h4>
          <p className="text-center text-muted mb-4">{props.message}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-link float-right"
            color="primary"
            onClick={props.toggleModal}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={props.confirmAction}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ConfirmModal;

ConfirmModal.propTypes = {
  toggleModal: PropTypes.func,
  confirmAction: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};

ConfirmModal.defaultProps = {};
