import React, { Fragment, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import PropTypes from "prop-types";
import { allContactTags } from "../../api_client/queries/contacts";
import useGQL from "../../api_client/UseGQL";

function TagsSelect(props) {
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2019; i--) {
      years.push({ value: i, label: i.toString() });
    }
    setYearOptions(years);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearOptions == []]);

  return (
    <Fragment>
      <CreatableSelect
        isMulti={props.isMulti}
        name="tags"
        className="react-select default"
        classNamePrefix="react-select"
        onChange={props.onChangeCallback}
        value={props.activeTags}
        options={yearOptions}
        isClearable
        placeholder={props.placeholder}
      />
    </Fragment>
  );
}

export default TagsSelect;

TagsSelect.propTypes = {
  activeTags: PropTypes.array,
  onChangeCallback: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
};

TagsSelect.defaultProps = {
  isMulti: true,
};
