import React, { Fragment, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {
  CustomTooltip,
  FREQUENCY_OPTIONS,
  MONTHLY,
  validateEndDate,
  validateStartDate,
} from "../../../../../modules/Helpers";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";

function DebitScheduleStep() {
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const [frequency, setFrequency] = useState({
    value: MONTHLY,
    label: "Monthly",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tooltipEndIsOpen, setTooltipEndIsOpen] = useState(false);

  function submit() {
    setState({
      ...state,
      frequency: frequency,
      startDate: startDate,
      endDate: endDate,
    });
    history.push("details", {
      ...history.location.state,
      currStep: 3,
    });
  }

  const validStartDate = (currentDate) => {
    return validateStartDate(currentDate, endDate);
  };

  const validEndDate = (currentDate) => {
    return validateEndDate(currentDate, startDate, endDate);
  };

  return (
    <Fragment>
      <h2 className="text-center">Schedule payment request frequency</h2>
      <div className="new-payment-inner-content">
        <Label>Frequency</Label>
        <FormGroup>
          <Select
            onChange={(option) => setFrequency(option)}
            className="react-select default"
            classNamePrefix="react-select"
            name="frequency"
            value={frequency}
            options={FREQUENCY_OPTIONS}
          />
        </FormGroup>
        <Row>
          <Col md="6">
            <Label>Start date</Label>
            <FormGroup>
              <ReactDatetime
                timeFormat={false}
                inputProps={{
                  placeholder: "First Payment Date",
                  required: true,
                }}
                isValidDate={validStartDate}
                onChange={(date) => setStartDate(date)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <Label>
              End date <span className="text-muted text-sm">(optional)</span>
            </Label>
            <CustomTooltip
              title="Leave blank if you want payments to continue until cancelled."
              placement="top"
              arrow
              open={tooltipEndIsOpen}
              onOpen={() => setTooltipEndIsOpen(true)}
              onClose={() => setTooltipEndIsOpen(false)}
              leaveTouchDelay={10000}
            >
              <Button
                onClick={() => setTooltipEndIsOpen(!tooltipEndIsOpen)}
                variant="contained"
                className="btn-link tooltip-button"
              >
                <i className="fas fa-info-circle" />
              </Button>
            </CustomTooltip>
            <FormGroup>
              <ReactDatetime
                timeFormat={false}
                inputProps={{ placeholder: "Termination Date" }}
                isValidDate={validEndDate}
                onChange={(date) => setEndDate(date)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button
          disabled={!frequency || !startDate}
          block
          color="primary"
          type="button"
          onClick={submit}
        >
          Proceed
        </Button>
      </div>
    </Fragment>
  );
}

export default DebitScheduleStep;

DebitScheduleStep.propTypes = {};
