/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import debitOneTime from "assets/img/banks/debitOneTime.svg";

function ArrowRightIcon(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || "100%";
  const height = props.height || "100%";
  const title = props.title || "arrow right";

  return (
    <svg
      height={height}
      width={width}
      className={props.className}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <path
          d="M42.293,11.879a1,1,0,0,0-1.414,0l-2.828,2.828a1,1,0,0,0,0,1.414L50.929,29H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H50.929L38.051,47.879a1,1,0,0,0,0,1.414l2.828,2.828a1,1,0,0,0,1.414,0L61.707,32.707a1,1,0,0,0,0-1.414Z"
          fill={fill}
        />
      </g>
    </svg>
    // <img
    //   src={debitOneTime}
    //   height={height}
    //   width={width}
    //   className={props.className}
    // />
  );
}

export default ArrowRightIcon;
