import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import ErrorIcon from "react-bootstrap-sweetalert/dist/components/ErrorIcon";
import { useHistory } from "react-router";

function FailedBatchCreditModal(props) {
  const history = useHistory();

  return (
    <Modal backdrop fade centered isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody className="mb-3">
        <Row>
          <Col sm="12">
            <Button close onClick={props.toggle} />
          </Col>
        </Row>
        <ErrorIcon />
        <h2 className="text-center mb-2">Payment Failure</h2>
        <p className="text-center text-muted mb-4">
          Your bill payment
          {props.failedTransactions.length > 1 && "s"} failed to send. Please
          retry importing. <br /> If the error persists contact support.
        </p>
        <Button
          className="btn-primary d-block m-auto"
          block
          onClick={() =>
            history.push("import", {
              ...history.location.state,
              currStep: 2,
            })
          }
        >
          Retry importing bill{props.failedTransactions.length > 1 && "s"}
        </Button>
        <Button
          onClick={() => history.push("/admin/payments")}
          block
          className="btn-primary btn-simple mt-3 mb-0"
        >
          Try again later, return to dashboard
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default FailedBatchCreditModal;

FailedBatchCreditModal.propTypes = {
  failedTransactions: PropTypes.array,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
