/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { CustomLoader } from "../../../components/loaders/CustomLoader";
import { useToasts } from "react-toast-notifications";
import useForm from "../../../hooks/UseForms";
import { contactFormValidation } from "../../../modules/form_validation/ContactFormValidation";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { getContact } from "../../../api_client/queries/contacts";
import useGQL from "../../../api_client/UseGQL";
import {
  setTagsContact,
  updateContact,
} from "../../../api_client/mutations/contacts";
import ContactInfoCard from "../../../components/cards/ContactInfoCard";
import ContactBankAccountCard from "../../../components/cards/ContactBankAccountCard";
import TransactionTable from "../../../components/tables/TransactionTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ContactActionsMobileDropdown from "../../../components/dropdowns/ContactActionsMobileDropdown";
import ContactActionsDesktop from "./ContactActionsDesktop";
import TagsSelect from "../../../components/custom_select/TagsSelect";

function ContactDetails(props) {
  const { values, setValues, handleChange, errors } = useForm(
    submit,
    contactFormValidation
  );
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState({});
  const [contactTags, setContactTags] = useState([]);
  const [editContact, setEditContact] = useState(false);
  let gqlHooks = useGQL();

  useEffect(() => {
    let tags = [];
    if (props.id) {
      setLoading(true);
      props.setBackdropClick(true);
      const identifier = props.id;
      let input = { contactId: identifier };
      let output = {
        createdAt: true,
        updatedAt: true,
        identifier: true,
        email: true,
        name: true,
        contactType: true,
        tags: true,
        bankAccounts: {
          createdAt: true,
          updatedAt: true,
          identifier: true,
          institution: true,
          accountNumber: true,
          title: true,
          state: true,
        },
        archived: true,
        transactions: {
          identifier: true,
        },
      };
      getContact(input, output, gqlHooks).then((response) => {
        if (response) {
          setContact(response.data);
          setFilters({ contactId: response.data.identifier });
          setValues({
            name: response.data.name,
            email: response.data.email,
          });
          response.data.tags.map((tag, key) => {
            tags.push({ value: key, label: tag });
          });
          setContactTags(tags);
          setLoading(false);
          props.setBackdropClick(false);
        } else {
          history.push({ search: "" });
          addToast("Could not load contact", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      });
    }
  }, [location.match]);

  if (loading) {
    return (
      <div className="content">
        <CustomLoader size={30} general={true} />
      </div>
    );
  }

  async function toggleArchive() {
    setLoading(true);
    props.setBackdropClick(true);
    let input;
    if (contact.archived === true) {
      input = {
        contactId: contact.identifier,
        archived: false,
      };
    } else {
      input = {
        contactId: contact.identifier,
        archived: true,
      };
    }

    let output = {
      contact: { archived: true },
    };

    const response = await updateContact(input, output, gqlHooks);
    if (response) {
      addToast(
        response.contact.archived === true
          ? "Contact archived"
          : "Contact activated",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      history.push({ search: "update=true" });
    } else {
      addToast(
        response.contact.archived === true
          ? "Something went wrong, could not archive contact"
          : "Something went wrong, could not activate contact",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
    setLoading(false);
    props.setBackdropClick(false);
  }

  async function submit() {
    setLoading(true);
    props.setBackdropClick(true);
    let input = {
      contactId: contact.identifier,
      name: values.name,
      email: values.email,
    };

    let output = {
      contact: { name: true, email: true },
    };

    const response = await updateContact(input, output, gqlHooks);
    if (response) {
      setContact({
        ...contact,
        name: response.contact.name,
        email: response.contact.email,
      });
      await updateTags();
    } else {
      addToast("Error updating contact", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setLoading(false);
    props.setBackdropClick(false);
  }

  async function updateTags() {
    setLoading(true);
    props.setBackdropClick(true);
    let tags = contactTags.map((tag) => {
      return tag.label;
    });
    let input = {
      contactId: contact.identifier,
      tags: tags,
    };

    let output = {
      contact: { tags: true },
    };

    const response = await setTagsContact(input, output, gqlHooks);
    if (response) {
      addToast("Contact updated", {
        appearance: "success",
        autoDismiss: true,
      });
      setContact({ ...contact, tags: response.contact.tags });
      setEditContact(false);
      history.goBack();
      history.push({ search: `${location.search}&update=true` });
    } else {
      addToast("Error updating contact", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setLoading(false);
    props.setBackdropClick(false);
  }

  function onBankAccountDeleted(bankAccountId) {
    setContact({
      ...contact,
      bankAccounts: contact.bankAccounts.filter(
        (item) => item.identifier !== bankAccountId
      ),
    });
  }

  function onBankAccountAdded() {
    history.push({ search: history.search + "&update=true" });
  }

  const copyIdToClipboard = () => {
    addToast("Identifier copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  function onSelectTags(value) {
    if (value === null) {
      value = [];
    }
    setContactTags(value);
  }

  return (
    <>
      <div className="content">
        {editContact ? (
          <>
            <Row>
              <Col>
                <Button
                  className="btn-link details-close-btn"
                  disabled={loading}
                  onClick={() => setEditContact(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <h3 className="font-weight-600 mt-1 mb-3">Edit contact</h3>
              </Col>
            </Row>
            <Label className="mt-2">Name</Label>
            <FormGroup>
              <Input
                type="text"
                name="name"
                placeholder="Add contact name..."
                onChange={handleChange}
                value={values.name || ""}
                invalid={!!errors.name}
              />
              <FormFeedback>{errors.name}</FormFeedback>
            </FormGroup>
            <Label>Email</Label>
            <FormGroup>
              <Input
                type="text"
                name="email"
                placeholder="Add contact email..."
                onChange={handleChange}
                value={values.email || ""}
                invalid={!!errors.email}
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <Label>Tags</Label>
            <FormGroup>
              <TagsSelect
                activeTags={contactTags}
                onChangeCallback={onSelectTags}
                placeholder="Type and press enter"
              />
            </FormGroup>
            <Button
              block
              color="primary"
              size="md"
              className="mb-2"
              onClick={submit}
              disabled={loading}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <p className="text-muted mb-0 mt-2">
                  Contact{" "}
                  <span className="text-warning">
                    {contact.archived && "Archived"}
                  </span>
                </p>
              </Col>
              <Col>
                <Button
                  className="btn-link details-close-btn"
                  disabled={loading}
                  onClick={() => history.goBack()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <div className="details-desktop-actions">
                  <ContactActionsDesktop
                    contact={contact}
                    editContact={() => setEditContact(true)}
                    toggleArchive={toggleArchive}
                  />
                </div>
                <div className="details-mobile-actions">
                  <ContactActionsMobileDropdown
                    contact={contact}
                    editContact={() => setEditContact(true)}
                    toggleArchive={toggleArchive}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <FontAwesomeIcon
                  style={{ fontSize: "30px" }}
                  className="pull-left mr-2"
                  icon={faUserCircle}
                />
                <div className="pull-left">
                  <h3 className="font-weight-600 d-inline-block mb-0">
                    {contact.name === null ? (
                      <span className="text-muted font-italic">
                        Name Unassigned
                      </span>
                    ) : (
                      contact.name
                    )}
                  </h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ContactInfoCard
                  contact={contact}
                  tags={contactTags}
                  selectTags={onSelectTags}
                  updateTags={updateTags}
                  copyIdToClipboard={copyIdToClipboard}
                />
              </Col>
            </Row>
            <ContactBankAccountCard
              contact={contact}
              setBackdropClick={props.setBackdropClick}
              onBankAccountDeleted={onBankAccountDeleted}
              onBankAccountAdded={onBankAccountAdded}
            />
            {contact.transactions.length !== 0 && (
              <>
                <Row>
                  <Col>
                    <h4 className="mt-2 mb-2">Payments</h4>
                  </Col>
                </Row>
                <TransactionTable filters={filters} cards={true} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ContactDetails;
ContactDetails.propTypes = {
  id: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  setBackdropClick: PropTypes.func,
};
