import React, { Fragment } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import AddIcon from "../../assets/icons/AddIcon";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../contexts/SimpleContext";

function CreateAdditionalPaymentDropdown() {
  const history = useHistory();
  const [, setState] = useSimpleContext();

  return (
    <Fragment>
      <UncontrolledDropdown className="d-inline-block mb-5">
        <DropdownToggle
          className="custom-dropdown-button btn-link btn-links font-weight-600 ml-0 pl-3"
          data-toggle="dropdown"
        >
          <AddIcon /> Create another payment
        </DropdownToggle>
        <DropdownMenu className="custom-dropdown-menu dropdown-menu-lg">
          <DropdownItem
            onClick={() => {
              history.push("/new-payment/debit/frequency", {
                currStep: 1,
                steps: 8,
              });
              setState({});
            }}
          >
            <span className="font-weight-600">Receive</span>
          </DropdownItem>
          <hr />
          <DropdownItem
            onClick={() => {
              history.push("/new-payment/credit/details", {
                currStep: 1,
                steps: 6,
              });
              setState({});
            }}
          >
            <span className="font-weight-600">Send</span>
          </DropdownItem>
          <hr />
          <DropdownItem
            onClick={() => {
              history.push("/new-payment/internal/details", {
                currStep: 1,
                steps: 4,
              });
              setState({});
            }}
          >
            <span className="font-weight-600">Transfer</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Fragment>
  );
}

export default CreateAdditionalPaymentDropdown;
