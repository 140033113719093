import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import { formattedDollarValue } from "../../../../../modules/Helpers";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import { createCredit } from "../../../../../api_client/mutations/transaction";
import useGQL from "../../../../../api_client/UseGQL";
import { useHistory, useLocation } from "react-router";
import BankDetail from "../../../../../components/generic_details/BankDetail";
import DepositDateInfo from "../../../../../components/generic_details/DepositDateInfo";
import InitiateDateInfo, {
  INITIATE,
} from "../../../../../components/generic_details/InitiateDateInfo";
import { useAppValue } from "../../../../../contexts/Context";
import NotesToContact from "components/text/NoteToContact";

function CreditReviewStep() {
  const [context] = useAppValue();
  const location = useLocation();
  const history = useHistory();
  const gqlHooks = useGQL();
  const [state] = useSimpleContext();
  const [loading, setLoading] = useState(false);
  const [tooltipInitiatedIsOpen, setTooltipInitiatedIsOpen] = useState(false);
  const [tooltipDepositIsOpen, setTooltipDepositIsOpen] = useState(false);

  // TODO context is only loaded in the dashboard, so if a user is directed here from an email, they might not have it
  const requiresApproval = !context.user?.isOwnerOrAdmin;
  const notesAlias = context.user.company.settings.notesAlias;

  useEffect(() => {
    if (
      history.action === "POP" &&
      history.location.pathname === "/new-payment/credit/review"
    ) {
      history.replace("/admin/payments");
    }
  }, [history]);

  async function submit() {
    setLoading(true);
    let input;
    if (state.toBankAccount.value) {
      input = {
        amount: state.amount,
        toBankAccountId: state.toBankAccount.value,
        fromBankAccountId: state.fromBankAccount.value,
        statement: state.statement,
        note: state.note,
      };
    } else {
      input = {
        amount: state.amount,
        fromBankAccountId: state.fromBankAccount.value,
        statement: state.statement,
        note: state.note,
        contactName: state.name || state.contactAccount.name || "Name",
        contactEmail: state.email || state.contactAccount.email,
        contactTags: state.contactAccount.tags.split(","),
      };
    }
    let output = {
      transaction: {
        identifier: true,
      },
    };
    const response = await createCredit(input, output, gqlHooks);
    setLoading(false);
    if (response) {
      history.push("success", {
        ...location.state,
        currStep: 6,
      });
    }
  }

  return (
    <Fragment>
      <h2 className="text-center">
        Review {requiresApproval && `draft `}payment
      </h2>
      {requiresApproval && (
        <div className="new-payment-inner-content text-center pt-0">
          <span>
            You are creating a payment for{" "}
            <b>{formattedDollarValue(state.amount)}</b>. This is a{" "}
            <b>draft payment</b> and will require an additional approval.
          </span>
        </div>
      )}

      <div className="new-payment-inner-content">
        <h5 className="mb-2">Pay to</h5>
        <div className="review-payment">
          <Row>
            <Col>
              <span>Amount</span>
              <h3>{formattedDollarValue(state.amount)}</h3>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Contact</span>
              <h5>
                {state.contactAccount.name
                  ? state.contactAccount.name
                  : state.contactAccount.email}
              </h5>
            </Col>
            <Col>
              <span>Delivery method</span>
              <h5>{state.contactAccount.email}</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <BankDetail
                description="Contact bank account"
                institution={
                  state.toBankAccount.value !== null &&
                  state.toBankAccount.institution
                }
                label={
                  state.toBankAccount.value !== null &&
                  state.toBankAccount.label
                }
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Payment description</span>
              <h5>{state.statement}</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <span>Frequency</span>
              <h5>One time</h5>
            </Col>
          </Row>
          {!requiresApproval && (
            <>
              <hr />
              <Row>
                <InitiateDateInfo
                  type={INITIATE}
                  isOpen={tooltipInitiatedIsOpen}
                  setOpen={setTooltipInitiatedIsOpen}
                />
                <DepositDateInfo
                  isOpen={tooltipDepositIsOpen}
                  setOpen={setTooltipDepositIsOpen}
                />
              </Row>
            </>
          )}
          {state.note && (
            <Fragment>
              <hr />
              <Row>
                <Col>
                  <span>
                    <NotesToContact alias={notesAlias} />
                  </span>
                  <h5>{state.note}</h5>
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
        <h5 className="mb-2 mt-4">Pay from</h5>
        <div className="review-payment">
          <BankDetail
            description="Your bank account"
            institution={state.fromBankAccount.institution}
            label={state.fromBankAccount.label}
          />
        </div>
        <Button
          disabled={loading}
          onClick={submit}
          block
          type="button"
          className="btn-primary mt-4"
        >
          {requiresApproval ? "Create draft payment" : "Send payment"}
        </Button>
      </div>
    </Fragment>
  );
}

export default CreditReviewStep;
