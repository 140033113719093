import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function upcomingBill(input, output, hooks) {
  const query = buildQuery(input, output, "upcomingBill");
  return callQuery(query, hooks);
}

upcomingBill.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function previousBills(input, output, hooks) {
  const query = buildQuery(input, output, "previousBills");
  return callQuery(query, hooks);
}

previousBills.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function bill(input, output, hooks) {
  const query = buildQuery(input, output, "bill");
  return callQuery(query, hooks);
}

bill.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function billingInfo(input, output, hooks) {
  const query = buildQuery(input, output, "billingInfo");
  return callQuery(query, hooks);
}

billingInfo.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};
