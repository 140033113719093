import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import BanksTable from "../../../../components/tables/BanksTable";
import AddBankAccountModal from "../../../../components/modals/AddBankAccountModal";
import AddIcon from "../../../../assets/icons/AddIcon";
import { useHistory, useLocation } from "react-router-dom";
import MicrodepositModal from "../../../../components/modals/MicrodepositModal";
import UpdateKycModal from "../../../../components/modals/UpdateKYC";
import QueryString from "query-string";
import { useAppValue } from "contexts/Context";
import Alert from "@mui/material/Alert";

function Banks(props) {
  const [context, dispatch] = useAppValue();
  const [addBankModal, setAddBankModal] = useState(false);
  let location = useLocation();
  const [microdepositModal, setMicrodepositModal] = useState(false);
  const [updateKycModal, setUpdateKycModal] = useState(false);
  const history = useHistory();

  const toggleMicrodepositModal = () => {
    setMicrodepositModal(!microdepositModal);
    history.push({ search: "" });
  };
  const toggleUpdateKycModal = () => {
    setUpdateKycModal(!updateKycModal);
    history.push({ search: "" });
  };

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.confirm) {
      setMicrodepositModal(true);
    } else if (search.bankKYC) {
      setUpdateKycModal(true);
    }
  }, [location]);

  return (
    <Fragment>
      {microdepositModal && (
        <MicrodepositModal
          isOpen={microdepositModal}
          toggle={toggleMicrodepositModal}
        />
      )}
      {updateKycModal && (
        <UpdateKycModal isOpen={updateKycModal} toggle={toggleUpdateKycModal} />
      )}
      <AddBankAccountModal
        title={`Add Bank Account`}
        toggle={() => setAddBankModal(!addBankModal)}
        isOpen={addBankModal}
      />

      <div
        className={`d-flex ${
          context.user.company?.isUsingConverge
            ? "justify-content-between"
            : "justify-content-end"
        }`}
      >
        {context.user.company?.isUsingConverge && (
          <div className="p-3">
            <Alert severity="info">
              If you want to add a new bank account, please reach out to{" "}
              <a href="mailto: support@baselinepayments.com">
                support@baselinepayments.com
              </a>
            </Alert>
          </div>
        )}
        <div className="p-3 d-flex justify-content-start">
          <div className="mb-3">
            <Button
              color="primary"
              className="btn-md btn-primary sp-button"
              onClick={() => setAddBankModal(!addBankModal)}
              disabled={context.user.company?.isUsingConverge}
            >
              <AddIcon /> Bank Account
            </Button>
          </div>
        </div>
      </div>
      <div className="p-3">
        <BanksTable {...props} />
      </div>
    </Fragment>
  );
}

export default Banks;
