import { baseUrl } from "../../modules/Helpers";
import React from "react";

function MaintenanceToast(addToast, ticketURL) {
  addToast(
    <div>
      We are unable to complete your request at this time, please{" "}
      <a href={ticketURL} target="_blank" rel="noreferrer">
        create a support ticket
      </a>
      .
    </div>,
    { appearance: "info", autoDismiss: true }
  );
}

export default MaintenanceToast;
