import React from "react";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { useHistory } from "react-router-dom";
import { FailedPaymentsTable } from "views/admin/payments/credit/bulk_payments/FailedPaymentsTable";

export const BulkDebitFailedPayments = () => {
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const totalAmount = state.totalAmountReceived;
  const numSuccessTransactions = state.numDebitsCreated || 0;
  const numFailedTransactions = state.numDebitsFailed || 0;

  const failedPaymentsData = state.bulkDebits?.map((bulkDebit, index) => {
    const error = state.debitErrors.find((error) => error.index == index);
    return {
      ...bulkDebit,
      status: error
        ? error.message
        : "Successfully sent, an email has been sent to the recipient",
      success: !error,
    };
  });

  const onBackToDashboard = () => {
    setState({
      ...state,
      bulkCredits: undefined,
    });
    history.push("/admin/payments");
  };

  return (
    <FailedPaymentsTable
      failedPaymentsData={failedPaymentsData}
      totalAmount={totalAmount}
      numSuccessTransactions={numSuccessTransactions}
      numFailedTransactions={numFailedTransactions}
      onBack={onBackToDashboard}
      onBackText={"Return to dashboard"}
      showDateCreate={false}
    />
  );
};
