import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  convertToDate,
  CustomTooltip,
  FREQUENCY_OPTIONS,
} from "../../modules/Helpers";
import moment from "moment";

const ContactConfirmedPlan = ({ createdAt, start }) => {
  return (
    <>
      <h5>Contact confirmed plan</h5>
      {createdAt ? (
        <p>{moment(createdAt).format("MMM DD, YYYY h:mma")}</p>
      ) : (
        <p className="text-muted font-italic">
          Pending Authorization
          <span className="text-warning">
            {" "}
            - expires {moment(start).format("MMM DD, YYYY")}
          </span>
        </p>
      )}
    </>
  );
};

ContactConfirmedPlan.propTypes = {
  createdAt: PropTypes.string,
  start: PropTypes.string,
};

function RecurringPlanInfoCard(props) {
  return (
    <Card className="details-card shadow-sm border mt-4">
      <Row className="details-row border-bottom">
        <Col>
          <h5>Frequency</h5>
          <p>
            {" "}
            {
              FREQUENCY_OPTIONS.find((frequency) => {
                return frequency.value === props.recurringPlan.frequency;
              }).label
            }
          </p>
        </Col>
        <Col>
          <h5>Start date</h5>
          <p>{moment(props.recurringPlan.start).format("MMM DD, YYYY")}</p>
        </Col>
        <Col>
          <h5>End date</h5>
          {props.recurringPlan.end ? (
            <p>{moment(props.recurringPlan.end).format("MMM DD, YYYY")}</p>
          ) : (
            <p className="text-muted">(None)</p>
          )}
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          <h5>Plan id</h5>
        </Col>
        <Col>
          <div>
            <CopyToClipboard
              onCopy={() => props.copyIdToClipboard()}
              text={props.recurringPlan.identifier}
            >
              <Button className="btn-link details-row-button">Copy</Button>
            </CopyToClipboard>
          </div>
        </Col>
        <Col sm="12">
          <p>
            {" "}
            <CustomTooltip title="Click to copy" placement="top" arrow>
              <CopyToClipboard
                onCopy={() => props.copyIdToClipboard()}
                text={props.recurringPlan.identifier}
              >
                <span style={{ cursor: "pointer" }}>
                  {props.recurringPlan.identifier}
                </span>
              </CopyToClipboard>
            </CustomTooltip>
          </p>
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          <h5>Created by</h5>
          <p>{props.recurringPlan.issuedBy.email}</p>
        </Col>
        <Col>
          <h5>Created date</h5>
          <p>{convertToDate(props.recurringPlan.createdAt)}</p>
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          {props.recurringPlan.customerCreditCard ? (
            <ContactConfirmedPlan
              createdAt={props.recurringPlan.customerCreditCard.createdAt}
              start={props.recurringPlan.start}
            />
          ) : (
            <ContactConfirmedPlan
              createdAt={props.recurringPlan.fromBankAccount?.createdAt}
              start={props.recurringPlan.start}
            />
          )}
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          <h5>Statement</h5>
          <p>{props.recurringPlan.statement}</p>
        </Col>
      </Row>

      <Row className="details-row">
        <Col>
          <h5>Notes to contact</h5>
          <p>
            {props.recurringPlan.description ? (
              props.recurringPlan.description
            ) : (
              <span className="text-muted font-italic">None</span>
            )}
          </p>
        </Col>
      </Row>
    </Card>
  );
}

export default RecurringPlanInfoCard;
RecurringPlanInfoCard.propTypes = {
  recurringPlan: PropTypes.object,
  copyIdToClipboard: PropTypes.func,
};
