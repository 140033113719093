import React, { Fragment, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import PropTypes from "prop-types";
import { allContactTags } from "../../api_client/queries/contacts";
import useGQL from "../../api_client/UseGQL";

function TagsSelect(props) {
  const [tagOptions, setTagOptions] = useState([]);
  let gqlHooks = useGQL();

  useEffect(() => {
    let tags = [];
    allContactTags({}, { tags: true }, gqlHooks).then((response) => {
      if (response) {
        response.data.tags.map((tag, key) => {
          tags.push({ value: key, label: tag });
        });
        setTagOptions(tags);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gqlHooks.context.refresh]);
  return (
    <Fragment>
      <CreatableSelect
        isMulti={props.isMulti}
        name="tags"
        className="react-select default"
        classNamePrefix="react-select"
        onChange={props.onChangeCallback}
        value={props.activeTags}
        options={tagOptions}
        isClearable
        placeholder={props.placeholder}
      />
    </Fragment>
  );
}

export default TagsSelect;

TagsSelect.propTypes = {
  activeTags: PropTypes.array,
  onChangeCallback: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
};

TagsSelect.defaultProps = {
  isMulti: true,
};
