/* eslint-disable react/prop-types */
import React from "react";
import debitEmail from "assets/img/banks/debitEmail.svg";

function MailIcon(props) {
  //const fill = props.fill || "currentColor";
  //const secondaryfill = props.secondaryfill || fill;
  //const strokewidth = props.strokewidth || 4;
  const width = props.width || "100%";
  const height = props.height || "100%";
  //const title = props.title || "MailIcon";

  return (
    // <svg
    //   height={height}
    //   width={width}
    //   viewBox="0 0 48 48"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <title>{title}</title>
    //   <g
    //     fill={secondaryfill}
    //     stroke={secondaryfill}
    //     strokeLinecap="square"
    //     strokeWidth={strokewidth}
    //   >
    //     <polyline
    //       fill="none"
    //       points="3.23 7.118 24 26 44.77 7.118"
    //       strokeLinecap="butt"
    //     />
    //     <rect
    //       height="36"
    //       width="44"
    //       fill="none"
    //       rx="4"
    //       stroke={fill}
    //       x="2"
    //       y="6"
    //     />
    //   </g>
    // </svg>
    <img src={debitEmail} height={height} width={width} />
  );
}

export default MailIcon;
