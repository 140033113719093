import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import { useHistory } from "react-router";
import moment from "moment";
import { formatDateYear, formattedDollarValue } from "../../modules/Helpers";

function BillCard(props) {
  const history = useHistory();

  return (
    <Fragment>
      <Card
        className="t-card shadow-sm border p-3 m-1"
        onClick={() =>
          history.push({
            search: `?overlay=true&type=bill&id=${props.item.identifier}`,
          })
        }
      >
        <div className="t-card-row">
          <span className="text-primary text-sm font-weight-600">Bill</span>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Billing Period</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>
              {" "}
              {moment(`${props.item.year}-${props.item.month}`).format(
                "MMMM YYYY"
              )}
            </div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Invoice Date</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>{formatDateYear(props.item.createdAt)}</div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Payment Volume</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div> {formattedDollarValue(props.item.amountTransacted)}</div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Total Payments</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>{props.item.numTransactions}</div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Billing Account</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>
              {" "}
              {props.item.owner.company.billingAccount.institution} -{" "}
              {props.item.owner.company.billingAccount.title} -{" "}
              {props.item.owner.company.billingAccount.accountNumber}
            </div>
          </div>
        </div>

        <div className="t-card-row justify-content-between">
          <div className="flex-column text-muted">
            <div>Amount Billed</div>
          </div>
          <div className="flex-column font-weight-bold text-right">
            <div>{formattedDollarValue(props.item.total)}</div>
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

export default BillCard;

BillCard.propTypes = {
  item: PropTypes.object,
  detailRoute: PropTypes.string,
};
