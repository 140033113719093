import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { domains } from "modules/Helpers";

function BanksDropdown(props) {
  let history = useHistory();
  let updateAccountDetails =
    props.account.state === "FAILED_MICRODEPOSIT" ||
    props.account.state === "PARTIALLY_READY";

  return (
    <>
      <UncontrolledDropdown className="pull-right">
        <div className="details-action-wrapper">
          <DropdownToggle className="details-more-btn" nav>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
        </div>
        <DropdownMenu
          right
          className="custom-dropdown-menu details-actions-dropdown"
        >
          {updateAccountDetails && (
            <DropdownItem
              onClick={() =>
                history.push({
                  search: `bankKYC=true&id=${props.account.identifier}`,
                })
              }
            >
              <span className="d-inline-block font-weight-600">
                {props.account.state === "PARTIALLY_READY"
                  ? "Resolve missing details"
                  : "Retry adding account"}
              </span>
            </DropdownItem>
          )}
          {/*{props.account.state === "CONFIRM_MICRODEPOSIT" && (*/}
          {/*  <DropdownItem*/}
          {/*    onClick={() =>*/}
          {/*      history.push({*/}
          {/*        search: `confirm=true&id=${props.account.identifier}`,*/}
          {/*      })*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <span className="d-inline-block font-weight-600">*/}
          {/*      Confirm micro-deposits*/}
          {/*    </span>*/}
          {/*  </DropdownItem>*/}
          {/*)}*/}
          {props.account.state === "PENDING_MICRODEPOSIT" && (
            <DropdownItem
              onClick={() =>
                window.open(
                  `https://${domains.HELP}/how-to-verify-a-bank-account-with-micro-deposits`,
                  "_blank"
                )
              }
            >
              <span className="d-inline-block font-weight-600">Learn more</span>
            </DropdownItem>
          )}
          {props.account.state === "READY" && !props.account.billing && (
            <DropdownItem onClick={() => props.setBillingAccount(true)}>
              <span className="d-inline-block font-weight-600">
                Make billing account
              </span>
            </DropdownItem>
          )}
          {!props.account.variableDebitPad && (
            <DropdownItem
              // eslint-disable-next-line
              onClick={(e) => {
                props.setVariablePad(true);
              }}
              className="text-primary"
            >
              <span className="d-inline-block font-weight-600">Sign PAD</span>
            </DropdownItem>
          )}
          {props.account.billing ? (
            <DropdownItem disabled>
              <span className="d-inline-block pl-2 pb-0 ml-0 text-danger opacity-4 font-weight-600">
                Delete
              </span>
              <br />
              <span className="pl-2 pr-2 text-muted text-sm">
                Set another billing account before deleting
              </span>
            </DropdownItem>
          ) : (
            <>
              <hr />
              <DropdownItem
                onClick={(e) =>
                  props.action ? props.action() : e.preventDefault()
                }
                className="text-danger"
              >
                <span className="d-inline-block font-weight-600 text-danger">
                  Delete
                </span>
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default BanksDropdown;

BanksDropdown.propTypes = {
  action: PropTypes.func,
  account: PropTypes.object,
  setRefresh: PropTypes.func,
  setBillingAccount: PropTypes.func,
  setVariablePad: PropTypes.func,
};
