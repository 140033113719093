import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { resetPasswordFormValidation } from "../../../modules/form_validation/ResetPasswordFormValidation";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import useGQL from "../../../api_client/UseGQL";
import { confirmPasswordReset } from "../../../api_client/mutations/auth";
import LogoColor from "../../../assets/img/logo-colour.png";
import { appName } from "../../../modules/Helpers";

function ResetPasswordForm(props) {
  const { values, handleChange, handleSubmit, errors, setErrors } = useForm(
    submit,
    resetPasswordFormValidation
  );
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    uid: "",
    token: "",
  });
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();

  useEffect(() => {
    passwordRef.current.focus();
    const { token, uid } = props.match.params;
    setState({
      uid: uid,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function submit() {
    if (values.password !== values.passwordConfirm) {
      passwordConfirmRef.current.focus();
      setErrors({
        passwordConfirm: "Passwords do not match",
      });
      return;
    }
    setLoading(true);
    let output = {
      ok: true,
    };
    const response = await confirmPasswordReset(
      {
        newPassword: values.password,
        passwordToken: state.token,
        userId: state.uid,
      },
      output,
      gqlHooks
    );
    setLoading(false);
    if (!response) {
      return;
    }
    if (response.fieldError) {
      if (response.fieldError.field === "newPassword") {
        passwordRef.current.focus();
        setErrors({
          password: response.fieldError.message,
        });
      } else {
        addToast("Something went wrong, please contact support", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    } else {
      addToast("Password reset successful, please login", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/auth/login/");
    }
  }

  return (
    <>
      <div className="text-center ">
        <div className="mr-auto ml-auto mb-3 auth-logo">
          <img src={LogoColor} width={50} alt={appName} />
        </div>
        <h2 className="font-weight-bold register-header mb-2">
          Reset Password
        </h2>
        <p className="text-muted mb-3">
          Must be 8 characters and not be considered a common password.
        </p>
      </div>
      <Col className="ml-auto mr-auto" md="7">
        <Form className="form" onSubmit={handleSubmit}>
          <Label>New Password</Label>
          <FormGroup>
            <Input
              innerRef={passwordRef}
              name="password"
              type="password"
              value={values.password || ""}
              invalid={!!errors.password}
              onChange={handleChange}
              required
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>
          <Label>Confirm Password</Label>
          <FormGroup>
            <Input
              innerRef={passwordConfirmRef}
              name="passwordConfirm"
              type="password"
              value={values.passwordConfirm || ""}
              invalid={!!errors.passwordConfirm}
              onChange={handleChange}
              required
            />
            <FormFeedback>{errors.passwordConfirm}</FormFeedback>
          </FormGroup>
          <Button
            block
            type="submit"
            color="primary"
            size="md"
            disabled={loading}
            className="mt-4"
          >
            Reset Password
          </Button>
        </Form>
      </Col>
    </>
  );
}

export default ResetPasswordForm;
ResetPasswordForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
      uid: PropTypes.string,
    }),
  }).isRequired,
};
