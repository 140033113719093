import React, { Fragment } from "react";
import { Button, Col, Row } from "reactstrap";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import {
  CustomTooltip,
  formattedDollarValue,
} from "../../../../../modules/Helpers";
import SuccessIcon from "react-bootstrap-sweetalert/dist/components/SuccessIcon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import CreateAdditionalPaymentDropdown from "../../../../../components/dropdowns/CreateAdditionalPaymentDropdown";

function DebitSuccessStep() {
  const history = useHistory();
  const [state] = useSimpleContext();
  const { addToast } = useToasts();

  const copyIdToClipboard = () => {
    addToast("copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  if (state.deliveryMethod === "Link") {
    return (
      <Fragment>
        <SuccessIcon />
        <h2 className="text-center">Payment request link created</h2>
        <div className="new-payment-inner-content text-center pt-0">
          <span>
            Copy the link below and include it in an invoice or email to request{" "}
            <b>{formattedDollarValue(state.amount)}</b> from{" "}
            <b>
              {state.contactAccount.name
                ? state.contactAccount.name
                : state.contactAccount.email}
            </b>
            .
          </span>
          <div className="review-payment pt-2 pb-2 mt-4 mb-4">
            <Row>
              <Col className="text-left col-md-9 col-8">
                <span>Link</span>

                <CopyToClipboard
                  onCopy={() => copyIdToClipboard()}
                  text={state.link}
                >
                  <CustomTooltip title="Click to copy" placement="top" arrow>
                    <h5 className="overflow-hidden text-nowrap cursor-pointer">
                      {state.link}
                    </h5>
                  </CustomTooltip>
                </CopyToClipboard>
              </Col>
              <Col className="col-md-3 col-4 pl-0">
                <CopyToClipboard
                  onCopy={() => copyIdToClipboard()}
                  text={state.link}
                >
                  <Button
                    block
                    style={{ marginTop: "6px" }}
                    className="btn-primary btn-sm"
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </Col>
            </Row>
          </div>
          <Button
            onClick={() => history.push("/admin/payments")}
            block
            className="btn-primary"
          >
            All done, return to dashboard
          </Button>
          <Row className="mt-3">
            <Col xs="5" className="ml-auto pl-0">
              <hr />
            </Col>
            <Col xs="1">
              <span>or</span>
            </Col>
            <Col xs="5" className="mr-auto pr-0">
              <hr />
            </Col>
          </Row>
          <CreateAdditionalPaymentDropdown />
        </div>
      </Fragment>
    );
  } else if (typeof state.frequency === "object" && state.frequency !== null) {
    return (
      <Fragment>
        <SuccessIcon />
        <h2 className="text-center">Payment request sent</h2>
        <div className="new-payment-inner-content text-center pt-0">
          <div className="mb-4">
            <span>
              You requested a <b>{state.frequency.label}</b> payment of{" "}
              <b>{formattedDollarValue(state.amount)}</b> from{" "}
              <b>
                {state.contactAccount.name
                  ? state.contactAccount.name
                  : state.contactAccount.email}
              </b>
              . You will receive a confirmation email once the request has been
              accepted.
            </span>
          </div>
          <Button
            onClick={() => history.push("/admin/payments")}
            block
            className="btn-primary"
          >
            All done, return to dashboard
          </Button>
          <Row className="mt-3">
            <Col xs="5" className="ml-auto pl-0">
              <hr />
            </Col>
            <Col xs="1">
              <span>or</span>
            </Col>
            <Col xs="5" className="mr-auto pr-0">
              <hr />
            </Col>
          </Row>
          <CreateAdditionalPaymentDropdown />
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <SuccessIcon />
        <h2 className="text-center">Payment request sent</h2>
        <div className="new-payment-inner-content text-center pt-0">
          <div className="mb-4">
            <span>
              You requested a <b>one time</b> payment of{" "}
              <b>{formattedDollarValue(state.amount)}</b> from{" "}
              <b>
                {state.contactAccount.name
                  ? state.contactAccount.name
                  : state.contactAccount.email}
              </b>
              . You will receive a confirmation email once the request has been
              paid.
            </span>
          </div>
          <Button
            onClick={() => history.push("/admin/payments")}
            block
            className="btn-primary"
          >
            All done, return to dashboard
          </Button>
          <Row className="mt-3">
            <Col xs="5" className="ml-auto pl-0">
              <hr />
            </Col>
            <Col xs="1">
              <span>or</span>
            </Col>
            <Col xs="5" className="mr-auto pr-0">
              <hr />
            </Col>
          </Row>
          <CreateAdditionalPaymentDropdown />
        </div>
      </Fragment>
    );
  }
}

export default DebitSuccessStep;

DebitSuccessStep.propTypes = {};
