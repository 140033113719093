import { buildMutation, callMutation } from "api_client/mutation";

export async function createApiToken(input, output, hooks) {
  const query = buildMutation(input, output, "createApiToken");
  return callMutation(query, hooks);
}

export async function deleteApiToken(input, output, hooks) {
  const query = buildMutation(input, output, "deleteApiToken");
  return callMutation(query, hooks);
}
