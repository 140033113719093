import {
  MAX_LENGTH_FULL_NAME,
  MAX_LENGTH_LONG_NAME,
  MAX_LENGTH_NOTE,
  verifyEmail,
  verifyLength,
  verifyMaxTwoDecimal,
  verifyStatementNames,
} from "../Helpers";

export function ImportBillsFormValidation(values, errors) {
  validateContactName(values, errors);

  validateContactEmail(values, errors);

  validateStatement(values, errors);

  validateNote(values, errors);

  validateAmount(values, errors);

  return errors;
}

function validateContactName(
  values,
  errors,
  key = "contactName",
  required = true
) {
  // no value present, not required, skip validation
  if (!required && !values[key]) {
    delete errors[key];
    return;
  }

  if (required && !values[key]) {
    errors[key] = "Please enter contact name";
    return;
  }

  if (verifyLength(values[key], MAX_LENGTH_FULL_NAME)) {
    errors[key] = "Max 60 characters";
  } else {
    delete errors[key];
  }
}

function validateContactEmail(
  values,
  errors,
  key = "contactEmail",
  required = true
) {
  // no value present, not required, skip validation
  if (!required && !values[key]) {
    delete errors[key];
    return;
  }

  if (required && !values[key]) {
    errors[key] = "Please enter contact email address";
    return;
  }

  if (!verifyEmail(values[key])) {
    errors[key] = "Please enter a valid email address";
  } else {
    delete errors[key];
  }
}

function validateStatement(values, errors, key = "statement", required = true) {
  // no value present, not required, skip validation
  if (!required && !values[key]) {
    delete errors[key];
    return;
  }

  if (required && !values[key]) {
    errors[key] = "Please enter statement";
    return;
  }

  if (verifyLength(values[key], MAX_LENGTH_LONG_NAME)) {
    errors[key] = "Max 30 characters";
  } else if (verifyStatementNames(values[key])) {
    errors[key] =
      "Allowed only alpha numeric characters and these symbols... = _ $ . & * ,";
  } else if (!/[a-zA-Z]/.test(values[key])) {
    errors[key] = "Please enter a statement";
  } else {
    delete errors[key];
  }
}

function validateNote(values, errors, key = "note", required = false) {
  // no value present, not required, skip validation
  if (!required && !values[key]) {
    delete errors[key];
    return;
  }

  if (required && !values[key]) {
    errors[key] = "Please enter note to contact";
    return;
  }

  if (values[key]) {
    if (values[key] && verifyLength(values[key], MAX_LENGTH_NOTE)) {
      errors[key] = "Max 200 characters";
    } else {
      delete errors[key];
    }
  }
}

function validateAmount(values, errors, key = "amount", required = true) {
  // no value present, not required, skip validation
  if (!required && !values[key]) {
    delete errors[key];
    return;
  }

  if (required && !values[key]) {
    errors[key] = "Please enter amount";
    return;
  }
  const amountFloat = parseFloat(values[key]);
  if (amountFloat > 600000.0) {
    errors[key] = "Max transaction is $600,000.00, ";
  } else if (!verifyMaxTwoDecimal(amountFloat)) {
    errors[key] = "Must be dollar value (ex. $123.45)";
  } else if (amountFloat < 10.0) {
    errors[key] = "Min transaction is $10.00";
  } else {
    delete errors[key];
  }
}
