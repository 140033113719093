import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { useToasts } from "react-toast-notifications";
import { loginFormValidation } from "../../../modules/form_validation/LoginFormValidation";
import { NavLink, useHistory } from "react-router-dom";
import useGQL from "../../../api_client/UseGQL";
import { resetPassword } from "../../../api_client/mutations/auth";
import LogoColor from "../../../assets/img/logo-colour.png";
import { appName } from "../../../modules/Helpers";

function ForgotPasswordForm() {
  const { values, handleChange, handleSubmit, errors } = useForm(
    submit,
    loginFormValidation
  );
  const [loading, setLoading] = useState(false);
  const emailRef = useRef(null);
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  async function submit() {
    setLoading(true);
    let output = {};
    const response = await resetPassword(values, output, gqlHooks);
    setLoading(false);
    if (response) {
      addToast(
        "If you have an account with us, you'll receive a link by email to reset your password",
        { appearance: "success", autoDismiss: true }
      );
      history.push("/auth/login/");
    }
  }

  return (
    <>
      <div className="text-center ">
        <div className="mr-auto ml-auto mb-3 auth-logo">
          <img src={LogoColor} width={50} alt={appName} />
        </div>
        <h2 className="font-weight-bold register-header mb-2">
          Reset Password
        </h2>
        <p className="text-muted mb-3">
          Don&apos;t need to reset? <NavLink to="/auth/login/">Login</NavLink>
        </p>
      </div>
      <Col className="ml-auto mr-auto" md="7">
        <Form className="form" onSubmit={handleSubmit}>
          <Label>Email address</Label>
          <FormGroup>
            <Input
              innerRef={emailRef}
              name="email"
              type="email"
              value={values.email}
              invalid={!!errors.email}
              required
              onChange={handleChange}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>

          <Button
            block
            color="primary"
            size="md"
            className="mt-4"
            disabled={loading}
          >
            Reset Password
          </Button>
        </Form>
      </Col>
    </>
  );
}

export default ForgotPasswordForm;
