import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { useGQLContext } from "./client";
import MaintenanceToast from "../components/alerts/alerts";
import dangerAlert from "../components/sweet_alert/DangerAlert";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { baseUrl } from "../modules/Helpers";

export function useApiFieldErrors(values = {}) {
  const [gqlContext] = useGQLContext();
  const [errors, setErrors] = useState({});
  const [initial, setInitial] = useState(true);
  const { addToast } = useToasts();

  useEffect(() => {
    if (initial) {
      gqlContext.fieldErrors = {};
    }
    let errors = gqlContext.fieldErrors;
    let modal = !!errors.MODAL;
    delete errors.MODAL;
    Object.keys(errors).forEach((key) => {
      if (!values[key] && !initial) {
        if (modal) {
          dangerAlert(
            () => gqlContext.setAlert(null),
            gqlContext.setAlert,
            errors[key]
          );
        } else {
          addToast(errors[key], {
            appearance: "error",
            autoDismiss: true,
          });
        }
        delete gqlContext.fieldErrors[key];
      }
    });
    setErrors(errors);
    setInitial(false);
  }, [gqlContext.fieldErrors]);
  return errors;
}

export function useErrorHandlerGQL(dispatch, setAlertModal) {
  const { addToast } = useToasts();
  let history = useHistory();
  const [context] = useGQLContext();

  function defaultErrorHandler(response) {
    if (response.errors) {
      if (response.data && response.data.mixpanelEvent === null) {
        return null;
      }
      const getTicketURL = () => {
        let ticketURL;
        const ticketQuery = "ticket=true";
        if (location.pathname.includes("/payment/debit/")) {
          ticketURL = location.protocol + "//" + location.host;
          if (
            location.pathname.includes("success") ||
            location.pathname.includes("track")
          ) {
            ticketURL += location.pathname.replace("success", "track");
            ticketURL += location.search;
            if (!location.search.includes(ticketQuery)) {
              ticketURL += location.search
                ? "&" + ticketQuery
                : "?" + ticketQuery;
            }
          } else {
            ticketURL += location.pathname + "?" + ticketQuery;
          }
        } else if (context.token) {
          ticketURL = baseUrl + "/admin/payments?" + ticketQuery;
        } else {
          ticketURL = baseUrl + "/auth/login?" + ticketQuery;
        }
        return ticketURL;
      };
      if (response.errors[0].message.includes("Error decoding signature")) {
        addToast("Please login to continue", {
          appearance: "info",
          autoDismiss: true,
        });
        dispatch({
          type: "logout",
        });
      } else if (setAlertModal) {
        console.log(response.errors);
        dangerAlert(
          () => setAlertModal(null),
          setAlertModal,
          "We are unable to complete your request at this time",
          "Please create a support ticket.",
          () => {
            window.open(getTicketURL(), "_blank", "noreferrer").focus();
          }
        );
      } else {
        MaintenanceToast(addToast, getTicketURL());
      }
      Sentry.captureException(response.errors[0].message);
      return null;
    } else if (!response.data) {
      if (setAlertModal) {
        dangerAlert(() => setAlertModal(null), setAlertModal);
      } else {
        addToast("Something went wrong, please contact support", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      return null;
    }
    let data = Object.values(response.data)[0];
    if (!data) {
      return null;
    }
    if (data.authenticationError) {
      addToast("Please login to continue", {
        appearance: "info",
        autoDismiss: true,
      });
      dispatch({
        type: "logout",
      });
      history.push("/auth/login/");
      return null;
    } else if (data.authorizationError) {
      if (setAlertModal) {
        dangerAlert(
          () => setAlertModal(null),
          setAlertModal,
          "Permission denied",
          data.authorizationError.message
        );
      } else {
        addToast(data.authorizationError.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      return null;
    } else if (data.rateLimitError) {
      if (setAlertModal) {
        dangerAlert(
          () => setAlertModal(null),
          setAlertModal,
          "Too many requests",
          "Too many request, please wait to make additional request"
        );
      } else {
        addToast("Too many request, please wait to make additional request", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      return null;
    } else if (data.fieldErrors) {
      const fieldErrors = {};
      data.fieldErrors.forEach((fieldError) => {
        fieldErrors[fieldError.field] = fieldError.message;
      });
      if (setAlertModal) {
        setAlertModal(null);
        fieldErrors.MODAL = true;
      }
      dispatch({
        type: "setFieldErrors",
        fieldErrors: fieldErrors,
      });
      return false;
    }
    return data;
  }

  return defaultErrorHandler;
}

function useGQL(modal = false, errorHandler = null) {
  const [context, dispatch] = useGQLContext();
  let setAlertModal;
  if (modal) {
    setAlertModal = context.setAlert;
  }
  const defaultErrorHandler = useErrorHandlerGQL(dispatch, setAlertModal);

  if (errorHandler === null) {
    errorHandler = defaultErrorHandler;
  }

  return { context, errorHandler, dispatch };
}

export function useMixPanelGQL() {
  const [context, dispatch] = useGQLContext();
  const errorHandler = (response) => response;
  return { context, errorHandler, dispatch };
}

export default useGQL;
