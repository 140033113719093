import React from "react";
import { Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

function InvoiceInfoCard(props) {
  const history = useHistory();
  const dummy = {
    identifier: "3b19479b-a15a-4097-87f8-53d07978aecc",
    amount: "2000",
    invoiceNumber: "INV220",
  };
  return props.transaction.invoice ? (
    <>
      <Row>
        <Col>
          <h4> Invoice </h4>
        </Col>
      </Row>
      <Card className="details-card shadow-sm border">
        <>
          <>
            <Row className="details-row border-bottom">
              <Col>
                <h5>Invoice ID</h5>
                <p>{props.transaction.invoice?.identifier}</p>
              </Col>{" "}
            </Row>
            <Row className="details-row border-bottom">
              <Col>
                {" "}
                <h5>Amount</h5>
                <p>${props.transaction.invoice?.amount}</p>
              </Col>{" "}
            </Row>
            <Row className="details-row border-bottom">
              <Col>
                <h5>Invoice Number</h5>
                <p>{props.transaction.invoice?.invoiceNumber}</p>
              </Col>
            </Row>
          </>
        </>
      </Card>
    </>
  ) : (
    ""
  );
}

export default InvoiceInfoCard;
InvoiceInfoCard.propTypes = {
  transaction: PropTypes.object,
};
