const ApiTokenScopes = {
  CREATE_DEBIT: "CREATE_DEBIT",
  CREATE_SCHEDULED_TRANSACTION: "CREATE_SCHEDULED_TRANSACTION",
  QUERY_TRANSACTION: "QUERY_TRANSACTION",
  CANCEL_TRANSACTION: "CANCEL_TRANSACTION",
};

export function apiTokenScopeName(scope) {
  switch (scope) {
    case ApiTokenScopes.CREATE_DEBIT:
      return "Create Debit";
    case ApiTokenScopes.CREATE_SCHEDULED_TRANSACTION:
      return "Create Scheduled Transaction";
    case ApiTokenScopes.QUERY_TRANSACTION:
      return "Query Transaction";
    case ApiTokenScopes.CANCEL_TRANSACTION:
      return "Cancel Transaction";
  }

  return scope
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export default ApiTokenScopes;
