import React, { useRef } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button, Card, CardBody, CardFooter } from "reactstrap";

import { ClipLoader } from "react-spinners";
import useImageUpload from "../../hooks/UseImageUpload";
import { useToasts } from "react-toast-notifications";

function ImageUpload(props) {
  const fileInputRef = useRef(null);
  const { addToast } = useToasts();
  const {
    handleImageChange,
    handleImageSubmit,
    handleClick,
    imageLoading,
    imageState,
  } = useImageUpload(fileInputRef, props.companyImage);

  async function submitImageUpload() {
    const response = await handleImageSubmit();
    if (response) {
      addToast("Company logo uploaded successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast("Company logo upload failed", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  return (
    <Card className="bg-transparent shadow-none">
      <CardBody className="fileinput text-center p-0">
        <input
          type="file"
          onChange={handleImageChange}
          ref={fileInputRef}
          accept="image/*"
        />
        <div className={"thumbnail" + (props.avatar ? " img-square" : "")}>
          <img src={imageState.imagePreviewUrl} alt="..." />
        </div>
      </CardBody>
      <CardFooter>
        <div className="text-center">
          {imageLoading ? (
            <div>
              <ClipLoader size={30} loading={imageLoading} color="#1F89F8" />
            </div>
          ) : imageState.file === null ? (
            <div className="mt-1 mb-1">
              <Button
                block
                color={props.addBtnColor}
                className="btn-simple"
                onClick={handleClick}
                type="button"
              >
                {props.avatar ? "Add Company Logo" : "Select image"}
              </Button>
            </div>
          ) : (
            <Button
              block
              color={props.addBtnColor}
              className="btn-primary"
              onClick={submitImageUpload}
            >
              Upload
            </Button>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary",
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  companyImage: PropTypes.string,
};

export default ImageUpload;
