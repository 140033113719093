import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function createContact(input, output, hooks) {
  const query = buildMutation(input, output, "createContact");
  return callMutation(query, hooks);
}

createContact.propTypes = {
  input: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tags: PropTypes.string.isRequired,
    emailInvite: PropTypes.bool,
    useSecurityQuestion: PropTypes.bool,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function checkSecurityAnswer(input, output, hooks) {
  const query = buildMutation(input, output, "checkSecurityAnswer");
  return callMutation(query, hooks);
}

checkSecurityAnswer.propTypes = {
  input: PropTypes.shape({
    supplierId: PropTypes.string.isRequired,
    securityAnswer: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function notifyCompletedSupplierInvite(input, output, hooks) {
  const query = buildMutation(input, output, "assignBankAccountToSupplier");
  return callMutation(query, hooks);
}

notifyCompletedSupplierInvite.propTypes = {
  input: PropTypes.shape({
    supplierId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function removeBankAccountFromContact(input, output, hooks) {
  const query = buildMutation(input, output, "removeBankAccountFromContact");
  return callMutation(query, hooks);
}

removeBankAccountFromContact.propTypes = {
  input: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function deleteContact(input, output, hooks) {
  const query = buildMutation(input, output, "deleteContact");
  return callMutation(query, hooks);
}

deleteContact.propTypes = {
  input: PropTypes.shape({
    contactId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function updateContact(input, output, hooks) {
  const query = buildMutation(input, output, "updateContact");
  return callMutation(query, hooks);
}

export function setTagsContact(input, output, hooks) {
  const query = buildMutation(input, output, "setTagsContact");
  return callMutation(query, hooks);
}

setTagsContact.propTypes = {
  input: PropTypes.shape({
    contactId: PropTypes.string.isRequired,
    tags: PropTypes.string,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function deleteContactTags(input, output, hooks) {
  const query = buildMutation(input, output, "deleteContactTags");
  return callMutation(query, hooks);
}

deleteContactTags.propTypes = {
  input: PropTypes.shape({
    tags: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function updateContactTagsMapping(input, output, hooks) {
  const query = buildMutation(input, output, "updateContactTagsMapping");
  return callMutation(query, hooks);
}

updateContactTagsMapping.propTypes = {
  input: PropTypes.shape({
    mapping: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
