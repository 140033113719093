import React, { Fragment, useEffect, useState } from "react";
import { Button, Nav } from "reactstrap";
import InviteTable from "../../../../components/tables/InviteTable";
import UserTable from "../../../../components/tables/UserTable";
import AddIcon from "../../../../assets/icons/AddIcon";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import AddUserModal from "./AddUserModal";

function Users(props) {
  const [active, setActive] = useState("#users");
  let location = useLocation();
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (location.state) {
      setActive(location.state.active);
    }
  }, []);

  const activeRoute = (name) => {
    return active === name ? "active" : "";
  };

  return (
    <Fragment>
      {alert}
      <AddUserModal
        setAlert={setAlert}
        isOpen={inviteUserModal}
        toggle={() => setInviteUserModal(!inviteUserModal)}
      />
      <div className="d-flex flex-row justify-content-between flex-grow-1 bottom-border">
        <Nav className="table-headers user-table-header pl-1">
          <li className={activeRoute("#users") + " nav-link"}>
            <NavLink
              to=""
              onClick={(e) => {
                e.preventDefault();
                setActive("#users");
              }}
            >
              Active
            </NavLink>
          </li>
          <li className={activeRoute("#invites") + " nav-link"}>
            <NavLink
              to=""
              onClick={(e) => {
                e.preventDefault();
                setActive("#invites");
              }}
            >
              Invites
            </NavLink>
          </li>
        </Nav>
        <div className="pr-2">
          <Button
            onClick={() => setInviteUserModal(true)}
            className="btn-primary sp-button"
            color="primary"
          >
            <AddIcon /> User
          </Button>
        </div>
      </div>
      {active === "#users" && <UserTable {...props} />}
      {active === "#invites" && (
        <InviteTable refresh={inviteUserModal} {...props} />
      )}
    </Fragment>
  );
}

export default Users;

Users.propTypes = {};

Users.defaultProps = {};
