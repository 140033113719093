import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import authRoutes from "../../routes/AuthRoutes";
import { useGQLContext } from "../../api_client/client";
import TrustFooter from "../../components/footers/TrustFooter";
import LogoWithLightText from "../../assets/img/LogoWithLightText.png";
import { Redirect } from "react-router";
import * as Sentry from "@sentry/browser";
import PopOutOverlay from "../../components/overlays/PopOutOverlay";
import { Button } from "reactstrap";
import AuthSupportForm from "../../components/forms/AuthSupportForm";
import { baseUrl, appName, domains } from "../../modules/Helpers";

function Auth() {
  const [context] = useGQLContext();
  let history = useHistory();
  const location = useLocation();
  const [overlayDetails, setOverlayDetails] = useState(false);

  useEffect(() => {
    if (window.location.host.match(/app\.(?:\w+\.)?divdot\.com/)) {
      window.location = `${baseUrl}${location.pathname}`;
    }
    try {
      Sentry.configureScope((scope) => scope.setTransactionName("Auth"));
    } catch (e) {
      console.log("sentry not loaded");
    }
  }, []);

  useEffect(() => {
    if (context.token && !location.pathname.includes("/auth/verification")) {
      history.push("/admin/");
    }
  }, []);

  useEffect(() => {
    if (location.search.includes("ticket=true")) {
      setOverlayDetails(true);
    }
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        let Component = prop.component;
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <Component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getSideContent = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth" && prop.sideContent) {
        let Component = prop.sideContent;
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <Component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="bg-white auth-wrapper">
        <PopOutOverlay
          isOpen={overlayDetails}
          toggle={() => {
            setOverlayDetails(!overlayDetails);
          }}
        >
          <AuthSupportForm
            toggleSupport={() => setOverlayDetails(!overlayDetails)}
          />
        </PopOutOverlay>
        <div className="d-flex flex-row">
          <div className="flex-column d-flex auth-content flex-grow-1">
            <div className="d-flex flex-row">
              <div
                className="mr-auto mb-3 cursor-pointer"
                style={{ maxWidth: "100px" }}
                onClick={() =>
                  window.open(`https://${domains.PRIMARY}/`, "_self")
                }
              >
                <img src={LogoWithLightText} alt={`${appName} Logo`} />
              </div>
              <div>
                <span className="desktop">Need some help?</span>
                <Button
                  onClick={() => setOverlayDetails(!overlayDetails)}
                  className="sp-button btn-primary mr-3"
                >
                  Talk to support
                </Button>
              </div>
            </div>
            <div className="content-aligner">
              <div className="content-aligner-item">
                <Switch>
                  {getRoutes(authRoutes)}
                  <Redirect from={"/auth/*"} to={"/auth/login"} />
                </Switch>
              </div>
            </div>
            <TrustFooter />
          </div>
          <div className="d-flex flex-column bg-dark basis-4 auth-side-content">
            <Switch>{getSideContent(authRoutes)}</Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
