export function MicrodepositValidation(values, errors) {
  if (values.amount1) {
    if (!values.amount1.match(/^[0-9]*$/)) {
      errors.amount1 =
        "Micro-deposit amount must only contain numerical digits";
    } else if (values.amount1.length !== 2) {
      errors.amount1 =
        "Micro-deposit amount must be 2 decimal places (eg. $0.23)";
    } else {
      delete errors.amount1;
    }
  } else {
    delete errors.amount1;
  }

  if (values.amount2) {
    if (!values.amount2.match(/^[0-9]*$/)) {
      errors.amount2 =
        "Micro-deposit amount must only contain numerical digits";
    } else if (values.amount2.length !== 2) {
      errors.amount2 =
        "Micro-deposit amount must be 2 decimal places (eg. $0.23)";
    } else {
      delete errors.amount2;
    }
  } else {
    delete errors.amount2;
  }

  return errors;
}
