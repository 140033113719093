import { Route, Switch, useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import React, { useEffect, useState } from "react";
import { BulkCreditDetails } from "./BulkCreditDetails";
import { BulkCreditSelectFromAccount } from "./BulkCreditSelectFromAccount";
import { BulkCreditReview } from "./BulkCreditReview";
import { BulkCreditComplete } from "./BulkCreditComplete";
import { BulkCreditFailedPayments } from "./BulkCreditFailedPayments";

export const BulkCredit = () => {
  const history = useHistory();
  const [state] = useSimpleContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!state) {
      history.push("/bulk-payment/credit/create/selectAccount", {
        steps: 5,
        currStep: 1,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null;
  } else {
    return (
      <>
        <Switch>
          <Route
            path="/bulk-payment/credit/create/selectAccount"
            render={() => <BulkCreditSelectFromAccount />}
          />
          <Route
            path="/bulk-payment/credit/create/details"
            render={() => <BulkCreditDetails />}
          />
          <Route
            path="/bulk-payment/credit/create/review"
            render={() => <BulkCreditReview />}
          />
          <Route
            path="/bulk-payment/credit/create/failed-payments"
            render={() => <BulkCreditFailedPayments />}
          />
          <Route
            path="/bulk-payment/credit/create/complete"
            render={() => <BulkCreditComplete />}
          />
        </Switch>
      </>
    );
  }
};
