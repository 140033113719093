import {
  PASSWORD_LENGTH,
  PHONE_ERROR,
  verifyLength,
  verifyPhone,
} from "../Helpers";

export function registerInviteFormValidation(values, errors) {
  if (values.password) {
    if (!verifyLength(values.password, PASSWORD_LENGTH)) {
      errors.password = "Password must be greater than 8 Characters";
    } else {
      delete errors.password;
    }
  }

  if (values.phone) {
    if (!verifyPhone(values.phone)) {
      errors.phone = PHONE_ERROR;
    } else {
      delete errors.phone;
    }
  }

  if (values.phone === "") {
    delete errors.phone;
  }

  if (values.password === "") {
    delete errors.password;
  }

  delete errors.securityPin;
  delete errors.email;
  return errors;
}
