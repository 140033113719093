import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function createDebit(input, output, hooks) {
  const query = buildMutation(input, output, "createDebit");
  return callMutation(query, hooks);
}

createDebit.propTypes = {
  input: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
    toBankAccountId: PropTypes.string.isRequired,
    statement: PropTypes.string.isRequired,
    padType: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function payDebit(input, output, hooks) {
  const query = buildMutation(input, output, "payDebit");
  return callMutation(query, hooks);
}

payDebit.propTypes = {
  input: PropTypes.shape({
    contactEmail: PropTypes.string,
    debitId: PropTypes.string.isRequired,
    fromBankAccountId: PropTypes.string.isRequired,
    signaturePad: PropTypes.bool.isRequired,
    signaturePadTime: PropTypes.number.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function createCustomerCreditCard(input, output, hooks) {
  const query = buildMutation(input, output, "createCustomerCreditCard");
  return callMutation(query, hooks);
}

createCustomerCreditCard.propTypes = {
  input: PropTypes.shape({
    contactEmail: PropTypes.string.isRequired,
    contactName: PropTypes.string,
    companyId: PropTypes.string.isRequired,
    ccToken: PropTypes.string.isRequired,
    merchantId: PropTypes.string,
    brand: PropTypes.string,
    masked_card_number: PropTypes.string,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function payDebitViaCreditCard(input, output, hooks) {
  const query = buildMutation(input, output, "payDebitViaCreditCard");
  return callMutation(query, hooks);
}

payDebitViaCreditCard.propTypes = {
  input: PropTypes.shape({
    debitId: PropTypes.string.isRequired,
    stripeCustomerId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function createInternal(input, output, hooks) {
  const query = buildMutation(input, output, "createInternal");
  return callMutation(query, hooks);
}

createInternal.propTypes = {
  input: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    toBankAccountId: PropTypes.string.isRequired,
    fromBankAccountId: PropTypes.string.isRequired,
    statement: PropTypes.string.isRequired,
    note: PropTypes.string,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function deleteDebit(input, output, hooks) {
  const query = buildMutation(input, output, "deleteTransaction");
  return callMutation(query, hooks);
}

deleteDebit.propTypes = {
  input: PropTypes.shape({
    transactionId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function resendTransactionRequestEmail(input, output, hooks) {
  const query = buildMutation(input, output, "resendTransactionRequestEmail");
  return callMutation(query, hooks);
}

resendTransactionRequestEmail.propTypes = {
  input: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.shape({ ok: PropTypes.bool }).isRequired,
};

export function createCredit(input, output, hooks) {
  const query = buildMutation(input, output, "createCredit");
  return callMutation(query, hooks);
}

createCredit.propTypes = {
  input: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    toBankAccountId: PropTypes.string,
    fromBankAccountId: PropTypes.string.isRequired,
    statement: PropTypes.string.isRequired,
    note: PropTypes.string,
    contactName: PropTypes.string,
    contactEmail: PropTypes.string,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function reviewCredit(input, output, hooks) {
  const query = buildMutation(input, output, "reviewCredit");
  return callMutation(query, hooks);
}

reviewCredit.propTypes = {
  input: PropTypes.shape({
    creditIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function makeApproval(input, output, hooks) {
  const query = buildMutation(input, output, "approveTransaction");
  return callMutation(query, hooks);
}

makeApproval.propTypes = {
  input: PropTypes.shape({
    transactions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export function approveTransaction(input, output, hooks) {
  const query = buildMutation(input, output, "approveTransaction");
  return callMutation(query, hooks);
}

approveTransaction.propTypes = {
  input: PropTypes.shape({
    creditIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function acceptCredit(input, output, hooks) {
  const query = buildMutation(input, output, "acceptCredit");
  return callMutation(query, hooks);
}

acceptCredit.propTypes = {
  input: PropTypes.shape({
    creditId: PropTypes.string.isRequired,
    toBankAccountId: PropTypes.string.isRequired,
    securityAnswer: PropTypes.string,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function retryTransaction(input, output, hooks) {
  const query = buildMutation(input, output, "retryTransaction");
  return callMutation(query, hooks);
}

retryTransaction.propTypes = {
  input: PropTypes.shape({
    transactionId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function reRouteTransaction(input, output, hooks) {
  const query = buildMutation(input, output, "reRouteTransaction");
  return callMutation(query, hooks);
}

reRouteTransaction.propTypes = {
  input: PropTypes.shape({
    transactionId: PropTypes.string.isRequired,
    institutionNumber: PropTypes.string.isRequired,
    transitNumber: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
  }).isRequired,
};

export function reSourceTransaction(input, output, hooks) {
  const query = buildMutation(input, output, "reSourceTransaction");
  return callMutation(query, hooks);
}

reSourceTransaction.propTypes = {
  input: PropTypes.shape({
    transactionId: PropTypes.string.isRequired,
    institutionNumber: PropTypes.string.isRequired,
    transitNumber: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
  }).isRequired,
};
