import Select from "react-select";
import React, { useEffect, useState } from "react";
import { getBankAccountsSelection } from "../../modules/Helpers";
import PropTypes from "prop-types";
import { allBankAccounts } from "../../api_client/queries/bankAccounts";
import useGQL from "../../api_client/UseGQL";
import VariableSporadicPADModal from "../modals/VariableSporadicPADModal";

function BankAccountSelection(props) {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [variableSporadicPADModal, setVariableSporadicPADModal] =
    useState(false);
  let gqlHooks = useGQL();
  const creditFlow = location.pathname.includes("credit");
  const debitFlow = location.pathname.includes("debit");
  const internalFlow =
    location.pathname.includes("internal") && props.padSignatureRequired;

  useEffect(() => {
    getAllBankAccounts(props.exclude);
    // Todo - remove reset dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exclude, props.reset]);

  function getAllBankAccounts(exclude) {
    let output = {
      identifier: true,
      title: true,
      institution: true,
      accountNumber: true,
      state: true,
      variableDebitPad: true,
      convergeMerchantAccount: { merchantId: true },
    };
    allBankAccounts({ limit: 100 }, output, gqlHooks).then((response) => {
      let readyAccounts = response.data.filter(
        (bankAccount) => bankAccount.state === "READY"
      );
      let bankAccounts = getBankAccountsSelection(readyAccounts);
      let includedBankAccounts = bankAccounts.filter((bankAccount) =>
        exclude ? exclude.key !== bankAccount.key : bankAccount.key
      );
      setBankAccounts(includedBankAccounts);
      const selectedBankAccount =
        props.bankAccount &&
        includedBankAccounts.filter(
          (account) => account.key === props.bankAccount.key
        )?.[0];
      const hasOneAccount = includedBankAccounts.length === 1;
      const signedPAD = includedBankAccounts[0]?.variableDebitPad;
      if (selectedBankAccount) {
        props.setBankAccount(selectedBankAccount);
      }
      // if the company only has one bank account AND they are either in the debit flow OR they have signed the variable PAD
      if (hasOneAccount && (debitFlow || (creditFlow && signedPAD))) {
        selectAccount(includedBankAccounts[0]);
      } else if (!props.bankAccount) {
        props.setBankAccount({
          value: "",
          label: "Select an account",
        });
      }
    });
  }

  function handlePADAccept() {
    getAllBankAccounts();
    setVariableSporadicPADModal(!variableSporadicPADModal);
  }

  function selectAccount(value) {
    props.setBankAccount(value);
    const requiresPADSignature =
      (creditFlow || internalFlow) && !value.variableDebitPad;
    if (requiresPADSignature) {
      setVariableSporadicPADModal(true);
    }
  }
  return (
    <>
      {(creditFlow || internalFlow) && props.bankAccount && (
        <VariableSporadicPADModal
          isOpen={variableSporadicPADModal}
          toggle={handlePADAccept}
          bankAccountIdentifier={props.bankAccount.value}
        />
      )}
      <Select
        onChange={(value) => selectAccount(value)}
        className="react-select default"
        classNamePrefix="react-select"
        name="toBankAccountId"
        value={props.bankAccount}
        options={bankAccounts}
        required
      />
    </>
  );
}

export default BankAccountSelection;

BankAccountSelection.propTypes = {
  setBankAccount: PropTypes.func.isRequired,
  bankAccount: PropTypes.object,
  exclude: PropTypes.object,
  padSignatureRequired: PropTypes.bool,
  reset: PropTypes.bool,
};
