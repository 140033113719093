import React, { Fragment, useEffect } from "react";
import { Button, Navbar } from "reactstrap";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import LogoWithText from "../../assets/img/LogoWithText.png";
import LogoWithLightText from "../../assets/img/LogoWithText-Light.png";
import { formattedDollarValue, appName } from "../../modules/Helpers";

function PaymentNavbar(props) {
  useEffect(() => {
    if (location.search.includes("ticket=true")) {
      props.toggleSupport();
    }
  }, []);

  return (
    <Fragment>
      <Navbar className="payment-navbar">
        <div className="navbar-items">
          <div className="d-inline-block wizard-info-col">
            {props.companyLogo ? (
              <img width={"32px"} src={props.companyLogo} alt="Company Logo" />
            ) : (
              <></>
            )}
            <span className="ml-2">
              {props.summaryText}
              {props.amount !== null ? (
                <b>: {formattedDollarValue(props.amount)}</b>
              ) : null}
              <span className="text-muted">
                {props.frequencyText.toLowerCase()}
              </span>
            </span>
          </div>
          <div className="help-logo-wrapper">
            <div>
              <span>Need some help?</span>
              <Button
                onClick={props.toggleSupport}
                className="sp-button btn-primary mr-3"
              >
                Talk to support
              </Button>
            </div>
            <div className="desktop mr-3">
              <NavLink
                to=""
                className="simple-text logo-mini"
                onClick={(e) => e.preventDefault()}
              >
                <div style={{ maxWidth: "140px" }}>
                  <img src={LogoWithText} alt={`${appName} Logo`} />
                </div>
              </NavLink>
            </div>
            <div className="mobile">
              <NavLink
                to=""
                className="simple-text logo-mini"
                onClick={(e) => e.preventDefault()}
              >
                <div style={{ maxWidth: "140px" }}>
                  <img src={LogoWithLightText} alt={`${appName} Logo`} />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </Navbar>
    </Fragment>
  );
}

export default PaymentNavbar;

PaymentNavbar.propTypes = {
  summaryText: PropTypes.string,
  companyLogo: PropTypes.any,
  amount: PropTypes.string,
  frequencyText: PropTypes.string,
  toggleSupport: PropTypes.func,
};

PaymentNavbar.defaultProps = {
  companyName: "Company Name",
  companyLogo: null,
  frequencyText: "",
};
