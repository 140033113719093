/* eslint-disable react/prop-types */
// Frameworks & Libraries
import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useAppValue } from "../contexts/Context";
import { useGQLContext } from "../api_client/client";
import { trackEvent } from "../modules/analytics";
import useGQL from "../api_client/UseGQL";
import useApiValue from "../hooks/useApiValue";
import VerifyEmailPage from "../views/onboarding/generic/VerifyEmailPage";
import { CustomLoader } from "../components/loaders/CustomLoader";
import * as Sentry from "@sentry/browser";

export function AuthGateway(props) {
  const [token] = useGQLContext();
  const [, dispatch] = useAppValue();
  const location = useLocation();
  let gqlHooks = useGQL();
  const { values, loading } = useApiValue({
    emails: {
      verified: true,
      address: true,
    },
  });

  useEffect(() => {
    if (location.pathname) {
      dispatch({
        type: "setRedirectURL",
        redirectURL: location.pathname + location.search + location.hash,
      });
    }
  }, []);

  if (loading) {
    return <CustomLoader general={true} />;
  }

  if (token.token) {
    if (values.emails[0].verified) {
      Sentry.setUser({ email: values.emails[0].address });
      return props.children;
    } else {
      return <VerifyEmailPage />;
    }
  } else {
    trackEvent("Logout", {}, gqlHooks);
    return <Redirect from="/" to="/auth/login" />;
  }
}
