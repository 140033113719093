import { useGQLContext } from "api_client/client";
import { useApiFieldErrors } from "api_client/UseGQL";

const { useEffect, useState } = require("react");

const useFormInline = (
  callback = () => null,
  validateAll = () => null,
  validateOne = null
) => {
  const [gqlContext, dispatch] = useGQLContext();
  const [values, setValues] = useState({});
  const [formValidationErrors, setErrors] = useState({});
  const apiErrors = useApiFieldErrors(values);

  let errors = { ...formValidationErrors, ...apiErrors };

  useEffect(() => {
    if (
      gqlContext.fieldErrors &&
      Object.entries(values).length === 0 &&
      Object.entries(gqlContext.fieldErrors).length !== 0
    ) {
      dispatch({
        type: "setFieldErrors",
        fieldErrors: {},
      });
    }
  });

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    let validateErrors = validateAll(values, formValidationErrors);
    if (Object.keys({ ...validateErrors, ...apiErrors }).length === 0) {
      if (Object.keys(formValidationErrors).length === 0) {
        setErrors({ ...validateErrors }, callback());
      } else {
        callback();
      }
    } else {
      setErrors({ ...validateErrors });
    }
  };

  const handleEventValue = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleEventApiErrors = (event) => {
    if (apiErrors[event.target.name]) {
      delete apiErrors[event.target.name];
      dispatch({
        type: "setFieldErrors",
        fieldErrors: apiErrors,
      });
    }
  };

  const validateFormItem = (name, value) => {
    if (validateOne) {
      let ers = {
        ...validateOne(
          name,
          { ...values, ...{ [name]: value } },
          formValidationErrors
        ),
      };

      setErrors(ers);
    } else {
      let ers = validateAll(
        { ...values, ...{ [name]: value } },
        formValidationErrors
      );

      setErrors(ers);
    }
  };

  const validateForm = () => {
    let ers = { ...validateAll(values, formValidationErrors) };

    setErrors(ers);
  };

  const handleChange = (event) => {
    event.persist();
    handleEventValue(event);
    handleEventApiErrors(event);
    validateFormItem(event.target.name, event.target.value);
  };

  const handleTableFieldChange = (event) => {
    event.persist();
    handleEventValue(event);
    validateFormItem(event.target.name, event.target.value);
  };

  return {
    handleChange,
    handleTableFieldChange,
    handleSubmit,
    validateForm,
    setErrors,
    values,
    setValues,
    errors,
    // waitErrors,
  };
};

export default useFormInline;
