/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import RecurringPlanTable from "../../../../components/tables/RecurringPlanTable";
import useForm from "../../../../hooks/UseForms";
import CustomTableSearch from "../../../../components/forms/CustomTableSearch";
import RecurringPlansFilters from "../../../../components/modals/RecurringPlansFilters";
import { Button, UncontrolledCollapse } from "reactstrap";
import FilterIcon from "../../../../assets/icons/FilterIcon";

function RecurringPlans(props) {
  const { values } = useForm(null, () => null);
  const [filters, setFilters] = useState({});
  const [filterCount, setFilterCount] = useState(0);
  const [numPlans, setNumPlans] = useState(0);

  useEffect(() => {
    let count = 0;
    if (filters.contactTag) {
      count++;
    }
    if (filters.active) {
      count++;
    }
    setFilterCount(count);
  }, [filters]);

  useEffect(() => {
    if (values.search !== undefined) {
      const timeoutId = setTimeout(
        () => setFilters({ ...filters, search: values.search }),
        500
      );
      return () => clearTimeout(timeoutId);
    }
  }, [values.search]);

  const getNumPlans = useCallback((num) => {
    setNumPlans(num);
  });

  return (
    <Fragment>
      <div className="content">
        <div className="d-flex flex-column justify-content-between">
          <h2 className="mb-3 font-weight-600 page-title">
            Plans{" "}
            <span className="font-weight-500 text-muted text-xl">
              ({numPlans})
            </span>
          </h2>
          <div className="d-flex flex-row flex-grow-1 justify-content-end">
            <CustomTableSearch filters={filters} setFilters={setFilters} />
            <div className="ml-3">
              <Button
                className="btn-simple btn-primary sp-button-simple"
                id="buttonToggler"
              >
                <FilterIcon width={15} height={15} strokewidth={3} />{" "}
                <span className="filter-btn-label">Filter</span>
                {filterCount > 0 && (
                  <Fragment>
                    {" "}
                    <span className="text-warning">{filterCount}</span>
                  </Fragment>
                )}
              </Button>
            </div>
          </div>
          <UncontrolledCollapse toggler="#buttonToggler">
            <RecurringPlansFilters filters={filters} setFilters={setFilters} />
          </UncontrolledCollapse>
        </div>
        <RecurringPlanTable
          getNumPlans={getNumPlans}
          {...props}
          filters={filters}
        />
      </div>
    </Fragment>
  );
}

export default RecurringPlans;
