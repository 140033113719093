import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import PropTypes from "prop-types";

export const ContentPlaceHolder = ({ titleText, icon }) => {
  return (
    <div
      style={{
        maxWidth: "536px",
        margin: "0 auto",
        padding: "60px 0",
        color: "#5f6368",
      }}
      className={"d-flex flex-column align-items-center justify-content-center"}
    >
      <FontAwesomeIcon icon={icon} size="5x" />
      <h2 style={{ color: "#5f6368" }} className={"mt-2 mb-1"}>
        {titleText}
      </h2>
    </div>
  );
};

ContentPlaceHolder.propTypes = {
  titleText: PropTypes.string,
  icon: PropTypes.element,
};
