import React from "react";
import { Col, Row } from "reactstrap";
import {
  convertToDate,
  getEmailStatus,
  getStatus,
} from "../../../modules/Helpers";
import PropTypes from "prop-types";

function TransactionTimeline(props) {
  const statusRow = (state) => {
    let status = getStatus(
      state.state,
      props.transaction.direction,
      state.state.reason && state.state.reason.in
    );
    let emailStatus = getEmailStatus(
      props.transaction.transactionRequestedContactEmail?.status,
      props.transaction?.direction
    );
    const link = state.state === 7 && props.transaction.method === "LINK";
    const emailWithStatus =
      state.state === 7 &&
      props.transaction.transactionRequestedContactEmail?.status;
    return (
      <div className="timeline-item" key={state.state}>
        <div className="timeline-item--content">
          <div className={`timeline-item--icon bg-gray}`} />
          <h5 className="mb-0 font-weight-bold">
            {link ? (
              "Link created"
            ) : emailWithStatus ? (
              <>{emailStatus?.value}</>
            ) : (
              <>{status?.value}</>
            )}
            <span className="font-weight-400 text-muted">
              {" "}
              - {convertToDate(state.createdAt)}
            </span>
          </h5>
          <p>
            {link ? (
              "Request link created. Waiting for link to be shared with and accepted by your contact."
            ) : emailWithStatus ? (
              <>{emailStatus?.description}</>
            ) : (
              <>{status?.description}</>
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <div className="timeline-list mt-4 mb-2">
            {props.states.map((state) => statusRow(state))}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default TransactionTimeline;
TransactionTimeline.propTypes = {
  states: PropTypes.array.isRequired,
  transaction: PropTypes.object,
};
