/* eslint-disable react/prop-types */
import React, { Fragment, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  convertToDate,
  CREDIT,
  DEBIT,
  formatDate,
  formattedDollarValue,
  INTERNAL,
} from "../../modules/Helpers";
import useGQL from "../../api_client/UseGQL";
import { allTransactions } from "../../api_client/queries/transactions";
import ReactTable from "./ReactTable";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import BillSkeletonCard from "../skeleton/BillSkeletonCard";
import BillTransactionCard from "../cards/BillTransactionCard";

function BillTransactionTable(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let gqlHooks = useGQL();
  const [count, setCount] = useState(0);
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2f2f2f",
      color: "#ffffff",
      maxWidth: 285,
      fontSize: theme.typography.pxToRem(13),
      padding: 15,
    },
    arrow: {
      color: "#2f2f2f",
    },
  }))(Tooltip);

  const getData = useCallback(async ({ pageSize }) => {
    setLoading(true);
    let offset = 0;
    const params = {
      limit: pageSize,
      offset: offset,
      billId: props.billId,
    };
    let output = {
      createdAt: true,
      estDepositDate: true,
      identifier: true,
      amount: true,
      statement: true,
      direction: true,
      cost: true,
      nsfCost: true,
      contact: {
        identifier: true,
        email: true,
        name: true,
      },
    };
    const response = await allTransactions(params, output, gqlHooks);
    if (response) {
      setData(response.data);
      setCount(response.count);
    }
    setLoading(false);
  }, []);

  let columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Deposit Date",
            accessor: "estDepositDate",
            // eslint-disable-next-line react/prop-types,react/display-name
            Cell: ({ cell }) => (
              <>
                <div className="font-weight-bold">
                  {formatDate(cell.row.original.estDepositDate)}
                </div>
              </>
            ),
          },
          {
            Header: "Date Created",
            accessor: "createdAt",
            // eslint-disable-next-line react/prop-types,react/display-name
            Cell: ({ cell }) => (
              <>
                <div className="text-muted">{convertToDate(cell.value)}</div>
              </>
            ),
          },
          {
            Header: "Contact",
            accessor: "contact",
            // eslint-disable-next-line react/prop-types,react/display-name
            Cell: ({ cell }) => (
              <>
                <div className="mt-1" style={{ lineHeight: "1.2" }}>
                  {cell.value && cell.value.name ? cell.value.name : ""}
                </div>
                <div className="text-muted text-sm mb-1">
                  {cell.row.original.direction === INTERNAL ? (
                    "Internal Transfer"
                  ) : (
                    <>
                      {cell.value
                        ? cell.value.email
                        : "Pending Payment Acceptance"}
                    </>
                  )}
                </div>
              </>
            ),
          },
          {
            Header: "Statement",
            accessor: "statement",
            // eslint-disable-next-line react/prop-types,react/display-name
            Cell: ({ cell }) => (
              <>
                <div>{cell.row.original.statement}</div>
              </>
            ),
          },
          {
            Header: "Amount",
            accessor: "amount",
            // eslint-disable-next-line react/display-name
            Cell: ({ cell }) => (
              <div>
                <div>
                  {cell.row.original.direction === DEBIT ? (
                    <div>
                      <div style={{ lineHeight: "1.2" }}>
                        {formattedDollarValue(cell.row.original.amount)}
                      </div>
                      <div className="text-muted text-sm">Receivable</div>
                    </div>
                  ) : cell.row.original.direction === CREDIT ? (
                    <div>
                      <div style={{ lineHeight: "1.2" }}>
                        ({formattedDollarValue(cell.row.original.amount)})
                      </div>
                      <div className="text-muted text-sm">Payable</div>
                    </div>
                  ) : (
                    <div>
                      <div className="mt-1" style={{ lineHeight: "1.2" }}>
                        {formattedDollarValue(cell.row.original.amount)}
                      </div>
                      <div className="text-muted text-sm">Internal</div>
                    </div>
                  )}
                </div>
              </div>
            ),
          },
          {
            Header: "Payment Cost",
            accessor: "cost",
            // eslint-disable-next-line react/prop-types,react/display-name
            Cell: ({ cell }) => (
              <>
                {cell.row.original.nsfCost === null ? (
                  <>
                    <div
                      className="mt-1 text-right font-weight-600"
                      style={{ lineHeight: "1.2" }}
                    >
                      {formattedDollarValue(cell.row.original.cost)}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="mt-1 text-right font-weight-600"
                      style={{ lineHeight: "1.2" }}
                    >
                      {formattedDollarValue(cell.row.original.cost)}
                    </div>
                    <div className="text-muted text-right text-sm mb-0">
                      <CustomTooltip
                        title={`${formattedDollarValue(
                          cell.row.original.nsfCost
                        )} Non-Sufficient Funds in bank account penalty`}
                        placement="top"
                        arrow
                      >
                        <p className="d-inline-block text-warning">
                          + {formattedDollarValue(cell.row.original.nsfCost)}{" "}
                          NSF Penalty
                        </p>
                      </CustomTooltip>
                    </div>
                  </>
                )}
              </>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Fragment>
      <ReactTable
        loading={loading}
        getData={getData}
        data={data}
        columns={columns}
        count={count}
        styles="transaction-table"
        MobileCard={BillTransactionCard}
        MobileSkeleton={BillSkeletonCard}
      />
    </Fragment>
  );
}

export default BillTransactionTable;

BillTransactionTable.propTypes = {
  filters: PropTypes.object,
  search: PropTypes.string,
};
