import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Fade, Row } from "reactstrap";
import useImageUpload from "../../../hooks/UseImageUpload";
import defaultImage from "../../../assets/img/DocumentPlaceholder.png";
import { PulseLoader } from "react-spinners";
import OnboardingDocuments from "../../../components/forms/OnboardingDocuments";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";
import { useHistory } from "react-router-dom";
import success from "../../../assets/img/onboarding/AddLegalInfoCompleteUpdated.svg";
import { withApiValue } from "../../../modules/WithApiValue";
import { domains } from "modules/Helpers";

const config = {
  company: {
    kyc: {
      submitted: true,
      customerInvoice: true,
      bankStatements1: true,
      bankStatements2: true,
      bankStatements3: true,
    },
  },
  kyc: {
    updateRequired: true,
    resubmitted: true,
  },
  email: true,
};

function OnboardingOrganizationDocuments(props) {
  const [updateOrganizationLegalInfo, setUpdateOrganizationLegalInfo] =
    useState(true);
  let gqlHooks = useGQL();
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [submitClicked, setSubmitClicked] = useState(false);
  const bankStatements1 = useImageUpload(
    fileInputRef2,
    defaultImage,
    "STATEMENTS",
    "bankStatements1"
  );
  const bankStatements2 = useImageUpload(
    fileInputRef3,
    defaultImage,
    "STATEMENTS",
    "bankStatements2"
  );
  const bankStatements3 = useImageUpload(
    fileInputRef4,
    defaultImage,
    "STATEMENTS",
    "bankStatements3"
  );
  const customerInvoice = useImageUpload(
    fileInputRef1,
    defaultImage,
    "INVOICE"
  );
  const [documentsUploaded, setDocumentsUploaded] = useState(false);

  useEffect(() => {
    if (Boolean(localStorage.getItem("documentsUploaded")) == true) {
      setSubmitClicked(true);
    }
    if (
      customerInvoice.imageState.file &&
      bankStatements1.imageState.file &&
      bankStatements2.imageState.file &&
      bankStatements3.imageState.file
    ) {
      setDocumentsUploaded(true);
    }
  }, [
    bankStatements1.imageState,
    bankStatements2.imageState,
    bankStatements3.imageState,
    customerInvoice.imageState,
  ]);

  useEffect(() => {
    if (props.company?.kyc) {
      if (
        props.company?.kyc.customerInvoice !== "" &&
        props.company?.kyc.bankStatements1 !== "" &&
        props.company?.kyc.bankStatements2 !== "" &&
        props.company?.kyc.bankStatements3 !== ""
      ) {
        setUpdateOrganizationLegalInfo(false);
      }
      if (props.company?.kyc.customerInvoice !== "") {
        customerInvoice.setImageState({
          file: { name: props.company.kyc.customerInvoice },
        });
      }
      if (props.company?.kyc.bankStatements1 !== "") {
        bankStatements1.setImageState({
          file: { name: props.company.kyc.bankStatements1 },
        });
      }
      if (props.company?.kyc.bankStatements2 !== "") {
        bankStatements2.setImageState({
          file: { name: props.company.kyc.bankStatements2 },
        });
      }
      if (props.company?.kyc.bankStatements3 !== "") {
        bankStatements3.setImageState({
          file: { name: props.company.kyc.bankStatements3 },
        });
      }
    }
  }, []);

  async function submit() {
    setLoading(true);
    let r;
    if (bankStatements1.imageState.file?.size < 1000000) {
      r = await bankStatements1.handleImageSubmit();
    } else {
      r = true;
    }
    if (bankStatements2.imageState.file?.size < 1000000) {
      r = await bankStatements2.handleImageSubmit();
    } else {
      r = true;
    }
    if (bankStatements3.imageState.file?.size < 1000000) {
      r = await bankStatements3.handleImageSubmit();
    } else {
      r = true;
    }
    if (r) {
      if (customerInvoice.imageState.file?.size < 1000000) {
        r = await customerInvoice.handleImageSubmit();
      } else {
        r = true;
      }
      if (r) {
        trackEvent("organizationDocumentsUploaded", {}, gqlHooks);
        setUpdateOrganizationLegalInfo(false);
        setDocumentsUploaded(true);
        setSubmitClicked(true);
        localStorage.setItem("documentsUploaded", true);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    setLoading(false);
  }

  function toggleUpdateLegalInfo() {
    setUpdateOrganizationLegalInfo(true);
    trackEvent("editOrganizationDocuments", {}, gqlHooks);
  }

  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Col className="m-auto" style={{ maxWidth: 600 }}>
          <Fade>
            {updateOrganizationLegalInfo ? (
              <Fragment>
                <h2 className="text-center mb-2 font-weight-600">
                  Upload verification documents
                </h2>
                <p className="text-muted text-center mb-3">
                  Learn why we need this information{" "}
                  <a
                    href={`https://${domains.HELP}/why-does-mazumago-need-this-information/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </p>

                <div>
                  <OnboardingDocuments
                    customerInvoice={customerInvoice}
                    bankStatements1={bankStatements1}
                    bankStatements2={bankStatements2}
                    bankStatements3={bankStatements3}
                    fileInputRef1={fileInputRef1}
                    fileInputRef2={fileInputRef2}
                    fileInputRef3={fileInputRef3}
                    fileInputRef4={fileInputRef4}
                  />
                </div>
                <Button
                  type="button"
                  onClick={submit}
                  block
                  className="mt-4"
                  color="primary"
                  disabled={!documentsUploaded}
                >
                  {loading ? <PulseLoader color="white" size={10} /> : "Submit"}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <div className="text-center">
                  <img
                    src={success}
                    alt="Legal Information Submitted"
                    width="100"
                    height="100"
                    className="m-auto d-block"
                  />
                  <h2 className="text-center mt-3 mb-4 font-weight-600">
                    Documents submitted!
                    <i
                      style={{ fontSize: "14px" }}
                      className="ml-2 cursor-pointer text-primary"
                      onClick={toggleUpdateLegalInfo}
                    >
                      Update
                    </i>
                  </h2>
                  <Button
                    disabled={!submitClicked}
                    onClick={() => {
                      if (
                        (props.kyc && props.kyc.updateRequired) ||
                        (props.kyc && props.kyc.resubmitted)
                      ) {
                        history.push("/onboarding/complete");
                      } else {
                        history.push("/onboarding/security");
                      }
                    }}
                    color={"primary"}
                    className="m-auto d-block mt-4"
                  >
                    {loading ? (
                      <PulseLoader color="white" size={10} />
                    ) : (
                      "Proceed"
                    )}
                  </Button>
                </div>
              </Fragment>
            )}
          </Fade>
        </Col>
      </Row>
    </Fragment>
  );
}

export default withApiValue(OnboardingOrganizationDocuments, config);

OnboardingOrganizationDocuments.propTypes = {
  company: PropTypes.object,
};
