import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Card } from "reactstrap";

function InviteCard(props) {
  return (
    <Fragment>
      {" "}
      <Card className="t-card border-bottom">
        <div className="t-card-row">
          <span className="text-primary text-sm font-weight-600">Invite</span>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <div className="font-weight-bold">{props.item?.email}</div>
            <div className="text-muted">
              Access code: <b>{props.item.securityPin}</b>
            </div>
            <div className="text-muted">
              {props.item?.member?.profile?.phone
                ? props.item?.member?.profile?.phone
                : ""}
            </div>
            <Button
              onClick={() =>
                props.removeInvite(props.item.identifier, props.item.email)
              }
              color="danger"
              className="p-0 btn-link"
              size="sm"
            >
              Delete <i className="tim-icons icon-simple-remove" />
            </Button>
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

export default InviteCard;

InviteCard.propTypes = {
  item: PropTypes.object,
  removeInvite: PropTypes.func,
};
