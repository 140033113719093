import React from "react";
import useApiValue from "../hooks/useApiValue";
import { CustomLoader } from "../components/loaders/CustomLoader";

// eslint-disable-next-line react/display-name
export const withApiValue =
  (Component, config, Loader = CustomLoader) =>
  // eslint-disable-next-line react/display-name
  ({ ...props }) => {
    const { values, loading, update, setLoading } = useApiValue({
      ...config,
    });

    if (loading) {
      return <Loader />;
    } else {
      return (
        <Component
          {...props}
          {...values}
          update={update}
          setLoading={setLoading}
        />
      );
    }
  };
