const UserPermissions = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  USER: "USER",
};

export function userPermissionNames(permission) {
  switch (permission) {
    case UserPermissions.OWNER:
      return "Owner";
    case UserPermissions.ADMIN:
      return "Admin";
    case UserPermissions.USER:
      return "User";
    case null:
      return null;
  }

  return permission
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export default UserPermissions;
