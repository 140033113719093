import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import VariableSporadicPAD from "../PAD/VariableSporadicPAD";

function VariableSporadicPADModal(props) {
  return (
    <Fragment>
      <Modal centered size="md" isOpen={props.isOpen} toggle={() => null}>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Button close onClick={props.toggle} />
            </Col>
          </Row>
          <h4 className="text-center">Pre Authorized Debit Agreement (PAD)</h4>
          <Col>
            <VariableSporadicPAD
              bankAccountIdentifier={props.bankAccountIdentifier}
              toggle={props.toggle}
            />
          </Col>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default VariableSporadicPADModal;

VariableSporadicPADModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  bankAccountIdentifier: PropTypes.string.isRequired,
};
