import React from "react";
import { Col, Row } from "reactstrap";
import { convertToDate, getStatusPublic } from "../../../modules/Helpers";
import PropTypes from "prop-types";

function TransactionTimelinePublic(props) {
  const statusRow = (state) => {
    let status = getStatusPublic(state.state, props.transaction.direction);
    return (
      <div className="timeline-item" key={state.state}>
        <div className="timeline-item--content">
          <div className={`timeline-item--icon bg-gray}`} />
          <h5 className="mb-0 font-weight-bold">
            {status.value}{" "}
            <span className="font-weight-400 text-muted">
              - {convertToDate(state.createdAt)}
            </span>
          </h5>
          <p>{status.description}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <div className="timeline-list mt-4 mb-2">
            {props.states.map((state) => statusRow(state))}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default TransactionTimelinePublic;
TransactionTimelinePublic.propTypes = {
  states: PropTypes.array.isRequired,
  transaction: PropTypes.object,
};
