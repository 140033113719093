import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faPen,
  faPowerOff,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { CustomTooltip } from "../../../modules/Helpers";
import { baseUrl } from "../../../modules/Helpers";
import { useToasts } from "react-toast-notifications";

function ContactActionsDesktop(props) {
  const { addToast } = useToasts();
  const copyLinkToClipboard = () => {
    addToast(
      "Customer portal link copied to clipboard, include in an email or message sent to them",
      {
        appearance: "success",
        autoDismiss: true,
      }
    );
  };
  return (
    <>
      <div className="details-action-wrapper">
        {props.contact.archived === true ? (
          <CustomTooltip title="Activate contact" placement="bottom" arrow>
            <Button className="btn-link" onClick={props.toggleArchive}>
              <FontAwesomeIcon icon={faPowerOff} />
            </Button>
          </CustomTooltip>
        ) : (
          <>
            <CustomTooltip title="Edit contact" placement="bottom" arrow>
              <Button className="btn-link" onClick={props.editContact}>
                <FontAwesomeIcon
                  style={{ fontSize: "1.2rem", marginTop: "5px" }}
                  icon={faPen}
                />
              </Button>
            </CustomTooltip>
            <CustomTooltip title="Archive contact" placement="bottom" arrow>
              <Button className="btn-link" onClick={props.toggleArchive}>
                <FontAwesomeIcon icon={faArchive} />
              </Button>
            </CustomTooltip>
            <CustomTooltip
              title="Copy this link, which sends your customer to their outstanding-transactions portal"
              placement="bottom"
              arrow
            >
              <Button className="btn-link">
                <CopyToClipboard
                  text={baseUrl + "/customer/" + props.contact.identifier}
                  onCopy={copyLinkToClipboard}
                >
                  <FontAwesomeIcon icon={faLink} />
                </CopyToClipboard>
              </Button>
            </CustomTooltip>
          </>
        )}
      </div>
    </>
  );
}

export default ContactActionsDesktop;
ContactActionsDesktop.propTypes = {
  contact: PropTypes.object,
  editContact: PropTypes.func,
  toggleArchive: PropTypes.func,
};
