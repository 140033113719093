import SweetAlert from "react-bootstrap-sweetalert";
import React from "react";

function successAlert(
  hideAlert,
  setAlert,
  message = "Completed successfully",
  title = "Success"
) {
  hideAlert();
  setAlert(
    <SweetAlert
      success
      title={title}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      showConfirm={false}
      showCancel={false}
    >
      {message}
    </SweetAlert>
  );
}

export default successAlert;
