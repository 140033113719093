import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import InternalDetailsStep from "./steps/InternalDetailsStep";
import InternalReviewStep from "./steps/InternalReviewStep";
import InternalSuccessStep from "./steps/InternalSuccessStep";
import { useSimpleContext } from "../../../../contexts/SimpleContext";

function CreateInternal() {
  const history = useHistory();
  const [state] = useSimpleContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!state) {
      history.push("details", { steps: 4, currStep: 1 });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null;
  } else {
    return (
      <Fragment>
        <Switch>
          <Route
            path="/new-payment/internal/details"
            render={() => <InternalDetailsStep />}
          />
          <Route
            path="/new-payment/internal/review"
            render={() => <InternalReviewStep />}
          />
          <Route
            path="/new-payment/internal/success"
            render={() => <InternalSuccessStep />}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default CreateInternal;

CreateInternal.propTypes = {};
