import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Modal, ModalBody, Row } from "reactstrap";
import useForm from "../../hooks/UseForms";
import { CSVLink } from "react-csv";
import { allTransactions } from "../../api_client/queries/transactions";
import useGQL from "../../api_client/UseGQL";
import { formatDate, getStatus } from "../../modules/Helpers";
import { useToasts } from "react-toast-notifications";
import { trackEvent } from "../../modules/analytics";
import { appName } from "../../modules/Helpers";

function ExportTransactionsModal(props) {
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm(submit);
  const [exportData, setExportData] = useState([]);
  const [download, setDownload] = useState(false);
  const csvLinkRef = useRef(null);
  let gqlHooks = useGQL();
  const { addToast } = useToasts();

  async function getExportData(count = 1) {
    const params = {
      ...props.filters,
      limit: count,
    };
    let output = {
      createdAt: true,
      amount: true,
      states: {
        state: true,
        createdAt: true,
      },
      statement: true,
      description: true,
      direction: true,
      contact: {
        email: true,
        name: true,
      },
      estDepositDate: true,
    };
    const response = await allTransactions(params, output, gqlHooks);
    if (response) {
      return response;
    }
  }

  async function submit() {
    setLoading(true);
    const response = await getExportData();
    let tempData = [];
    await getExportData(response.count).then((response) =>
      response.data.flat().map((transaction) => {
        tempData.push({
          ...transaction,
          states: getStatus(transaction.states[0].state, transaction.direction)
            .value,
          states_updated_at: formatDate(transaction.states[0].createdAt),
          createdAt: formatDate(transaction.createdAt),
          estDepositDate: transaction.estDepositDate
            ? formatDate(transaction.estDepositDate)
            : "Pending",
          contact: transaction.contact
            ? transaction.contact.name
              ? transaction.contact.name
              : transaction.contact.email
            : "",
        });
      })
    );

    setExportData(tempData);
    setLoading(false);
    setDownload(true);
    csvLinkRef.current.link.click();
    setExportData([]);
    setDownload(false);
    addToast("Payments downloaded", {
      appearance: "success",
      autoDismiss: true,
    });
    props.toggle();
    trackEvent("downloadTransactionsCSV", {}, gqlHooks);
  }

  return (
    <Modal backdrop fade centered isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Button close onClick={props.toggle} />
          </Col>
        </Row>
        <h4 className="text-center">Download Payments CSV</h4>
        <p className="text-center text-muted">
          Download all payments with filters
        </p>
        <Form onSubmit={handleSubmit}>
          <Button block color="primary" type="submit" disabled={loading}>
            Download
          </Button>
          {download && (
            <CSVLink
              data={exportData}
              filename={`${appName}-payments.csv`}
              className="hidden"
              ref={csvLinkRef}
              target="_blank"
            />
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ExportTransactionsModal;

ExportTransactionsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
};
