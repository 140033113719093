import React, { Fragment } from "react";
import {
  Badge,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

function ContactTagBadge(tags, identifier, popOnly = false, popOver = true) {
  if (identifier) {
    identifier = identifier.replace(/-/g, "");
    identifier = identifier.replace(/" "/g, "");
    identifier = identifier.replace(/[0-9]/g, "");
  } else {
    identifier = "placeholderTextForTag";
  }
  if (tags?.length < 1) {
    popOver = false;
  }

  return (
    <React.Fragment>
      {popOver && (
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Tags</PopoverHeader>
          <PopoverBody>
            {" "}
            {tags.map((tag, key) => (
              <Badge
                key={key}
                id={identifier}
                color="default"
                className="mb-2 mr-1 ml-0"
                pill
              >
                {tag}
              </Badge>
            ))}
          </PopoverBody>
        </UncontrolledPopover>
      )}
      {!popOnly &&
        tags.map((tag, key) => (
          <Fragment key={key}>
            {key < 2 && (
              <Badge id={identifier} color="default" className="mb-0" pill>
                {tag}
              </Badge>
            )}
            {key === 2 && (
              <span className="font-weight-600 text-sm">
                {" "}
                +{tags.length - 2}
              </span>
            )}
          </Fragment>
        ))}
    </React.Fragment>
  );
}

export default ContactTagBadge;

ContactTagBadge.propTypes = {};
