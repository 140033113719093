import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router";
import Cloud1 from "assets/img/checkout/bank-cloud-1.svg";
import Cloud2 from "assets/img/checkout/bank-cloud-2.svg";
import BanksTop from "../../../assets/img/checkout/banks-updated-1.svg";
import BanksBottom from "../../../assets/img/checkout/banks-updated-2.svg";
import BanksSendTop from "../../../assets/img/checkout/banks-updated-send-1.svg";
import BanksSendBottom from "../../../assets/img/checkout/banks-updated-send-2.svg";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { formatDate } from "../../../modules/Helpers";
import useExitPrompt from "../../../hooks/useExitPrompt";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";

function PaymentSuccessStep() {
  const [state] = useSimpleContext();
  const history = useHistory();
  const [, setShowExitPrompt] = useExitPrompt(true);
  const gqlHooks = useGQL();
  const [transactionType, SetTransactionType] = useState("");

  useEffect(() => {
    setShowExitPrompt(false);
  }, []);

  useEffect(() => {
    if (state.transaction) {
      if (state.transaction.direction === "CREDIT") {
        trackEvent(
          "creditSuccess",
          {
            transactionId: state.transaction.identifier,
            companyId: state.transaction.company?.identifier,
            contactId: state.transaction.contact.identifier,
          },
          gqlHooks
        );
        SetTransactionType("CREDIT");
      } else {
        trackEvent(
          "debitSuccess",
          {
            transactionId: state.transaction.identifier,
            companyId: state.transaction.company?.identifier,
            contactId: state.transaction.contact.identifier,
            customerCreditCard: !!state.transaction?.customerCreditCard,
          },
          gqlHooks
        );
        SetTransactionType("DEBIT");
      }
    } else {
      trackEvent(
        "recurringSuccess",
        {
          recurringId: state.recurringPlan.identifier,
          companyId: state.recurringPlan.company.identifier,
          contactId: state.recurringPlan.contact.identifier,
        },
        gqlHooks
      );
    }
  }, []);

  return (
    <Fragment>
      <div className="bg-paymentSuccess-- bg-payment-on-way debitSuccess-body">
        <Container className="bg-default- rounded-top ">
          {transactionType === "DEBIT" ? (
            <div className="payment-on-way-height">
              <div className="cloud-images-body paymentSuccess cloud-images-body-absolute">
                <div className="cloud-images">
                  <img className="cloud-1" src={Cloud1} alt="Cloud" />
                  <img className="cloud-2" src={Cloud2} alt="Cloud" />
                </div>
              </div>

              <Row>
                <Col md="8" lg="8" className="m-auto pb-4 overflow-hidden">
                  <img
                    className="ml-auto mr-auto d-block bankstop"
                    src={BanksTop}
                    alt="Success Icon"
                  />
                  {/* <img
                className="ml-auto mr-auto d-block pt-4 mb-2"
                style={{ maxWidth: "64px" }}
                src={SuccessIcon}
                alt="Success Icon"
              /> */}
                  <h3 className="text-white text-center mb-0">
                    {state.recurringPlan
                      ? "Recurring payments are set up!"
                      : "Your payment is in progress."}
                  </h3>
                  <img
                    className="ml-auto mr-auto d-block banksbottom"
                    src={BanksBottom}
                    alt="Success Icon"
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <>
              <div className="cloud-images-body debitSuccess cloud-images-body-absolute">
                <div className="cloud-images">
                  <img className="cloud-1" src={Cloud1} alt="Cloud" />
                  <img className="cloud-2" src={Cloud2} alt="Cloud" />
                </div>
              </div>
              <Row>
                <Col md="8" lg="8" className="m-auto pb-4 overflow-hidden">
                  <h3 className="text-white text-center mb-3 mt-5">
                    {state.recurringPlan
                      ? "Recurring payments are set up!"
                      : "Connection successful, your funds are on their way."}
                  </h3>

                  <div className="debitbody-img-1">
                    <img
                      className="ml-auto mr-auto d-block"
                      src={BanksSendTop}
                      alt="Success Icon"
                    />
                  </div>

                  {/* <img
                className="ml-auto mr-auto d-block pt-4 mb-2"
                style={{ maxWidth: "64px" }}
                src={SuccessIcon}
                alt="Success Icon"
              /> */}
                </Col>
              </Row>
              <div className="debitbody-img-2">
                <div className="debitbody-img-2-gap"></div>
                <img
                  className="ml-auto mr-auto d-block debitbody-img-2"
                  src={BanksSendBottom}
                  alt="Success Icon"
                />
              </div>
            </>
          )}
        </Container>
        {transactionType === "DEBIT" ? (
          <Container className="bg-default- position-relative z-index-1">
            <Row>
              <Col md="8" lg="8" className="m-auto">
                <Button
                  className="btn-primary method-btn btn-lg mt-0 mb-2"
                  block
                  onClick={() => history.push("track")}
                >
                  Track your payment
                  {state.recurringPlan && "s"}
                </Button>
                <p className="text-white text-center text-lg mb-4">
                  {state.recurringPlan ? (
                    <>
                      We&apos;ve fired up the money mover. <br />
                      <span className="text-sm">
                        Your first payment is scheduled for{" "}
                        {formatDate(state.recurringPlan.nextRun)}.
                      </span>
                    </>
                  ) : state.transaction.direction === "DEBIT" ? (
                    <>
                      We&apos;ve fired up the money mover. <br />
                      <span className="text-sm">
                        Funds will leave your account within one business day.
                      </span>
                    </>
                  ) : (
                    <>
                      We&apos;ve fired up the money mover. <br />
                      <span className="text-sm">
                        {" "}
                        Funds will arrive in your account within two business
                        days.
                      </span>
                    </>
                  )}
                </p>
              </Col>
            </Row>
          </Container>
        ) : null}
      </div>
    </Fragment>
  );
}

export default PaymentSuccessStep;

PaymentSuccessStep.propTypes = {};
