import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import SecurityIcon from "../../../../assets/icons/SecurityIcon";
import { setCompanySettings } from "../../../../api_client/mutations/companyProfile";
import { useToasts } from "react-toast-notifications";
import useForm from "../../../../hooks/UseForms";
import { securityQuestionFormValidation } from "../../../../modules/form_validation/SecurityQuestionFormValidation";
import useGQL from "../../../../api_client/UseGQL";
import { trackEvent } from "../../../../modules/analytics";
import { withApiValue } from "../../../../modules/WithApiValue";
import { domains } from "modules/Helpers";

const config = {
  company: {
    settings: {
      securityQuestion: true,
      useSecurityQuestion: true,
    },
  },
};

function Security(props) {
  const [updateSecurityQuestion, setUpdateSecurityQuestion] = useState(false);
  const [useSecurityQuestion, setUseSecurityQuestion] = useState(null);
  const { addToast } = useToasts();
  let gqlHooks = useGQL();
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    submit,
    securityQuestionFormValidation
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      securityQuestion: props.company.settings.securityQuestion || "",
      securityAnswer: props.company.settings.securityAnswer || "",
    });
    setUseSecurityQuestion(props.company.settings.useSecurityQuestion);
  }, []);

  async function submit() {
    setLoading(true);
    let output = {
      settings: {
        securityQuestion: true,
        useSecurityQuestion: true,
      },
    };
    const response = await setCompanySettings(
      { ...values, useSecurityQuestion: true },
      output,
      gqlHooks
    );
    setUpdateSecurityQuestion(false);
    if (response) {
      addToast("Updated security question settings successfully!", {
        appearance: "success",
        autoDismiss: true,
      });
      props.update(response.settings);
      trackEvent("saveSecurityQuestion", {}, gqlHooks);
      setUseSecurityQuestion(true);
    } else {
      addToast("Error updating security question settings", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setLoading(false);
  }

  return (
    <Fragment>
      <div className="content">
        <Row>
          <Col sm="6" className="pl-5 pt-3">
            <SecurityIcon width={22} height={22} />
            <h4 className="d-inline-block ml-2">
              Universal Security Question
              {useSecurityQuestion === true && (
                <span className="text-success ml-2">Enabled</span>
              )}
            </h4>
            <p className="mb-4 mt-3">
              The first time you send a payment to a contact, they are required
              to answer your universal security question. Ensure your answer is
              unique and cannot be guessed by the general public.{" "}
              <a
                href={`https://${domains.HELP}/enabling-a-security-question`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </p>
            {updateSecurityQuestion ? (
              <Row>
                <Col>
                  <Form className="form" onSubmit={handleSubmit}>
                    <Label>Question</Label>
                    <FormGroup>
                      <Input
                        name="securityQuestion"
                        type="text"
                        value={values.securityQuestion}
                        invalid={!!errors.securityQuestion}
                        onChange={handleChange}
                        required
                      />
                      <FormFeedback>{errors.securityQuestion}</FormFeedback>
                    </FormGroup>
                    <Label>Answer</Label>
                    <FormGroup>
                      <Input
                        name="securityAnswer"
                        type="text"
                        invalid={!!errors.securityAnswer}
                        onChange={handleChange}
                        required
                      />
                      <FormFeedback>{errors.securityAnswer}</FormFeedback>
                    </FormGroup>
                    <Button
                      className="btn-sm float-left mb-4 mt-2"
                      color="primary"
                      type="submit"
                      disabled={
                        loading ||
                        !(values.securityQuestion && values.securityAnswer)
                      }
                    >
                      Save
                    </Button>
                  </Form>
                </Col>
              </Row>
            ) : (
              <Row>
                {useSecurityQuestion === true && (
                  <Col>
                    <div className="pull-left mr-4 mb-3">
                      <p className="font-weight-600 mb-3">Question</p>
                      <p className="font-weight-600">Answer</p>
                    </div>
                    <div className="mb-3">
                      <p className="text-muted mb-3">
                        {values?.securityQuestion}
                      </p>
                      <p className="text-muted">******************</p>
                    </div>
                  </Col>
                )}
              </Row>
            )}
            <Button
              onClick={() => setUpdateSecurityQuestion(true)}
              className={`btn-sm btn-primary float-left mb-4 ${
                updateSecurityQuestion && "d-none"
              }`}
              color="primary"
              id="update-button"
            >
              {useSecurityQuestion === true ? "Edit" : "Enable"}
            </Button>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default withApiValue(Security, config);

Security.propTypes = {
  company: PropTypes.object,
  update: PropTypes.func,
};
