import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function allBankAccounts(input, output, hooks) {
  const query = buildQuery(input, output, "allBankAccounts", true);
  return callQuery(query, hooks);
}

allBankAccounts.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function getBankAccount(input, output, hooks) {
  const query = buildQuery(input, output, "bankAccount");
  return callQuery(query, hooks);
}

getBankAccount.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function publicBankAccount(input, output, hooks) {
  const query = buildQuery(input, output, "publicBankAccount");
  return callQuery(query, hooks);
}

publicBankAccount.propTypes = {
  input: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
