import Cookies from "js-cookie";

const setUser = (user) => {
  Cookies.set("user", JSON.stringify(user), { expires: 1 });
};

const getUser = () => {
  let user = Cookies.get("user");
  if (user) {
    return JSON.parse(Cookies.get("user"));
  } else {
    return {};
  }
};

export const userReducer = (state, action) => {
  if (action.type === "setUser") {
    setUser(action.user);
    return action.user;
  } else {
    return getUser();
  }
};
