import SweetAlert from "react-bootstrap-sweetalert";
import { CustomLoader } from "../loaders/CustomLoader";
import React from "react";

function loadingAlert(
  setAlert,
  message = "Authorizing with bank... This may take a few minutes."
) {
  setAlert(
    <SweetAlert
      custom
      title={<CustomLoader />}
      onCancel={() => null}
      onConfirm={() => null}
      showConfirm={false}
      showCancel={false}
    >
      {message}
    </SweetAlert>
  );
}

export default loadingAlert;
