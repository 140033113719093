/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useForm from "../../../../../../../hooks/UseForms";
import { RequestLinkFormValidation } from "../../../../../../../modules/form_validation/RequestLinkFormValidation";
import SelectContactField from "../../../../../../../components/forms/SelectContactField";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { baseUrl } from "modules/Helpers";
import { SelectDepositAccount } from "./SelectDepositAccount";
import Select from "react-select";
import { useAppValue } from "../../../../../../../contexts/Context";
import NoteToContact from "components/text/NoteToContact";

const AllPaymentDetailsForm = ({
  submit,
  contactType,
  onAccountSelected,
  updateContact,
  initialState,
  setAddBankAccountModal,
}) => {
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    () => submit(values, contactAccount),
    RequestLinkFormValidation
  );
  const [contactAccount, setContactAccount] = useState();
  const [context] = useAppValue();

  const onUpdateContact = (contact) => {
    updateContact(contact);
    setContactAccount({ ...contact, tags: contact?.tags.join(",") });
  };
  const notesRequired = context.user.company.settings.notesRequired;
  const notesAlias = context.user.company.settings.notesAlias;

  useEffect(() => {
    if (initialState) {
      setValues({
        amount: initialState?.amount,
        statement: initialState?.statement,
        note: initialState?.note,
      });
      initialState.contact &&
        setContactAccount({
          ...initialState.contact,
          tags: initialState.contact?.tags.join(","),
        });
    }
  }, []);

  return (
    <>
      <h2 className="text-center">Add payment details</h2>
      <div className="new-payment-inner-content">
        <Label>Send to</Label>
        <SelectContactField
          contactType={contactType}
          contactAccount={contactAccount}
          setContactAccount={setContactAccount}
        />
        <Form className="form" onSubmit={handleSubmit}>
          <Label>Amount</Label>
          <FormGroup>
            <Input
              type="number"
              step="any"
              name="amount"
              value={values.amount || ""}
              onChange={handleChange}
              invalid={!!errors.amount}
              required
            />
            <FormFeedback>
              {errors.amount}
              {errors?.amount?.startsWith("Min") ? null : (
                <a>
                  please contact{" "}
                  <a
                    href={baseUrl + "/admin/payments?ticket=true"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    support
                  </a>{" "}
                  to request higher amounts
                </a>
              )}
            </FormFeedback>
          </FormGroup>
          <Label>Payment description</Label>
          <FormGroup>
            <Input
              type="text"
              name="statement"
              value={values.statement || ""}
              onChange={handleChange}
              invalid={!!errors.statement}
              required
            />
            <FormFeedback>{errors.statement}</FormFeedback>
          </FormGroup>
          <label>
            <NoteToContact alias={notesAlias} />{" "}
            <span className="text-muted text-sm">
              {notesRequired ? "(required)" : "(optional)"}
            </span>
          </label>
          <FormGroup>
            <Input
              type="textarea"
              name="note"
              value={values.note || ""}
              onChange={handleChange}
              invalid={!!errors.note}
              required={notesRequired}
            />
            <FormFeedback>{errors.note}</FormFeedback>
          </FormGroup>

          {contactAccount ? (
            contactAccount.value || contactAccount.identifier ? (
              <SelectDepositAccount
                contactAccount={contactAccount}
                onAccountSelected={onAccountSelected}
                updateContact={onUpdateContact}
                defaultAccount={initialState?.toBankAccount}
                showHeading={false}
                setAddBankAccountModal={setAddBankAccountModal}
              />
            ) : null
          ) : (
            <>
              <Label>
                Contact bank account (select a contact to show accounts)
              </Label>{" "}
              <Select
                className="react-select default disabled"
                classNamePrefix="react-select"
                disabled
              />
            </>
          )}
          <Button
            disabled={
              contactAccount?.value === "" ||
              !values?.amount ||
              !values?.statement
            }
            block
            className="mt-4"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </Form>
      </div>
    </>
  );
};

export default AllPaymentDetailsForm;

AllPaymentDetailsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  contactType: PropTypes.string.isRequired,
};
