import React, { Fragment, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import useHubspotForm from "../../hooks/useHubspotForm";
import useForm from "../../hooks/UseForms";
import { verifyEmail } from "../../modules/Helpers";
import PropTypes from "prop-types";

function HubspotRefferalForm(props) {
  const { back } = props;

  const { data, isLoading, handleHubspotSubmit } = useHubspotForm({
    portalId: "6217266",
    formId: "d77a9bae-107a-4f0f-9ed9-10943420391a",
  });
  const { values, handleChange, errors, validateForm, handleSubmit } = useForm(
    doSubmit,
    (_, errors) => {
      if (!values.email || !verifyEmail(values.email)) {
        errors.email = "You must enter a valid email address.";
      } else {
        delete errors.email;
      }
      return errors;
    }
  );

  const { addToast } = useToasts();

  useEffect(() => {
    if (data && data.status === 200) {
      addToast("Thank you for referring a business", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  }, [data]);

  function validateAndSubmit(e) {
    validateForm("email", values.email);
    handleSubmit(e);
  }

  function doSubmit() {
    let data = {
      email: values.email,
      firstname: values.firstName,
      lastname: values.lastName,
      company: values.company,
      who_referred_you_: values.your_name,
    };

    for (let entry of Object.entries(data)) {
      if (!entry[1]) delete data[entry[0]];
    }

    handleHubspotSubmit(data);
  }

  return (
    <Fragment>
      <Row>
        <Col md={6} className="ml-auto mr-auto text-left">
          <Form onSubmit={validateAndSubmit}>
            <Label>Enter their email address</Label>
            <FormGroup>
              <Input
                value={values.email || ""}
                name="email"
                type="email"
                onChange={handleChange}
                valid={!errors.email && !!values.email}
                invalid={!!errors.email}
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <Label>Enter their first name</Label>
            <FormGroup>
              <Input
                value={values.firstName || ""}
                name="firstName"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <Label>Enter their last name</Label>
            <FormGroup>
              <Input
                value={values.lastName || ""}
                name="lastName"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <Label>
              What is the name of the company you would like to refer?
            </Label>
            <FormGroup>
              <Input
                value={values.company || ""}
                name="company"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <Label>What is your name?</Label>
            <FormGroup>
              <Input
                value={values.your_name || ""}
                name="your_name"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
            <Row>
              <Col md={8}>
                <Button
                  className="btn-primary mt-3"
                  disabled={
                    isLoading ||
                    !values.email ||
                    !!errors.email ||
                    !!(data && data.status === 200)
                  }
                  type="submit"
                  block
                >
                  Refer a business
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  className="btn-simple btn-primary mt-3"
                  onClick={back}
                  block
                >
                  Go back
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
}

export default HubspotRefferalForm;

HubspotRefferalForm.propTypes = {
  back: PropTypes.func,
};
