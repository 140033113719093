import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import AddBankAccountModal from "../../../../../../components/modals/AddBankAccountModal";
import AddIcon from "../../../../../../assets/icons/AddIcon";
import BankAccountSelection from "../../../../../../components/dropdowns/BankAccountSelection";
import CreditWithdrawalNewAccountButton from "components/buttons/CreditWithdrawalNewAccountButton";

export const BulkCreditSelectFromAccount = () => {
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const [account, setAccount] = useState();
  const [newAccountModal, setNewAccountModal] = useState(false);

  useEffect(() => {
    if (state?.fromBankAccount) {
      setAccount(state.fromBankAccount);
    }
  }, []);

  return (
    <Fragment>
      <AddBankAccountModal
        toggle={() => setNewAccountModal(!newAccountModal)}
        isOpen={newAccountModal}
        title={"Add bank account"}
      />
      <h2 className="text-center">Select an account to pay from</h2>
      <div className="new-payment-inner-content">
        <Label>Withdrawal account</Label>
        <Row className="new-payment-account-wrapper">
          <CreditWithdrawalNewAccountButton setModal={setNewAccountModal} />
          <Col>
            <FormGroup>
              <BankAccountSelection
                bankAccount={account}
                setBankAccount={setAccount}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button
          onClick={() => {
            setState({
              ...state,
              fromBankAccount: account,
            });
            history.push("/bulk-payment/credit/create/details", {
              ...history.location.state,
              currStep: 2,
            });
          }}
          disabled={
            !account || account.value === "" || !account.variableDebitPad
          }
          block
          color="primary"
          type="button"
        >
          Proceed
        </Button>
      </div>
    </Fragment>
  );
};
