import PropTypes from "prop-types";
import { buildQuery, callQuery } from "api_client/query";

export function validApiTokens(input, output, hooks) {
  const query = buildQuery(input, output, "validApiTokens", false);
  return callQuery(query, hooks);
}

validApiTokens.propTypes = {
  input: PropTypes.shape({}),
  output: PropTypes.element.isRequired,
};
