/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import ConfirmModal from "../../../../components/modals/ConfirmModal";
import useForm from "../../../../hooks/UseForms";
import { profileFormValidation } from "../../../../modules/form_validation/ProfileFormValidation";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../../api_client/UseGQL";
import { changePassword } from "../../../../api_client/mutations/userProfile";
import { useGQLContext } from "../../../../api_client/client";

function ChangePasswordCard() {
  const { values, handleChange, handleSubmit, errors, setErrors } = useForm(
    submit,
    profileFormValidation
  );
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [, dispatch] = useGQLContext();
  const { addToast } = useToasts();
  let gqlHooks = useGQL();

  async function submit() {
    setLoading(true);
    setConfirmModal(false);
    let output = {
      ok: true,
    };
    const response = await changePassword(
      { password: values.password, newPassword: values.newPassword },
      output,
      gqlHooks
    );
    if (response.ok) {
      addToast("Password changed successfully, please login again", {
        appearance: "success",
        autoDismiss: true,
      });
      dispatch({
        type: "logout",
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (values.newPassword !== values.newPasswordConfirm) {
      setErrors((errors) => ({
        ...errors,
        newPasswordConfirm: "Passwords do not match",
      }));
    } else {
      delete errors.newPasswordConfirm;
      setErrors(errors);
    }
  }, [values.newPasswordConfirm, values.newPassword]);

  const toggleModal = (e) => {
    e.preventDefault();
    setConfirmModal(!confirmModal);
  };
  return (
    <>
      <ConfirmModal
        message={
          "Are you sure you want to change your password, you will be logged out"
        }
        isOpen={confirmModal}
        toggleModal={toggleModal}
        confirmAction={handleSubmit}
      />
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className=" pull-left">
            Change Password
          </CardTitle>
        </CardHeader>
        <Form className="form" onSubmit={(e) => toggleModal(e)}>
          <CardBody>
            <Col>
              <Label>Current Password *</Label>
              <FormGroup>
                <Input
                  name="password"
                  type="password"
                  value={values.password || ""}
                  invalid={!!errors.password}
                  onChange={handleChange}
                  required
                />
                <FormFeedback>{errors.password}</FormFeedback>
              </FormGroup>
              <Label>New Password *</Label>
              <FormGroup>
                <Input
                  name="newPassword"
                  type="password"
                  value={values.newPassword || ""}
                  onChange={handleChange}
                  required
                  invalid={!!errors.newPassword}
                />
                <FormFeedback>{errors.newPassword}</FormFeedback>
              </FormGroup>
              <Label>Confirm New Password *</Label>
              <FormGroup>
                <Input
                  name="newPasswordConfirm"
                  type="password"
                  value={values.newPasswordConfirm || ""}
                  onChange={handleChange}
                  required
                  invalid={!!errors.newPasswordConfirm}
                />
                <FormFeedback>{errors.newPasswordConfirm}</FormFeedback>
              </FormGroup>
            </Col>
          </CardBody>
          <CardFooter>
            <Button
              block
              color="primary"
              type="submit"
              disabled={loading || Object.entries(errors).length > 0}
            >
              Change Password
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default ChangePasswordCard;

ChangePasswordCard.propTypes = {};

ChangePasswordCard.defaultProps = {};
