import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, Collapse } from "reactstrap";
import { NavLink } from "react-router-dom";

function MobileSettingsDropdown(props) {
  return (
    <Fragment>
      <div
        aria-multiselectable={true}
        className="card-collapse mobile-tab-nav"
        id="accordion"
        role="tablist"
      >
        <Card className="card-plain">
          <CardHeader role="tab">
            <a
              aria-expanded={props.mobileCollapse}
              href="#pablo"
              data-parent="#accordion"
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                props.setMobileCollapse(!props.mobileCollapse);
              }}
            >
              <span className="font-weight-bold text-primary">
                {props.active.substring(1, props.active.length).capitalize()}
              </span>{" "}
              <i className="tim-icons icon-minimal-down" />
            </a>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={props.mobileCollapse}
            className="mobile-tab-nav"
          >
            <li className={props.activeRouteMobile("#company") + " nav-link"}>
              <NavLink activeClassName="" to="#company">
                Company
              </NavLink>
            </li>
            <li className={props.activeRouteMobile("#profile") + " nav-link"}>
              <NavLink activeClassName="" to="#profile">
                Profile
              </NavLink>
            </li>
            <li className={props.activeRouteMobile("#banks") + " nav-link"}>
              <NavLink activeClassName="" to="#banks">
                Bank Accounts
              </NavLink>
            </li>
            <li className={props.activeRouteMobile("#users") + " nav-link"}>
              <NavLink activeClassName="" to={"#users"}>
                Users
              </NavLink>
            </li>
            <li className={props.activeRouteMobile("#billing") + " nav-link"}>
              <NavLink activeClassName="" to="#billing">
                Billing
              </NavLink>
            </li>
            <li className={props.activeRouteMobile("#security") + " nav-link"}>
              <NavLink activeClassName="" to="#security">
                Security
              </NavLink>
            </li>
            <li
              className={props.activeRouteMobile("#integrations") + " nav-link"}
            >
              <NavLink activeClassName="" to="#integrations">
                Integrations
              </NavLink>
            </li>
          </Collapse>
        </Card>
      </div>
    </Fragment>
  );
}

export default MobileSettingsDropdown;

MobileSettingsDropdown.propTypes = {
  mobileCollapse: PropTypes.bool.isRequired,
  setMobileCollapse: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  activeRouteMobile: PropTypes.func.isRequired,
};
