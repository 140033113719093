import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { codeFormValidation } from "../../../modules/form_validation/CodeFormValidation";
import { useToasts } from "react-toast-notifications";
import { useHistory, useLocation } from "react-router-dom";
import useGQL from "../../../api_client/UseGQL";
import {
  confirmLogin,
  resendMFACode,
} from "../../../api_client/mutations/auth";
import { useGQLContext } from "../../../api_client/client";
import CodeImage from "../../../assets/img/2fa_page/2fa -icon.svg";
import { useAppValue } from "../../../contexts/Context";
import { appName } from "../../../modules/Helpers";
import { reportSetToken } from "../../../modules/analytics";

function CodeForm() {
  const { values, handleChange, handleSubmit, errors } = useForm(
    submit,
    codeFormValidation
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const mfaCodeRef = useRef(null);
  const { addToast } = useToasts();
  let history = useHistory();
  const location = useLocation();
  const [context, dispatch] = useGQLContext();
  const [appContext] = useAppValue();
  const gqlHooks = useGQL();

  const resend = async () => {
    let output = {};
    const response = await resendMFACode(
      { loginToken: token },
      output,
      gqlHooks
    );
    if (response) {
      addToast(
        "A new verification code has been sent, please allow 30 seconds before sending another code",
        {
          appearance: "info",
          autoDismiss: true,
        }
      );
    }
  };

  useEffect(() => {
    if (context.fieldErrors.loginToken) {
      history.push("/auth/login");
    }
  }, [context]);

  useEffect(() => {
    mfaCodeRef.current.focus();
    if (location.state) {
      setToken(location.state.token);
    } else {
      history.push("/auth/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function submit() {
    setLoading(true);
    const output = {
      token: true,
      payload: true,
      refreshExpiresIn: true,
    };
    const response = await confirmLogin(
      { mfaCode: values.mfaCode, loginToken: token },
      output,
      gqlHooks
    );
    setLoading(false);
    if (response) {
      if (response.token) {
        dispatch({
          type: "setToken",
          token: response.token,
        });
        reportSetToken();
        if (appContext.redirectURL !== "") {
          history.push(appContext.redirectURL);
        } else {
          history.push("/admin/payments");
        }
      }
    }
  }

  return (
    <Fragment>
      <div className="text-center ">
        <div className="mr-auto ml-auto mb-3 auth-logo">
          <img src={CodeImage} alt={appName} />
        </div>
        <h2 className="font-weight-bold register-header mb-2">
          Two-step verification
        </h2>
        <p className="text-muted mb-3">
          Thanks for keeping your account secure. Check your mobile device.
        </p>
      </div>
      <Col className="ml-auto mr-auto" md="7">
        <Form className="form" onSubmit={handleSubmit}>
          <label>Verification code</label>
          <FormGroup>
            <Input
              name="mfaCode"
              type="text"
              innerRef={mfaCodeRef}
              value={values.mfaCode || ""}
              onChange={handleChange}
              invalid={!!errors.mfaCode}
              required
            />
            <FormFeedback>{errors.mfaCode}</FormFeedback>
            <Button
              className="btn-link pull-right mb-2 pr-0"
              type="button"
              size="sm"
              onClick={resend}
              color="links"
            >
              Resend code
            </Button>
          </FormGroup>

          <div>
            <Button
              disabled={loading}
              block
              color="primary"
              size="md"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Col>
    </Fragment>
  );
}

export default CodeForm;
