import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FlinksIframe from "../../../../modules/FlinksIframe";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { CustomLoader } from "../../../../components/loaders/CustomLoader";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import useGQL from "../../../../api_client/UseGQL";
import {
  assignBankAccountToCompany,
  createBankAccountViaFlinks,
} from "../../../../api_client/mutations/bankAccount";
import { allBankAccounts } from "../../../../api_client/queries/bankAccounts";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OnboardingTabPanel from "../../../onboarding/generic/OnboardingTabPanel";
import SecurityIcon from "../../../../assets/icons/SecurityIcon";
import NewManualBankAccount from "./manual/NewManualBankAccount";
import OnlineLogin from "../../../../assets/img/OnlineLogin.png";
import ManualEntry from "../../../../assets/img/ManualEntry.png";
import { trackEvent } from "../../../../modules/analytics";
import LegacyUpdateKYCModal from "../../../../components/modals/LegacyUpdateKYCModal";
import WarningIcon from "react-bootstrap-sweetalert/dist/components/WarningIcon";
import Cheque from "../../../../assets/img/Cheque.png";
import VariableSporadicPADModal from "../../../../components/modals/VariableSporadicPADModal";
import { domains } from "modules/Helpers";

function AddBankAccount(props) {
  const [state, setState] = useState({
    loginId: "",
    accountId: "",
    firstAccount: false,
  });
  const [loading, setLoading] = useState(false);
  const [bankAccountsCount, setBankAccountsCount] = useState(-1);
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();
  const [isOpen, setIsOpen] = useState(false);
  const [missingKyc, setMissingKyc] = useState([]);
  const [bankAccount, setBankAccount] = useState({});
  const [bankMethodTab, setBankMethodTab] = React.useState(0);
  const [bankLoginError, setBankLoginError] = useState(false);
  const [variablePad, setVariablePad] = useState(false);

  const handleChange = (event, newValue) => {
    setBankMethodTab(newValue);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let mounted = true;
    allBankAccounts({}, { identifier: true }, gqlHooks).then((response) => {
      if (response) {
        if (mounted) {
          setBankAccountsCount(response.count);
        }
      }
    });
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.accountId !== "" && state.loginId !== "") {
      addBankAccount().then(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.accountId, state.loginId]);

  function setLoginId(data) {
    if (data.loginId !== undefined && state.loginId === "") {
      let loginId = data.loginId;
      setState((state) => ({ ...state, loginId: loginId }));
    }
  }

  function setAccountId(data) {
    if (data.accountId !== undefined && state.accountId === "") {
      let accountId = data.accountId;
      setState((state) => ({ ...state, accountId: accountId }));
    }
  }

  async function addBankAccount() {
    if (state.loginId === "" || state.accountId === "") {
      return;
    }
    setLoading(true);
    let output = {
      bankAccount: {
        identifier: true,
        state: true,
      },
    };
    const response = await createBankAccountViaFlinks(
      {
        bankAccount: {
          flinksLoginId: state.loginId,
          flinksBankAccountId: state.accountId,
          refresh: false,
        },
      },
      output,
      gqlHooks
    );
    if (response) {
      if (response.bankAccount) {
        let output = {
          bankAccount: {
            identifier: true,
            missingKyc: true,
            state: true,
            institutionNumber: true,
            transitNumber: true,
            accountNumber: true,
            institution: true,
            title: true,
            accountType: true,
            holderName: true,
            holderAddress: true,
            holderAddressPostalCode: true,
            holderAddressCity: true,
            holderAddressCountry: true,
            holderEmail: true,
          },
        };
        const response2 = await assignBankAccountToCompany(
          { bankAccountId: response.bankAccount.identifier },
          output,
          gqlHooks
        );
        if (response2 && response2.bankAccount) {
          setBankAccount(response2.bankAccount);
          if (
            response2.bankAccount.missingKyc.length > 0 &&
            response2.bankAccount.state === "PARTIALLY_READY"
          ) {
            setMissingKyc(response2.bankAccount.missingKyc);
            toggleModal();
          } else {
            setVariablePad(true);
          }
          history.push({ search: "update=true" });
        } else {
          setBankLoginError(true);
        }
      }
    } else {
      setBankLoginError(true);
    }
    setLoading(false);
  }

  async function finish() {
    addToast("Bank account added successfully", {
      appearance: "success",
      autoDismiss: true,
    });
    trackEvent("addBankComplete", {}, gqlHooks);
    props.toggle();
    if (props.setBankAccount) {
      props.setBankAccount(bankAccount);
    } else if (bankAccountsCount === 0) {
      history.push("/admin/banks/add-billing", {
        account: bankAccount,
      });
    }
  }

  function toggleManualEntry() {
    setBankLoginError(false);
    setBankMethodTab(1);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Fragment>
      {isOpen && (
        <LegacyUpdateKYCModal
          isOpen={isOpen}
          toggle={toggleModal}
          missingKyc={missingKyc}
          bankAccount={bankAccount}
          success={finish}
        />
      )}
      {variablePad && (
        <VariableSporadicPADModal
          isOpen={variablePad}
          toggle={() => finish()}
          bankAccountIdentifier={bankAccount.identifier}
        />
      )}
      <Row className="add-bank-wrapper">
        <Col sm="6">
          <Row>
            <Col sm="12" className="text-center">
              <div className="add-bank-methods-wrapper">
                <h2 className="add-bank-title">Add bank account</h2>
                <div className="add-bank-method">
                  <div className="add-bank-method-icon-wrapper">
                    <img src={OnlineLogin} alt="Online Login Icon" />
                  </div>
                  <h4 className="add-bank-method-title">Online Login</h4>
                  <div className="add-bank-method-description-wrapper">
                    <p
                      className="add-bank-method-description text-muted"
                      style={{ marginBottom: "30px" }}
                    >
                      Select your bank from the list and log in using your
                      online account credentials. Use the search bar if your
                      bank isn&apos;t initially visible.{" "}
                      <a
                        href={`https://${domains.HELP}/how-to-add-a-bank-account-via-online-login`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                  </div>
                </div>
                <div
                  style={{ paddingRight: 100, paddingLeft: 100 }}
                  className="hide-on-mobile"
                >
                  <p className="or">
                    <span>Or</span>
                  </p>
                </div>
                <div className="add-bank-method">
                  <div className="add-bank-method-icon-wrapper">
                    <img src={ManualEntry} alt="Digital Cheque Icon" />
                  </div>
                  <h4 className="add-bank-method-title">Digital Cheque</h4>
                  <div className="add-bank-method-description-wrapper">
                    <p
                      className="add-bank-method-description text-muted"
                      style={{ marginBottom: "20px" }}
                    >
                      Select the &apos;Digital Cheque&apos; tab and complete the
                      form with your bank account routing information found on
                      your cheque or direct deposit form.{" "}
                      <a
                        href={`https://${domains.HELP}/how-to-add-a-bank-account-via-manual-entry`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                    <p
                      className="add-bank-method-description text-muted"
                      style={{ marginBottom: "30px" }}
                    >
                      We use micro-deposits to authenticate accounts added
                      manually (within next business day).{" "}
                      <a
                        href={`https://${domains.HELP}/how-to-verify-a-bank-account-with-micro-deposits`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="text-center mt-2">
            <Col>
              <div className="add-bank-trust-wrapper">
                <SecurityIcon
                  fill={"#8e959b"}
                  className="d-inline-block"
                  width={14}
                  height={14}
                />
                <p className="d-inline-block ml-1 add-bank-encrypted-text">
                  Secure 256-bit SSL encrypted connection
                </p>
                <p className="add-bank-trust-text">
                  Partnered with the RBC and regulated by Fintrac Canada.
                  <br />
                  Learn more at{" "}
                  <a
                    href={`https://${domains.PRIMARY}/security`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="add-bank-trust-link"
                  >
                    {domains.PRIMARY}/security
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm="6" className="add-bank-tab-col">
          <Card className="add-bank-tab-wrapper">
            <CardBody className="p-0">
              {bankLoginError ? (
                <>
                  <div className="justify-content-center p-4">
                    <WarningIcon />
                    <h3 className="text-center mb-2">Online Login Error</h3>
                    <p className="text-center text-muted mb-0">
                      There was an error logging in. Please try entering the
                      account routing information found on your cheque or direct
                      deposit form.{" "}
                      <a
                        href={`https://${domains.HELP}/how-to-add-a-bank-account-via-manual-entry`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </p>
                    <img src={Cheque} alt="Cheque image" className="p-4" />
                    <Button
                      block
                      className="btn-primary"
                      onClick={toggleManualEntry}
                    >
                      Enter bank account details manually
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Tabs
                    value={bankMethodTab}
                    onChange={handleChange}
                    centered
                    textColor="primary"
                    indicatorColor="primary"
                  >
                    <Tab label="Online Login" {...a11yProps(0)} />
                    <Tab label="Digital Cheque" {...a11yProps(1)} />
                  </Tabs>
                  <OnboardingTabPanel value={bankMethodTab} index={0}>
                    <div className="add-bank-flinks-wrapper">
                      {loading ? (
                        <div className="mt-lg">
                          <CustomLoader />
                        </div>
                      ) : (
                        <FlinksIframe
                          page="add-bank-flinks"
                          setLoginId={setLoginId}
                          setAccountId={setAccountId}
                          redirectFunc={() => null}
                        />
                      )}
                    </div>
                  </OnboardingTabPanel>
                  <OnboardingTabPanel value={bankMethodTab} index={1}>
                    <NewManualBankAccount {...props} />
                  </OnboardingTabPanel>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default AddBankAccount;

AddBankAccount.propTypes = {
  setLoginId: PropTypes.func,
  setAccountId: PropTypes.func,
  toggle: PropTypes.func,
  setBankAccount: PropTypes.func,
};

AddBankAccount.defaultProps = {
  toggle: () => null,
};
