import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function createRailzConnection(input, output, hooks) {
  const query = buildMutation(input, output, "createRailzConnection");
  return callMutation(query, hooks);
}

createRailzConnection.propTypes = {
  input: PropTypes.shape({
    serviceName: PropTypes.string,
    noServiceName: PropTypes.bool,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function syncRailzData(input, output, hooks) {
  const query = buildMutation(input, output, "syncRailzData");
  return callMutation(query, hooks);
}

syncRailzData.propTypes = {
  input: PropTypes.shape({
    sync: PropTypes.bool,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function createBatchCredit(input, output, hooks) {
  const query = buildMutation(input, output, "createBatchCredit");
  return callMutation(query, hooks);
}

createBatchCredit.propTypes = {
  input: PropTypes.shape({
    creditBatch: PropTypes.array.isRequired,
    fromBankAccountId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function createBatchDebit(input, output, hooks) {
  const query = buildMutation(input, output, "createBatchDebit");
  return callMutation(query, hooks);
}

createBatchDebit.propTypes = {
  input: PropTypes.shape({
    debitBatch: PropTypes.array.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
