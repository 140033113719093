import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import CreateContactForm from "../../views/admin/contacts/CreateContactForm";

function CreateContactModal(props) {
  return (
    <Modal backdrop fade centered isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Button close onClick={props.toggle} />
          </Col>
        </Row>
        <CreateContactForm {...props} />
      </ModalBody>
    </Modal>
  );
}

export default CreateContactModal;

CreateContactModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
