import React, { Fragment, useCallback, useEffect, useState } from "react";
import ContactsTable from "../../../components/tables/ContactsTable";
import useForm from "../../../hooks/UseForms";
import CustomTableSearch from "../../../components/forms/CustomTableSearch";
import { Button, UncontrolledCollapse } from "reactstrap";
import AddIcon from "../../../assets/icons/AddIcon";
import CreateContactModal from "../../../components/modals/CreateContactModal";
import ContactsFilters from "../../../components/modals/ContactsFilters";
import FilterIcon from "../../../assets/icons/FilterIcon";

function Contacts(props) {
  const { values } = useForm(null, () => null);
  const [filters, setFilters] = useState({});
  const [createContactModal, setCreateContactModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [numContacts, setNumContacts] = useState(0);

  useEffect(() => {
    let count = 0;
    if (filters.contactTag) {
      count++;
    }
    if (filters.archived) {
      count++;
    }
    setFilterCount(count);
  }, [filters]);

  useEffect(() => {
    if (values.search !== undefined) {
      const timeoutId = setTimeout(
        () => setFilters({ ...filters, search: values.search }),
        500
      );
      return () => clearTimeout(timeoutId);
    }
  }, [values.search]);

  const getNumContacts = useCallback((num) => {
    setNumContacts(num);
  });

  return (
    <Fragment>
      <CreateContactModal
        toggle={() => setCreateContactModal(!createContactModal)}
        isOpen={createContactModal}
      />
      <div className="content">
        <div className="d-flex flex-column justify-content-between">
          <h2 className="mb-3 page-title">
            Contacts{" "}
            <span className="text-warning">
              {filters.archived && "Archived"}
            </span>
            <span className="font-weight-500 text-muted text-xl mr-3">
              ({numContacts})
            </span>
          </h2>
          <div className="d-flex flex-row flex-grow-1 justify-content-end">
            <CustomTableSearch
              disableAmount
              filters={filters}
              setFilters={setFilters}
            />
            <div className="ml-3">
              <Button
                className="btn-simple btn-primary sp-button-simple"
                id="buttonToggler"
              >
                <FilterIcon width={15} height={15} strokewidth={3} />{" "}
                <span className="filter-btn-label">Filter</span>
                {filterCount > 0 && (
                  <Fragment>
                    {" "}
                    <span className="text-warning">{filterCount}</span>
                  </Fragment>
                )}
              </Button>
            </div>
            <div>
              <Button
                className="btn-primary sp-button"
                onClick={() => setCreateContactModal(!createContactModal)}
              >
                <AddIcon height={13} width={13} /> <span>Contact</span>
              </Button>
            </div>
          </div>
          <UncontrolledCollapse toggler="#buttonToggler">
            <ContactsFilters filters={filters} setFilters={setFilters} />
          </UncontrolledCollapse>
        </div>
        <ContactsTable
          {...props}
          filters={filters}
          refresh={createContactModal}
          getNumContacts={getNumContacts}
        />
      </div>
    </Fragment>
  );
}

export default Contacts;

Contacts.propTypes = {};
