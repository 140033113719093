/* eslint react/prop-types: 0 */
import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import useGQL from "../../../../../../../api_client/UseGQL";
import NewContactBankAccount from "../../../../../contacts/NewContactBankAccount";
import { getContact } from "../../../../../../../api_client/queries/contacts";
import {
  CustomTooltip,
  getContactBankAccountsSelection,
} from "../../../../../../../modules/Helpers";
import AddIcon from "../../../../../../../assets/icons/AddIcon";

export const getBankLabel = (bankAccount) => {
  return `${bankAccount?.institution} - ${
    bankAccount?.title
  } - ${bankAccount?.accountNumber?.slice(-3)}`;
};

export const SelectDepositAccount = ({
  contactAccount,
  onAccountSelected,
  updateContact,
  defaultAccount,
  showHeading = true,
  setAddBankAccountModal,
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [newAccountTooltipIsOpen, setNewAccountTooltipIsOpen] = useState(false);
  const [toAccount, setToAccount] = useState({
    value: "",
    label: "Let recipient decide",
  });
  let gqlHooks = useGQL();

  useEffect(() => {
    if (defaultAccount && !toAccount.value) {
      const bankAccounts = contactAccount?.bankAccounts;
      const bank = bankAccounts?.find(
        (account) => account.identifier === defaultAccount
      );

      onAccountSelected(bank);
      setToAccount({
        key: bank.identifier,
        value: bank.identifier,
        label: getBankLabel(bank),
      });
    } else if (contactAccount?.bankAccounts?.length === 1) {
      const bank = contactAccount.bankAccounts[0];
      onAccountSelected(bank);
      setToAccount({
        key: bank.identifier,
        value: bank.identifier,
        label: getBankLabel(bank),
      });
    } else {
      onAccountSelected(undefined);
      setToAccount({
        key: "",
        value: "",
        label: "Let recipient decide",
      });
    }
  }, [contactAccount]);

  function toAccountOnChange(value, type) {
    if (type.action === "clear" || !value?.value) {
      setToAccount({
        value: "",
        label: "Let recipient decide",
      });
      onAccountSelected("", "Let recipient decide");
    } else {
      const bankAccounts = contactAccount?.bankAccounts;
      const bank = bankAccounts?.find(
        (account) => account.identifier === value?.value
      );
      onAccountSelected(bank);
      setToAccount(value);
    }
  }

  function addBankAccount() {
    setAddBankAccountModal(
      <SweetAlert title={""} showConfirm={false} onConfirm={() => {}}>
        <Row style={{ marginTop: -30 }}>
          <Col sm="12">
            <Button close onClick={() => setAddBankAccountModal(null)} />
          </Col>
        </Row>
        <h2 className="text-center">Add a bank account</h2>
        <NewContactBankAccount
          done={(account) => {
            setAddBankAccountModal(null);
            updateContactAndSetAccount(account);
          }}
          contact={{
            identifier: contactAccount.value
              ? contactAccount.value
              : contactAccount.identifier,
          }}
        />
      </SweetAlert>
    );
  }

  async function updateContactAndSetAccount(account) {
    let output = {
      identifier: true,
      name: true,
      createdAt: true,
      email: true,
      contactType: true,
      tags: true,
      bankAccounts: {
        identifier: true,
        state: true,
        institution: true,
        title: true,
        accountNumber: true,
      },
    };
    let result = await getContact(
      {
        contactId: contactAccount.value
          ? contactAccount.value
          : contactAccount.identifier,
      },
      output,
      gqlHooks
    );
    let contact = result.data;
    updateContact(contact);

    let queriedAccount = contact.bankAccounts.find(
      (ba) => ba.identifier == account.identifier
    );
    if (queriedAccount) {
      setToAccount(getContactBankAccountsSelection([queriedAccount])[0]);
    }
  }

  return (
    <Fragment>
      {showHeading && (
        <h2 className="text-center">
          Select your contact&apos;s deposit account
        </h2>
      )}
      <div className="new-payment-inner-content mt-3">
        <Label>Contact bank account</Label>
        <CustomTooltip
          title="Letting the recipient decide will prompt your contact to add a bank account and answer your security question."
          placement="top"
          arrow
          open={tooltipIsOpen}
          onOpen={() => setTooltipIsOpen(true)}
          onClose={() => setTooltipIsOpen(false)}
          leaveTouchDelay={10000}
        >
          <Button
            onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
            variant="contained"
            className="btn-link tooltip-button"
          >
            <i className="fas fa-info-circle" />
          </Button>
        </CustomTooltip>
        <Row className="new-payment-account-wrapper">
          <Col>
            <CustomTooltip
              title="If you already have your contact's bank account routing information, you can enter it here to send funds directly to their account."
              placement="top"
              arrow
              open={newAccountTooltipIsOpen}
              onOpen={() => setNewAccountTooltipIsOpen(true)}
              onClose={() => setNewAccountTooltipIsOpen(false)}
              leaveTouchDelay={10000}
            >
              <Button
                color="links"
                className="btn-link new-payment-new-account-btn"
                onClick={() => addBankAccount()}
              >
                <AddIcon /> New account
              </Button>
            </CustomTooltip>
          </Col>
        </Row>
        <FormGroup>
          <Select
            onChange={(value, type) => toAccountOnChange(value, type)}
            className="react-select default"
            classNamePrefix="react-select"
            name="toBankAccountId"
            value={toAccount}
            options={getContactBankAccountsSelection(
              contactAccount.bankAccounts
            )}
            required
          />
        </FormGroup>
      </div>
    </Fragment>
  );
};

SelectDepositAccount.propTypes = {};
