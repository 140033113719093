import RegisterForm from "views/auth/views/RegisterForm.jsx";

import ForgotPasswordForm from "../views/auth/views/ForgotPasswordForm.jsx";
import ResetPasswordForm from "../views/auth/views/ResetPasswordForm.jsx";
import EmailConfirmed from "../views/auth/views/EmailConfirmed.jsx";
import CodeForm from "../views/auth/views/CodeForm.jsx";
import RegisterInviteForm from "../views/auth/views/RegisterInviteForm";
import LoginForm from "../views/auth/views/LoginForm";
import RegisterFormSideContent from "../views/auth/side_content/RegisterFormSideContent";
import CodeFormSideContent from "../views/auth/side_content/CodeFormSideContent";
import RetryPassbase from "../views/auth/views/RetryPassbase";

const authRoutes = [
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: LoginForm,
    sideContent: CodeFormSideContent,
    layout: "/auth",
  },
  {
    path: "/login-code",
    name: "Login Code",
    mini: "L",
    component: CodeForm,
    sideContent: CodeFormSideContent,
    layout: "/auth",
  },
  {
    path: "/forgot",
    name: "Forgot",
    mini: "F",
    component: ForgotPasswordForm,
    sideContent: CodeFormSideContent,
    layout: "/auth",
  },
  {
    path: "/reset/:uid/:token",
    name: "Reset Password",
    mini: "F",
    component: ResetPasswordForm,
    sideContent: CodeFormSideContent,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    mini: "R",
    component: RegisterForm,
    layout: "/auth",
    sideContent: RegisterFormSideContent,
  },
  {
    path: "/verify/:uid/:token",
    name: "Verify Email",
    mini: "UP",
    component: EmailConfirmed,
    sideContent: CodeFormSideContent,
    layout: "/auth",
  },
  {
    path: "/register-invite/:token",
    name: "Company Invite",
    mini: "UP",
    component: RegisterInviteForm,
    sideContent: RegisterFormSideContent,
    layout: "/auth",
  },
  {
    path: "/verification/:userId/:previousReferenceId",
    name: "Retry Verification",
    component: RetryPassbase,
    sideContent: CodeFormSideContent,
    layout: "/auth",
  },
];

export default authRoutes;
