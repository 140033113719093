import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import { baseUrl } from "../../modules/Helpers";
import SelectContactField from "./SelectContactField";
import useForm from "../../hooks/UseForms";
import { RequestLinkFormValidation } from "../../modules/form_validation/RequestLinkFormValidation";
import { useSimpleContext } from "../../contexts/SimpleContext";
import { defaultToContact } from "../../modules/Helpers";
import { useLocation } from "react-router-dom";
import { useAppValue } from "../../contexts/Context";
import NoteToContact from "../text/NoteToContact";

function PaymentDetailsForm(props) {
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    () => props.submit(values, contactAccount),
    RequestLinkFormValidation
  );

  const [context] = useAppValue();

  const [state] = useSimpleContext();
  const [contactAccount, setContactAccount] = useState(defaultToContact);
  const location = useLocation();
  const notesRequired = context.user.company.settings.notesRequired;
  const notesAlias = context.user.company.settings.notesAlias;
  const initialState = props.initialState;
  const contactType = props.contactType;
  useEffect(() => {
    if (initialState) {
      setValues({
        amount: initialState?.amount,
        statement: initialState?.statement,
        note: initialState?.note,
      });
      initialState.contact &&
        setContactAccount({
          ...initialState.contact,
          tags: initialState.contact?.tags.join(","),
        });
    }
    if (state && state.amount) {
      setValues(state);
      setContactAccount(state.contactAccount);
    }
  }, []);

  return (
    <Fragment>
      <h2 className="text-center">
        {location.pathname === "/new-payment/credit/details"
          ? "Add payment details"
          : "Add payment request details"}
      </h2>
      <div className="new-payment-inner-content">
        <Label>
          {location.pathname === "/new-payment/credit/details"
            ? "Send to"
            : "Request from"}
        </Label>
        <SelectContactField
          contactType={contactType}
          contactAccount={contactAccount}
          setContactAccount={setContactAccount}
        />
        <Form className="form" onSubmit={handleSubmit}>
          <Label>Amount</Label>
          <FormGroup>
            <Input
              type="number"
              step="any"
              name="amount"
              value={values.amount || ""}
              onChange={handleChange}
              invalid={!!errors.amount}
              required
            />
            <FormFeedback>
              {errors.amount}
              {errors?.amount?.startsWith("Min") ? null : (
                <a>
                  please contact{" "}
                  <a
                    href={baseUrl + "/admin/payments?ticket=true"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    support
                  </a>{" "}
                  to request higher amounts
                </a>
              )}
            </FormFeedback>
          </FormGroup>
          <Label>Payment description</Label>
          <FormGroup>
            <Input
              type="text"
              name="statement"
              value={values.statement || ""}
              onChange={handleChange}
              invalid={!!errors.statement}
              required
            />
            <FormFeedback>{errors.statement}</FormFeedback>
          </FormGroup>
          <label>
            <NoteToContact alias={notesAlias} />{" "}
            <span className="text-muted text-sm">
              {notesRequired ? "(required)" : "(optional)"}
            </span>
          </label>
          <FormGroup>
            <Input
              type="textarea"
              name="note"
              value={values.note || ""}
              onChange={handleChange}
              invalid={!!errors.note}
              required={notesRequired}
            />
            <FormFeedback>{errors.note}</FormFeedback>
          </FormGroup>
          <Button
            disabled={contactAccount.value === ""}
            block
            className="mt-4"
            color="primary"
            type="submit"
          >
            Proceed
          </Button>
        </Form>
      </div>
    </Fragment>
  );
}

export default PaymentDetailsForm;

PaymentDetailsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  contactType: PropTypes.string.isRequired,
  initialState: PropTypes.string.isRequired,
};
