import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import { Button, Col, Row } from "reactstrap";
import { formattedDollarValue } from "../../../../../modules/Helpers";
import SuccessIcon from "react-bootstrap-sweetalert/dist/components/SuccessIcon";
import CreateAdditionalPaymentDropdown from "../../../../../components/dropdowns/CreateAdditionalPaymentDropdown";
import { useAppValue } from "../../../../../contexts/Context";

function CreditSuccessStep() {
  const [context] = useAppValue();
  const history = useHistory();
  const [state] = useSimpleContext();

  const requiresApproval = !context.user?.isOwnerOrAdmin;

  return (
    <Fragment>
      <SuccessIcon />
      <h2 className="text-center">
        {!requiresApproval ? "Payment sent" : "Draft payment created"}
      </h2>
      <div className="new-payment-inner-content text-center pt-0">
        <span>
          {!requiresApproval
            ? "You sent a one time payment of "
            : "You created a one time draft payment of "}
          <b>{formattedDollarValue(state.amount)}</b> to{" "}
          <b>
            {state.contactAccount.name
              ? state.contactAccount.name
              : state.contactAccount.email}
          </b>
          .{" "}
          {!requiresApproval
            ? "They will receive an email confirmation of this payment."
            : "The company admin will be notified for their approval."}
        </span>
        <Button
          onClick={() => history.push("/admin/payments")}
          block
          className="btn-primary mt-4"
        >
          All done, return to dashboard
        </Button>
        <Row className="mt-3">
          <Col xs="5" className="ml-auto pl-0">
            <hr />
          </Col>
          <Col xs="1">
            <span>or</span>
          </Col>
          <Col xs="5" className="mr-auto pr-0">
            <hr />
          </Col>
        </Row>
        <CreateAdditionalPaymentDropdown />
      </div>
    </Fragment>
  );
}

export default CreditSuccessStep;

CreditSuccessStep.propTypes = {};
