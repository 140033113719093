import React, { Fragment } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import RecurringIcon from "../../../../../assets/icons/RecurringIcon";
import ArrowRightIcon from "../../../../../assets/icons/ArrowRightIcon";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debitOneTime from "assets/img/banks/debitOneTime.svg";
import bulkCredit from "assets/img/banks/bulkCredit.svg";

function DebitFrequencyStep() {
  const history = useHistory();
  const [, setState] = useSimpleContext();
  return (
    <Fragment>
      <h2 className="text-center">Select payment request frequency</h2>
      <ButtonGroup
        style={{ maxWidth: "450px", marginTop: "11px" }}
        className="business-type-btn-wrapper flex-column"
      >
        <Button
          className="business-type-btn-custom mb-2"
          onClick={() => {
            setState({ frequency: "One time" });
            history.push("details", {
              ...history.location.state,
              currStep: 3,
              steps: 8,
            });
          }}
        >
          <div className="icon-text-wrapper">
            <div className="btn-custom-icon">
              <img src={debitOneTime} height={40} width={40} />
              {/* <ArrowRightIcon width={40} height={40} fill="#8125D2" /> */}
            </div>
            <div className="btn-custom-text">
              <h4>One Time</h4>
              <span>Collect a one time payment</span>
            </div>
          </div>
        </Button>
        <Button
          className="business-type-btn-custom"
          onClick={() => {
            setState({ frequency: "recurring" });
            history.push("schedule", {
              ...history.location.state,
              steps: 8,
              currStep: 2,
            });
          }}
        >
          <div className="icon-text-wrapper">
            <div className="btn-custom-icon">
              <RecurringIcon width={40} height={40} fill="#8125D2" />
            </div>
            <div className="btn-custom-text">
              <h4>Recurring</h4>
              <span>Collect a recurring payment</span>
            </div>
          </div>
        </Button>
        <Button
          className="business-type-btn-custom mb-2"
          onClick={() => {
            setState({ frequency: "One time" });
            history.push("/new-payment/debit/bulk/deposit-account", {
              ...history.location.state,
              steps: 5,
              currStep: 1,
            });
          }}
        >
          <div className="icon-text-wrapper">
            <div className="btn-custom-icon">
              <img src={bulkCredit} style={{ width: "60px", height: "60px" }} />
              {/* <img src={bulkCredit} style={{ width: "60px", height: "60px" }} /> */}
            </div>
            <div className="btn-custom-text">
              <h4>Bulk receivable</h4>
              <span>
                Create and send multiple payment requests simultaneously
              </span>
            </div>
          </div>
        </Button>
      </ButtonGroup>
    </Fragment>
  );
}

export default DebitFrequencyStep;
