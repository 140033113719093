import React, { Fragment, useEffect, useState } from "react";
import { Card, Nav } from "reactstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Company from "./company/EditCompany";
import Banks from "./banks/Banks";
import Billing from "./billing/Billing";
import Users from "./users/Users";
import Security from "./security/Security";
import Profile from "./profile/Profile";
import MobileSettingsDropdown from "../../../components/dropdowns/MobileSettingsDropdown";
import ContactTags from "./contact_tags/ContactTags";
import * as Sentry from "@sentry/browser";
import Integrations from "./integrations/Integrations";
import Configuration from "./configuration/Configuration";
import { useAppValue } from "../../../contexts/Context";

function Settings(props) {
  const [context] = useAppValue();
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState("#company");
  const [alertState] = useState(null);
  const [mobileCollapse, setMobileCollapse] = useState(false);

  let isOwnerOrAdmin = context.user?.isOwnerOrAdmin || false;

  useEffect(() => {
    try {
      Sentry.configureScope((scope) => scope.setTransactionName("Settings"));
    } catch (e) {
      console.log("sentry not loaded");
    }
    let params;
    params = location.hash || active;
    setActive(params);
    setMobileCollapse(false);
  }, [location.hash]);

  const activeRoute = (routeName) => {
    return active === routeName ? "active" : "";
  };

  const activeRouteMobile = (routeName) => {
    return active === routeName ? "d-none" : "";
  };

  const getActiveComponent = () => {
    if (active === "#company") {
      return <Company {...props} />;
    } else if (active === "#profile") {
      return <Profile {...props} />;
    } else if (active === "#banks") {
      return <Banks {...props} />;
    } else if (active === "#users" && isOwnerOrAdmin) {
      return <Users {...props} />;
    } else if (active === "#billing") {
      return <Billing {...props} />;
    } else if (active === "#security") {
      return <Security {...props} />;
    } else if (active === "#tags") {
      return <ContactTags {...props} />;
    } else if (active === "#integrations" && isOwnerOrAdmin) {
      return <Integrations {...props} />;
    } else if (active === "#configuration" && isOwnerOrAdmin) {
      return <Configuration {...props} />;
    }
  };

  return (
    <Fragment>
      <div className="content">
        {alertState}
        <h2 className="mb-3 font-weight-600 page-title">Settings</h2>
        <Card className="shadow-sm border">
          <MobileSettingsDropdown
            mobileCollapse={mobileCollapse}
            setMobileCollapse={setMobileCollapse}
            active={active}
            activeRouteMobile={activeRouteMobile}
          />
          <div className="d-flex flex-row">
            <Nav className="table-headers" vertical>
              <li
                className={activeRoute("#company") + " nav-link cursor-pointer"}
                onClick={() => history.push("#company")}
              >
                <NavLink activeClassName="" to="#company">
                  Company
                </NavLink>
              </li>
              <li
                className={activeRoute("#profile") + " nav-link cursor-pointer"}
                onClick={() => history.push("#profile")}
              >
                <NavLink activeClassName="" to="#profile">
                  Profile
                </NavLink>
              </li>
              <li
                className={activeRoute("#banks") + " nav-link cursor-pointer"}
                onClick={() => history.push("#banks")}
              >
                <NavLink activeClassName="" to="#banks">
                  Bank accounts
                </NavLink>
              </li>
              {isOwnerOrAdmin && (
                <li
                  className={activeRoute("#users") + " nav-link cursor-pointer"}
                  onClick={() => history.push("#users")}
                >
                  <NavLink activeClassName="" to={"#users"}>
                    Users
                  </NavLink>
                </li>
              )}
              <li
                className={activeRoute("#billing") + " nav-link cursor-pointer"}
                onClick={() => history.push("#billing")}
              >
                <NavLink activeClassName="" to="#billing">
                  Billing
                </NavLink>
              </li>
              <li
                className={
                  activeRoute("#security") + " nav-link cursor-pointer"
                }
                onClick={() => history.push("#security")}
              >
                <NavLink activeClassName="" to="#security">
                  Security
                </NavLink>
              </li>
              {isOwnerOrAdmin && process.env.REACT_APP_ENV !== "production" && (
                <li
                  className={
                    activeRoute("#configuration") + " nav-link cursor-pointer"
                  }
                  onClick={() => history.push("#configuration")}
                >
                  <NavLink activeClassName="" to="#configuration">
                    Configuration
                  </NavLink>
                </li>
              )}
              <li
                className={activeRoute("#tags") + " nav-link cursor-pointer"}
                onClick={() => history.push("#tags")}
              >
                <NavLink activeClassName="" to="#tags">
                  Contact tags
                </NavLink>
              </li>
              {isOwnerOrAdmin && (
                <li
                  className={
                    activeRoute("#integrations") + " nav-link cursor-pointer"
                  }
                  onClick={() => history.push("#integrations")}
                >
                  <NavLink activeClassName="" to="#integrations">
                    Integrations
                  </NavLink>
                </li>
              )}
            </Nav>
            <div className="flex-grow-1">{getActiveComponent()}</div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}

export default Settings;
