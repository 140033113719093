import { convertToDate, getStatusPublic } from "../../modules/Helpers";
import React from "react";
import {
  Badge,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

export function statusPillPublic(value, transaction) {
  // need to remove number and dashes from identifier in order for the dom call to work
  let identifier = transaction.identifier;

  identifier = identifier.replace(/-/g, "");
  identifier = identifier.replace(/[0-9]/g, "");
  let statusDate = transaction.states[0].createdAt;

  let width = 80;

  let status = getStatusPublic(value, transaction.direction);

  if (value === 0) {
    // Processing ** DEPRECATED **
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          triggle="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 1) {
    // Deposited
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="success"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 2) {
    // Failed
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          target={identifier}
          trigger="hover"
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="danger"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 3) {
    // Initiated
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          target={identifier}
          trigger="hover"
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Processing - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Processing
        </Badge>
      </React.Fragment>
    );
  } else if (value === 4) {
    // Withdrawn
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Processing - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Processing
        </Badge>
      </React.Fragment>
    );
  } else if (value === 5) {
    // In transit
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Processing - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Processing
        </Badge>
      </React.Fragment>
    );
  } else if (value === 6) {
    // On hold
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="warning"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 7) {
    // Unpaid
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="default"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 8) {
    // Cancelled
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="danger"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 9) {
    // Accepted
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Processing - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Processing
        </Badge>
      </React.Fragment>
    );
  } else if (value === 10) {
    // Returned
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="success"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 11) {
    // Returning
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 12) {
    // Rerouted
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 13) {
    // Retrying
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 14) {
    // Pending approval
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="default"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  } else if (value === 18) {
    // Charged (with credit card)
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          target={identifier}
          trigger="hover"
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Processing - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Processing
        </Badge>
      </React.Fragment>
    );
  } else if (value === 20) {
    // Resumed
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          target={identifier}
          trigger="hover"
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            {status.value} - {`${convertToDate(statusDate)}`}
          </PopoverHeader>
          <PopoverBody>{status.description}</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="primary"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          {status.value}
        </Badge>
      </React.Fragment>
    );
  }
}
