import React, { Fragment, useEffect, useRef, useState } from "react";
import useForm from "../../../../../hooks/UseForms";
import { InternalTransactionFormValidation } from "../../../../../modules/form_validation/InternalTransactionFormValidation";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import BankAccountSelection from "../../../../../components/dropdowns/BankAccountSelection";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import { useHistory, useLocation } from "react-router";
import { baseUrl } from "modules/Helpers";

function InternalDetailsStep() {
  const { values, setValues, handleChange, handleSubmit, errors } = useForm(
    submit,
    InternalTransactionFormValidation
  );
  const [state, setState] = useSimpleContext();
  const [fromBankAccount, setFromBankAccount] = useState({
    value: "",
    label: "Select an account",
  });
  const [toBankAccount, setToBankAccount] = useState({
    value: "",
    label: "Select an account",
  });
  const amountRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    amountRef.current.focus();
  }, []);

  useEffect(() => {
    if (state && state.amount) {
      setValues(state);
      setToBankAccount(state.toBankAccount);
      setFromBankAccount(state.fromBankAccount);
    }
  }, []);

  function submit() {
    setState({
      ...state,
      ...values,
      fromBankAccount,
      toBankAccount,
    });
    history.push("review", {
      ...location.state,
      currStep: 2,
    });
  }

  return (
    <Fragment>
      <h2 className="text-center">Add internal transfer details</h2>
      <div className="new-payment-inner-content">
        <Form className="form" onSubmit={handleSubmit}>
          <Label>Amount</Label>
          <FormGroup>
            <Input
              type="number"
              step="any"
              name="amount"
              value={values.amount || ""}
              onChange={handleChange}
              invalid={!!errors.amount}
              innerRef={amountRef}
              required
            />
            <FormFeedback>
              {errors.amount}
              {errors?.amount?.startsWith("Min") ? null : (
                <a>
                  please contact{" "}
                  <a
                    href={baseUrl + "/admin/payments?ticket=true"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    support
                  </a>{" "}
                  to request higher amounts
                </a>
              )}
            </FormFeedback>
          </FormGroup>

          <Label>From account</Label>
          <FormGroup>
            <BankAccountSelection
              bankAccount={fromBankAccount}
              setBankAccount={setFromBankAccount}
              padSignatureRequired={true}
            />
          </FormGroup>

          <Label>To account</Label>
          <FormGroup>
            <BankAccountSelection
              bankAccount={toBankAccount}
              setBankAccount={setToBankAccount}
              exclude={fromBankAccount}
            />
          </FormGroup>

          <Label>Payment description</Label>
          <FormGroup>
            <Input
              type="text"
              name="statement"
              value={values.statement || ""}
              onChange={handleChange}
              invalid={!!errors.statement}
              required
            />
            <FormFeedback>{errors.statement}</FormFeedback>
          </FormGroup>
          <Button
            disabled={
              fromBankAccount.value === "" ||
              toBankAccount.value === "" ||
              !fromBankAccount.variableDebitPad
            }
            block
            className="mt-4"
            color="primary"
            type="submit"
          >
            Proceed
          </Button>
        </Form>
      </div>
    </Fragment>
  );
}

export default InternalDetailsStep;

InternalDetailsStep.propTypes = {};
