import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function submitCompanyKyc(input, output, hooks) {
  const query = buildMutation(input, output, "submitCompanyKyc");
  return callMutation(query, hooks);
}

submitCompanyKyc.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    industry: PropTypes.string,
    purpose: PropTypes.string,
    address: PropTypes.string,
    addressCity: PropTypes.string,
    addressProvince: PropTypes.string,
    addressPostalCode: PropTypes.string,
    incorporationNumber: PropTypes.string,
    companyAttestation: PropTypes.bool,
    owners: PropTypes.array,
    structure: PropTypes.string,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
