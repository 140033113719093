import { callGQL } from "./utils";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { _ } from "mixpanel-browser/src/utils.js";

export function buildMutation(input, output, mutationName) {
  const analytics = JSON.stringify(_.info.properties());
  Object.keys(input).forEach(
    (key) => input[key] === undefined && delete input[key]
  );

  let query = { mutation: {} };
  query.mutation[mutationName] = {
    __args: { input: input, analytics: analytics },
    ...output,
    authenticationError: {
      message: true,
    },
    rateLimitError: {
      message: true,
    },
    authorizationError: {
      message: true,
    },
    fieldErrors: {
      field: true,
      message: true,
    },
  };

  if (Object.entries(input).length === 0) {
    delete query.mutation[mutationName].__args.input;
  }

  Object.keys(input).forEach(
    (key) => input[key] === undefined && delete input[key]
  );
  return jsonToGraphQLQuery(query, { pretty: true })
    .replace(/"</g, "")
    .replace(/>"/g, "");
}

export async function callMutation(query, { context, errorHandler, dispatch }) {
  let data = await callGQL(query, context.token ? `JWT ${context.token}` : "");
  dispatch({ type: "refresh", refresh: !context.refresh });
  return errorHandler(data);
}
