import useForm from "../../hooks/UseForms";
import { ImportBillsFormValidation } from "../../modules/form_validation/ImportBillsFormValidation";
import React, { Fragment, useEffect, useState } from "react";
import { FormFeedback, Input } from "reactstrap";
import PropTypes from "prop-types";
import {
  getContactBankAccountsSelection,
  objectMap,
} from "../../modules/Helpers";

export const ToBankAccountCell = ({
  cell,
  updateMyData,
  column: { id },
  value: initialValue,
}) => {
  const defaultValue = "recipient-decision";
  const bankAccounts = cell.row.original?.contact?.value?.bankAccounts || [];
  const toBankAccountId = cell.row.original.toBankAccountId;
  const initialAccount =
    bankAccounts.length > 1
      ? defaultValue
      : toBankAccountId.value || defaultValue;
  const [toAccount, setToAccount] = useState(initialAccount);

  useEffect(() => {
    setToAccount(initialValue.value || defaultValue);
  }, [initialValue]);

  const toAccountOnChange = (value) => {
    const bankIdentifier = value === defaultValue ? null : value;
    setToAccount(value);
    updateMyData(cell.row.original.billId.value, id, bankIdentifier, true);
  };
  const options = getContactBankAccountsSelection(bankAccounts, defaultValue);
  return (
    <Fragment>
      <Input
        name={id}
        type="select"
        value={toAccount}
        onChange={(event) => toAccountOnChange(event.target.value)}
        required
      >
        {options.map((option, index) => (
          <option key={index} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </Input>
    </Fragment>
  );
};

ToBankAccountCell.propTypes = {
  value: PropTypes.object,
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  updateMyData: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
};

function MakeImportBillsTableCell(accessor, inputType, placeholder) {
  const ImportBillsTableCell = ({
    value: initialValue,
    row,
    updateMyData,
    data,
    lastBillIdToggled,
  }) => {
    const [editing, setEditing] = useState(false);
    const { values, handleTableFieldChange, setValues, errors, setErrors } =
      useForm(null, ImportBillsFormValidation);
    const billId = row.original.billId;
    const selection = row.original.selection;
    const amountDue = row.original.amountDue;
    const [tableLoadErrors, setTableLoadErrors] = useState({});

    // Update data when the input is blurred
    const onBlur = () => {
      setEditing(false);
      updateMyData(billId.value, accessor, values[accessor], !errors[accessor]);
    };

    const onChange = (event) => {
      setEditing(true);
      setTableLoadErrors({});
      handleTableFieldChange(event);
    };

    useEffect(() => {
      // when we update react table's data for example after a search/filter query
      // we need to manually show validation errors, since the useForm will only show them
      // in the onChange
      // TODO try to clean this up
      setTableLoadErrors({});
      setErrors({});
      if (selection.value && !editing) {
        const mappedRow = objectMap(row.original, (entry) => entry.value, {});
        const rowErrors = ImportBillsFormValidation(mappedRow, {});
        if (Object.keys(rowErrors).length !== 0) {
          setTableLoadErrors(rowErrors);
        }
      }
    }, [data]);

    useEffect(() => {
      // we do not update react table data after selection changes for performance reasons
      // so instead track lastBillIdToggled, and manually set validation errors on the input
      // TODO try to clean this up
      if (lastBillIdToggled === billId.value) {
        const mappedRow = objectMap(row.original, (entry) => entry.value, {});
        const rowErrors = ImportBillsFormValidation(mappedRow, {});
        if (Object.keys(rowErrors).length !== 0) {
          setTableLoadErrors(rowErrors);
        }
      }
    }, [lastBillIdToggled]);

    useEffect(() => {
      // editing is true when the user has made a modification to the input value
      if (editing) {
        updateMyData(
          billId.value,
          accessor,
          values[accessor],
          !errors[accessor]
        );
      }
    }, [errors[accessor], selection.value]);

    useEffect(() => {
      setValues({
        ...values,
        [accessor]: initialValue?.value,
      });
    }, [initialValue]);

    return (
      <Fragment>
        <Input
          className={tableLoadErrors?.[accessor] && "border-warning"}
          name={accessor}
          type={inputType}
          value={values[accessor] || ""}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          invalid={!!errors[accessor]}
        />
        <FormFeedback>{errors[accessor]}</FormFeedback>
        {parseFloat(amountDue.value) < parseFloat(values[accessor]) &&
          accessor === "amount" && (
            <div className="text-muted" style={{ fontSize: "11px" }}>
              Amount entered exceeds amount due
            </div>
          )}
        {tableLoadErrors?.[accessor] && (
          <div
            className="text-warning"
            style={{ fontSize: "11px", marginTop: "0.25rem" }}
          >
            {tableLoadErrors[accessor]}
          </div>
        )}
      </Fragment>
    );
  };
  ImportBillsTableCell.propTypes = {
    value: PropTypes.object,
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    updateMyData: PropTypes.func.isRequired,
    cell: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    lastBillIdToggled: PropTypes.string.isRequired,
  };
  return ImportBillsTableCell;
}
export default MakeImportBillsTableCell;

export const SelectionCell = ({ row, updateMyData }) => {
  useEffect(() => {
    setChecked(row.original.selection.value);
  }, [row]);

  const [checked, setChecked] = useState(row.original.selection.value);
  const rowSelected = (isChecked) => {
    setChecked(isChecked);
    updateMyData(row.original.billId.value, "selection", isChecked, true);
  };

  return (
    <div className="mb-3">
      <Input
        id="cb-1"
        className="form-check-input ml-2"
        type="checkbox"
        checked={checked}
        onChange={() => rowSelected(!checked)}
      />
      <span className="form-check-sign">
        <span className="check" />
      </span>
    </div>
  );
};

SelectionCell.propTypes = {
  row: PropTypes.object.isRequired,
  updateMyData: PropTypes.func.isRequired,
};

export const SelectionHeaderCell = ({
  toggleAllSelected,
  filteredBillIds,
  data,
}) => {
  // find out if the current data shown in react table is all checked or not
  const allChecked = data
    ?.map(({ selection, billId }) => {
      return (
        selection.value &&
        filteredBillIds?.find((filteredBillId) => filteredBillId === billId)
      );
    })
    ?.every((row) => !!row);

  const [checked, setChecked] = useState(data.length < 1 ? false : allChecked);

  // if the user updates the filtered bills (uses the search bar), update
  // the bulk checkbox value
  // TODO see if this is necessary
  useEffect(() => {
    setChecked(allChecked);
  }, [filteredBillIds]);

  const allSelected = () => {
    toggleAllSelected(!checked);
    setChecked(!checked);
  };
  return (
    <div style={{ padding: "0 10px 22px 28px" }}>
      <Input
        id="cb-1"
        className="form-check-input"
        type="checkbox"
        checked={checked}
        onChange={allSelected}
      />
      <span className="form-check-sign">
        <span className="check" />
      </span>
    </div>
  );
};

SelectionHeaderCell.propTypes = {
  data: PropTypes.array.isRequired,
  filteredBillIds: PropTypes.array.isRequired,
  toggleAllSelected: PropTypes.func.isRequired,
};
