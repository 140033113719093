import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import TagsSelect from "../custom_select/TagsSelect";

function AddContactModal(props) {
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const [loading] = useState(false);
  const [contactTags, setContactTags] = useState([]);

  useEffect(() => {
    if (props.defaultTag) {
      setContactTags([{ key: props.defaultTag, label: props.defaultTag }]);
    }
  }, []);

  function onSelectTags(value) {
    if (value === null) {
      value = [];
    }
    setContactTags(value);
  }

  function FormToContact(e, tags) {
    e.preventDefault();

    let flatTags = tags.map((tag) => {
      return tag.label;
    });

    const contact = {
      email: props.values.email,
      name: props.values.name,
      tags: flatTags,
    };

    return contact;
  }

  const handleSubmit = (e) => {
    const contact = FormToContact(e, contactTags);
    props.addContact(contact);
    props.toggle();
  };

  return (
    <Modal backdrop centered fade isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Button close onClick={props.toggle} />
          </Col>
        </Row>
        <h4 className="text-center">New Contact</h4>
        <Form onSubmit={handleSubmit}>
          <Label>Name</Label>
          <FormGroup>
            <Input
              type="text"
              name="name"
              innerRef={nameRef}
              onChange={props.handleChange}
              value={props.values.name || ""}
              invalid={!!props.errors.name}
            />
            <FormFeedback>{props.errors.name}</FormFeedback>
          </FormGroup>
          <Label>Email</Label>
          <FormGroup>
            <Input
              type="email"
              name="email"
              innerRef={emailRef}
              onChange={props.handleChange}
              value={props.values.email || ""}
              required
            />
          </FormGroup>
          <TagsSelect
            activeTags={contactTags}
            onChangeCallback={onSelectTags}
          />
          <div className="mt-3">
            <Button
              block
              color="primary"
              type="submit"
              disabled={loading || !(props.values.name && props.values.email)}
            >
              Add
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default AddContactModal;

AddContactModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  addContact: PropTypes.func.isRequired,
  defaultTag: PropTypes.string.isRequired,
};
