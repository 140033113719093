import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { useHistory } from "react-router";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";
import { Button } from "reactstrap";

function ListSavedAccounts(props) {
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const gqlHooks = useGQL();

  useEffect(() => {
    if (state.transaction) {
      if (state.transaction.direction === "CREDIT") {
        trackEvent(
          "creditListSaved",
          {
            transactionId: state.transaction.identifier,
            companyId: state.transaction.company?.identifier,
            contactId: state.transaction.contact.identifier,
          },
          gqlHooks
        );
      } else {
        trackEvent(
          "debitListSaved",
          {
            transactionId: state.transaction.identifier,
            companyId: state.transaction.company?.identifier,
            contactId: state.transaction.contact.identifier,
          },
          gqlHooks
        );
      }
    } else {
      trackEvent(
        "recurringListSaved",
        {
          recurringId: state.recurringPlan.identifier,
          companyId: state.recurringPlan.company.identifier,
          contactId: state.recurringPlan.contact.identifier,
        },
        gqlHooks
      );
    }
  }, []);

  return (
    <Fragment>
      <h4>Select a saved account</h4>
      <div>
        {state.bankAccounts.map((account) => (
          <Fragment key={account.identifier}>
            <Button
              block
              className="cursor-pointer btn-primary p-3 text-white flex-column d-flex rounded mb-3"
              onClick={() => {
                setState({ ...state, bankAccount: account });
                history.push("review", { search: "" });
                props.setListAccounts(false);
              }}
            >
              <span className="text-lg font-weight-600">
                {account.institution}
              </span>
              <span className="font-weight-500">
                Account ending in {account.accountNumber}
              </span>
            </Button>
          </Fragment>
        ))}
        {state.bankAccounts.length === 0 && (
          <div className="text-center">
            <p className="text-muted">No saved bank accounts yet</p>
          </div>
        )}
        <Button
          className="text-primary text-underline btn-link d-block m-auto pb-4"
          onClick={() => {
            props.setListAccounts(false);
            props.setEnterMfa(false);
          }}
        >
          Use a different number or email
        </Button>
      </div>
    </Fragment>
  );
}

export default ListSavedAccounts;

ListSavedAccounts.propTypes = {
  setListAccounts: PropTypes.func,
  setEnterMfa: PropTypes.func,
};
