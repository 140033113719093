import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { withApiValue } from "../../modules/WithApiValue";
import useGQL from "../../api_client/UseGQL";
import { useToasts } from "react-toast-notifications";
import { agreeVariableDebitPad } from "../../api_client/mutations/bankAccount";
import { trackEvent } from "../../modules/analytics";
import { appName, emailAccounts, orgName } from "modules/Helpers";

const config = {
  company: {
    fullName: true,
    name: true,
    kyc: {
      name: true,
    },
  },
};

function VariableSporadicPAD(props) {
  const [signaturePAD, setSignaturePAD] = useState(false);
  const [signaturePADTime, setSignaturePADTime] = useState(null);
  const [loading, setLoading] = useState(false);
  let gqlHooks = useGQL();
  const { addToast } = useToasts();

  async function agreePAD() {
    setLoading(true);
    let output = {
      ok: true,
    };
    let input = {
      bankAccountId: props.bankAccountIdentifier,
      signaturePad: signaturePAD,
      signaturePadTime: signaturePADTime,
    };

    const response = await agreeVariableDebitPad(input, output, gqlHooks);
    if (response && response.ok) {
      trackEvent("agreeToPadComplete", {}, gqlHooks);
      props.toggle();
    } else {
      addToast("Something went wrong, please contact support", {
        appearance: "error",
        autoDismiss: true,
      });
      setLoading(false);
    }
  }

  const agreeToPAD = () => {
    // / 1000 to get seconds instead of miliseconds
    const time = new Date().getTime() / 1000;
    setSignaturePAD(!signaturePAD);
    setSignaturePADTime(Math.floor(time));
  };
  return (
    <Fragment>
      <div>
        <Row>
          <Col sm="12">
            <blockquote className="blockquote billing-pad-wrapper">
              <p className="blockquote-footer">Type of PAD: Business.</p>
              <p className="blockquote-footer font-weight-bold">
                YOU WAIVE ANY LEGISLATIVE OR REGULATORY REQUIREMENT FOR
                PRE-NOTIFICATION.
              </p>
              <p className="blockquote-footer">
                You will be debited following{" "}
                <b>credit events created on the {appName} dashboard.</b>
              </p>
              <p className="blockquote-footer">
                Transaction dates that fall on a weekend or holiday will be
                processed the next business day.
              </p>
              <p className="blockquote-footer">
                You agree to be debited a variable amount depending on your
                usage.
              </p>
              <p className="blockquote-footer">
                You have certain recourse rights if any debit does not comply
                with this agreement. For example, you have the right to receive
                reimbursement for any debit that is not authorized or is not
                consistent with this PAD Agreement. To obtain more information
                on your recourse rights, contact your financial institution or
                visit <u>payments.ca</u>.
              </p>
              <p className="blockquote-footer">
                If the first attempt to debit your account fails, you agree to
                allow us to debit your account one additional time for the
                amount specified in this agreement.
              </p>
              <p className="blockquote-footer">
                <b>
                  If multiple attempts fail to debit your account you agree to
                  be charged a CA $30 NSF fee. Your {appName} account will also
                  be frozen until payment has been made.
                </b>
              </p>
              <p className="blockquote-footer">
                Your Payor&apos;s PAD Agreement may be cancelled provided notice
                is received 30 days before the next scheduled PAD. If any of the
                above details are incorrect, please contact us immediately at
                {emailAccounts.SUPPORT}. If the details are correct, you do not
                need to do anything further and your Pre-Authorized Debits will
                be processed and start on the Payment Start Date indicated
                above.
              </p>
              <p className="blockquote-footer">
                This PAD is between{" "}
                <b>
                  {props.company?.kyc?.name ||
                    props.company?.fullName ||
                    props.company.name}
                </b>{" "}
                and{" "}
                <u>
                  <b>
                    {appName} by {orgName}
                  </b>
                </u>
                . ({appName})
              </p>
            </blockquote>
          </Col>
          <Col sm="12" className="pt-0">
            <FormGroup check className="mb-3 mt-2">
              <Label className="form-check-label">
                <Input
                  id="cb-1"
                  className="form-check-input"
                  type="checkbox"
                  checked={signaturePAD}
                  onChange={(e) => agreeToPAD(e)}
                />
                I agree to the terms and conditions of the pre authorized debit
                agreement
                <span className="form-check-sign">
                  <span className="check" />
                </span>
              </Label>
            </FormGroup>
            <Button
              block
              className="btn-primary text-white"
              type="button"
              disabled={!signaturePAD || loading}
              onClick={agreePAD}
            >
              Accept
            </Button>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default withApiValue(VariableSporadicPAD, config);

VariableSporadicPAD.propTypes = {
  company: PropTypes.object,
  bankAccountIdentifier: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};
