import PropTypes from "prop-types";
import React from "react";

function NoteToContact({ alias }) {
  return <>{alias || "Note to contact"}</>;
}

NoteToContact.propTypes = {
  alias: PropTypes.string,
};

export default NoteToContact;
