import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import QueryString from "query-string";

import { allPublicTransactions } from "api_client/queries/transactions";
import PopOutOverlay from "../../components/overlays/PopOutOverlay";
import TransactionDetails from "views/admin/transactions/TransactionDetails";
import { withApiValue } from "../../modules/WithApiValue";
import { AdminLoader } from "../../components/loaders/AdminLoader";
import CustomerTransactions from "./CustomerTransactions";

// javascript plugin used to create scrollbars on windows
// react plugin for creating notifications over the activity

const config = {
  identifier: true,
};

const Loader = () => AdminLoader;

function Customer(props) {
  const [overlayDetails, setOverlayDetails] = useState(false);
  const location = useLocation();
  const [Component, setComponent] = useState(null);
  const history = useHistory();
  const [fullScreen, setFullScreen] = useState(false);
  const [disableBackdropClick, setDisableBackdropClick] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [Banner, setBanner] = useState(null);

  //todo get rid of this and handle it a different way

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.overlay && search.type && search.id) {
      setFullScreen(false);
      if (search.type === "payment") {
        setComponent(
          <TransactionDetails
            customer={true}
            toggle={() => history.push({ search: "" })}
            id={search.id}
            setBackdropClick={setDisableBackdropClick}
          />
        );
      }
      setOverlayDetails(true);
    } else if (search.type === "notifications") {
      setOverlayDetails(true);
    } else if (search.type === "information") {
      setOverlayDetails(true);
    } else {
      setOverlayDetails(false);
    }
  }, [location.search]);

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.ticket) {
      setSupportModal(true);
    } else {
      setSupportModal(false);
    }
  }, [location.search]);

  return (
    <Fragment>
      <div className="wrapper">
        <div className="main-panel">
          <PopOutOverlay
            fullscreen={fullScreen}
            disableBackdropClick={disableBackdropClick}
            isOpen={overlayDetails}
            toggle={() => {
              setOverlayDetails(!overlayDetails);
              history.goBack();
            }}
          >
            {Component}
          </PopOutOverlay>
          <Switch>
            <Route
              path={["/customer/:identifier"]}
              render={() => <CustomerTransactions />}
            />
          </Switch>
        </div>
      </div>
    </Fragment>
  );
}
export default Customer;

Customer.propTypes = {};
