import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function railzBillData(input, output, hooks) {
  const query = buildQuery(input, output, "railzBillData");
  return callQuery(query, hooks);
}

railzBillData.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};
