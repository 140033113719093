import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function deleteBankAccount(input, output, hooks) {
  const query = buildMutation(input, output, "deleteBankAccount");
  return callMutation(query, hooks);
}

deleteBankAccount.propTypes = {
  input: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function createBankAccountViaFlinks(input, output, hooks) {
  const query = buildMutation(input, output, "createBankAccountViaFlinks");
  return callMutation(query, hooks);
}

createBankAccountViaFlinks.propTypes = {
  input: PropTypes.shape({
    flinksLoginId: PropTypes.string.isRequired,
    flinksBankAccountId: PropTypes.string.isRequired,
    refresh: PropTypes.bool.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function createBankAccountManual(input, output, hooks) {
  const query = buildMutation(input, output, "createBankAccountManual");
  return callMutation(query, hooks);
}

createBankAccountManual.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function assignBankAccountToCompany(input, output, hooks) {
  const query = buildMutation(input, output, "assignBankAccountToCompany");
  return callMutation(query, hooks);
}

assignBankAccountToCompany.propTypes = {
  input: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function assignBankAccountAsBilling(input, output, hooks) {
  const query = buildMutation(input, output, "assignBankAccountAsBilling");
  return callMutation(query, hooks);
}

assignBankAccountAsBilling.propTypes = {
  input: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
    signaturePAD: PropTypes.bool.isRequired,
    signaturePADTime: PropTypes.number.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function updateBankAccountKyc(input, output, hooks) {
  const query = buildMutation(input, output, "updateBankAccountKyc");
  return callMutation(query, hooks);
}

updateBankAccountKyc.propTypes = {
  input: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
    kyc: PropTypes.shape({
      transitNumber: PropTypes.string,
      institutionNumber: PropTypes.string,
      accountNumber: PropTypes.string,
      institution: PropTypes.string,
      title: PropTypes.string,
      accountType: PropTypes.string,
      holderName: PropTypes.string,
      holderAddress: PropTypes.string,
      holderAddressPostalCode: PropTypes.string,
      holderAddressCity: PropTypes.string,
      holderAddressCountry: PropTypes.string,
      holderEmail: PropTypes.string,
    }),
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function confirmMicrodeposits(input, output, hooks) {
  const query = buildMutation(input, output, "confirmMicrodeposits");
  return callMutation(query, hooks);
}

confirmMicrodeposits.propTypes = {
  input: PropTypes.shape({
    bankAccountId: PropTypes.string.isRequired,
    amount1: PropTypes.number.isRequired,
    amount2: PropTypes.number.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function retrieveSavedBankAccounts(input, output, hooks) {
  const query = buildMutation(input, output, "retrieveSavedBankAccounts");
  return callMutation(query, hooks);
}

export function saveBankAccount(input, output, hooks) {
  const query = buildMutation(input, output, "saveBankAccount");
  return callMutation(query, hooks);
}

export function bankAccountEmailMfa(input, output, hooks) {
  const query = buildMutation(input, output, "bankAccountEmailMfa");
  return callMutation(query, hooks);
}

export function bankAccountTextMfa(input, output, hooks) {
  const query = buildMutation(input, output, "bankAccountTextMfa");
  return callMutation(query, hooks);
}

export function agreeVariableDebitPad(input, output, hooks) {
  const query = buildMutation(input, output, "agreeVariableDebitPad");
  return callMutation(query, hooks);
}
