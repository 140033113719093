import { verifyEmail, verifyPhone } from "../Helpers";

export function saveBankModalFormValidation(values, errors) {
  if (values.contactMethod) {
    if (values.contactMethod.length <= 1) {
      delete errors.contactMethod;
    } else if (
      !verifyPhone(values.contactMethod) &&
      !verifyEmail(values.contactMethod)
    ) {
      errors.contactMethod =
        "Please enter a valid phone number or email eg: 2505551111";
    } else {
      delete errors.contactMethod;
    }
  } else if (values.code) {
    delete errors.code;
  }
  return errors;
}
