import AddIcon from "assets/icons/AddIcon";
import ApiTokensTable from "components/tables/ApiTokensTable";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Button } from "reactstrap";
import AddApiTokenModal from "./AddApiTokenModal";

function Integrations(props) {
  let location = useLocation();
  const [addTokenModal, setAddTokenModal] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (location.state) {
      SecurityPolicyViolationEvent(location.state.active);
    }
  }, []);

  return (
    <Fragment>
      {alert}
      {addTokenModal && (
        <AddApiTokenModal
          setAlert={setAlert}
          toggle={() => setAddTokenModal(!addTokenModal)}
        />
      )}
      <div className="mb-3">
        <div className="d-flex justify-content-end pr-2">
          <Button
            color="primary"
            className="btn-md btn-primary sp-button"
            onClick={() => setAddTokenModal(!addTokenModal)}
          >
            <AddIcon /> Create Token
          </Button>
        </div>
      </div>
      <div className="p-3">
        <ApiTokensTable {...props} />
      </div>
    </Fragment>
  );
}

export default Integrations;

Integrations.propTypes = {};
