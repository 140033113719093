import { Button, Col } from "reactstrap";
import {
  CustomTooltip,
  estDepositDay,
  estReRouteDay,
} from "../../modules/Helpers";
import React from "react";
import PropTypes, { string } from "prop-types";

const REROUTE = "reroute";
const RETRY = "retry";

function DepositDateInfo(props) {
  let { type, isOpen, setOpen } = props;

  return (
    <Col>
      <span>Estimated deposit date</span>
      <CustomTooltip
        title="For EFT payments to/from large Canadian financial institutions, you can expect funds in your account
               within 2-3 business days. Smaller institutions and credit unions could have longer processing times."
        placement="top"
        arrow
        open={isOpen}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        leaveTouchDelay={10000}
      >
        <Button
          onClick={() => setOpen(!isOpen)}
          variant="contained"
          className="btn-link tooltip-button"
        >
          <i className="fas fa-info-circle" />
        </Button>
      </CustomTooltip>
      <h5>{type === REROUTE ? estReRouteDay() : estDepositDay()}</h5>
    </Col>
  );
}

export { DepositDateInfo as default, RETRY, REROUTE };

DepositDateInfo.propTypes = {
  type: PropTypes.oneOf(string[(REROUTE, RETRY)]),
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
};
