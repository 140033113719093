import React, { Fragment, useEffect } from "react";
import TrustFooter from "../../components/footers/TrustFooter";
import OnboardingBusinessType from "./generic/OnboardingBusinessType";
import { Switch } from "react-router";
import { Redirect, Route } from "react-router-dom";
import OnboardingConcierge from "./generic/OnboardingConcierge";
import OnboardingCreateProfile from "./generic/OnboardingCreateProfile";
import OnboardingIndividualLegal from "./individual/OnboardingIndividualLegal";
import OnboardingOrganizationLegal from "./organization/OnboardingOrganizationLegal";
import OnboardingOwners from "./generic/OnboardingOwners";
import OnboardingAddBankAccount from "./generic/OnboardingAddBankAccount";
import OnboardingSecurityQuestion from "./generic/OnboardingSecurityQuestion";
import OnboardingVerifyIdentity from "./generic/OnboardingVerifyIdentity";
import OnboardingComplete from "./generic/OnboardingComplete";
import OnboardingNavbav from "../../components/wizards/OnboardingNavbav";
import * as Sentry from "@sentry/browser";
import OnboardingOrganizationDocuments from "./organization/OnboardingOrganizationDocuments";

function Onboarding() {
  useEffect(() => {
    try {
      Sentry.configureScope((scope) => scope.setTransactionName("Onboarding"));
    } catch (e) {
      console.log("sentry not loaded");
    }
  }, []);

  return (
    <Fragment>
      <div className="bg-white onboarding-wrapper">
        <div className="d-flex flex-column auth-content flex-grow-1">
          <OnboardingNavbav />
          <div className="onboarding-content">
            <Switch>
              {/* 
              <Route
                path="/onboarding/concierge"
                render={() => <OnboardingConcierge />}
              />
              */}
              <Route
                path="/onboarding/business-type"
                render={() => <OnboardingBusinessType />}
              />
              <Route
                path="/onboarding/profile"
                render={() => <OnboardingCreateProfile />}
              />
              <Route
                path="/onboarding/individual-legal"
                render={() => <OnboardingIndividualLegal />}
              />
              <Route
                path="/onboarding/organization-legal"
                render={() => <OnboardingOrganizationLegal />}
              />
              <Route
                path="/onboarding/organization-owners"
                render={() => <OnboardingOwners />}
              />
              <Route
                path="/onboarding/organization-documents"
                render={() => <OnboardingOrganizationDocuments />}
              />
              <Route
                path="/onboarding/add-bank"
                render={() => <OnboardingAddBankAccount />}
              />
              <Route
                path="/onboarding/security"
                render={() => <OnboardingSecurityQuestion />}
              />
              <Route
                path="/onboarding/identity"
                render={() => <OnboardingVerifyIdentity />}
              />
              <Route
                path="/onboarding/complete"
                render={() => <OnboardingComplete />}
              />
              <Redirect from="/*" to="/onboarding/business-type" />
            </Switch>
          </div>
          <TrustFooter />
        </div>
      </div>
    </Fragment>
  );
}

export default Onboarding;
