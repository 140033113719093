/* eslint-disable react/prop-types,react/display-name */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Badge,
  Button,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import useGQL from "../../api_client/UseGQL";
import { allContactTags } from "../../api_client/queries/contacts";
import {
  deleteContactTags,
  updateContactTagsMapping,
} from "../../api_client/mutations/contacts";
import ReactTable from "./ReactTable";
import useForm from "../../hooks/UseForms";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { DEFAULT_TAGS } from "../../modules/Helpers";
import { contactTagsFormValidation } from "../../modules/form_validation/ContactTagsFormValidation";
import { withApiValue } from "../../modules/WithApiValue";
import SweetAlert from "react-bootstrap-sweetalert";

const config = {
  company: { contactTagsMapping: true },
};

function ContactTagsTable(props) {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const { values, setValues, errors, handleChange, handleSubmit } = useForm(
    submit,
    contactTagsFormValidation
  );
  const [editDefaultTag, setEditDefaultTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState(null);
  const { addToast } = useToasts();
  let gqlHooks = useGQL();
  const [contactTagsMapping, setContactTagsMapping] = useState(
    JSON.parse(props.company.contactTagsMapping)
  );

  useEffect(() => {
    setContactTagsMapping(JSON.parse(props.company.contactTagsMapping));
  }, [props.company.contactTagsMapping]);

  useEffect(() => {
    setValues({
      supplier: contactTagsMapping[DEFAULT_TAGS.SUPPLIER],
      customer: contactTagsMapping[DEFAULT_TAGS.CUSTOMER],
    });
  }, []);

  useEffect(() => {
    if (values.search) {
      const timeoutId = setTimeout(() => searchTags(), 500);
      return () => clearTimeout(timeoutId);
    } else {
      setData(originalData);
    }
  }, [values.search]);

  const getData = useCallback(async () => {
    setLoading(true);

    let tags = [];
    const response = await allContactTags({}, { tags: true }, gqlHooks);
    if (response) {
      response.data.tags.map((tag, key) => {
        if (tag) {
          if (tag === contactTagsMapping.supplier) {
            tags.unshift({ value: key, label: tag });
          } else if (tag === contactTagsMapping.customer) {
            tags.unshift({ value: key, label: tag });
          } else {
            tags.push({ value: key, label: tag });
          }
        }
      });
      setData(tags);
      setOriginalData(tags);
    }
    setLoading(false);
  }, [contactTagsMapping]);

  function searchTags() {
    if (values.search === "") {
      setData(originalData);
    } else if (originalData.length >= 1) {
      let search = values.search.toLowerCase();
      let newData = originalData.filter(
        (tag) => !tag.label.toLowerCase().search(search)
      );
      setData(newData);
    }
  }

  function submit() {
    setEditDefaultTag(false);
    setLoading(true);

    let mapping = {};
    mapping[DEFAULT_TAGS.CUSTOMER] = values.customer;
    mapping[DEFAULT_TAGS.SUPPLIER] = values.supplier;

    let input = {
      mapping: JSON.stringify(mapping),
    };
    updateContactTagsMapping(input, { mapping: true }, gqlHooks).then(
      (response) => {
        if (response) {
          addToast("Default tags updated", {
            appearance: "success",
            autoDismiss: true,
          });
          props.update({ company: { contactTagsMapping: response.mapping } });
        } else {
          addToast("Could not update default tags", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    );
    setLoading(false);
  }

  function confirm(tag) {
    setAlertState(
      <SweetAlert
        warning
        title={"Confirm Delete"}
        onConfirm={() => deleteTag(tag)}
        onCancel={() => hideAlert()}
        showConfirm={true}
        showCancel={true}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        backdrop={false}
      >
        Are you sure you want to delete this tag?
      </SweetAlert>
    );
  }

  const hideAlert = () => {
    setAlertState(null);
  };

  function deleteTag(tag) {
    hideAlert();
    setLoading(true);

    deleteContactTags({ tags: [tag] }, {}, gqlHooks).then((response) => {
      if (response) {
        if (response) {
          addToast("Contact tag removed", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast("Could not remove contact tag", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    });
    setLoading(false);
  }

  let columns = useMemo(
    () => [
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ cell }) => (
          <Fragment>
            <div className="font-weight-bold mt-1 mb-1">
              {Object.values(contactTagsMapping).includes(
                cell.row.original.label
              ) ? (
                <Fragment>
                  <Badge
                    color="default"
                    style={{ minWidth: "80px", marginBottom: "0" }}
                    pill
                  >
                    {cell.row.original.label}
                  </Badge>
                  <span className="font-weight-400 text-muted text-sm font-italic">
                    {" "}
                    Default
                  </span>
                </Fragment>
              ) : (
                <Badge
                  color="default"
                  style={{ minWidth: "80px", marginBottom: "0" }}
                  pill
                >
                  {cell.row.original.label}
                </Badge>
              )}
            </div>
          </Fragment>
        ),
      },
      {
        accessor: "action",
        Cell: ({ cell }) => (
          <Fragment>
            {Object.values(contactTagsMapping).includes(
              cell.row.original.label
            ) ? (
              <Button
                onClick={() => setEditDefaultTag(true)}
                color="links"
                className="p-0 btn-link pull-right"
                size="sm"
              >
                Edit
              </Button>
            ) : (
              <Button
                onClick={() => confirm(cell.row.original.label)}
                color="danger"
                className="p-0 btn-link pull-right"
                size="sm"
              >
                Delete
              </Button>
            )}
          </Fragment>
        ),
      },
    ],
    [contactTagsMapping]
  );

  return (
    <>
      <CardBody className="p-2">
        {editDefaultTag ? (
          <Fragment>
            <h4>Edit Default Tags</h4>
            <Col md="6">
              <Form className="form" onSubmit={handleSubmit}>
                <Label>
                  Default Tag{" "}
                  <span className="text-muted text-sm">
                    (for credit payments)
                  </span>
                </Label>
                <FormGroup>
                  <Input
                    type="text"
                    name={DEFAULT_TAGS.SUPPLIER}
                    onChange={handleChange}
                    value={values.supplier || ""}
                    invalid={!!errors.supplier}
                  />
                  <FormFeedback>{errors.supplier}</FormFeedback>
                </FormGroup>
                <Label>
                  Default Tag{" "}
                  <span className="text-muted text-sm">
                    (for debit payments)
                  </span>
                </Label>
                <FormGroup>
                  <Input
                    type="text"
                    name={DEFAULT_TAGS.CUSTOMER}
                    onChange={handleChange}
                    value={values.customer || ""}
                    invalid={!!errors.customer}
                  />
                  <FormFeedback>{errors.customer}</FormFeedback>
                </FormGroup>
                <Button
                  block
                  color="primary"
                  size="md"
                  disabled={loading}
                  className="mb-3"
                >
                  Save
                </Button>
              </Form>
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            {alertState}
            <h4 className="mb-4">Manage Tags</h4>
            <ReactTable
              loading={loading}
              getData={getData}
              data={data}
              columns={columns}
              hidePagination={true}
              styles="contact-tags-table"
            />
          </Fragment>
        )}
      </CardBody>
    </>
  );
}

export default withApiValue(ContactTagsTable, config);

ContactTagsTable.propTypes = {
  company: PropTypes.object,
  update: PropTypes.func,
};
