import { validateEmail } from "./transaction/general";

export function AddBankAccountValidation(values, errors) {
  if (values.institutionNumber) {
    if (!values.institutionNumber.match(/^[0-9]*$/)) {
      errors.institutionNumber =
        "Institution number must only contain numerical digits";
    } else if (values.institutionNumber.length !== 3) {
      errors.institutionNumber = "Institution numbers have 3 digits";
    } else {
      delete errors.institutionNumber;
    }
  } else {
    delete errors.institutionNumber;
  }

  if (values.transitNumber) {
    if (!values.transitNumber.match(/^[0-9]*$/)) {
      errors.transitNumber =
        "Transit number must only contain numerical digits";
    } else if (values.transitNumber.length !== 5) {
      errors.transitNumber = "Transit numbers have 5 digits";
    } else {
      delete errors.transitNumber;
    }
  } else {
    delete errors.transitNumber;
  }

  if (values.accountNumber) {
    if (!values.accountNumber.match(/^[0-9]*$/)) {
      errors.accountNumber =
        "Account number must only contain numerical digits";
    } else if (values.accountNumber.length > 12) {
      errors.accountNumber = "Account numbers have a maximum of 12 digits";
    } else if (values.accountNumber.length < 7) {
      errors.accountNumber = "Account numbers have a minimum of 7 digits";
    } else {
      delete errors.accountNumber;
    }
  } else {
    delete errors.accountNumber;
  }

  if (values.title) {
    delete errors.title;
  }
  if (values.institution) {
    delete errors.institution;
  }
  if (values.holderName) {
    delete errors.holderName;
  }
  validateEmail(values, errors, "holderEmail");
  if (values.holderAddress) {
    delete errors.holderAddress;
  }
  if (values.holderAddressPostalCode) {
    if (
      !values.holderAddressPostalCode.match(
        /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
      )
    ) {
      errors.holderAddressPostalCode =
        "Invalid canadian postal code. Must be V8R 6L8 or V8R6L8 or V8R-6L8";
    } else {
      delete errors.holderAddressPostalCode;
    }
  } else {
    delete errors.holderAddressPostalCode;
  }
  if (values.holderAddressCity) {
    delete errors.holderAddressCity;
  }

  return errors;
}
