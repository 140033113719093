import React, { Fragment, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import {
  appName,
  formatDate,
  formattedDollarValue,
} from "../../../modules/Helpers";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { useHistory } from "react-router";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";

function RecurringDetailsStep() {
  const [state] = useSimpleContext();
  const history = useHistory();
  const gqlHooks = useGQL();

  useEffect(() => {
    trackEvent(
      "recurringDetails",
      {
        recurringId: state.recurringPlan.identifier,
        companyId: state.recurringPlan.company.identifier,
        contactId: state.recurringPlan.contact.identifier,
      },
      gqlHooks
    );
  }, []);
  const creditCardsEnabled = state.recurringPlan.company.settings.ccEnabled;
  const bankTransfersEnabled = state.recurringPlan.company.settings.eftEnabled;
  return (
    <Fragment>
      <div className="payment-details-header desktop">
        <div
          style={{
            backgroundImage: `url(${state.recurringPlan.company?.displayImage})`,
          }}
          className={`${
            !state.recurringPlan.company?.displayImage && "d-none"
          } payment-details-header-logo`}
        />
        <h4 className="ml-3 mb-0 font-weight-600">
          {state.recurringPlan.company.fullName ||
            state.recurringPlan.company.name}
        </h4>
        <h4 className="ml-auto font-weight-400 mb-0">
          Recurring Payment Request
        </h4>
      </div>
      <Container>
        <div className="d-flex flex-column mt-4 mobile">
          <h3 className="text-primary text-center mb-4">
            Recurring payment request from
          </h3>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <img
              width={"50px"}
              src={state.recurringPlan.company?.displayImage}
              className={`${
                !state.recurringPlan.company?.displayImage && "d-none"
              }`}
              alt="Company Logo"
            />
            <h4 className="ml-3 mb-0 font-weight-600">
              {state.recurringPlan.company.fullName ||
                state.recurringPlan.company.name}
            </h4>
          </div>
        </div>
        <Row className="m-0">
          <Col sm="12" md="10" className="m-auto p-0">
            <h4 className="text-center font-weight-400 pt-4 pb-4 mb-0">
              {state.recurringPlan.expired ? (
                <span style={{ color: "red" }}>
                  Request has expired. Contact{" "}
                  {state.recurringPlan.company.fullName ||
                    state.recurringPlan.company.name}{" "}
                  for a new request.
                </span>
              ) : (
                <>
                  {state.recurringPlan.company.fullName ||
                    state.recurringPlan.company.name}{" "}
                  has requested a <b>recurring payment</b> through {appName} for
                  the amount of:
                </>
              )}
            </h4>
          </Col>
        </Row>
        <Row className="m-0">
          <Col>
            <h1 className="text-center font-weight-400 pb-4 mb-0">
              {formattedDollarValue(state.recurringPlan.amount)}
              <span className="text-muted text-lg">
                /{state.recurringPlan.frequency.slice(0, -2).toLowerCase()}
              </span>
            </h1>
          </Col>
        </Row>
      </Container>
      <Container style={{ background: "#F1F0F3" }} className="pt-3 pb-2">
        <div className="payment-info">
          <Row>
            <Col>
              <h5>Paid to</h5>
              <p>
                {state.recurringPlan.company.fullName ||
                  state.recurringPlan.company.name}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Start date</h5>
              <p style={state.recurringPlan.expired ? { color: "red" } : null}>
                {formatDate(state.recurringPlan.start)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>End date</h5>
              <p>
                {state.recurringPlan.end
                  ? formatDate(state.recurringPlan.end)
                  : "Continue until cancelled"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Statement</h5>
              <p>{state.recurringPlan.statement}</p>
            </Col>
          </Row>
          {state.recurringPlan.description && (
            <Row>
              <Col>
                <h5>Notes</h5>
                <p className="font-italic">
                  <q>{state.recurringPlan.description}</q>
                </p>
              </Col>
            </Row>
          )}
        </div>
      </Container>
      <Container>
        <div className="d-flex justify-content-center">
          {!state.recurringPlan.expired && (
            <Button
              className="btn-lg btn-primary mt-4 mb-4"
              onClick={() =>
                creditCardsEnabled
                  ? bankTransfersEnabled
                    ? history.push("method")
                    : history.push("credit-card")
                  : history.push("bank-transfer")
              }
            >
              <div className="d-flex align-items-center flex-grow-1">
                <span className="mr-3">Accept recurring plan</span>
                <div>
                  <ArrowRightIcon height={20} width={20} />
                </div>
              </div>
            </Button>
          )}
        </div>
      </Container>
    </Fragment>
  );
}

export default RecurringDetailsStep;

RecurringDetailsStep.propTypes = {};
