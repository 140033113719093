import React from "react";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { useHistory } from "react-router-dom";
import { FailedPaymentsTable } from "../FailedPaymentsTable";

export const BulkApprovalFailedPayments = () => {
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const totalAmount = state.totalAmountSent;
  const numSuccessTransactions = state.approvedTransactions?.length || 0;
  const numFailedTransactions =
    (state.approvedDraftCredits?.length || 0) - numSuccessTransactions;

  const failedPaymentsData = state.approvedDraftCredits?.map(
    (approvedCredit) => {
      const error = state.creditErrors.find(
        (error) => error.identifier === approvedCredit.identifier
      );
      return {
        ...approvedCredit,
        status: error
          ? error.message
          : "Successfully sent, an email has been sent to the recipient",
        success: !error,
      };
    }
  );

  const onBackToApprovalTable = () => {
    setState({
      ...state,
      approvedDraftCredits: undefined,
      creditErrors: undefined,
    });
    history.push("/bulk-payment/credit/approve/select");
  };

  return (
    <FailedPaymentsTable
      failedPaymentsData={failedPaymentsData}
      totalAmount={totalAmount}
      numSuccessTransactions={numSuccessTransactions}
      numFailedTransactions={numFailedTransactions}
      onBack={onBackToApprovalTable}
      onBackText={"Try again"}
    />
  );
};
