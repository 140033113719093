/* eslint-disable react-hooks/exhaustive-deps */
import { emailAccounts } from "modules/Helpers";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { billingInfo } from "../../../../api_client/queries/bills";
import useGQL from "../../../../api_client/UseGQL";
import { CustomLoader } from "../../../../components/loaders/CustomLoader";

function BillingPricingDetails() {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({});
  const gqlHooks = useGQL();

  useEffect(() => {
    setLoading(true);
    let output = {
      creditPercent: true,
      debitPercent: true,
      internalPercent: true,
      perNsf: true,
    };
    billingInfo({}, output, gqlHooks).then((response) => {
      if (response && response.data) {
        setInfo(response.data);
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return (
      <div className="content">
        <CustomLoader size={15} />
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="flex-row">
          <a href={`mailto:${emailAccounts.SUPPORT}`}>
            <Button size="sm" color="primary" className="btn-simple pull-right">
              Contact Sales
            </Button>
          </a>
          <h4 className="mb-0 d-inline-block">Pricing</h4>
          <h3 className="font-weight-bold mt-3 mb-4">
            $0{" "}
            <span
              style={{ lineHeight: "0" }}
              className="text-muted text-sm font-weight-400"
            >
              monthly fee
            </span>
          </h3>
        </div>
        <div className="flex-row">
          <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
            Payables
          </h5>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Send any amount</p>
          <p className="pull-right font-weight-600 mb-2">
            {info.debitPercent * 100}% of volume
          </p>
        </div>
        <div className="flex-row">
          <h5 className="text-primary font-weight-600 mb-2 mt-3 text-uppercase">
            Receivables
          </h5>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Receive any amount</p>
          <p className="pull-right font-weight-600 mb-2">
            {info.creditPercent * 100}% of volume
          </p>
        </div>
        <div className="flex-row">
          <h5 className="text-primary font-weight-600 mb-2 mt-3 text-uppercase">
            Internal
          </h5>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Transfer any amount</p>
          <p className="pull-right font-weight-600 mb-2">
            {info.internalPercent * 100}% of volume
          </p>
        </div>
        <div className="flex-row">
          <h5 className="text-primary font-weight-600 mb-2 mt-3 text-uppercase">
            Non-Sufficient Funds
          </h5>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">
            Bank account NSF penalty
          </p>
          <p className="pull-right font-weight-600 mb-2">${info.perNsf}</p>
        </div>
        <div className="flex-row">
          <h5 className="text-primary font-weight-600 mb-2 mt-3 text-uppercase">
            Currencies
          </h5>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-0">
            $CAD to $CAD payments only
          </p>
        </div>
      </div>
    </>
  );
}

export default BillingPricingDetails;
