import React, { Fragment } from "react";
import { Button } from "reactstrap";
import ReviewBillsTable from "../../../../../../components/tables/ReviewBillsTable";
import { useHistory } from "react-router";
import { withApiValue } from "../../../../../../modules/WithApiValue";
import PropTypes from "prop-types";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import {
  calcBillsPaymentTotal,
  CustomTooltip,
} from "../../../../../../modules/Helpers";

const config = {
  company: {
    railzConnection: {
      serviceName: true,
    },
  },
};

function CreditReviewImportedBillsStep(props) {
  const history = useHistory();
  const [state] = useSimpleContext();

  function submit() {
    history.push("withdrawal-account", {
      ...history.location.state,
      currStep: 4,
    });
  }

  return (
    <Fragment>
      <div className="wrapper">
        <div className="main-panel bg-white">
          <div className="content">
            <div className="d-flex flex-row justify-content-between">
              <h2 className="mb-3 font-weight-600 page-title">
                Review bills{" "}
                <span className="text-muted font-weight-300">
                  ({state.bills.length}) |
                </span>{" "}
                <span className="text-success">
                  {props.company.railzConnection?.serviceName.capitalize()}
                </span>
              </h2>
              <div className="d-flex flex-row flex-grow-1 justify-content-end mb-4">
                <div className="desktop d-flex align-items-center">
                  <CustomTooltip
                    title="The total amount you have selected to pay."
                    placement="bottom"
                    arrow
                  >
                    <p
                      style={{ cursor: "default" }}
                      className="d-inline-block font-weight-bold m-0 mr-3"
                    >
                      Total: {calcBillsPaymentTotal(state.bills)}
                    </p>
                  </CustomTooltip>
                  <Button
                    className="btn-primary sp-button m-0"
                    onClick={submit}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </div>
            <ReviewBillsTable />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default withApiValue(CreditReviewImportedBillsStep, config);

CreditReviewImportedBillsStep.propTypes = {
  company: PropTypes.object,
};
