import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import CreditDetailsStep from "./steps/CreditDetailsStep";
import CreditAccountStep from "./steps/CreditAccountStep";
import CreditDepositStep from "./steps/CreditDepositStep";
import CreditReviewStep from "./steps/CreditReviewStep";
import CreditSuccessStep from "./steps/CreditSuccessStep";
import { useSimpleContext } from "../../../../contexts/SimpleContext";
import CreditConnectIntegrationStep from "./steps/accounting_integration/CreditConnectIntegrationStep";
import CreditImportBillsStep from "./steps/accounting_integration/CreditImportBillsStep";
import CreditReviewImportedBillsStep from "./steps/accounting_integration/CreditReviewImportedBillsStep";
import CreditWithdrawalAccountStep from "./steps/accounting_integration/CreditWithdrawalAccountStep";
import CreditReviewBillPaymentStep from "./steps/accounting_integration/CreditReviewBillPaymentStep";
import CreditPaymentSuccessStep from "./steps/accounting_integration/CreditPaymentSuccessStep";
import CreditPaymentWarningStep from "./steps/accounting_integration/CreditPaymentWarningSuccessStep";
import { SelectCreditPaymentType } from "./SelectCreditPaymentType";

function CreateCredit() {
  const history = useHistory();
  const [state] = useSimpleContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!state) {
      history.push("payment-type", { steps: 7, currStep: 1 });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null;
  } else {
    return (
      <Fragment>
        <Switch>
          <Route
            path="/new-payment/credit/payment-type"
            render={() => <SelectCreditPaymentType />}
          />
          <Route
            path="/new-payment/credit/details"
            render={() => <CreditDetailsStep />}
          />
          <Route
            path="/new-payment/credit/source-account"
            render={() => <CreditAccountStep />}
          />
          <Route
            path="/new-payment/credit/deposit-account"
            render={() => <CreditDepositStep />}
          />
          <Route
            path="/new-payment/credit/review"
            render={() => <CreditReviewStep />}
          />
          <Route
            path="/new-payment/credit/success"
            render={() => <CreditSuccessStep />}
          />
          <Route
            path="/new-payment/credit/connect-integration"
            render={() => <CreditConnectIntegrationStep />}
          />
          <Route
            path="/new-payment/credit/import"
            render={() => <CreditImportBillsStep />}
          />
          <Route
            path="/new-payment/credit/review-imports"
            render={() => <CreditReviewImportedBillsStep />}
          />
          <Route
            path="/new-payment/credit/withdrawal-account"
            render={() => <CreditWithdrawalAccountStep />}
          />
          <Route
            path="/new-payment/credit/review-payment"
            render={() => <CreditReviewBillPaymentStep />}
          />
          <Route
            path="/new-payment/credit/payment-success"
            render={() => <CreditPaymentSuccessStep />}
          />
          <Route
            path="/new-payment/credit/payment-warning-success"
            render={() => <CreditPaymentWarningStep />}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default CreateCredit;

CreateCredit.propTypes = {};
