import {
  validateAmount,
  validateDescription,
  validateLongName,
  validateNote,
} from "./transaction/general";

export function RequestLinkFormValidation(values, errors) {
  validateAmount(values, errors);

  validateLongName(values, errors);

  validateDescription(values, errors);

  validateNote(values, errors);

  return errors;
}
