import PropTypes from "prop-types";
import { Badge, Button, Col, ModalBody, ModalHeader } from "reactstrap";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";

function TokenModalBodyShowValue(props) {
  const { token, toggle, identifier } = props;
  const { addToast } = useToasts();
  const history = useHistory();
  const tokenString = `${identifier}:${token}`;
  return (
    <>
      <ModalHeader>
        <h3>Token created!</h3>
        <span className="text-default">
          This token will only be shown to you once. You must copy it and save
          it securely into your application.
        </span>
      </ModalHeader>
      <ModalBody>
        <Col>
          <div>
            <Badge
              color="default"
              style={{ marginBottom: "0", whiteSpace: "break-spaces" }}
              pill
            >
              {tokenString}
            </Badge>
          </div>
          <br />

          <CopyToClipboard text={tokenString}>
            <Button
              color="primary"
              size="md"
              block
              onClick={() => {
                addToast("Copied to clipboard.", {
                  appearance: "success",
                  autoDismiss: true,
                });
              }}
            >
              COPY TOKEN
            </Button>
          </CopyToClipboard>
          <Button
            color="primary"
            size="md"
            className="btn-simple"
            block
            onClick={() => {
              toggle();
              history.push({ search: "update=true" });
            }}
          >
            CLOSE
          </Button>
        </Col>
      </ModalBody>
    </>
  );
}

export default TokenModalBodyShowValue;

TokenModalBodyShowValue.propTypes = {
  token: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
};
