import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  CustomTooltip,
  FREQUENCY_OPTIONS,
  RailzIntegrations,
} from "../../modules/Helpers";
import { useAppValue } from "../../contexts/Context";
import NotesToContact from "components/text/NoteToContact";

function TransactionInfoCard(props) {
  const railzServiceName = props.transaction?.railzConnection?.serviceName;
  const railzServiceNameDisplay = RailzIntegrations?.[railzServiceName];
  const [context] = useAppValue();
  const notesAlias =
    props.notesAlias || props.useGivenNotes
      ? props.notesAlias
      : context.user.company.settings.notesAlias;

  return (
    <Card className="details-card shadow-sm border mt-4">
      <Row className="details-row border-bottom">
        <Col>
          <h5>Frequency</h5>
          <p>
            {props.transaction.recurringPlan
              ? FREQUENCY_OPTIONS.find((frequency) => {
                  return (
                    frequency.value ===
                    props.transaction.recurringPlan.frequency
                  );
                }).label
              : "One time"}
          </p>
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          <h5>Payment id</h5>
        </Col>
        <Col>
          <div>
            <CopyToClipboard
              onCopy={() => props.copyIdToClipboard()}
              text={props.transaction.identifier}
            >
              <Button className="btn-link details-row-button">Copy</Button>
            </CopyToClipboard>
          </div>
        </Col>
        <Col sm="12">
          <p>
            {" "}
            <CustomTooltip title="Click to copy" placement="top" arrow>
              <CopyToClipboard
                onCopy={() => props.copyIdToClipboard()}
                text={props.transaction.identifier}
              >
                <span style={{ cursor: "pointer" }}>
                  {props.transaction.identifier}
                </span>
              </CopyToClipboard>
            </CustomTooltip>
          </p>
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          <h5>Created by</h5>
          <p>{props.transaction.issuedBy.email}</p>
        </Col>
        {railzServiceNameDisplay && (
          <Col>
            <h5>Imported from</h5>
            <p>{railzServiceNameDisplay}</p>
          </Col>
        )}
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          <h5>Statement</h5>
          <p>{props.transaction.statement}</p>
        </Col>
      </Row>
      {props.transaction.direction !== "INTERNAL" && (
        <Row className="details-row">
          <Col>
            <h5>
              <NotesToContact alias={notesAlias} />
            </h5>
            <p>
              {props.transaction.description ? (
                props.transaction.description
              ) : (
                <span className="text-muted font-italic">None</span>
              )}
            </p>
          </Col>
        </Row>
      )}
    </Card>
  );
}

export default TransactionInfoCard;
TransactionInfoCard.propTypes = {
  transaction: PropTypes.object,
  copyIdToClipboard: PropTypes.func,
};
