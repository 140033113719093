import {
  MAX_LENGTH_FULL_NAME,
  MAX_LENGTH_SHORT_NAME,
  PASSWORD_LENGTH,
  PHONE_ERROR,
  verifyLength,
  verifyPhone,
  verifyStatementNames,
} from "../Helpers";

export function profileFormValidation(values, errors) {
  if (values.name) {
    if (
      verifyStatementNames(values.name) ||
      verifyLength(values.name, MAX_LENGTH_SHORT_NAME)
    ) {
      errors.name =
        "Business name statement descriptor must be less than 15 characters and only use valid characters";
    } else {
      delete errors.name;
    }
  }

  if (values.fullName) {
    if (
      verifyStatementNames(values.fullName) ||
      verifyLength(values.fullName, MAX_LENGTH_FULL_NAME)
    ) {
      errors.fullName =
        "Public business name must be less than 60 characters and only use valid characters";
    } else {
      delete errors.fullName;
    }
  }

  if (values.firstName) {
    if (verifyLength(values.firstName, 30)) {
      errors.firstName = "First name cannot be longer than 30 characters";
    } else {
      delete errors.firstName;
    }
  }

  if (values.lastName) {
    if (verifyLength(values.lastName, 80)) {
      errors.lastName = "Last name cannot be longer than 80 characters";
    } else {
      delete errors.lastName;
    }
  }

  if (values.phone) {
    if (!verifyPhone(values.phone)) {
      errors.phone = PHONE_ERROR;
    } else {
      delete errors.phone;
    }
  }

  if (values.currentPassword) {
    delete errors.currentPassword;
  }

  if (values.newPassword) {
    if (!verifyLength(values.newPassword, PASSWORD_LENGTH)) {
      errors.newPassword = "Password must be greater than 8 Characters";
    } else {
      delete errors.newPassword;
    }
  }
  if (values.sourceOfWealth) {
    if (values.sourceOfWealth.length > 100) {
      errors.sourceOfWealth =
        "source of wealth must be less than 100 characters";
    } else {
      delete errors.sourceOfWealth;
    }
  } else {
    delete errors.sourceOfWealth;
  }

  if (values.avgMonthlyVolume) {
    if (isNaN(values.avgMonthlyVolume) || values.avgMonthlyVolume <= 0) {
      errors.avgMonthlyVolume =
        "Average monthly volume must be a positive number";
    } else {
      delete errors.avgMonthlyVolume;
    }
  } else {
    delete errors.avgMonthlyVolume;
  }

  if (values.avgTransactionSize) {
    if (isNaN(values.avgTransactionSize) || values.avgTransactionSize <= 0) {
      errors.avgTransactionSize =
        "Average transaction size must be a positive number";
    } else {
      delete errors.avgTransactionSize;
    }
  } else {
    delete errors.avgTransactionSize;
  }

  return errors;
}
