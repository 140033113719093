import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

function creditCardErrorAlert(
  hideAlert,
  setAlert,
  message = "If the issue persists, please contact support or reach out to your credit card provider.",
  title = "Your card has been declined by the credit card processor.",
  onConfirm = () => {
    hideAlert();
    window.location.reload();
  },
  confirmBtnText = "Try a different card"
) {
  hideAlert();
  setAlert(
    <SweetAlert
      danger
      title={title}
      onCancel={() => hideAlert()}
      onConfirm={onConfirm}
      confirmBtnText={confirmBtnText}
      showConfirm={true}
      showCancel={false}
    >
      {message}
    </SweetAlert>
  );
}

export default creditCardErrorAlert;
