import { Button, Col, Modal, ModalBody } from "reactstrap";
import React, { Fragment } from "react";
import InternalPAD from "../PAD/InternalPAD";
import PropTypes from "prop-types";

function PADModal(props) {
  return (
    <Fragment>
      <Modal centered size="md" isOpen={props.isOpen} toggle={props.toggle}>
        <ModalBody>
          <Col>
            <InternalPAD
              amount={props.amount}
              type={props.type}
              recurringFrequency={props.recurringFrequency}
            />
          </Col>
          <Button
            block
            className="btn-simple btn-primary"
            type="button"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default PADModal;

PADModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  amount: PropTypes.string,
  type: PropTypes.string,
  recurringFrequency: PropTypes.string,
};
