import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router";
import GetSavedBadge from "../saved/GetSavedBadge";
import PaymentBackTitle from "../components/PaymentBackTitle";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";
import { appName, CustomTooltip } from "../../../modules/Helpers";

function PaymentMethodStep() {
  const history = useHistory();
  const [state] = useSimpleContext();
  const gqlHooks = useGQL();
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  useEffect(() => {
    if (state.transaction?.direction === "CREDIT") {
      trackEvent(
        "creditMethod",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact.identifier,
        },
        gqlHooks
      );
    } else {
      if (state.transaction) {
        trackEvent(
          "debitMethod",
          {
            transactionId: state.transaction.identifier,
            companyId: state.transaction.company?.identifier,
            contactId: state.transaction.contact.identifier,
          },
          gqlHooks
        );
      } else if (state.recurringPlan) {
        trackEvent(
          "debitMethod",
          {
            transactionId: state.recurringPlan.identifier,
            companyId: state.recurringPlan.company?.identifier,
            contactId: state.recurringPlan.contact.identifier,
          },
          gqlHooks
        );
      }
    }
  }, []);
  let creditCardsEnabled =
    state.transaction?.company.settings.ccEnabled ||
    state.recurringPlan?.company.settings.ccEnabled;
  let bankTransfersEnabled =
    state.transaction?.company.settings.eftEnabled ||
    state.recurringPlan?.company.settings.eftEnabled;
  return (
    <Fragment>
      <PaymentBackTitle
        title="Choose payment method"
        backAction={() => history.push("details")}
      />
      <Container>
        <Row>
          <Col md="8" lg="8" className="m-auto pb-3">
            {bankTransfersEnabled && (
              <Button
                aria-label="Bank transfer payment method"
                className="btn-primary text-left method-btn btn-lg mt-0 mb-2 "
                block
                onClick={() => history.push("bank-transfer")}
              >
                <div className="d-flex align-items-center flex-grow-1 justify-content-between">
                  <span>Bank transfer</span>
                  <span className="font-weight-normal">Free</span>
                </div>
              </Button>
            )}
            {creditCardsEnabled &&
              (!state.transaction?.company?.isUsingConverge ||
                state.transaction?.toBankAccount.convergeMerchantAccount) && (
                <Button
                  aria-label="Credit card payment method"
                  className="ml-0 btn-primary text-left method-btn btn-lg mb-3"
                  block
                  onClick={() => history.push("credit-card")}
                >
                  <div className="d-flex align-items-center flex-grow-1 justify-content-between">
                    <span>Credit card</span>
                    <CustomTooltip
                      title={`${appName} charges a 3.5% platform fee for using this checkout system. This is not a credit card service fee`}
                      placement="top"
                      arrow
                      open={tooltipIsOpen}
                      onOpen={() => setTooltipIsOpen(true)}
                      onClose={() => setTooltipIsOpen(false)}
                      leaveTouchDelay={10000}
                    >
                      <span className="font-weight-normal">
                        3.5% Platform fee
                      </span>
                    </CustomTooltip>
                  </div>
                </Button>
              )}
          </Col>
        </Row>
      </Container>
      <GetSavedBadge />
    </Fragment>
  );
}

export default PaymentMethodStep;

PaymentMethodStep.propTypes = {};
