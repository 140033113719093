import { verifyLength } from "../Helpers";

export function contactTagsFormValidation(values, errors) {
  if (values.supplier) {
    if (verifyLength(values.supplier, 30)) {
      errors.supplier = "Tag name cannot be more than 30 characters";
    } else {
      delete errors.supplier;
    }
  }

  if (values.customer) {
    if (verifyLength(values.customer, 30)) {
      errors.customer = "Tag name cannot be more than 30 characters";
    } else {
      delete errors.customer;
    }
  }

  return errors;
}
