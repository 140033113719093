import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import { Button } from "reactstrap";

function PaymentBackTitle(props) {
  return (
    <Fragment>
      <div className="checkout-back-title">
        <Button
          aria-label="Go back to previous step"
          className="d-flex btn-link p-0 m-0 cursor-pointer"
          onClick={props.backAction}
        >
          <div className="fa-rotate-180 mr-2">
            <ArrowRightIcon className="mb-0" height={20} width={20} />
          </div>
          <span className="text-lg font-weight-600">{props.title}</span>
        </Button>
      </div>
    </Fragment>
  );
}

export default PaymentBackTitle;

PaymentBackTitle.propTypes = {
  title: PropTypes.string.isRequired,
  backAction: PropTypes.func.isRequired,
};
