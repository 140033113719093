import { Button, Card, CardBody, Col, Row } from "reactstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //faHeadset,
  //faInfoCircle,
  //faMapSigns,
  // faPlayCircle,
  //faRss,
  //faSmileBeam,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { domains } from "modules/Helpers";
import helpCenter from "assets/img/helpLearn/helpCenter.svg";
import ContactSupport from "assets/img/helpLearn/contactSupport.svg";
import featureRoadmap from "assets/img/helpLearn/featureRoadmap.svg";
import readBlog from "assets/img/helpLearn/readBlog.svg";
import customerStories from "assets/img/helpLearn/customerStories.svg";
//import playTutorial from "assets/img/helpLearn/playTutorial.svg";
import referandpay from "assets/img/helpLearn/referandpay.svg";

function Information() {
  const history = useHistory();

  return (
    <>
      <Row>
        <Col>
          <Button
            className="btn-link details-close-btn"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <h3 className="font-weight-600 mt-1 mb-4">Help & Learn</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="shadow-sm border flex-column">
            <CardBody className="p-0">
              <Row
                className="information-row border-bottom"
                onClick={() => window.open(`https://${domains.HELP}/`)}
              >
                <Col>
                  <div className="information-wrapper">
                    <div className="information-icon pr-2 pt-1">
                      {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                      <img src={helpCenter} />
                    </div>
                    <div>
                      <h4>Help center</h4>
                      <span>Getting started, FAQs, user guides, and more!</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row
                className="information-row border-bottom"
                onClick={() => {
                  history.push({ search: "ticket=true" });
                }}
              >
                <Col>
                  <div className="information-wrapper">
                    <div className="information-icon pr-2 pt-1">
                      {/* <FontAwesomeIcon icon={faHeadset} /> */}
                      <img src={ContactSupport} />
                    </div>
                    <div>
                      <h4>Contact support</h4>
                      <span>Need assistance? Submit a support ticket.</span>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <Row
                className="information-row border-bottom"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCizIEPNY7D7H3akgsu_S64Q"
                  )
                }
              >
                <Col>
                  <div className="information-wrapper">
                    <div className="information-icon pr-2 pt-1">
                      {/* <FontAwesomeIcon /> */}
              {/* <img src={playTutorial} />
                    </div>
                    <div>
                      <h4>Tutorial videos</h4>
                      <span>Watch and learn how to use the app.</span>
                    </div>
                  </div>
                </Col> */}
              {/* </Row>  */}
              <Row
                className="information-row border-bottom"
                // onClick={() =>
                //   window.open(`https://${domains.PRIMARY}/roadmap`)
                // }
              >
                <Col>
                  <div className="information-wrapper">
                    <div className="information-icon pr-2 pt-1">
                      {/* <FontAwesomeIcon icon={faMapSigns} /> */}
                      <img src={featureRoadmap} />
                    </div>
                    <div>
                      <h4>Feature roadmap</h4>
                      <span>Find out what we&apos;re working on next.</span>
                      <span className="badge badge-warning ml-2">
                        Coming Soon
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row
                className="information-row border-bottom"
                // onClick={() => window.open(`https://${domains.PRIMARY}/blog`)}
              >
                <Col>
                  <div className="information-wrapper">
                    <div className="information-icon pr-2 pt-1">
                      {/* <FontAwesomeIcon icon={faRss} /> */}
                      <img src={readBlog} />
                    </div>
                    <div>
                      <h4>Read our blog</h4>
                      <span>
                        Keep up with our latest features and announcements.
                      </span>
                      <span className="badge badge-warning ml-2">
                        Coming Soon
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row
                className="information-row"
                onClick={() => window.open(`https://${domains.PRIMARY}/`)}
              >
                <Col>
                  <div className="information-wrapper">
                    <div className="information-icon pr-2 pt-1">
                      {/* <FontAwesomeIcon icon={faSmileBeam} /> */}
                      <img src={customerStories} />
                    </div>
                    <div>
                      <h4>Customer stories</h4>
                      <span>Learn how we help businesses grow.</span>
                    </div>
                  </div>
                </Col>
              </Row>
              {/*
              <Row
                className="information-row"
                // onClick={() =>
                //   window.open(
                //     `https://${domains.PRIMARY}/categories/customer-stories`
                //   )
                // }
              >
                <Col>
                  <div className="information-wrapper">
                    <div className="information-icon pr-2 pt-1">
                      <img src={referandpay} />
                    </div>
                    <div>
                      <h4>Refer & earn $250</h4>
                      <span>
                        Earn $250 for every business that signs up on your
                        referral.
                      </span>
                    </div>
                  </div>
              </Col> 
              </Row>
              */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Information;
