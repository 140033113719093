import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MobileStepper from "@material-ui/core/MobileStepper";

function CustomStepper() {
  const location = useLocation();
  const [currStep, setCurrStep] = useState(location.state?.currStep || 1);
  const [totalSteps, setTotalSteps] = useState(location.state?.steps || 5);
  useEffect(() => {
    if (location.state) {
      setCurrStep(location.state.currStep);
      setTotalSteps(location.state.steps);
    }
  }, [location]);

  return (
    <Fragment>
      <MobileStepper
        variant="progress"
        steps={totalSteps}
        position="static"
        activeStep={currStep}
        className="custom-stepper"
      />
    </Fragment>
  );
}

export default CustomStepper;
