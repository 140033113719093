import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function setCompanyNotificationRead(input, output, hooks) {
  const query = buildMutation(input, output, "setCompanyNotificationRead");
  return callMutation(query, hooks);
}

setCompanyNotificationRead.propTypes = {
  input: PropTypes.shape({
    identifiers: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
