import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import useForm from "../../hooks/UseForms";
import useGQL from "../../api_client/UseGQL";
import { AddBankAccountValidation } from "../../modules/form_validation/AddBankAccountValidation";
import { updateBankAccountKyc } from "../../api_client/mutations/bankAccount";
import { CustomLoader } from "../loaders/CustomLoader";
import ManualBankForm from "../../views/admin/settings/banks/manual/ManualBankForm";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import QueryString from "query-string";
import { useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";
import { getBankAccount } from "../../api_client/queries/bankAccounts";

function UpdateKycModal(props) {
  const { values, errors, handleChange, handleSubmit, setValues, setErrors } =
    useForm(submit, AddBankAccountValidation);
  let gqlHooks = useGQL();
  const [loading, setLoading] = useState(true);
  const refs = {
    institutionNumber: useRef(null),
    transitNumber: useRef(null),
    accountNumber: useRef(null),
    title: useRef(null),
    accountType: useRef(null),
    institution: useRef(null),
    holderName: useRef(null),
    holderEmail: useRef(null),
    holderAddress: useRef(null),
    holderAddressPostalCode: useRef(null),
    holderAddressCity: useRef(null),
  };
  const [success, setSuccess] = useState(false);
  const [bankAccount, setBankAccount] = useState();
  const location = useLocation();
  const { addToast } = useToasts();

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.bankKYC) {
      let input = {
        bankAccountId: search.id,
      };
      let output = {
        identifier: true,
        missingKyc: true,
        state: true,
        institutionNumber: true,
        transitNumber: true,
        accountNumber: true,
        institution: true,
        title: true,
        accountType: true,
        holderName: true,
        holderAddress: true,
        holderAddressPostalCode: true,
        holderAddressCity: true,
        holderAddressCountry: true,
        holderEmail: true,
        billing: true,
      };
      getBankAccount(input, output, gqlHooks).then((response) => {
        if (response && response.data) {
          setBankAccount(response.data);
          setLoading(false);
        } else {
          addToast("Could not retrieve bank account", {
            appearance: "warning",
            autoDismiss: true,
          });
          props.toggle();
        }
      });
    } else {
      props.toggle();
    }
  }, []);

  useEffect(() => {
    setValues(bankAccount);
    if (bankAccount && bankAccount.missingKyc) {
      let errs = {};
      bankAccount.missingKyc.forEach(
        (field) => (errs[field] = "This field is missing or incorrect")
      );
      setErrors(errs);
    }
  }, [bankAccount]);

  async function submit() {
    setLoading(true);
    let input = {
      bankAccountId: bankAccount.identifier,
      kyc: {},
    };
    let accountNumber = values.accountNumber;
    if (values.accountNumber.includes("*")) {
      delete values.accountNumber;
    }

    if (bankAccount.state === "PARTIALLY_READY") {
      bankAccount.missingKyc.forEach(
        (field) => (input.kyc[field] = values[field])
      );
    } else {
      Object.keys(refs).forEach((field) =>
        values[field] ? (input.kyc[field] = values[field]) : null
      );
    }
    values.accountNumber = accountNumber;
    let output = {
      ok: true,
    };
    let response = await updateBankAccountKyc(input, output, gqlHooks);
    if (response) {
      if (response.ok) {
        setSuccess(true);
      } else if (response.fieldErrors) {
        response.fieldErrors.forEach(
          (error) => (errors[error.field] = error.message)
        );
      }
    }
    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <SweetAlert
          custom
          title={<CustomLoader general={true} />}
          onCancel={() => null}
          onConfirm={() => null}
          showConfirm={false}
          showCancel={false}
        >
          Authorizing...
        </SweetAlert>
      ) : success && props.isOpen ? (
        <SweetAlert
          success
          title={"Successfully Updated"}
          onConfirm={() => props.toggle()}
          onCancel={() => props.toggle()}
          showConfirm={false}
          showCancel={false}
        >
          Details were successfully updated
        </SweetAlert>
      ) : (
        <Modal
          size="lg"
          backdrop
          fade
          centered
          isOpen={props.isOpen}
          toggle={props.toggle}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <Button close onClick={props.toggle} />
              </Col>
            </Row>
            <Col>
              <h4 className="text-center">
                {bankAccount.state === "PARTIALLY_READY"
                  ? "Resolve Missing Details"
                  : "Retry Adding Account "}
              </h4>
              <p className="text-center text-muted">
                {bankAccount.state === "PARTIALLY_READY"
                  ? "Please add all missing bank account details"
                  : "Please verify all account details are correct"}
              </p>
            </Col>
            <ManualBankForm
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              hideAccountNumber={false}
              refs={refs}
              uneditable={Object.keys(bankAccount).filter(
                (k) =>
                  !bankAccount.missingKyc.includes(k) &&
                  bankAccount.state === "PARTIALLY_READY"
              )}
            />
            <Col>
              <Button
                block
                color="primary"
                type="submit"
                onClick={submit}
                className="pull-right"
              >
                {bankAccount.state === "PARTIALLY_READY" ? "Update" : "Submit"}
              </Button>
            </Col>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

UpdateKycModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default UpdateKycModal;
