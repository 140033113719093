import React, { Fragment, useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import GetMFACodeForm from "./GetMFACodeForm";
import EnterMFACodeForm from "./EnterMFACodeForm";
import ListSavedAccounts from "./ListSavedAccounts";
import PropTypes from "prop-types";

function SavedBankInfoModal(
  { onBankAccountSaved, onClosed, type, open, toggle },
  props
) {
  const [enterMFA, setEnterMfa] = useState(false);
  const [listAccounts, setListAccounts] = useState(false);

  return (
    <Fragment>
      <Modal
        centered
        fade={open}
        isOpen={open}
        toggle={toggle}
        onClosed={onClosed}
      >
        <ModalBody className="pb-0">
          <Row>
            <Col sm="12">
              <Button close onClick={toggle} />
            </Col>
          </Row>
          {listAccounts ? (
            <ListSavedAccounts
              setListAccounts={setListAccounts}
              setEnterMfa={setEnterMfa}
            />
          ) : !enterMFA ? (
            <GetMFACodeForm setEnterMfa={setEnterMfa} />
          ) : (
            <EnterMFACodeForm
              collectBankInfoOnly={props.collectBankInfoOnly}
              contactIdentifier={props.contactIdentifier}
              setListAccounts={setListAccounts}
              type={type}
              setEnterMfa={setEnterMfa}
              onBankAccountSaved={onBankAccountSaved}
              toggle={toggle}
            />
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default SavedBankInfoModal;

SavedBankInfoModal.propTypes = {
  onBankAccountSaved: PropTypes.func,
  onClosed: PropTypes.func,
  type: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

SavedBankInfoModal.defaultProps = {
  type: "saved",
};
