import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import LogoWithText from "../../assets/img/LogoWithText.png";
import VerifiedCheckmark from "../../assets/img/VerifiedCheckmark.png";
import { appName } from "../../modules/Helpers";

function VerificationSuccess() {
  return (
    <Fragment>
      <div className="full-page">
        <div className="wrapper bg-white p-5">
          <Row>
            <Col className="ml-auto mr-auto text-center mt-5">
              <img
                width="260px"
                src={LogoWithText}
                alt="..."
                className="m-auto"
              />
              <Col className="ml-auto mr-auto mt-3 mb-3">
                <img width="120px" src={VerifiedCheckmark} alt="..." />
              </Col>
              <h2 className="font-weight-bold mb-0">Verification Complete!</h2>
              <p className="text-muted mt-2">
                You can now close this page and return to {appName}, thank you!
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
}

export default VerificationSuccess;
