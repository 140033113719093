import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import useForm from "../../hooks/UseForms";
import { trackEvent } from "../../modules/analytics";
import useGQL from "../../api_client/UseGQL";
import { useToasts } from "react-toast-notifications";
import { confirmMicrodeposits } from "../../api_client/mutations/bankAccount";
import { MicrodepositValidation } from "../../modules/form_validation/MicrodepositValidation";
import QueryString from "query-string";
import { useLocation } from "react-router";
import { getBankAccount } from "../../api_client/queries/bankAccounts";

function MicrodepositModal(props) {
  const amount1Ref = useRef(null);
  const amount2Ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [, setSuccess] = useState(false);
  let gqlHooks = useGQL();
  const { addToast } = useToasts();
  const { values, setValues, errors, handleChange, handleSubmit } = useForm(
    submit,
    MicrodepositValidation
  );
  const location = useLocation();
  const [bankAccount, setBankAccount] = useState();

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.confirm) {
      let input = {
        bankAccountId: search.id || search.confirm,
      };
      let output = {
        identifier: true,
        missingKyc: true,
        state: true,
        institutionNumber: true,
        transitNumber: true,
        accountNumber: true,
        institution: true,
        title: true,
        accountType: true,
        holderName: true,
        holderAddress: true,
        holderAddressPostalCode: true,
        holderAddressCity: true,
        holderAddressCountry: true,
        holderEmail: true,
        billing: true,
      };
      getBankAccount(input, output, gqlHooks).then((response) => {
        if (response && response.data) {
          setBankAccount(response.data);
          setLoading(false);
        } else {
          addToast("Could not retrieve bank account", {
            appearance: "warning",
            autoDismiss: true,
          });
          props.toggle();
        }
      });
    } else {
      props.toggle();
    }
  }, [location]);

  useEffect(() => {
    // Populate values with field 'amount' so we can catch errors in the form under name 'amount'
    // as well as 'amount1' and 'amount2'
    setValues({ amount: 1 });
  }, []);

  useEffect(() => {
    delete errors.amount;
    gqlHooks.dispatch({
      type: "setFieldErrors",
      fieldErrors: {},
    });
  }, [values.amount1, values.amount2]);

  async function submit() {
    setLoading(true);
    const input = {
      bankAccountId: bankAccount.identifier,
      amount1: "0." + values.amount1,
      amount2: "0." + values.amount2,
    };
    confirmMicrodeposits(input, {}, gqlHooks).then((response) => {
      if (response) {
        addToast("Micro-deposit amounts confirmed successfully!", {
          appearance: "success",
          autoDismiss: true,
        });
        setSuccess(true);
        trackEvent("microdepositsConfirmed", {}, gqlHooks);
        props.toggle();
      } else {
        trackEvent("microdepositsFailed", {}, gqlHooks);
      }
      setLoading(false);
    });
  }

  if (loading) {
    return null;
  }

  return (
    <Modal backdrop fade centered isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Button close onClick={props.toggle} />
          </Col>
        </Row>
        <h4 className="text-center">Confirm Micro-deposits</h4>
        <p className="text-center text-muted">
          Please confirm the <b>2 deposits</b> in the range of{" "}
          <b>$0.01 - $0.99</b> that arrived in your account{" "}
          <b>
            {bankAccount.institution} {bankAccount.accountNumber}
          </b>
          .
        </p>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Label>First Deposit</Label>
              <FormGroup>
                <Row>
                  <Col sm="1">
                    <h4 className="d-inline-block pt-2">$0.</h4>
                  </Col>
                  <Col className="pl-1">
                    <Input
                      type="number"
                      min={0}
                      max={99}
                      name="amount1"
                      innerRef={amount1Ref}
                      onChange={handleChange}
                      value={values.amount1 || ""}
                      placeholder="Enter first deposit amount"
                      invalid={!!errors.amount1 || !!errors.amount}
                      required
                    />
                    <FormFeedback>
                      {errors.amount || errors.amount1}
                    </FormFeedback>
                  </Col>
                </Row>
              </FormGroup>
              <Label>Second Deposit</Label>
              <FormGroup>
                <Row>
                  <Col sm="1">
                    <h4 className="d-inline-block pt-2">$0.</h4>
                  </Col>
                  <Col className="pl-1">
                    <Input
                      type="number"
                      min={0}
                      max={99}
                      name="amount2"
                      innerRef={amount2Ref}
                      onChange={handleChange}
                      value={values.amount2 || ""}
                      placeholder="Enter second deposit amount"
                      invalid={!!errors.amount2 || !!errors.amount}
                      required
                    />
                    <FormFeedback>
                      {errors.amount || errors.amount2}
                    </FormFeedback>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Button
            block
            type="submit"
            color="primary"
            disabled={loading || !(values.amount1 && values.amount2)}
          >
            Submit
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default MicrodepositModal;

MicrodepositModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
