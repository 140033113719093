import React, { Fragment } from "react";
import Check from "../../../assets/img/register_page/gradient-check-updated.svg";
import Jetpack from "../../../assets/img/register_page/spaceship.svg";
import spaceshipSecond from "../../../assets/img/register_page/spaceshipSecond.svg";
import gradientPlus from "assets/img/register_page/gradient-plus.svg";
// import BillStack from "../../../assets/img/register_page/bill-stack.svg";
// import MoneyRoll from "../../../assets/img/register_page/money-roll.svg";
// import CreditCard from "../../../assets/img/register_page/credit-card.svg";

function RegisterFormSideContent() {
  return (
    <Fragment>
      <div className="bg-signup">
        {/* style={{ backgroundImage: "url(" + Pattern + ")" }} */}
        <div className="justify-content-center d-flex pt-3 bg-image-singup bg-image-singup-top position-relative">
          <img src={Jetpack} width="60%" alt=" " />
        </div>
        <div className="text-white text-center- pl-md-4 pl-lg-5 pt-3">
          <div>
            <h2 className="text-white font-weight-bold mb-0 heading-big-4 heading-sm">
              Get started in minutes.
            </h2>
            <h4 className="text-white font-weight-bold sub-heading-big-4 sub-heading-sm">
              <span className="text-primary-- text-primary-green">
                Send and Receive
              </span>{" "}
              business payments
            </h4>
          </div>
          <ul className="register-ul-side-content sub-heading-big-4 sub-heading-sm ml-0 pl-0">
            <li>
              <img src={Check} width="14" height="14" alt=" " />
              <span className="text-white text-lg">No limits.</span>
            </li>
            <li>
              <img src={Check} width="14" height="14" alt=" " />
              <span className="text-white text-lg">No paperwork.</span>
            </li>
            <li>
              <img src={Check} width="14" height="14" alt=" " />
              <span className="text-white text-lg">No headaches.</span>
            </li>
            <li>
              <img src={gradientPlus} alt="" width="14" height="14" />
              <span className="text-white text-lg">Plus much more!</span>
            </li>
          </ul>
        </div>
        {/* style={{ backgroundImage: "url(" + Pattern + ")" }} */}
        <div className="">
          <div className="justify-content-center d-flex pt-3 bg-image-singup position-relative bg-image-singup-bottom">
            <img src={spaceshipSecond} width="60%" alt=" " />
          </div>
        </div>
        {/* <img src={BillStack} className="bill-stack" alt=" " />
      <img src={MoneyRoll} className="money-roll" alt=" " />
      <img src={CreditCard} className="credit-card" alt=" " /> */}
      </div>
    </Fragment>
  );
}

export default RegisterFormSideContent;
