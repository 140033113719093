import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { CustomTooltip } from "../../../modules/Helpers";

function NotificationActionsDesktop(props) {
  return (
    <>
      <div className="details-action-wrapper">
        <CustomTooltip
          title="Mark all notifications as read"
          placement="bottom"
          arrow
        >
          <Button className="btn-link" onClick={props.readAllNotifications}>
            <FontAwesomeIcon icon={faEnvelopeOpen} />
          </Button>
        </CustomTooltip>
      </div>
    </>
  );
}

export default NotificationActionsDesktop;
NotificationActionsDesktop.propTypes = {
  readAllNotifications: PropTypes.func,
};
