/* eslint react/prop-types: 0 */
import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { getBankLabel } from "./SelectDepositAccount";
import AllPaymentDetailsForm from "./AllPaymentDetailsForm";

export const AddCreditModal = ({
  toggle,
  onSubmit,
  initialState,
  setAddBankAccountModal,
}) => {
  const [newTransaction, setNewTransaction] = useState(initialState);

  const submitDetails = (values, contactAccount) => {
    onSubmit({
      ...newTransaction,
      ...values,
      contact: { ...contactAccount, tags: [contactAccount.tags] },
    });
  };

  const onAccountSelected = (bank) => {
    if (bank) {
      setNewTransaction({
        ...newTransaction,
        toBankAccount: bank.identifier,
        toBankAccountLabel: getBankLabel(bank),
      });
    } else {
      setNewTransaction({
        ...newTransaction,
        toBankAccount: "",
        toBankAccountLabel: "Let recipient decide",
      });
    }
  };

  const updateContact = (contact) => {
    setNewTransaction({
      ...newTransaction,
      contact,
    });
  };

  const close = () => {
    setNewTransaction(undefined);
    toggle();
  };

  return (
    <Modal
      style={{ maxWidth: "600px" }}
      backdrop
      fade
      centered
      isOpen={true}
      toggle={close}
    >
      <ModalBody>
        <AllPaymentDetailsForm
          contactType="supplier"
          submit={submitDetails}
          initialState={initialState}
          onAccountSelected={onAccountSelected}
          updateContact={updateContact}
          setAddBankAccountModal={setAddBankAccountModal}
        />
      </ModalBody>
    </Modal>
  );
};

AddCreditModal.propTypes = {};
