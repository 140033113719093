import React, { Fragment, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import successImage from "../../assets/img/VerifiedCheckmark.png";
import PropTypes from "prop-types";
import { CustomTooltip } from "../../modules/Helpers";

function OnboardingDocuments(props) {
  const [statementTooltip, setStatementTooltip] = useState(false);
  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Label>Recent customer invoice</Label>
          <FormGroup>
            <input
              type="file"
              onChange={props.customerInvoice.handleImageChange}
              ref={props.fileInputRef1}
              accept="image/*,application/pdf"
              className="d-none"
            />
            <Button
              onClick={props.customerInvoice.handleClick}
              type="button"
              className="btn-simple btn-primary btn-sm"
            >
              + Upload
            </Button>{" "}
            <img
              height={30}
              src={
                props.customerInvoice.imageState.file
                  ? successImage
                  : props.customerInvoice.imageState.imagePreviewUrl
              }
              alt="..."
            />
            <span className="text-xs text-primary font-weight-bold  ">
              <br />
              {props.customerInvoice.imageState?.file?.name}
            </span>
          </FormGroup>
        </Col>
        <Col lg="12">
          <Label>3 months bank statements</Label>
          <CustomTooltip
            title="Please upload 3 months bank statements for your primary business account"
            placement="top"
            arrow
            open={statementTooltip}
            onOpen={() => setStatementTooltip(true)}
            onClose={() => setStatementTooltip(false)}
            leaveTouchDelay={10000}
          >
            <Button
              onClick={() => setStatementTooltip(!statementTooltip)}
              variant="contained"
              className="btn-link tooltip-button"
            >
              <i className="fas fa-info-circle" />
            </Button>
          </CustomTooltip>
        </Col>
        <Col md="4">
          <FormGroup>
            <input
              type="file"
              onChange={props.bankStatements1.handleImageChange}
              ref={props.fileInputRef2}
              accept="image/*,application/pdf"
              className="d-none"
            />
            <Button
              onClick={props.bankStatements1.handleClick}
              type="button"
              className="btn-simple btn-primary btn-sm"
            >
              + Upload
            </Button>{" "}
            <img
              height={30}
              src={
                props.bankStatements1.imageState.file
                  ? successImage
                  : props.bankStatements1.imageState.imagePreviewUrl
              }
              alt="..."
            />
            <span className="text-xs text-primary font-weight-bold  ">
              <br />
              {props.bankStatements1.imageState?.file?.name}
            </span>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <input
              type="file"
              onChange={props.bankStatements2.handleImageChange}
              ref={props.fileInputRef3}
              accept="image/*,application/pdf"
              className="d-none"
            />
            <Button
              onClick={props.bankStatements2.handleClick}
              type="button"
              className="btn-simple btn-primary btn-sm"
            >
              + Upload
            </Button>{" "}
            <img
              height={30}
              src={
                props.bankStatements2.imageState.file
                  ? successImage
                  : props.bankStatements2.imageState.imagePreviewUrl
              }
              alt="..."
            />
            <span className="text-xs text-primary font-weight-bold  ">
              <br />
              {props.bankStatements2.imageState?.file?.name}
            </span>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <input
              type="file"
              onChange={props.bankStatements3.handleImageChange}
              ref={props.fileInputRef4}
              accept="image/*,application/pdf"
              className="d-none"
            />
            <Button
              onClick={props.bankStatements3.handleClick}
              type="button"
              className="btn-simple btn-primary btn-sm"
            >
              + Upload
            </Button>{" "}
            <img
              height={30}
              src={
                props.bankStatements3.imageState.file
                  ? successImage
                  : props.bankStatements3.imageState.imagePreviewUrl
              }
              alt="..."
            />
            <span className="text-xs text-primary font-weight-bold  ">
              <br />
              {props.bankStatements3.imageState?.file?.name}
            </span>
          </FormGroup>
        </Col>
        {props.articleOfIncorportation ? (
          <Col sm="12">
            <Label>Articles of Incorporation</Label>
            <br />
            <input
              type="file"
              onChange={props.articleOfIncorportation.handleImageChange}
              ref={props.fileInputRef5}
              accept="image/*,application/pdf"
              className="d-none"
            />
            <Button
              onClick={props.articleOfIncorportation.handleClick}
              type="button"
              className="btn-simple btn-primary btn-sm"
            >
              + Upload Document
            </Button>{" "}
            <img
              height={30}
              src={
                props.articleOfIncorportation.imageState.file
                  ? successImage
                  : props.articleOfIncorportation.imageState.imagePreviewUrl
              }
              alt="..."
            />
            <span className="text-xs text-primary font-weight-bold  ">
              <br />
              {props.articleOfIncorportation.imageState?.file?.name}
            </span>
          </Col>
        ) : null}
        {props.amandmentsDocuments ? (
          <Col sm="12">
            <Label>Amendments Document</Label>
            <br />
            <input
              type="file"
              onChange={props.amandmentsDocuments.handleImageChange}
              ref={props.fileInputRef6}
              accept="image/*,application/pdf"
              className="d-none"
            />
            <Button
              onClick={props.amandmentsDocuments.handleClick}
              type="button"
              className="btn-simple btn-primary btn-sm"
            >
              + Upload Document
            </Button>{" "}
            <img
              height={30}
              src={
                props.amandmentsDocuments.imageState.file
                  ? successImage
                  : props.amandmentsDocuments.imageState.imagePreviewUrl
              }
              alt="..."
            />
            <span className="text-xs text-primary font-weight-bold  ">
              <br />
              {props.amandmentsDocuments.imageState?.file?.name}
            </span>
          </Col>
        ) : null}
      </Row>
    </Fragment>
  );
}

export default OnboardingDocuments;

OnboardingDocuments.propTypes = {
  customerInvoice: PropTypes.object.isRequired,
  bankStatements1: PropTypes.object.isRequired,
  bankStatements2: PropTypes.object.isRequired,
  bankStatements3: PropTypes.object.isRequired,
  articleOfIncorportation: PropTypes.object.isRequired,
  amandmentsDocuments: PropTypes.object.isRequired,
  fileInputRef1: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  fileInputRef2: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  fileInputRef3: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  fileInputRef4: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  fileInputRef5: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  fileInputRef6: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

OnboardingDocuments.defaultProps = {};
