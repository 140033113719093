import { verifyLength } from "../Helpers";
import { validateEmail, validatePhone } from "./transaction/general";

export function contactFormValidation(values, errors) {
  if (values.name) {
    if (verifyLength(values.name, 64)) {
      errors.name = "Contact name cannot be more than 64 characters";
    } else {
      delete errors.name;
    }
  }
  validateEmail(values, errors, "email");

  validatePhone(values, errors, "phone");

  return errors;
}
