import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import {
  convertToDate,
  CustomTooltip,
  formattedDollarValue,
  getCurrentState,
} from "../../../modules/Helpers";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { statusPillPublic } from "../../../components/badge/StatusBadgePublic";

function RecurringHistory(props) {
  const { addToast } = useToasts();

  const copyIdToClipboard = () => {
    addToast("Identifier copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <Fragment>
      <Container className="recurring-history-wrapper">
        {props.recurringPlan.transactions.map((transaction) => (
          <Row className="border-bottom p-3" key={transaction.states[0].state}>
            <Col>
              <p className="mb-1 font-weight-500 text-muted">
                {convertToDate(transaction.states[0].createdAt)}
              </p>
              <div className="d-flex align-items-center">
                <h4 className="mb-0 mr-3">
                  {formattedDollarValue(transaction.amount)}
                </h4>
                {statusPillPublic(
                  getCurrentState(transaction.states).state,
                  transaction
                )}
                <CustomTooltip title="Click to copy" placement="top" arrow>
                  <CopyToClipboard
                    onCopy={() => copyIdToClipboard()}
                    text={transaction.identifier}
                  >
                    <p
                      style={{
                        cursor: "pointer",
                        marginBottom: "0",
                        marginLeft: "16px",
                      }}
                    >
                      <b>Id:</b> {props.recurringPlan.identifier}
                    </p>
                  </CopyToClipboard>
                </CustomTooltip>
              </div>
            </Col>
          </Row>
        ))}
        {props.recurringPlan.transactions.length === 0 && (
          <p className="text-center text text-muted mt-4 mb-4">
            No payments yet
          </p>
        )}
      </Container>
    </Fragment>
  );
}

export default RecurringHistory;
RecurringHistory.propTypes = {
  recurringPlan: PropTypes.object.isRequired,
};
