import React, { useEffect, useRef, useState } from "react";
// reactstrap components
import { Button, Col, Form, FormFeedback, FormGroup, Input } from "reactstrap";

import { NavLink, useHistory } from "react-router-dom";
import useForm from "../../../hooks/UseForms";

import { loginFormValidation } from "../../../modules/form_validation/LoginFormValidation";
import { useToasts } from "react-toast-notifications";
import { login } from "../../../api_client/mutations/auth";
import useGQL from "../../../api_client/UseGQL";
import LogoWithText from "../../../assets/img/LogoWithText.png";
import { appName } from "../../../modules/Helpers";

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const { values, handleChange, handleSubmit, errors } = useForm(
    submit,
    loginFormValidation
  );
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  async function submit() {
    setLoading(true);
    let output = {
      loginToken: true,
    };
    const response = await login(values, output, gqlHooks);
    setLoading(false);
    if (response) {
      addToast(
        "Login code has been sent. Please allow 30 seconds before sending another code",
        {
          appearance: "info",
          autoDismiss: true,
        }
      );
      history.push("/auth/login-code/", {
        token: response.loginToken,
      });
    }
  }

  return (
    <>
      <div className="text-center">
        <div
          className="mr-auto ml-auto mb-3 auth-logo"
          style={{ width: "200px" }}
        >
          <img src={LogoWithText} alt={appName} className="mx-4 mb-4" />
        </div>
        <h3 className="font-weight-bold register-header mb-3">Welcome Back!</h3>
        <p className="text-muted mb-4">
          Need an account? <NavLink to="/auth/register/">Register</NavLink>
        </p>
      </div>
      <Col className="ml-auto mr-auto" md="7">
        <Form className="form" onSubmit={handleSubmit}>
          <label>Email address</label>
          <FormGroup>
            <Input
              name="email"
              type="email"
              innerRef={emailRef}
              value={values.email || ""}
              invalid={!!errors.email}
              onChange={handleChange}
              required
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <label>Password</label>
          <FormGroup className="mb-1">
            <Input
              type="password"
              name="password"
              id="password"
              innerRef={passwordRef}
              value={values.password || ""}
              invalid={!!errors.password}
              onChange={handleChange}
              required
              autoComplete="on"
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>
          <NavLink
            active="true"
            className="sm pull-right mb-3"
            to="/auth/forgot"
          >
            Forgot password?
          </NavLink>
          <div>
            <Button
              disabled={loading}
              block
              type="submit"
              color="primary"
              size="md"
            >
              Login
            </Button>
          </div>
        </Form>
      </Col>
    </>
  );
}

export default LoginForm;
