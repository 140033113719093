import React, { Fragment } from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function TransactionCardSkeleton() {
  return (
    <Fragment>
      <Skeleton
        className="t-skeleton-card rounded"
        variant="rect"
        height={141}
      />
    </Fragment>
  );
}

export default TransactionCardSkeleton;

TransactionCardSkeleton.propTypes = {};
