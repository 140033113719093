import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import AddBankAccount from "../../views/admin/settings/banks/AddBankAccount";

function AddBankAccountModal(props) {
  return (
    <Modal
      className="add-bank-modal-wrapper"
      backdrop
      fade
      centered
      isOpen={props.isOpen}
      toggle={props.toggle}
    >
      <ModalBody className="add-bank-modal-body">
        <Row>
          <Col sm="12" className="add-bank-modal-header">
            <Button close className="p-4" onClick={props.toggle} />
          </Col>
        </Row>
        <AddBankAccount {...props} />
      </ModalBody>
    </Modal>
  );
}

export default AddBankAccountModal;

AddBankAccountModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  companyLogo: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.string,
};
