// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import axios from "axios";

const useHubspotForm = ({ portalId, formId }) => {
  const [data, setData] = useState();
  const [url] = useState(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`
  );
  const [form, setForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const formData = form;
      const data = {
        fields: [],
      };
      for (const [key, value] of Object.entries(formData)) {
        data.fields.push({ name: key, value: value });
      }

      const result = await axios({
        method: "post",
        url,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setData(result);
      setForm(false);
    } catch (e) {
      setIsError(true);
      setForm(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (form) {
      fetchData();
    }
  }, [form]);

  const handleHubspotSubmit = (data) => {
    setForm(data);
  };

  return {
    setData,
    data,
    isLoading,
    isError,
    handleHubspotSubmit: handleHubspotSubmit,
  };
};

export default useHubspotForm;
