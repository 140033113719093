import { useHistory } from "react-router";
import React, { Fragment } from "react";
import { Button, ButtonGroup } from "reactstrap";
// import { faPlus, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import singleCredit from "assets/img/banks/singleCredit.svg";
import bulkCredit from "assets/img/banks/bulkCredit.svg";

export const SelectCreditPaymentType = () => {
  const history = useHistory();
  return (
    <Fragment>
      <h2 className="text-center">Select payment type</h2>
      <ButtonGroup
        style={{ maxWidth: "450px", marginTop: "11px" }}
        className="business-type-btn-wrapper flex-column"
      >
        <Button
          className="business-type-btn-custom mb-2"
          onClick={() =>
            history.push("/new-payment/credit/details", {
              steps: 7,
              currStep: 2,
            })
          }
        >
          <div className="icon-text-wrapper">
            <div className="btn-custom-icon">
              {/* <FontAwesomeIcon
                style={{ width: "40px", height: "40px" }}
                icon={faPlus}
              /> */}
              <img
                src={singleCredit}
                style={{ width: "60px", height: "60px" }}
              />
            </div>
            <div className="btn-custom-text">
              <h4>Single payment</h4>
              <span>Quickly create and send a single payment</span>
            </div>
          </div>
        </Button>
        <Button
          className="business-type-btn-custom mb-2"
          onClick={() =>
            history.push("/bulk-payment/credit/create/selectAccount", {
              steps: 5,
              currStep: 1,
            })
          }
        >
          <div className="icon-text-wrapper">
            <div className="btn-custom-icon">
              {/* <FontAwesomeIcon
                style={{ width: "40px", height: "40px" }}
                icon={faFolderPlus}
              /> */}
              <img src={bulkCredit} style={{ width: "60px", height: "60px" }} />
            </div>
            <div className="btn-custom-text">
              <h4>Bulk payable</h4>
              <span>Create and send multiple payments simultaneously</span>
            </div>
          </div>
        </Button>
      </ButtonGroup>
    </Fragment>
  );
};
