import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function createRecurringPlan(input, output, hooks) {
  const query = buildMutation(input, output, "createRecurringPlan");
  return callMutation(query, hooks);
}

createRecurringPlan.propTypes = {
  input: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    toBankAccountId: PropTypes.string.isRequired,
    statement: PropTypes.string.isRequired,
    note: PropTypes.string,
    startEpoch: PropTypes.number.isRequired,
    endEpoch: PropTypes.number,
    padType: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function acceptRecurringPlan(input, output, hooks) {
  const query = buildMutation(input, output, "acceptRecurringPlan");
  return callMutation(query, hooks);
}

acceptRecurringPlan.propTypes = {
  input: PropTypes.shape({
    recurringPlanId: PropTypes.string.isRequired,
    fromBankAccountId: PropTypes.string.isRequired,
    signaturePad: PropTypes.bool.isRequired,
    signaturePadTime: PropTypes.number.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function acceptRecurringPlanViaCreditCard(input, output, hooks) {
  const query = buildMutation(
    input,
    output,
    "acceptRecurringPlanViaCreditCard"
  );
  return callMutation(query, hooks);
}

acceptRecurringPlanViaCreditCard.propTypes = {
  input: PropTypes.shape({
    recurringPlanId: PropTypes.string.isRequired,
    stripeCustomerId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function cancelRecurringPlan(input, output, hooks) {
  const query = buildMutation(input, output, "cancelRecurringPlan");
  return callMutation(query, hooks);
}

cancelRecurringPlan.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};
