import { PASSWORD_LENGTH, verifyLength } from "../Helpers";

export function resetPasswordFormValidation(values, errors) {
  if (values.password) {
    if (!verifyLength(values.password, PASSWORD_LENGTH)) {
      errors.password = "Password must be greater than 8 Characters";
    } else {
      delete errors.password;
    }
  }

  if (values.password === "") {
    delete errors.password;
  }
  delete errors.passwordConfirm;

  return errors;
}
