import React from "react";

function IndicatorCircle(props) {
  const fill = props.fill || "#03c200";
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || "1%";
  const height = props.height || "1%";
  const title = props.title || "Indicator Circle";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 512 512"
    >
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" fill={fill} />
    </svg>
  );
}

export default IndicatorCircle;
