import React, { Fragment, useEffect } from "react";
import useHubspotForm from "../../hooks/useHubspotForm";
import useForm from "../../hooks/UseForms";
import { registerFormValidation } from "../../modules/form_validation/RegisterFormValidation";
import useGQL from "../../api_client/UseGQL";
import successAlert from "../sweet_alert/SuccessAlert";
import { useSimpleContext } from "../../contexts/SimpleContext";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function CheckoutSupportForm(props) {
  const { data, isLoading, handleHubspotSubmit } = useHubspotForm({
    portalId: "6217266",
    formId: "d49a4d42-cc4a-46c8-99bb-23197d9b2125",
  });
  const { values, setValues, handleChange, handleSubmit, errors, waitErrors } =
    useForm(submit, registerFormValidation);
  let gqlHooks = useGQL(true);
  const [state] = useSimpleContext();

  useEffect(() => {
    if (data && data.status === 200) {
      successAlert(
        () => gqlHooks.context.setAlert(null),
        gqlHooks.context.setAlert,
        "Ticket submitted a team member will contact you shortly"
      );
      props.toggleSupport();
    }
    setValues({
      email:
        state.transaction?.contact?.email ||
        state.recurringPlan?.contactType?.email,
      transactionId:
        state.transaction?.identifier || state.recurringPlan.identifier,
      subject: "Checkout issue",
    });
  }, [data]);

  function submit() {
    handleHubspotSubmit({
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      phone: values.phone,
      "TICKET.subject": values.subject || "none",
      "TICKET.content": values.description || "none",
      "TICKET.transaction_id": values.transactionId || "none",
      "TICKET.what_troubleshooting_have_you_tried_": values.info || "none",
    });
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Button
            className="btn-link details-close-btn"
            onClick={() => props.toggleSupport()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <h3 className="font-weight-600 mt-1 mb-4">Support ticket</h3>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Label>First name</Label>
            <FormGroup>
              <Input
                name="firstName"
                type="text"
                value={values.firstName || ""}
                onChange={handleChange}
                invalid={!!errors.firstName}
                required
              />
            </FormGroup>
          </Col>
          <Col>
            <Label>Last name</Label>
            <FormGroup>
              <Input
                name="lastName"
                type="text"
                value={values.lastName || ""}
                onChange={handleChange}
                invalid={!!errors.lastName}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Label>Email</Label>
        <FormGroup>
          <Input
            name="email"
            type="email"
            value={values.email || ""}
            onChange={handleChange}
            invalid={!!errors.email}
            required
          />
          <FormFeedback>{errors.email}</FormFeedback>
        </FormGroup>
        <Label>Phone number</Label>
        <FormGroup>
          <Input
            type="tel"
            name="phone"
            value={values.phone || ""}
            invalid={!!waitErrors.phone}
            onChange={handleChange}
            required
          />
          <FormFeedback>{errors.phone}</FormFeedback>
        </FormGroup>
        <Label>Subject</Label>
        <FormGroup>
          <Input
            name="subject"
            type="text"
            value={values.subject || ""}
            onChange={handleChange}
            invalid={!!errors.subject}
            required
          />
          <FormFeedback>{errors.subject}</FormFeedback>
        </FormGroup>
        <Label>
          {state.recurringPlan ? " Plan id" : " Payment id"}{" "}
          <span className="text-muted text-sm">(optional)</span>
        </Label>
        <FormGroup>
          <Input
            name="transactionId"
            type="text"
            value={values.transactionId || ""}
            onChange={handleChange}
            invalid={!!errors.transactionId}
          />
        </FormGroup>
        <Label>Description</Label>
        <FormGroup>
          <Input
            name="description"
            type="textarea"
            value={values.description || ""}
            onChange={handleChange}
            invalid={!!errors.description}
            required
          />
        </FormGroup>
        <Label>
          What troubleshooting have you tried so far?{" "}
          <span className="text-muted text-sm">(optional)</span>
        </Label>
        <FormGroup>
          <Input
            name="info"
            type="textarea"
            value={values.info || ""}
            onChange={handleChange}
            invalid={!!errors.info}
          />
        </FormGroup>
        <Button
          disabled={isLoading}
          type="submit"
          className="btn-primary"
          block
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );
}

export default CheckoutSupportForm;

CheckoutSupportForm.propTypes = {
  toggleSupport: PropTypes.func,
};
