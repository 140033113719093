import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import useForm from "../../../../hooks/UseForms";
import PropTypes from "prop-types";
import { profileFormValidation } from "../../../../modules/form_validation/ProfileFormValidation";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../../api_client/UseGQL";
import { setUserProfile } from "../../../../api_client/mutations/userProfile";
import { withApiValue } from "../../../../modules/WithApiValue";
import { CustomTooltip } from "../../../../modules/Helpers";

const config = {
  profile: { firstName: true, lastName: true, phone: true },
};

function EditProfileCard(props) {
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    submit,
    profileFormValidation
  );
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  let gqlHooks = useGQL();
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  useEffect(() => {
    setValues(props.profile);
  }, []);

  async function submit() {
    setLoading(true);
    let output = {
      profile: {
        firstName: true,
        lastName: true,
      },
    };
    const response = await setUserProfile(
      { firstName: values.firstName, lastName: values.lastName },
      output,
      gqlHooks
    );
    if (response) {
      props.update(response);
      addToast("Updated profile successfully!", {
        appearance: "success",
        autoDismiss: true,
      });
    }
    setLoading(false);
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="pull-left">
            Edit Profile
          </CardTitle>
        </CardHeader>
        <Form className="form" onSubmit={handleSubmit}>
          <CardBody>
            <Col>
              <Label>First Name</Label>
              <FormGroup>
                <Input
                  name="firstName"
                  value={values.firstName || ""}
                  type="text"
                  invalid={!!errors.firstName}
                  onChange={handleChange}
                  required
                />
                <FormFeedback>{errors.firstName}</FormFeedback>
              </FormGroup>
              <Label>Last Name</Label>
              <FormGroup>
                <Input
                  name="lastName"
                  value={values.lastName || ""}
                  type="text"
                  invalid={!!errors.lastName}
                  onChange={handleChange}
                />
                <FormFeedback>{errors.lastName}</FormFeedback>
              </FormGroup>
              <Label>Phone Number</Label>
              <CustomTooltip
                title="Please contact support to change your phone number"
                placement="top"
                arrow
                open={tooltipIsOpen}
                onOpen={() => setTooltipIsOpen(true)}
                onClose={() => setTooltipIsOpen(false)}
                leaveTouchDelay={10000}
              >
                <Button
                  onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                  variant="contained"
                  className="btn-link tooltip-button"
                >
                  <i className="fas fa-info-circle" />
                </Button>
              </CustomTooltip>
              <FormGroup>
                <Input
                  name="phone"
                  value={values.phone || ""}
                  type="text"
                  invalid={!!errors.phone}
                  onChange={handleChange}
                  readOnly
                />
                <FormFeedback>{errors.phone}</FormFeedback>
              </FormGroup>
            </Col>
          </CardBody>
          <CardFooter>
            <Button block color="primary" type="submit" disabled={loading}>
              Save
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default withApiValue(EditProfileCard, config);

EditProfileCard.propTypes = {
  profile: PropTypes.object,
  update: PropTypes.func,
};
