import React, { Fragment, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import Select from "react-select";
import { enumValue, statuses } from "../../modules/Helpers";
import YearsSelect from "../custom_select/YearsSelect";

function BillingFilters(props) {
  const { addToast } = useToasts();
  const [startYear, setStartYear] = useState(undefined);
  const [endYear, setEndYear] = useState(undefined);

  const handleClickYearWiseBilling = () => {
    if (startYear && endYear && startYear?.value <= endYear?.value) {
      props.setFilters({
        ...props.filters,
        startYear: startYear ? startYear?.value : new Date().getFullYear(),
        endYear: endYear ? endYear?.value : new Date().getFullYear(),
      });
    } else if (startYear && endYear && startYear?.value > endYear?.value) {
      addToast(`Start Year must be equal or greater than End Year`, {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (startYear == undefined || endYear == undefined) {
      addToast(`Start Year and End Year both are required to select`, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      addToast(`Please try again. If you are experiencing issues`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <Fragment>
      <Row>
        <Col md={4}>
          <Label>Start Year</Label>
          <FormGroup>
            <YearsSelect
              isMulti={false}
              activeYears={startYear}
              onChangeCallback={(value) => setStartYear(value)}
              isClearable
              clear={() => setStartYear(undefined)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <Label>End Year</Label>
          <FormGroup>
            <YearsSelect
              isMulti={false}
              activeYears={endYear}
              onChangeCallback={(value) => setEndYear(value)}
              isClearable
              clear={() => setEndYear(undefined)}
            />
          </FormGroup>
        </Col>
        <Col
          md={3}
          className="d-flex  justify-content-start align-items-center"
        >
          <Button
            size="sm"
            color="primary"
            onClick={handleClickYearWiseBilling}
            className="btn-simple"
          >
            Search
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default BillingFilters;

BillingFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

BillingFilters.defaultProps = {};
