import SweetAlert from "react-bootstrap-sweetalert";
import React from "react";

function dangerAlert(
  hideAlert,
  setAlert,
  message = "Please contact support",
  title = "Something went wrong",
  onConfirm = () => {
    hideAlert();
  },
  confirmBtnText = "Contact support"
) {
  hideAlert();
  setAlert(
    <SweetAlert
      danger
      title={title}
      onCancel={() => hideAlert()}
      onConfirm={onConfirm}
      confirmBtnText={confirmBtnText}
      showConfirm={true}
      showCancel={false}
    >
      {message}
    </SweetAlert>
  );
}

export default dangerAlert;
