import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MobileStepper from "@material-ui/core/MobileStepper";
import { Button, Col, Row } from "reactstrap";
import {
  convertToDate,
  CustomTooltip,
  formatDate,
  getCurrentState,
  getProgressBarBackgroundColor,
  getProgressBarColor,
  getStatus,
  PULL,
  PUSH,
} from "../../modules/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import TransactionStates from "../../variables/TransactionStates";
import { useHistory } from "react-router-dom";
import useGQL, { useErrorHandlerGQL } from "../../api_client/UseGQL";
import {
  reRouteTransaction,
  reSourceTransaction,
  retryTransaction,
} from "../../api_client/mutations/transaction";
import { useToasts } from "react-toast-notifications";
import TransactionRetryModal from "../modals/TransactionRetryModal";
import TransactionReRouteSourceModal from "../modals/TransactionReRouteSourceModal";
import { trackEvent } from "../../modules/analytics";
import { useGQLContext } from "../../api_client/client";
import DangerAlert from "../sweet_alert/DangerAlert";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    flexGrow: 1,
    paddingLeft: "0",
    paddingRight: "0",
    marginTop: "4px",
    backgroundColor: "#ffffff",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: (props) => props.progressColor,
    },
  },
  progress: {
    width: "100%",
    height: "10px",
    borderRadius: "5px",
    backgroundColor: (props) => props.progressBackgroundColor,
  },
}));

function TransactionProgressBar(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const { setAlertState, loading, setLoading, transaction } = props;
  const history = useHistory();
  const { addToast } = useToasts();
  const [, dispatch] = useGQLContext();
  const defaultErrorHandler = useErrorHandlerGQL(dispatch, false);
  let availableTransitionsLC = (transaction.availableTransitions || []).map(
    (t) => t.toLowerCase()
  );

  let state = getCurrentState(props.transaction.states);
  let status = state.state;
  let failedDir = state.reason?.includes("push") ? PUSH : PULL;
  const classes = useStyles({
    progressColor: getProgressBarColor(status),
    progressBackgroundColor: getProgressBarBackgroundColor(status),
  });

  const statesNotShowingTransactionBar = [
    TransactionStates.PROCESSING,
    TransactionStates.FAILED,
    TransactionStates.ON_HOLD,
    TransactionStates.CANCELLED,
    TransactionStates.WAITING_APPROVAL,
    TransactionStates.DRAFT,
    TransactionStates.EMAIL_ERROR,
    TransactionStates.UNAUTHORIZED,
    TransactionStates.RESUMED,
  ];

  const showProgressBar = !statesNotShowingTransactionBar.includes(status);

  useEffect(() => {
    setActiveStep(findStep());
  }, [status]);

  function handleError(response) {
    if (response.errors && response.errors[0].message) {
      DangerAlert(
        () => setAlertState(null),
        setAlertState,
        response.errors[0].message,
        "Something went wrong"
      );
    } else {
      return defaultErrorHandler(response);
    }
  }

  let gqlHooksWithError = useGQL(false, handleError);
  let gqlHooks = useGQL();

  async function doRetry() {
    setAlertState(null);
    setLoading(true);
    props.setBackdropClick(true);

    let input = { transactionId: transaction.identifier };
    let output = { ok: true };

    let response = await retryTransaction(input, output, gqlHooksWithError);

    props.setBackdropClick(false);

    if (response && response.ok) {
      addToast("Transaction is being retried", {
        appearance: "success",
        autoDismiss: true,
      });

      trackEvent(
        "transactionRetry",
        {
          requestId: transaction.identifier,
          companyId: transaction.company.identifier,
        },
        gqlHooks
      );

      history.push({ search: "update=true" });
    } else {
      setLoading(false);
    }
  }

  async function doReSource({
    institutionNumber,
    transitNumber,
    accountNumber,
  }) {
    setAlertState(null);
    setLoading(true);
    props.setBackdropClick(true);

    let input = {
      transactionId: transaction.identifier,
      institutionNumber: institutionNumber,
      transitNumber: transitNumber,
      accountNumber: accountNumber,
    };
    let output = { ok: true };

    let response = await reSourceTransaction(input, output, gqlHooksWithError);

    props.setBackdropClick(false);

    if (response && response.ok) {
      addToast("Transaction is being re-attempted with new source account", {
        appearance: "success",
        autoDismiss: true,
      });

      trackEvent(
        "transactionReSource",
        {
          requestId: transaction.identifier,
          companyId: transaction.company.identifier,
        },
        gqlHooks
      );

      history.push({ search: "update=true" });
    } else {
      setLoading(false);
    }
  }

  async function doReRoute({
    institutionNumber,
    transitNumber,
    accountNumber,
  }) {
    setAlertState(null);
    setLoading(true);
    props.setBackdropClick(true);

    let input = {
      transactionId: transaction.identifier,
      institutionNumber: institutionNumber,
      transitNumber: transitNumber,
      accountNumber: accountNumber,
    };
    let output = { ok: true };

    let response = await reRouteTransaction(input, output, gqlHooksWithError);

    props.setBackdropClick(false);

    if (response && response.ok) {
      addToast("Transaction is being sent to new destination account", {
        appearance: "success",
        autoDismiss: true,
      });

      trackEvent(
        "transactionReRoute",
        {
          requestId: transaction.identifier,
          companyId: transaction.company.identifier,
        },
        gqlHooks
      );

      history.push({ search: "update=true" });
    } else {
      setLoading(false);
    }
  }

  function confirmRetry() {
    setAlertState(
      <TransactionRetryModal doRetry={doRetry} setAlertState={setAlertState} />
    );
  }

  function confirmReSource() {
    setAlertState(
      <TransactionReRouteSourceModal
        doAction={doReSource}
        setAlertState={setAlertState}
      />
    );
  }

  function confirmReRoute() {
    setAlertState(
      <TransactionReRouteSourceModal
        doAction={doReRoute}
        setAlertState={setAlertState}
      />
    );
  }

  function findStep() {
    switch (status) {
      case TransactionStates.PROCESSING: // Processing
        return 1;
      case TransactionStates.COMPLETED: // Deposited
        return 6;
      case TransactionStates.FAILED: // Failed
        return 5;
      case TransactionStates.INITIATED: // Initiated
        return 3;
      case TransactionStates.PULLED: // Pulled
        return 4;
      case TransactionStates.PUSHED: // In Transit
        return 5;
      case TransactionStates.ON_HOLD: // On Hold
        return 5;
      case TransactionStates.REQUESTED: // Requested
        return 1;
      case TransactionStates.CANCELLED: // Cancelled
        return 0;
      case TransactionStates.PENDING: // Paid / Pending
        return 2;
      case TransactionStates.RETURNED: // Returned
        return 6;
      case TransactionStates.RETURNING: // Returning
        return 5;
      case TransactionStates.REROUTED: // Rerouted
        return 5;
      case TransactionStates.RETRYING: // Retrying
        return 1;
      case TransactionStates.WAITING_APPROVAL: // Waiting Approval
        return 1;
      case TransactionStates.DRAFT: // Draft
        return 1;
      case TransactionStates.EMAIL_ERROR: // Email Error
        return 1;
      case TransactionStates.UNAUTHORIZED: // Hold
        return 1;
      case TransactionStates.PAID: // Charged (with credit card)
        return 3;
      case TransactionStates.RESUMED: // Resumed
        return 5;
      default:
        return 0;
    }
  }

  const retryAvailable = availableTransitionsLC.includes("retry");
  const reSourceAvailable = availableTransitionsLC.includes("re_source");
  const reRouteAvailable = availableTransitionsLC.includes("re_route");

  function renderRetryButtons() {
    let text;
    let onClick;
    if (retryAvailable) {
      text = "Retry Transaction";
      onClick = confirmRetry;
    } else if (reSourceAvailable) {
      text = "Update Contact Bank Details";
      onClick = confirmReSource;
    } else if (reRouteAvailable) {
      text = "Update Contact Bank Details";
      onClick = confirmReRoute;
    } else {
      return;
    }

    return (
      <Button
        block
        color="primary"
        size="md"
        className="mb-2"
        onClick={onClick}
        disabled={loading}
      >
        {text}
      </Button>
    );
  }

  return (
    <div className="hide-on-print">
      {showProgressBar ? (
        <>
          <Row>
            <Col>
              <div>
                <MobileStepper
                  variant="progress"
                  steps={7}
                  position="static"
                  activeStep={activeStep}
                  classes={{
                    root: classes.root,
                    progress: classes.progress,
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="progress-bar-details">
            <Col sm="4" xs="6" className="order-sm-0 order-0 pr-0">
              <h5>Date Created</h5>
              <h4 className="font-weight-500 mb-0">
                {convertToDate(props.transaction.createdAt)}
              </h4>
            </Col>
            <Col
              sm="4"
              xs="12"
              className="order-sm-1 order-2 pt-sm-1 pt-3 pr-0 pl-0"
            >
              <Button
                onClick={props.toggleHistory}
                className="btn-link m-auto d-block font-weight-500"
                color="links"
              >
                Tracking history
                {props.showHistoryState ? (
                  <FontAwesomeIcon
                    style={{ marginBottom: "0px", marginLeft: "5px" }}
                    icon={faChevronUp}
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ marginBottom: "0px", marginLeft: "5px" }}
                    icon={faChevronDown}
                  />
                )}
              </Button>
            </Col>
            <Col sm="4" xs="6" className="order-sm-2 order-1 pl-0">
              <h5 className="text-right">
                {props.transaction.states[0].state === 1
                  ? "Deposited"
                  : "Estimated Deposit"}
                <CustomTooltip
                  title="For EFT payments to/from large Canadian financial institutions, you can expect funds in your account
                         within 2-3 business days. Smaller institutions and credit unions could have longer processing times."
                  placement="top"
                  arrow
                  open={isOpen}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  leaveTouchDelay={10000}
                >
                  <Button
                    onClick={() => setOpen(!isOpen)}
                    variant="contained"
                    className="btn-link tooltip-button"
                  >
                    <i className="fas fa-info-circle" />
                  </Button>
                </CustomTooltip>
              </h5>
              <h4 className="text-purple-rain font-weight-600 mb-0 text-right">
                <>
                  {props.transaction.states[0].state === 7 ? (
                    <span className="text-purple-rain">Pending</span>
                  ) : (
                    formatDate(props.transaction.estDepositDate)
                  )}
                </>
              </h4>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <h4 className="font-weight-600 text-muted text-center mb-2 mt-3">
            {
              getStatus(status, props.transaction.direction, failedDir)
                .description
            }
          </h4>
          {renderRetryButtons()}
          <Button
            onClick={props.toggleHistory}
            className="btn-link m-auto d-block font-weight-500"
            color="links"
          >
            Tracking history
            {props.showHistoryState ? (
              <FontAwesomeIcon
                style={{ marginBottom: "0px", marginLeft: "5px" }}
                icon={faChevronUp}
              />
            ) : (
              <FontAwesomeIcon
                style={{ marginBottom: "0px", marginLeft: "5px" }}
                icon={faChevronDown}
              />
            )}
          </Button>
        </>
      )}
    </div>
  );
}

export default TransactionProgressBar;

TransactionProgressBar.propTypes = {
  transaction: PropTypes.object,
  toggleHistory: PropTypes.func,
  showHistoryState: PropTypes.bool,
  setAlertState: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setBackdropClick: PropTypes.func,
};
