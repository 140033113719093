/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  convertToDate,
  CREDIT,
  DEBIT,
  formatDate,
  formattedDollarValue,
  getCurrentState,
  INTERNAL,
} from "../../modules/Helpers";
import { statusPill } from "../badge/StatusBadge";
import useGQL from "../../api_client/UseGQL";
import {
  allPublicTransactions,
  allTransactions,
} from "../../api_client/queries/transactions";
import ReactTable from "./ReactTable";
import ContactTagBadge from "../badge/ContactTagBadge";
import TransactionCard from "../cards/TransactionCard";
import TransactionCardSkeleton from "../skeleton/TransactionCardSkeleton";
import { useHistory, useLocation } from "react-router";
import QueryString from "query-string";
import { statusPillEmail } from "../badge/StatusBadgeEmail";
import TransactionStates from "../../variables/TransactionStates";

function TransactionTable(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let gqlHooks = useGQL();
  const [count, setCount] = useState(0);
  const location = useLocation();
  const [update, setUpdate] = useState(false);
  const history = useHistory();
  const customer = props.customer ? true : false;

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.update) {
      setUpdate(!update);
      history.push({ search: "" });
    }
  }, [location]);

  const getData = useCallback(
    async ({ pageSize = 1 }) => {
      setLoading(true);
      let offset = 0;
      const params = {
        ...props.filters,
        limit: pageSize,
        offset: offset,
      };
      let output = {
        createdAt: true,
        estDepositDate: true,
        method: true,
        identifier: true,
        amount: true,
        statement: true,
        description: true,
        padType: true,
        direction: true,
        states: {
          state: true,
          createdAt: true,
        },
        contact: {
          identifier: true,
          email: true,
          name: true,
          tags: true,
        },
        transactionRequestedContactEmail: {
          status: true,
        },
      };
      const response = customer
        ? await allPublicTransactions(params, output, gqlHooks)
        : await allTransactions(params, output, gqlHooks);
      if (response) {
        setData([...response.data]);
        setCount(response.count);
      }
      setLoading(false);
    },
    [props.filters, update]
  );

  let columns = useMemo(
    () => [
      {
        Header: "Contact",
        accessor: "contact",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.value && cell.value.name ? cell.value.name : ""}
            </div>
            <div className="text-muted text-sm mb-1">
              {cell.row.original.direction === INTERNAL ? (
                "Internal Transfer"
              ) : (
                <>
                  {cell.value ? cell.value.email : "Pending Payment Acceptance"}
                </>
              )}
            </div>
          </>
        ),
      },
      {
        Header: "Tags",
        accessor: "none",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.contact === null ? (
              ""
            ) : (
              <div>
                {ContactTagBadge(
                  cell.row.original.contact.tags,
                  cell.row.original.identifier
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Statement",
        accessor: "statement",
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div className="text-muted">{convertToDate(cell.value)}</div>
          </>
        ),
      },
      {
        Header: "Estimated Deposit",
        accessor: "estDepositDate",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div className="text-muted">
              {cell.row.original.states[0].state ===
                TransactionStates.REQUESTED ||
              cell.row.original.states[0].state ===
                TransactionStates.CANCELLED ||
              cell.row.original.states[0].state ===
                TransactionStates.WAITING_APPROVAL
                ? "Pending"
                : formatDate(cell.row.original.estDepositDate)}
            </div>
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "states",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            {/* eslint-disable-next-line react/prop-types */}
            {cell.value[0].state === TransactionStates.REQUESTED &&
            cell.row.original.transactionRequestedContactEmail?.status ? (
              <>
                {statusPillEmail(
                  cell.row.original.transactionRequestedContactEmail?.status,
                  cell.row.original
                )}
              </>
            ) : (
              <>
                {statusPill(
                  getCurrentState(cell.value).state,
                  cell.row.original
                )}
              </>
            )}
          </>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        style: { th: { textAlign: "right" } },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <div className="text-right">
            <div className="text-right">
              {cell.row.original.direction === DEBIT ? (
                <div>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    {formattedDollarValue(cell.row.original.amount)}
                  </div>
                  <div className="text-muted text-sm">Receivable</div>
                </div>
              ) : cell.row.original.direction === CREDIT ? (
                <div>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    ({formattedDollarValue(cell.row.original.amount)})
                  </div>
                  <div className="text-muted text-sm">Payable</div>
                </div>
              ) : (
                <div>
                  <div
                    className="font-weight-bold mt-1"
                    style={{ lineHeight: "1.2" }}
                  >
                    {formattedDollarValue(cell.row.original.amount)}
                  </div>
                  <div className="text-muted text-sm">Transfer</div>
                </div>
              )}
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ReactTable
        hidePagination={true}
        loading={loading}
        getData={getData}
        data={data}
        columns={columns}
        count={count}
        overlayType="payment"
        styles="transaction-table"
        cards={props.cards}
        MobileCard={TransactionCard}
        MobileSkeleton={TransactionCardSkeleton}
        numTd={7}
      />
    </>
  );
}

export default TransactionTable;

TransactionTable.propTypes = {
  filters: PropTypes.object,
  cards: PropTypes.bool,
};

TransactionTable.defaultProps = {
  cards: false,
};
