import React, { Fragment } from "react";
import { Button } from "reactstrap";
import LogoWithText from "../../../assets/img/LogoWithText.png";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../api_client/UseGQL";
import { resendVerificationEmail } from "../../../api_client/mutations/auth";
import PropTypes from "prop-types";
import { useGQLContext } from "../../../api_client/client";
import TrustFooter from "../../../components/footers/TrustFooter";
import LogoColor from "../../../assets/img/logo-colour.png";
import CodeFormSideContent from "../../auth/side_content/CodeFormSideContent";
import { withApiValue } from "../../../modules/WithApiValue";
import { appName } from "modules/Helpers";
import { reportEmailVerificationResent } from "../../../modules/analytics";

const config = {
  emails: {
    address: true,
    identifier: true,
  },
};

function VerifyEmailPage(props) {
  const { addToast } = useToasts();
  let gqlHooks = useGQL();
  const [, dispatch] = useGQLContext();

  const resend = async () => {
    let output = {
      ok: true,
    };
    const response = await resendVerificationEmail(
      { emailId: props.emails[0].identifier },
      output,
      gqlHooks
    );
    if (response === null) {
      return;
    }
    if (!response) {
      addToast(
        "Could not send another email, please wait 5 minutes and then try again",
        { appearance: "info", autoDismiss: true }
      );
    } else {
      addToast("We sent you another verification email", {
        appearance: "success",
        autoDismiss: true,
      });
      reportEmailVerificationResent();
    }
  };

  function logout() {
    dispatch({
      type: "logout",
    });
  }

  return (
    <Fragment>
      <div className="bg-white auth-wrapper">
        <div className="d-flex flex-row">
          <div className="flex-column d-flex auth-content flex-grow-1">
            <div className="col">
              <div
                className=" mb-3 cursor-pointer"
                style={{ maxWidth: "150px" }}
              >
                <img src={LogoWithText} alt={`${appName} Logo`} />
              </div>
            </div>
            <div className="col">
              <Button
                type="button"
                onClick={logout}
                size="sm"
                className="btn btn-link btn-primary font-weight-600 pull-right mt-0 pt-0 pr-0 mr-0"
                color="primary"
              >
                Logout
              </Button>
            </div>
            <div className="content-aligner">
              <div className="content-aligner-item">
                <div className="text-center ">
                  <div className="mr-auto ml-auto mb-3 auth-logo">
                    <img src={LogoColor} width={50} height={50} alt={appName} />
                  </div>
                  <h2 className="font-weight-bold register-header mb-2">
                    Check your email!
                  </h2>
                  <p className="text-muted">
                    We just sent a verification link to{" "}
                    <b>{props.emails[0].address}</b>.
                  </p>
                  <p className="text-muted mb-4">
                    Click on the link in your email to get started with{" "}
                    {appName}!
                  </p>
                  <span className="p text-muted">
                    Didn&apos;t receive the email?
                  </span>{" "}
                  <Button
                    onClick={resend}
                    className="btn-link font-weight-bold p-0 m-0"
                    color="primary"
                  >
                    Resend email
                  </Button>
                </div>
              </div>
            </div>
            <TrustFooter />
          </div>
          <div className="d-flex flex-column bg-dark basis-4 auth-side-content">
            <CodeFormSideContent />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default withApiValue(VerifyEmailPage, config);

VerifyEmailPage.propTypes = {
  emails: PropTypes.array,
};
