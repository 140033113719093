import { useEffect } from "react";
import useGQL from "../api_client/UseGQL";
import { useLocation } from "react-router-dom";
import { reportPageView } from "../modules/analytics";

export function AnalyticsGateway(props) {
  const gqlHooks = useGQL();
  const location = useLocation();

  useEffect(() => {
    reportPageView(location, gqlHooks);
  }, [location.pathname]);

  return props.children;
}
