/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { appName, emailAccounts, orgName } from "modules/Helpers";

function BillingPAD(props) {
  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <blockquote className="blockquote billing-pad-wrapper">
              <p className="blockquote-footer">Type of PAD: Business.</p>
              <p className="blockquote-footer font-weight-bold">
                YOU WAIVE ANY LEGISLATIVE OR REGULATORY REQUIREMENT FOR
                PRE-NOTIFICATION.
              </p>
              <p className="blockquote-footer">
                You will be debited on the{" "}
                <b>
                  1st of every month for the previous months transactions
                  processed.
                </b>
              </p>
              <p className="blockquote-footer">
                Transaction dates that fall on a weekend or holiday will be
                processed the next business day.
              </p>
              <p className="blockquote-footer">
                You agree to be debited a variable amount depending on your
                usage.
              </p>
              <p className="blockquote-footer">
                You have certain recourse rights if any debit does not comply
                with this agreement. For example, you have the right to receive
                reimbursement for any debit that is not authorized or is not
                consistent with this PAD Agreement. To obtain more information
                on your recourse rights, contact your financial institution or
                visit <u>payments.ca</u>.
              </p>
              <p className="blockquote-footer">
                If the first attempt to debit your account fails, you agree to
                allow us to debit your account one additional time for the
                amount specified in this agreement.
              </p>
              <p className="blockquote-footer">
                <b>
                  If multiple attempts fail to debit your account you agree to
                  be charged a CA $30 NSF fee. Your {appName} account will also
                  be frozen until payment has been made.
                </b>
              </p>
              <p className="blockquote-footer">
                Your Payor's PAD Agreement may be cancelled provided notice is
                received 30 days before the next scheduled PAD. If any of the
                above details are incorrect, please contact us immediately at
                {emailAccounts.SUPPORT}. If the details are correct, you do not
                need to do anything further and your Pre-Authorized Debits will
                be processed and start on the Payment Start Date indicated
                above.
              </p>
              <p className="blockquote-footer">
                This PAD is between <b>{props.companyName}</b> and{" "}
                <u>
                  <b>
                    {appName} by {orgName}
                  </b>
                </u>
                . ({appName})
              </p>
            </blockquote>
          </Col>
          <Col sm="12" className="pt-0">
            <FormGroup check className="mb-3 mt-2">
              <Label className="form-check-label">
                <Input
                  id="cb-1"
                  className="form-check-input"
                  type="checkbox"
                  checked={props.signaturePAD}
                  onChange={(e) => props.agreeToPAD(e)}
                />
                I agree to the terms and conditions of the pre authorized debit
                agreement
                <span className="form-check-sign">
                  <span className="check" />
                </span>
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default BillingPAD;

BillingPAD.propTypes = {
  companyName: PropTypes.string.isRequired,
  amount: PropTypes.string,
  signaturePAD: PropTypes.bool,
  contactEmail: PropTypes.string,
  agreeToPAD: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};
