import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import React, { Fragment } from "react";
import SuccessIcon from "react-bootstrap-sweetalert/dist/components/SuccessIcon";
import {
  formattedDollarValue,
  pluralize,
} from "../../../../../../modules/Helpers";
import { Button, Col, Row } from "reactstrap";
import CreateAdditionalPaymentDropdown from "../../../../../../components/dropdowns/CreateAdditionalPaymentDropdown";
import { useAppValue } from "../../../../../../contexts/Context";

export const BulkDebitComplete = () => {
  const [context] = useAppValue();
  const history = useHistory();
  const [state] = useSimpleContext();
  const totalAmount = state.totalAmountReceived;
  const numTransactions = state.transactions?.length || 0;
  const requiresApproval = !context.user?.isOwnerOrAdmin;
  const many = numTransactions > 1;

  return (
    <Fragment>
      <SuccessIcon />
      <h2 className="text-center">
        {requiresApproval
          ? `Draft ${pluralize("payment request", many)} created`
          : `${pluralize("Payment request", many)} sent`}
      </h2>
      <div className="new-payment-inner-content text-center pt-0">
        <span>
          You {requiresApproval ? "created" : "requested"}{" "}
          <b>
            {numTransactions} {pluralize("payment request", many)}
          </b>{" "}
          totalling <b>{formattedDollarValue(totalAmount)}</b>.{" "}
          {requiresApproval
            ? `An account admin will receive a notification to approve pending payment requests.`
            : " You will receive a confirmation email when a request has been paid."}
        </span>
        <Button
          onClick={() => history.push("/admin/payments")}
          block
          className="btn-primary mt-4"
        >
          All done, return to dashboard
        </Button>
        <Row className="mt-3">
          <Col xs="5" className="ml-auto pl-0">
            <hr />
          </Col>
          <Col xs="1">
            <span>or</span>
          </Col>
          <Col xs="5" className="mr-auto pr-0">
            <hr />
          </Col>
        </Row>
        <CreateAdditionalPaymentDropdown />
      </div>
    </Fragment>
  );
};
