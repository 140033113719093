import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";
import { host } from "../client";

export async function updateDisplayImage(
  input,
  output,
  { context, errorHandler }
) {
  let file = input.file;
  let formData = new FormData();
  let operations = {
    query:
      "mutation ( $file: Upload ) { setCompanyProfile ( input: { displayImage: $file } ) { company { identifier, name, displayImage } authorizationError { message } authenticationError { message } } }",
    variables: { file: null },
  };
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", '{"nFile": ["variables.file"]}');
  formData.append("nFile", file);

  let response = await fetch(host, {
    method: "POST",
    headers: {
      Authorization: `JWT ${context.token}`,
    },
    body: formData,
  });
  let data = await response.json();
  return errorHandler(data);
}

export function setCompanyProfile(input, output, hooks) {
  const query = buildMutation(input, output, "setCompanyProfile");
  return callMutation(query, hooks);
}

setCompanyProfile.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function createCompanyInvite(input, output, hooks) {
  const query = buildMutation(input, output, "createCompanyInvite");
  return callMutation(query, hooks);
}

createCompanyInvite.propTypes = {
  input: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function deleteCompanyInvite(input, output, hooks) {
  const query = buildMutation(input, output, "deleteCompanyInvite");
  return callMutation(query, hooks);
}

deleteCompanyInvite.propTypes = {
  input: PropTypes.shape({
    companyInviteId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function deleteCompanyMember(input, output, hooks) {
  const query = buildMutation(input, output, "deleteCompanyMember");
  return callMutation(query, hooks);
}

deleteCompanyMember.propTypes = {
  input: PropTypes.shape({
    companyInviteId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function setCompanySettings(input, output, hooks) {
  const query = buildMutation(input, output, "setCompanySettings");
  return callMutation(query, hooks);
}

setCompanySettings.propTypes = {
  input: PropTypes.shape({
    securityQuestion: PropTypes.string,
    securityAnswer: PropTypes.string,
    useSecurityQuestion: PropTypes.bool,
    requireNotes: PropTypes.bool,
    requiredPaymentApprovals: PropTypes.Integer,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function changeMemberPermissions(input, output, hooks) {
  const query = buildMutation(input, output, "updateCompanyMember");
  return callMutation(query, hooks);
}
changeMemberPermissions.propTypes = {};
