/* eslint-disable react/prop-types */
import React from "react";

function FilterIcon(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || "100%";
  const height = props.height || "100%";
  const title = props.title || "FilterIcon";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap="square"
        strokeWidth={strokewidth}
      >
        <polyline
          fill="none"
          points="45.377 10.423 28 31 28 46 20 46 20 31 2.623 10.423"
          strokeLinecap="butt"
        />
        <ellipse cx="24" cy="9" fill="none" rx="22" ry="6" stroke={fill} />
      </g>
    </svg>
  );
}

export default FilterIcon;
