import React from "react";
import { Col, Row } from "reactstrap";
// import bbb from "../../assets/img/trust/BBB-Grey.png";
// import bmo from "../../assets/img/trust/BMO-Grey.png";
import chb from "../../assets/img/trust/CHBA-Grey.png";
import fintrac from "../../assets/img/trust/Fintrac-Grey.png";
import ncfa from "../../assets/img/trust/NCFA-Grey.png";
import bsia from "../../assets/img/trust/BSIA-Grey.png";
import rbc from "../../assets/img/trust/RBC-Grey.png";

import paymentsCanada from "../../assets/img/trust/Payments-Canada-Grey.png";

function TrustFooter() {
  return (
    <div className="trust-footer d-flex flex-column mt-auto hide-on-print desktop">
      <div className="m-auto onboarding-footer" style={{ maxWidth: "1000px" }}>
        <h5
          className="text-center text-uppercase font-weight-bold text-muted mb-3"
          style={{ letterSpacing: 1 }}
        >
          Trusted and regulated Across Canada
        </h5>
        <Row className="text-center justify-content-center flex-nowrap">
          <Col>
            <img
              src={bsia}
              alt="Balsillie School of International Affairs"
              width="50"
            />
          </Col>

          <Col>
            <img src={rbc} alt="Royal Bank of Canada Logo" width="40" />
          </Col>
          <Col>
            <img
              src={chb}
              alt="Canadian Home Builders Association Logo"
              width="100"
            />
          </Col>
          <Col>
            <img src={fintrac} alt="Fintrac Canafe Logo" width="100" />
          </Col>
          <Col>
            <img
              src={ncfa}
              alt="National Crowdfunding & Fintech Association Logo"
              width="110"
              className="mt-2"
            />
          </Col>
          <Col>
            <img
              src={paymentsCanada}
              alt="Payments Canada Logo"
              width="100"
              className="mt-2"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TrustFooter;
