import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/divdot-dashboard.scss?v=1.0.0";
import "assets/demo/demo.css";
import "react-notification-alert/dist/animate.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import AdminError from "./modules/error_wrappers/AdminError";
import { ToastProvider } from "react-toast-notifications";

const sentry_env = (() => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
    case "prod":
      return "PRODUCTION";
    case "develop":
    case "development":
      return "DEVELOPMENT";
    case "state":
    case "staging":
      return "STAGING";
    case "graphql":
    case "feature":
      return "GRAPHQL";
    case "demo":
      return "DEMO";
    default:
      return "LOCAL";
  }
})();

Sentry.init({
  dsn: "https://fe128d31dec443b9b55bf29cbfa67879@o4504170120151040.ingest.sentry.io/4504170470047744",
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        "https://api.dev.jetpay.baselinepayments.com/graphql/",
        "https://api.demo.jetpay.baselinepayments.com/graphql/",
        "https://api.jetpay.baselinepayments.com/graphql/",
      ],
      networkRequestHeaders: ["Content-Type"],
      networkResponseHeaders: ["Content-Type"],
    }),
  ],
  environment: sentry_env,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production

  // tracesSampleRate sets a header which throws a CORS error when pointed at localhost api
  ...(process.env.REACT_APP_ENV !== "local" && { tracesSampleRate: 1.0 }),

  denyUrls: [/hubspot\.com/i, /google-analytics\.com/i, /hotjar\.com/i],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

const myFallback = <AdminError />;

// Disable error boundary in local environment
const app = process.env.REACT_APP_ENV ? (
  <ToastProvider>
    <Sentry.ErrorBoundary fallback={myFallback}>
      <App />
    </Sentry.ErrorBoundary>
  </ToastProvider>
) : (
  <ToastProvider>
    <Sentry.ErrorBoundary fallback={myFallback}>
      <App />
    </Sentry.ErrorBoundary>
  </ToastProvider>
);

ReactDOM.render(app, document.getElementById("root"));
