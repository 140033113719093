import { CustomTooltip } from "../../modules/Helpers";
import { Button } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

function PermissionsTooltip(props) {
  return (
    <CustomTooltip
      title={
        <>
          <div>Permissions determine what a user can and cannot do.</div>
          <br />
          <ul>Owner: full access to everything.</ul>
          <ul>
            Admin: access to everything except cannot add users or tokens.
          </ul>
          <ul>
            User: more restricted access and payables require approval from
            admin/owner.
          </ul>
        </>
      }
      placement="top"
      arrow
      open={props.permissionsTooltipOpen}
      onOpen={() => props.setPermissionsTooltipOpen(true)}
      onClose={() => props.setPermissionsTooltipOpen(false)}
      leaveTouchDelay={10000}
    >
      <Button
        onClick={() =>
          props.setPermissionsTooltipOpen(!props.permissionsTooltipOpen)
        }
        variant="contained"
        className="btn-link tooltip-button"
      >
        <i className="fas fa-info-circle" />
      </Button>
    </CustomTooltip>
  );
}

export default PermissionsTooltip;

PermissionsTooltip.propTypes = {
  permissionsTooltipOpen: PropTypes.bool,
  setPermissionsTooltipOpen: PropTypes.func,
};
