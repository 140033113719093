import React, { Fragment, useEffect, useState } from "react";
import CustomerTransactionTable from "components/tables/CustomerTransactionTable";

import ExportTransactionsModal from "components/modals/ExportTransactionsModal";
import TransactionFilters from "components/modals/TransactionFilters";
import { Button, UncontrolledCollapse } from "reactstrap";
import ExportIcon from "assets/icons/ExportIcon";
import CustomTableSearch from "components/forms/CustomTableSearch";
import { useAppValue } from "contexts/Context";
import FilterIcon from "assets/icons/FilterIcon";
import { useRouteMatch } from "react-router-dom";

function CustomerTransactions(props) {
  let identifier = useRouteMatch().params.identifier;
  const [filters, setFilters] = useState({
    contactId: identifier,
  });
  const [exportTransactionsModal, setExportTransactionsModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [context] = useAppValue();

  useEffect(() => {
    let count = 0;
    if (filters.states) {
      count++;
    }
    setFilterCount(count);
  }, [filters]);

  function toggleExportTransactionsModal() {
    setExportTransactionsModal(!exportTransactionsModal);
  }

  return (
    <Fragment>
      <div className="main-panel my-sm">
        <ExportTransactionsModal
          toggle={toggleExportTransactionsModal}
          isOpen={exportTransactionsModal}
          filters={filters}
        />
        <div className="content">
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex justify-content-between">
              <h2 className="mb-3 font-weight-600 page-title">
                Payments{" "}
                <span className="font-weight-500 text-muted text-xl">
                  ({context.transactionCount})
                </span>
              </h2>
            </div>

            <div className="d-flex flex-row flex-grow-1">
              <CustomTableSearch setFilters={setFilters} filters={filters} />
              <div className="ml-3">
                <Button
                  className="btn-simple btn-primary sp-button-simple"
                  id="buttonToggler"
                >
                  <FilterIcon width={15} height={15} strokewidth={3} />{" "}
                  <span className="filter-btn-label">Filter</span>
                  {filterCount > 0 && (
                    <Fragment>
                      {" "}
                      <span className="text-warning">{filterCount}</span>
                    </Fragment>
                  )}
                </Button>
              </div>
              <div className="desktop">
                <Button
                  className="btn-primary sp-button"
                  onClick={toggleExportTransactionsModal}
                >
                  <ExportIcon height={13} width={13} /> <span>Export</span>
                </Button>
              </div>
            </div>
            <UncontrolledCollapse toggler="#buttonToggler">
              <TransactionFilters
                customer={true}
                filters={filters}
                setFilters={setFilters}
              />
            </UncontrolledCollapse>
          </div>
          <CustomerTransactionTable
            {...props}
            customer={true}
            filters={filters}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default CustomerTransactions;
