/* eslint-disable react/prop-types */
import React from "react";

import { useSimpleContext } from "../contexts/SimpleContext";
import { PageNotAvailableError } from "../modules/error_wrappers/PageNotAvailableError";

export function CreditCardsGateway(props) {
  const [state] = useSimpleContext();
  const creditCardsEnabled =
    state.transaction?.company.settings.ccEnabled ||
    state.recurringPlan?.company.settings.ccEnabled;
  if (!creditCardsEnabled) {
    return (
      <PageNotAvailableError errorBodyText="Sorry, credit cards are not enabled for this transaction." />
    );
  }

  return props.children;
}
