import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function setUserProfile(input, output, hooks) {
  const query = buildMutation(input, output, "setUserProfile");
  return callMutation(query, hooks);
}

setUserProfile.propTypes = {
  input: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function changePassword(input, output, hooks) {
  const query = buildMutation(input, output, "changePassword");
  return callMutation(query, hooks);
}

changePassword.propTypes = {
  input: PropTypes.shape({
    password: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
