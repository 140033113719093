import { Button, Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import useGQL from "../../../api_client/UseGQL";
import { allCompanyNotifications } from "../../../api_client/queries/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { convertToDate } from "../../../modules/Helpers";
import { setCompanyNotificationRead } from "../../../api_client/mutations/notifications";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import QueryString from "query-string";
import { Waypoint } from "react-waypoint";
import NotificationActionsDropdown from "../../../components/dropdowns/NotificationActionsDropdown";
import PropTypes from "prop-types";
import NotificationActionsDesktop from "./NotificationActionsDesktop";

function Notifications(props) {
  const [notificationCount, setNotificationCount] = useState(20);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  let gqlHooks = useGQL();
  const { addToast } = useToasts();
  const [update, setUpdate] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.update) {
      setUpdate(!update);
      history.push({ search: "" });
    }
  }, [location]);

  useEffect(() => {
    setLoading(true);
    props.setBackdropClick(true);
    let output = {
      identifier: true,
      body: true,
      read: true,
      objIdentifier: true,
      createdAt: true,
    };
    allCompanyNotifications(
      { limit: notificationCount },
      output,
      gqlHooks
    ).then((response) => {
      if (response) {
        setNotifications(response.data);
        setLoading(false);
        props.setBackdropClick(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationCount]);

  async function readNotification(notification) {
    setLoading(true);
    props.setBackdropClick(true);
    let input = {
      identifiers: [notification.identifier],
    };
    let output = {
      ok: true,
    };
    setCompanyNotificationRead(input, output, gqlHooks).then((response) => {
      if (response) {
        if (response.ok) {
          // TODO api should return notification type to avoid this logic
          if (notification.objIdentifier) {
            history.push({
              search: `overlay=true&type=payment&id=${notification.objIdentifier}`,
            });
          } else if (notification.body.toLowerCase().includes("draft")) {
            history.push("/bulk-payment/credit/approve/select");
          }
        } else {
          addToast("Error viewing notification", {
            appearance: "error",
            autoDismiss: true,
          });
        }
        setLoading(false);
        props.setBackdropClick(false);
      }
    });
  }

  async function readAllNotifications() {
    setLoading(true);
    props.setBackdropClick(true);
    let input = {
      identifiers: notifications.map((notification) => {
        return notification.identifier;
      }),
    };
    let output = {
      ok: true,
    };
    setCompanyNotificationRead(input, output, gqlHooks).then((response) => {
      if (response) {
        if (response.ok) {
          addToast("Marked all notifications as read", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast("Error marking all notifications as read", {
            appearance: "error",
            autoDismiss: true,
          });
        }
        setLoading(false);
        props.setBackdropClick(false);
        if (!loading) {
          history.goBack();
        }
      }
    });
  }

  const handleBottomScroll = () => {
    setNotificationCount(() => notificationCount + 10);
  };

  return (
    <>
      <Row>
        <Col className="col-sm col-sm-6 col-md-3">
          <h3 className="font-weight-600 mt-1 mb-4">Notifications</h3>
        </Col>
        <Col>
          <Button
            className="btn-link details-close-btn"
            disabled={loading}
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div className="details-desktop-actions">
            <NotificationActionsDesktop
              readAllNotifications={readAllNotifications}
            />
          </div>
          <div className="details-mobile-actions">
            <NotificationActionsDropdown
              readAllNotifications={readAllNotifications}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="shadow-sm border flex-column">
            <CardBody className="p-0">
              {notifications.map((notification) => (
                <div
                  key={notification.identifier}
                  onClick={() => readNotification(notification)}
                >
                  <Row
                    className={`notification-row border-bottom ${
                      !notification.read ? "bg-light" : null
                    }`}
                  >
                    <Col>
                      <div className="notification-wrapper">
                        <div className="notification-icon">
                          <i className="tim-icons icon-money-coins" />
                        </div>
                        <div>
                          <p>
                            {notification.body}{" "}
                            <span className="text-muted d-inline-block">
                              {convertToDate(notification.createdAt)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
              {notifications.length === 0 && !loading && (
                <p className="text-center text text-muted mt-4 mb-4">
                  No notifications
                </p>
              )}
            </CardBody>
          </Card>
          <Waypoint onEnter={() => handleBottomScroll()} />
        </Col>
      </Row>
    </>
  );
}

export default Notifications;

Notifications.propTypes = {
  setBackdropClick: PropTypes.func,
};
