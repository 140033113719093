import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faEllipsisH,
  faPen,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";

// import { useHistory } from "react-router-dom";

function ContactActionsMobileDropdown(props) {
  // let history = useHistory();
  return (
    <>
      <UncontrolledDropdown className="pull-right">
        <div className="details-action-wrapper">
          <DropdownToggle className="details-more-btn" nav>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
        </div>
        <DropdownMenu
          right
          className="custom-dropdown-menu details-actions-dropdown"
        >
          {/*{props.contact.archived === false && (*/}
          {/*  <>*/}
          {/*    <DropdownItem>*/}
          {/*      <div>*/}
          {/*        <Button*/}
          {/*          className="btn-link"*/}
          {/*          onClick={() =>*/}
          {/*            history.push("/new-payment/credit/details", {*/}
          {/*              contact: props.contact,*/}
          {/*              currStep: 1,*/}
          {/*              steps: 6,*/}
          {/*            })*/}
          {/*          }*/}
          {/*        >*/}
          {/*          <FontAwesomeIcon icon={faArrowRight} />*/}
          {/*          <span className="d-inline-block ml-3 font-weight-600">*/}
          {/*            Send payment*/}
          {/*          </span>*/}
          {/*        </Button>*/}
          {/*      </div>*/}
          {/*    </DropdownItem>*/}
          {/*    <hr />*/}
          {/*    <DropdownItem>*/}
          {/*      <div>*/}
          {/*        <Button*/}
          {/*          className="btn-link"*/}
          {/*          onClick={() =>*/}
          {/*            history.push("/new-payment/debit/frequency", {*/}
          {/*              contact: props.contact,*/}
          {/*              currStep: 1,*/}
          {/*              steps: 8,*/}
          {/*            })*/}
          {/*          }*/}
          {/*        >*/}
          {/*          <FontAwesomeIcon icon={faArrowLeft} />*/}
          {/*          <span className="d-inline-block ml-3 font-weight-600">*/}
          {/*            Receive payment*/}
          {/*          </span>*/}
          {/*        </Button>*/}
          {/*      </div>*/}
          {/*    </DropdownItem>*/}
          {/*    <hr />*/}
          {/*  </>*/}
          {/*)}*/}
          {props.contact.archived === true ? (
            <>
              <DropdownItem onClick={props.toggleArchive}>
                <span className="d-inline-block font-weight-600">
                  <FontAwesomeIcon className="mr-3" icon={faPowerOff} />
                  Activate contact
                </span>
              </DropdownItem>
            </>
          ) : (
            <>
              <DropdownItem onClick={props.editContact}>
                <span className="d-inline-block font-weight-600">
                  <FontAwesomeIcon className="mr-3" icon={faPen} />
                  Edit contact
                </span>
              </DropdownItem>
              <hr />
              <DropdownItem onClick={props.toggleArchive}>
                <span className="d-inline-block font-weight-600">
                  <FontAwesomeIcon className="mr-3" icon={faArchive} />
                  Archive contact
                </span>
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default ContactActionsMobileDropdown;
ContactActionsMobileDropdown.propTypes = {
  contact: PropTypes.object,
  editContact: PropTypes.func,
  toggleArchive: PropTypes.func,
};
