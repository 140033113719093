import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, FormFeedback, FormGroup, Input } from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { saveBankModalFormValidation } from "../../../modules/form_validation/BankInfoModalFormValidation";
import {
  bankAccountEmailMfa,
  bankAccountTextMfa,
} from "../../../api_client/mutations/bankAccount";
import useGQL from "../../../api_client/UseGQL";
import { appName, verifyEmail } from "../../../modules/Helpers";
import { useSimpleContext } from "../../../contexts/SimpleContext";

function GetMFACodeForm(props) {
  const { values, handleChange, handleSubmit, waitErrors } = useForm(
    submit,
    saveBankModalFormValidation
  );
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const [state, setState] = useSimpleContext();

  async function submit() {
    setLoading(true);
    let input;
    let response;
    let output = {
      ok: true,
    };
    if (verifyEmail(values.contactMethod)) {
      input = { email: values.contactMethod };
      response = await bankAccountEmailMfa(input, output, gqlHooks);
      if (response && response.ok) {
        setState({ ...state, contactMethod: values.contactMethod });
        props.setEnterMfa(true);
      }
    } else {
      input = { phone: values.contactMethod };
      response = await bankAccountTextMfa(input, output, gqlHooks);
      if (response && response.ok) {
        setState({ ...state, contactMethod: values.contactMethod });
        props.setEnterMfa(true);
      }
    }
    setLoading(false);
  }
  return (
    <Fragment>
      <h4>Your saved account details</h4>
      <p>
        {appName} can save account details to speed up your checkout process.
      </p>
      <p>
        For security reasons, we&apos;ll send you a confirmation code to the{" "}
        <b>mobile phone</b> or <b>email address</b> associated with your
        accounts.
      </p>
      <div className="mt-4">
        <label>Phone or Email</label>
        <FormGroup>
          <Input
            aria-label="Phone or email"
            bsSize="lg"
            className="rounded"
            name="contactMethod"
            type="text"
            value={values.contactMethod || ""}
            onChange={handleChange}
            invalid={!!waitErrors.contactMethod}
            required
          />
          <FormFeedback>{waitErrors.contactMethod}</FormFeedback>
        </FormGroup>
      </div>
      <div className="pb-4 pt-0">
        <Button
          disabled={loading || !values.contactMethod}
          onClick={handleSubmit}
          block
          className="btn-primary btn-lg text-white mt-0 mb-0"
        >
          Send confirmation code
        </Button>
      </div>
    </Fragment>
  );
}

export default GetMFACodeForm;

GetMFACodeForm.propTypes = {
  setEnterMfa: PropTypes.func,
};
