import React, { Fragment } from "react";
import { UncontrolledAlert } from "reactstrap";
import PropTypes from "prop-types";

function CustomGenericBanner(props) {
  return (
    <Fragment>
      <UncontrolledAlert
        color="white"
        className={`announcement-banner shadow-sm ${props.customClass}`}
      >
        <h4>{props.title}</h4>
        <h3>{props.subtitle}</h3>
        <>{props.text}</>
        {props.actionButton}
      </UncontrolledAlert>
    </Fragment>
  );
}

export default CustomGenericBanner;

CustomGenericBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  actionButton: PropTypes.object.isRequired,
  customClass: PropTypes.string,
};
