import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import AddIcon from "assets/icons/AddIcon";
const { useAppValue } = require("contexts/Context");

function CreditWithdrawalNewAccountButton(props) {
  const [context] = useAppValue();
  return (
    <>
      {!context.user.company?.isUsingConverge && (
        <Button
          color="links"
          className="btn-link new-payment-new-account-btn"
          onClick={() => props.setModal(true)}
        >
          <AddIcon /> New account
        </Button>
      )}
    </>
  );
}

export default CreditWithdrawalNewAccountButton;

CreditWithdrawalNewAccountButton.propTypes = {
  setModal: PropTypes.func.isRequired,
};
