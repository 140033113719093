import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { withApiValue } from "../../modules/WithApiValue";
import ReviewBillsTable from "../tables/ReviewBillsTable";
import { useSimpleContext } from "../../contexts/SimpleContext";
import { CustomTooltip } from "../../modules/Helpers";
import { useHistory } from "react-router";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const config = {
  company: {
    railzConnection: {
      serviceName: true,
    },
  },
};

function ReviewBillsOverlay(props) {
  const [state] = useSimpleContext();
  const history = useHistory();

  return (
    <Fragment>
      <Dialog
        fullScreen
        className="modal-overlay"
        open={props.isOpen}
        onClose={props.toggle}
        TransitionComponent={Transition}
      >
        <div className="wrapper">
          <div className="main-panel bg-white">
            <div className="content">
              <Row>
                <Col>
                  <Button
                    className="btn-link details-close-btn"
                    onClick={props.toggle}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                  {location.pathname.includes("review-payment") && (
                    <div className="details-action-wrapper">
                      <CustomTooltip
                        title="Edit bills"
                        placement="bottom"
                        arrow
                      >
                        <Button
                          className="btn-link details-close-btn mr-3"
                          onClick={() =>
                            history.push("import", {
                              ...history.location.state,
                              currStep: 2,
                            })
                          }
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: "1.2rem", marginTop: "5px" }}
                            icon={faPen}
                          />
                        </Button>
                      </CustomTooltip>
                    </div>
                  )}
                </Col>
              </Row>
              <div className="d-flex flex-row justify-content-between">
                <h2 className="mb-3 font-weight-600 page-title">
                  {location.pathname.includes("review-payment")
                    ? "Review bills"
                    : "Paid bills"}{" "}
                  <span className="text-muted font-weight-300">
                    ({state.bills.length}) |
                  </span>{" "}
                  <span className="text-success">
                    {props.company.railzConnection?.serviceName.capitalize()}
                  </span>
                </h2>
              </div>
              <ReviewBillsTable />
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}

export default withApiValue(ReviewBillsOverlay, config, () => null);

ReviewBillsOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  company: PropTypes.object,
};
