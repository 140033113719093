import {
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PAD_TYPE_OPTIONS } from "../../../../../modules/Helpers";
import Select from "react-select";
import Cheque from "../../../../../assets/img/Cheque.png";

function ManualBankForm(props) {
  const [accountType, setAccountType] = useState(PAD_TYPE_OPTIONS[0]);

  useEffect(() => {
    Object.keys(props.refs)
      .reverse()
      .forEach((key) => {
        if (!props.uneditable.includes(key)) {
          if (props.refs[key].current !== null) {
            props.refs[key].current.focus();
          }
        }
      });
  }, []);

  useEffect(() => {
    props.setValues({ ...props.values, accountType: accountType.value });
  }, [accountType.value]);

  return (
    <Fragment>
      <CardBody className={props.white && "white-form"}>
        <Row>
          <img src={Cheque} alt="Cheque image" className="manual-bank-cheque" />
        </Row>
        <Row>
          <Col
            sm="3"
            className={
              props.uneditable.includes("institutionNumber") && "d-none"
            }
          >
            <Label>Institution #</Label>
            <FormGroup>
              <Input
                aria-label="Institution number"
                name="institutionNumber"
                type="text"
                innerRef={props.refs.institutionNumber}
                value={props.values.institutionNumber || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.institutionNumber}
                required
                readOnly={props.uneditable.includes("institutionNumber")}
              />
              <FormFeedback>{props.errors.institutionNumber}</FormFeedback>
            </FormGroup>
          </Col>
          <Col
            sm="4"
            className={props.uneditable.includes("transitNumber") && "d-none"}
          >
            <Label>Transit #</Label>
            <FormGroup>
              <Input
                aria-label="Transit number"
                name="transitNumber"
                type="text"
                innerRef={props.refs.transitNumber}
                value={props.values.transitNumber || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.transitNumber}
                required
                readOnly={props.uneditable.includes("transitNumber")}
              />
              <FormFeedback>{props.errors.transitNumber}</FormFeedback>
            </FormGroup>
          </Col>
          <Col
            sm="5"
            className={props.uneditable.includes("accountNumber") && "d-none"}
          >
            <Label>Account #</Label>
            <FormGroup>
              <Input
                aria-label="Account number"
                name="accountNumber"
                type={props.hideAccountNumber ? "password" : "text"}
                innerRef={props.refs.accountNumber}
                value={props.values.accountNumber || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.accountNumber}
                required
                readOnly={props.uneditable.includes("accountNumber")}
              />
              <FormFeedback>{props.errors.accountNumber}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col
            sm="6"
            className={props.uneditable.includes("title") && "d-none"}
          >
            <Label>Account title</Label>
            <FormGroup>
              <Input
                aria-label="Account title"
                name="title"
                type="text"
                innerRef={props.refs.title}
                value={props.values.title || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.title}
                required
                readOnly={props.uneditable.includes("title")}
              />
              <FormFeedback>{props.errors.title}</FormFeedback>
            </FormGroup>
          </Col>
          <Col
            sm="6"
            className={props.uneditable.includes("accountType") && "d-none"}
          >
            <Label>Account type</Label>
            <FormGroup>
              <Select
                aria-label="Account type"
                onChange={(option) => setAccountType(option)}
                className="react-select default"
                classNamePrefix="react-select"
                name="accountType"
                value={accountType}
                options={PAD_TYPE_OPTIONS.reverse()}
                readOnly={props.uneditable.includes("accountType")}
              />
            </FormGroup>
          </Col>
          <Col
            sm="6"
            className={props.uneditable.includes("institution") && "d-none"}
          >
            <Label>Institution name</Label>
            <FormGroup>
              <Input
                aria-label="Institution name"
                name="institution"
                type="text"
                innerRef={props.refs.institution}
                value={props.values.institution || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.institution}
                required
                readOnly={props.uneditable.includes("institution")}
              />
              <FormFeedback>{props.errors.institution}</FormFeedback>
            </FormGroup>
          </Col>
          <Col
            sm="6"
            className={props.uneditable.includes("holderName") && "d-none"}
          >
            <Label>Account holder name</Label>
            <FormGroup>
              <Input
                aria-label="Account holder name"
                name="holderName"
                type="text"
                innerRef={props.refs.holderName}
                value={props.values.holderName || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.holderName}
                required
                readOnly={props.uneditable.includes("holderName")}
              />
              <FormFeedback>{props.errors.holderName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col
            sm="6"
            className={props.uneditable.includes("holderEmail") && "d-none"}
          >
            <Label>Account holder email</Label>
            <FormGroup>
              <Input
                aria-label="Account holder email"
                name="holderEmail"
                type="email"
                innerRef={props.refs.holderEmail}
                value={props.values.holderEmail || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.holderEmail}
                required
                readOnly={props.uneditable.includes("holderEmail")}
              />
              <FormFeedback>{props.errors.holderEmail}</FormFeedback>
            </FormGroup>
          </Col>
          <Col
            sm="6"
            className={props.uneditable.includes("holderAddress") && "d-none"}
          >
            <Label>Account holder address</Label>
            <FormGroup>
              <Input
                aria-label="Account holder address"
                name="holderAddress"
                type="text"
                innerRef={props.refs.holderAddress}
                value={props.values.holderAddress || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.holderAddress}
                required
                readOnly={props.uneditable.includes("holderAddress")}
              />
              <FormFeedback>{props.errors.holderAddress}</FormFeedback>
            </FormGroup>
          </Col>
          <Col
            sm="6"
            className={
              props.uneditable.includes("holderAddressPostalCode") && "d-none"
            }
          >
            <Label>Account holder postal code</Label>
            <FormGroup>
              <Input
                aria-label="Account holder postal code"
                name="holderAddressPostalCode"
                type="text"
                innerRef={props.refs.holderAddressPostalCode}
                value={props.values.holderAddressPostalCode || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.holderAddressPostalCode}
                required
                readOnly={props.uneditable.includes("holderAddressPostalCode")}
              />
              <FormFeedback>
                {props.errors.holderAddressPostalCode}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col sm="6">
            <Label>Account holder city</Label>
            <FormGroup
              className={
                props.uneditable.includes("holderAddressCity") && "d-none"
              }
            >
              <Input
                aria-label="Account holder city"
                name="holderAddressCity"
                type="text"
                innerRef={props.refs.holderAddressCity}
                value={props.values.holderAddressCity || ""}
                onChange={props.handleChange}
                invalid={!!props.errors.holderAddressCity}
                required
                readOnly={props.uneditable.includes("holderAddressCity")}
              />
              <FormFeedback>{props.errors.holderAddressCity}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Fragment>
  );
}

export default ManualBankForm;

ManualBankForm.defaultProps = {
  uneditable: [],
};

ManualBankForm.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  hideAccountNumber: PropTypes.bool.isRequired,
  accountState: PropTypes.string,
  refs: PropTypes.shape({
    institutionNumber: PropTypes.object,
    transitNumber: PropTypes.object,
    accountNumber: PropTypes.object,
    institution: PropTypes.object,
    title: PropTypes.object,
    accountType: PropTypes.object,
    holderName: PropTypes.object,
    holderEmail: PropTypes.object,
    holderAddress: PropTypes.object,
    holderAddressPostalCode: PropTypes.object,
    holderAddressCity: PropTypes.object,
  }),
  uneditable: PropTypes.array,
  white: PropTypes.bool,
};
