import { verifyLength } from "../Helpers";

export function securityQuestionFormValidation(values, errors) {
  if (values.securityQuestion) {
    if (verifyLength(values.securityQuestion, 80)) {
      errors.securityQuestion =
        "Security question cannot be longer than 80 characters";
    } else {
      delete errors.securityQuestion;
    }
  }

  if (values.securityAnswer) {
    if (verifyLength(values.securityAnswer, 80)) {
      errors.securityAnswer =
        "Security answer cannot be longer than 80 characters";
    } else {
      delete errors.securityQuestion;
    }
  }

  return errors;
}
