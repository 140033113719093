import { mixpanelEvent } from "../api_client/mutations/mixpanel";

const GTAG_ONBOARDING_COMPLETE_CONV_ID = "-1JcCIOTp_kBEPiK-r0C";
const GTAG_REPORT_SIGNUP_CONV_ID = "lwXjCNeNx_wBEPiK-r0C";

export const trackEvent = (event, properties, gqlHooks) => {
  let output = {
    ok: true,
  };
  let data = { ...properties };
  let input = {
    eventName: event,
    data: JSON.stringify(data),
  };
  mixpanelEvent(input, output, gqlHooks);
};

function gtag_report_signup(url) {
  if (
    !process.env.REACT_APP_GOOGLE_ANALYTICS_AW ||
    process.env.REACT_APP_GOOGLE_ANALYTICS_AW === "none"
  ) {
    return;
  }

  const callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  window.gtag("event", "conversion", {
    send_to: `${process.env.REACT_APP_GOOGLE_ANALYTICS_AW}/${GTAG_REPORT_SIGNUP_CONV_ID}`,
    event_callback: callback,
  });
  return false;
}

function gtag_report_onboarding_complete(url) {
  if (
    !process.env.REACT_APP_GOOGLE_ANALYTICS_AW ||
    process.env.REACT_APP_GOOGLE_ANALYTICS_AW === "none"
  ) {
    return;
  }

  const callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  window.gtag("event", "conversion", {
    send_to: `${process.env.REACT_APP_GOOGLE_ANALYTICS_AW}/${GTAG_ONBOARDING_COMPLETE_CONV_ID}`,
    event_callback: callback,
  });
  return false;
}

export const reportSignUp = (sendUetq) => {
  if (window.gtag) {
    window.gtag("event", "Sign Up");
    gtag_report_signup();
  }

  if (window.fbq) {
    window.fbq("track", "Sign Up");
  }

  if (window.uetq && sendUetq) {
    window.uetq.push("event", "page_view", { page_path: "/register/" });
    window.uetq.push("event", "Sign Up", {
      event_category: "Registration",
      event_label: "MQL",
      event_value: "1",
    });
  }
};

export const reportOnboardingComplete = (gqlHooks) => {
  trackEvent("onboardingComplete", {}, gqlHooks);
  if (window.gtag) {
    window.gtag("event", "Onboarding Complete");
    gtag_report_onboarding_complete();
  }

  if (window.fbq) {
    window.fbq("track", "Onboarding Complete");
  }

  if (window.uetq) {
    window.uetq.push("event", "Onboarding Complete", {
      event_category: "Registration",
      event_label: "SQL",
      event_value: "2",
    });
  }
};

export const reportContactCreated = (gqlHooks) => {
  trackEvent("contactCreated", {}, gqlHooks);
  if (window.gtag) {
    window.gtag("event", "contact_created");
  }
};

export const reportVerificationComplete = (gqlHooks) => {
  trackEvent("onboardingVerificationComplete", {}, gqlHooks);
  if (window.gtag) {
    window.gtag("event", "Verification Complete");
  }
};

export const reportPageView = (location, gqlHooks) => {
  trackEvent(location.pathname, { page: location.pathname }, gqlHooks);
  if (window.gtag) {
    window.gtag("event", "page_view", { page_path: location.pathname });
  }
};

export const reportSetToken = () => {
  if (window.gtag) {
    window.gtag("event", "2FA Success");
  }
};

export const reportEmailVerificationResent = () => {
  if (window.gtag) {
    window.gtag("event", "Email Verification Resent");
  }
};
