import React, { useEffect, useState } from "react";
import QueryString from "query-string";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { useHistory, useLocation } from "react-router";
import useGQL, {
  useErrorHandlerGQL,
  useMixPanelGQL,
} from "../../../api_client/UseGQL";
import {
  acceptCredit,
  payDebit,
  payDebitViaCreditCard,
} from "../../../api_client/mutations/transaction";
import PaymentReviewDetails from "../components/PaymentReviewDetails";
import warningAlert from "../../../components/sweet_alert/WarningAlert";
import { Button } from "reactstrap";
import UnexpectedErrorAlert from "../../../components/sweet_alert/UnexpectedErrorAlert";
import { useGQLContext } from "../../../api_client/client";
import { trackEvent } from "../../../modules/analytics";
import creditCardErrorAlert from "components/sweet_alert/CreditCardErrorAlert";

function PaymentReviewStep() {
  const location = useLocation();
  let search = QueryString.parse(location.search);
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [, dispatch] = useGQLContext();
  const defaultErrorHandler = useErrorHandlerGQL(dispatch, false);
  const mixPanelGqlHooks = useMixPanelGQL();

  useEffect(() => {
    if (state.transaction.direction === "CREDIT") {
      trackEvent(
        "creditReview",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact?.identifier,
        },
        mixPanelGqlHooks
      );
    } else {
      trackEvent(
        "debitReview",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact?.identifier,
        },
        mixPanelGqlHooks
      );
    }
  }, []);

  function handlePaymentError(response) {
    const debitFieldErrors =
      response.data?.payDebit?.fieldErrors ||
      response.data?.payDebitViaCreditCard?.fieldErrors;
    if (debitFieldErrors) {
      var field = debitFieldErrors?.[0].field;
      var message = debitFieldErrors?.[0].message;
      if (field === "balance") {
        warningAlert(
          () => setAlert(null),
          setAlert,
          <>
            <p>
              The bank has returned a lower <b>available balance</b> than the
              amount of the transaction.
            </p>
            <p>
              This can happen if you recently deposited funds into the account
              in order to cover the cost of this payment.
            </p>
            <p>
              <b>Possible solutions:</b>
            </p>
            <ul className="text-left">
              <li>Wait 1-2 business days before making this payment</li>
              <li>
                <Button
                  className="btn-link btn-primary btn-sm pl-0"
                  onClick={() => {
                    setState({ ...state, bankAccount: undefined });
                    history.push("bank-transfer");
                  }}
                >
                  Use a different bank account to pay this transaction
                </Button>
              </li>
              <li>Use the digital cheque bank transfer option</li>
              <li>
                Contact your financial institution and ensure you have enough
                available funds
              </li>
            </ul>
            <Button onClick={() => setAlert(null)} className="btn-primary">
              Okay, I will resolve this
            </Button>
            <br />
            <small className="font-weight-bold">
              If you still need help please contact support
            </small>
          </>,
          "Insufficient available balance in account"
        );
      } else if (field === "creditCard") {
        creditCardErrorAlert(
          () => setAlert(null),
          setAlert,
          <>
            <p>
              The credit card processor has declined this charge due to the
              following error:
            </p>
            <br />
            <h2>{message}</h2>
          </>,
          "Card declined"
        );
      } else {
        UnexpectedErrorAlert(() => setAlert(null), setAlert);
      }
    } else if (response?.errors) {
      UnexpectedErrorAlert(() => setAlert(null), setAlert);
    } else {
      return defaultErrorHandler(response);
    }
  }
  const gqlHooks = useGQL(false, handlePaymentError);

  async function payTransactionViaBankTransfer() {
    setLoading(true);
    let input = {
      contactEmail: state.transaction.contact.email,
      contactName: state.transaction.contact.name || undefined,
      bankAccountToken: state.bankAccount.bankAccountToken,
      debitId: state.transaction.identifier,
      signaturePad: state.signaturePAD,
      signaturePadTime: state.signaturePADTime,
    };
    let output = {
      transaction: {
        identifier: true,
        estDepositDate: true,
        createdAt: true,
        updatedAt: true,
        fromBankAccount: {
          institution: true,
          title: true,
          accountNumber: true,
          institutionNumber: true,
        },
        states: {
          createdAt: true,
          updatedAt: true,
          state: true,
        },
      },
    };
    const response = await payDebit(input, output, gqlHooks);
    if (response) {
      if (response.transaction) {
        setState({
          ...state,
          transaction: {
            ...state.transaction,
            ...response.transaction,
          },
        });
        history.push("success");
      }
      setLoading(false);
    } else {
      trackEvent(
        "checkoutFailed",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact.identifier,
          errorData: response,
        },
        mixPanelGqlHooks
      );
      setLoading(false);
    }
  }

  async function payTransactionViaCreditCard() {
    setLoading(true);
    let input = {
      debitId: state.transaction.identifier,
      customerCreditCardId: state.transaction.customerCreditCard.identifier,
    };
    let output = {
      transaction: {
        identifier: true,
        estDepositDate: true,
        states: {
          createdAt: true,
          updatedAt: true,
          state: true,
        },
        updatedAt: true,
        customerCreditCard: {
          brand: true,
          last4: true,
        },
      },
    };
    const response = await payDebitViaCreditCard(input, output, gqlHooks);
    if (response) {
      if (response.transaction) {
        setState({
          ...state,
          transaction: {
            ...state.transaction,
            ...response.transaction,
          },
        });
        history.push("success");
      }
    } else {
      setLoading(false);
      //todo handle error case
    }
  }

  async function acceptTransaction() {
    setLoading(true);
    let input = {
      creditId: state.transaction.identifier,
      bankAccountToken: state.bankAccount.bankAccountToken,
      securityAnswer: state.securityAnswer,
    };
    let output = {
      transaction: {
        identifier: true,
        estDepositDate: true,
        states: {
          createdAt: true,
          updatedAt: true,
          state: true,
        },
        toBankAccount: {
          institution: true,
          title: true,
          accountNumber: true,
          institutionNumber: true,
        },
      },
    };
    const response = await acceptCredit(input, output, gqlHooks);
    if (response) {
      if (response.transaction) {
        setState({
          ...state,
          transaction: {
            ...state.transaction,
            ...response.transaction,
          },
        });
        history.push("success");
      }
    } else {
      setLoading(false);
      //todo handle error case
    }
  }

  if (search.paymentMethod === "creditCard") {
    return (
      <>
        {alert}
        <PaymentReviewDetails
          paymentMethod="Credit card"
          paymentMethodBrand={state?.transaction?.customerCreditCard?.brand}
          paymentMethodNumber={`Card ending in ${state?.transaction?.customerCreditCard?.last4}`}
          paymentCharges={true}
          payDebitAction={payTransactionViaCreditCard}
          loading={loading}
        />
      </>
    );
  } else {
    return (
      <>
        {alert}
        <PaymentReviewDetails
          paymentMethod="Bank transfer"
          paymentMethodBrand={state?.bankAccount?.institution}
          paymentMethodNumber={state?.bankAccount?.accountNumber}
          paymentCharges={false}
          payDebitAction={payTransactionViaBankTransfer}
          acceptCreditAction={acceptTransaction}
          loading={loading}
        />
      </>
    );
  }
}

export default PaymentReviewStep;

PaymentReviewStep.propTypes = {};
