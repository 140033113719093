import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Fade,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { setCompanySettings } from "../../../api_client/mutations/companyProfile";
import { useToasts } from "react-toast-notifications";
import useForm from "../../../hooks/UseForms";
import { securityQuestionFormValidation } from "../../../modules/form_validation/SecurityQuestionFormValidation";
import useGQL from "../../../api_client/UseGQL";
import { trackEvent } from "../../../modules/analytics";
import SecurityQuestion from "../../../assets/img/onboarding/SecurityQuestionUpdated.svg";
import SecurityQuestionComplete from "../../../assets/img/onboarding/SecurityQuestionCompleteUpdated.svg";
import { useHistory } from "react-router";
import { withApiValue } from "../../../modules/WithApiValue";
import { domains } from "modules/Helpers";
const config = {
  company: {
    settings: {
      securityQuestion: true,
      useSecurityQuestion: true,
    },
    kyc: {
      updateRequired: true,
      resubmitted: true,
    },
  },
};

function OnboardingSecurityQuestion(props) {
  const [updateSecurityQuestion, setUpdateSecurityQuestion] = useState(false);
  const [useSecurityQuestion, setUseSecurityQuestion] = useState(null);
  const { addToast } = useToasts();
  let gqlHooks = useGQL();
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    submit,
    securityQuestionFormValidation
  );
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      (props.company && props.company.kyc.updateRequired) ||
      (props.company && props.company.kyc.resubmitted)
    ) {
      history.push("/onboarding/identity");
    }

    if (props.company.settings.useSecurityQuestion) {
      setUpdateSecurityQuestion(false);
      setValues({
        securityQuestion: props.company.settings.securityQuestion,
      });
      setUseSecurityQuestion(true);
    } else {
      setUpdateSecurityQuestion(true);
    }
  }, []);

  async function submit() {
    setLoading(true);
    let output = {
      settings: {
        securityQuestion: true,
        useSecurityQuestion: true,
      },
    };
    const response = await setCompanySettings(
      { ...values, useSecurityQuestion: true },
      output,
      gqlHooks
    );
    setUpdateSecurityQuestion(false);
    if (response) {
      addToast("Updated security question settings successfully!", {
        appearance: "success",
        autoDismiss: true,
      });
      setUseSecurityQuestion(true);
    } else {
      addToast("Error updating security question settings", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setLoading(false);
  }

  function toggleUpdateQuestion() {
    setUpdateSecurityQuestion(true);
    trackEvent("editSecurityQuestion", {}, gqlHooks);
  }

  return (
    <Fragment>
      <div className="content">
        <Row className="m-0">
          <Col className="m-auto" style={{ maxWidth: 600 }}>
            {updateSecurityQuestion ? (
              <Row>
                <Col>
                  <Fade>
                    <div
                      className="text-center m-auto"
                      style={{ maxWidth: 500 }}
                    >
                      <img
                        src={SecurityQuestion}
                        alt="Setup Complete"
                        width="90"
                        className="m-auto d-block"
                      />
                      <h2 className="mb-2 mt-3 font-weight-600">
                        Create universal security question
                      </h2>
                      <p className="mb-4 text-muted">
                        The first time you send a payment to a contact, they are
                        required to answer your universal security question.
                        Ensure your answer is unique and cannot be guessed by
                        the general public.{" "}
                        <a
                          href={`https://${domains.HELP}/enabling-a-security-question`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more
                        </a>
                      </p>
                    </div>
                    <Form className="form" onSubmit={handleSubmit}>
                      <Label>Question</Label>
                      <FormGroup>
                        <Input
                          name="securityQuestion"
                          type="text"
                          value={values.securityQuestion}
                          invalid={!!errors.securityQuestion}
                          onChange={handleChange}
                          required
                        />
                        <FormFeedback>{errors.securityQuestion}</FormFeedback>
                      </FormGroup>
                      <Label>Answer</Label>
                      <FormGroup>
                        <Input
                          name="securityAnswer"
                          type="text"
                          invalid={!!errors.securityAnswer}
                          onChange={handleChange}
                          required
                        />
                        <FormFeedback>{errors.securityAnswer}</FormFeedback>
                      </FormGroup>
                      <Button
                        block
                        className="float-left mt-2"
                        color="primary"
                        type="submit"
                        disabled={
                          loading ||
                          !(values.securityQuestion && values.securityAnswer)
                        }
                      >
                        Next step
                      </Button>
                    </Form>
                  </Fade>
                </Col>
              </Row>
            ) : (
              <Row>
                {useSecurityQuestion === true && (
                  <Col>
                    <Fade>
                      <div className="mb-3 text-center">
                        <img
                          src={SecurityQuestionComplete}
                          alt="Setup Complete"
                          width="100"
                          className="m-auto d-block"
                        />
                        <h2 className="text-center mt-3 mb-2 font-weight-600">
                          Universal security question created!
                        </h2>
                        <p className="text-muted mb-3">
                          {values.securityQuestion}
                          <i
                            style={{ fontSize: "14px" }}
                            className="ml-2 cursor-pointer text-primary"
                            onClick={toggleUpdateQuestion}
                          >
                            Update
                          </i>
                        </p>{" "}
                        <p className="text-muted mb-0">
                          ***************************
                        </p>
                      </div>
                      <Button
                        className="m-auto d-block mt-4"
                        color={"primary"}
                        onClick={() => history.push("/onboarding/complete")}
                      >
                        Proceed
                      </Button>
                    </Fade>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
export default withApiValue(OnboardingSecurityQuestion, config);

OnboardingSecurityQuestion.propTypes = {
  company: PropTypes.object,
};
