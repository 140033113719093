/* eslint-disable react-hooks/exhaustive-deps,react/display-name,react/prop-types */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  calcBillsPaymentTotal,
  convertToDate,
  CustomTooltip,
  delay,
  formatDateTime,
  formattedDollarValue,
  objectMap,
} from "../../modules/Helpers";
import ReactTable from "./ReactTable";
import { Button, CardBody } from "reactstrap";
import { railzBillData } from "../../api_client/queries/accountingIntegration";
import { syncRailzData } from "../../api_client/mutations/accountingIntegration";
import useGQL from "../../api_client/UseGQL";
import useForm from "../../hooks/UseForms";
import { useSimpleContext } from "../../contexts/SimpleContext";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import MakeImportBillsTableCell, {
  SelectionCell,
  SelectionHeaderCell,
  ToBankAccountCell,
} from "../table_cells/ImportBillsTableCell";
import mapValues from "lodash.mapvalues";
import { withApiValue } from "../../modules/WithApiValue";
import { ClipLoader } from "react-spinners";
import { currentUser } from "../../api_client/queries/user";
import SelectBillsTableSearch from "../forms/SelectBillsTableSearch";
import { ImportBillsFormValidation } from "../../modules/form_validation/ImportBillsFormValidation";
import { useToasts } from "react-toast-notifications";

const currentUserOutput = {
  company: {
    railzConnection: {
      serviceName: true,
      lastSuccessfulSync: true,
      syncInProgress: true,
    },
  },
};

function ImportBillsTable(props) {
  const [loading, setLoading] = useState(false);
  const { values, handleChange } = useForm(() => null);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  let gqlHooks = useGQL();
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [tooltipSyncingIsOpen, setTooltipSyncingIsOpen] = useState(false);
  const [railzConnection, setRailzConnection] = useState(
    props.company.railzConnection
  );
  const firstConnection =
    railzConnection?.connected && !railzConnection?.initialDataSynchronized;
  const syncInProgress = railzConnection?.syncInProgress;
  const serviceName = railzConnection?.serviceName.capitalize();
  const [filteredBillIds, setFilteredBillIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [lastBillIdToggled, setLastBillIdToggled] = useState();
  const { addToast } = useToasts();

  useEffect(() => {
    if (syncInProgress) {
      updateRailzConnection();
    }
  }, [syncInProgress]);

  const filterData = (billIds) => {
    setFilteredBillIds(billIds);
  };

  const updateRailzConnection = async () => {
    const currentUserRes = await currentUser({}, currentUserOutput, gqlHooks);
    const railzConnectionData = currentUserRes.data.company.railzConnection;
    setRailzConnection(railzConnectionData);
    if (railzConnectionData.syncInProgress === false) {
      loadTable(1);
      return;
    } else {
      await delay(3000);
      await updateRailzConnection();
    }
  };

  const columnsAccessors = {
    dueDate: "dueDate",
    contactName: "contactName",
    contactEmail: "contactEmail",
    statement: "statement",
    note: "note",
    amount: "amount",
    totalAmount: "totalAmount",
    toBankAccountId: "toBankAccountId",
  };

  function syncBills() {
    let input = {
      sync: true,
    };
    let output = {
      syncStatus: true,
    };
    setRailzConnection({ ...railzConnection, syncInProgress: true });
    toggleAllSelected(false);
    syncRailzData(input, output, gqlHooks).then((response) => {
      if (response) {
        updateRailzConnection();
      } else {
        setRailzConnection({ ...railzConnection, syncInProgress: false });
      }
    });
  }

  const getData = useCallback(async ({ pageSize = 1 }) => {
    await loadTable(pageSize);
  }, []);

  const loadTable = async (pageSize) => {
    setLoading(true);
    let offset = 0;
    const params = {
      limit: pageSize,
      offset: offset,
    };
    let output = {
      identifier: true,
      billId: true,
      vendorId: true,
      totalAmount: true,
      amountDue: true,
      note: true,
      contactName: true,
      contactEmail: true,
      dueDate: true,
      statement: true,
      contact: {
        bankAccounts: {
          identifier: true,
          state: true,
          institution: true,
          title: true,
          accountNumber: true,
        },
      },
    };
    const allBills = state?.allBills;
    if (allBills && allBills?.length > 0) {
      setData(allBills);
      setFilteredBillIds(allBills.map((row) => row.billId.value));
      setLoading(false);
      return;
    }
    const response = await railzBillData(params, output, gqlHooks);
    if (response) {
      const dataWithExtraColumns = response.data?.map((d) => {
        const toBankAccountIdValue =
          (d?.contact?.bankAccounts || []).length > 1
            ? null
            : d?.contact?.bankAccounts?.[0]?.identifier;
        return {
          toBankAccountId: toBankAccountIdValue,
          amount: d.amountDue,
          selection: false,
          ...d,
        };
      });
      const formattedData = dataWithExtraColumns?.map((d) =>
        mapValues(d, (column) => ({ value: column, valid: true }))
      );

      setData(formattedData);
      setFilteredBillIds(formattedData.map((row) => row.billId.value));
      setCount(response.count);
      setLoading(false);
    }
  };

  const updateMyData = (billId, columnId, value, cellIsValid) => {
    // we do not update react table data after selection changes for performance reasons
    // so instead track lastBillIdToggled, and manually set validation errors on the input
    if (columnId === "selection") {
      setLastBillIdToggled(billId);
    }
    const updatedData = data.map((row) => {
      if (row.billId.value === billId) {
        return {
          ...row,
          [columnId]: { value: value, valid: cellIsValid },
        };
      }
      return row;
    });
    setData(updatedData);
  };

  const toggleAllSelected = (checked) => {
    setData((old) =>
      old.map((row) => {
        if (filteredBillIds.find((billId) => row.billId.value === billId)) {
          return {
            ...row,
            selection: { ...row["selection"], value: checked },
          };
        }
        return row;
      })
    );
    // set global state to track whether all bills are selected or not
    // this is currently used to re-render the table
    // TODO try to clean this up
    setAllSelected(!allSelected);
  };

  const validateTableValues = (tableValues) => {
    const errors = tableValues
      .map((row) => {
        const mappedRow = objectMap(row, (entry) => entry.value, {});
        const rowErrors = ImportBillsFormValidation(mappedRow, {});
        if (Object.keys(rowErrors).length !== 0) {
          return rowErrors;
        }
      })
      .filter((error) => error);
    return errors;
  };
  function nextStep() {
    const finalSelectedRows = data.filter((row) => row.selection.value);
    if (finalSelectedRows.length < 1) {
      addToast("Please select bills to pay before proceeding to the Proceed.", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    const errors = validateTableValues(finalSelectedRows);
    if (errors.length > 0) {
      addToast(
        "Something went wrong. Could not submit the table with existing errors. Please correct them and try again.",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
      return;
    }
    setState({ ...state, bills: finalSelectedRows, allBills: data });
    history.push("review-imports", {
      ...history.location.state,
      currStep: 3,
    });
  }

  let columns = useMemo(
    () => [
      {
        id: "selection",
        Header: SelectionHeaderCell,
        Cell: SelectionCell,
      },
      {
        Header: "Due Date",
        accessor: columnsAccessors.dueDate,
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment>{convertToDate(cell.row.original.dueDate.value)}</Fragment>
        ),
      },
      {
        Header: "Contact Name",
        accessor: columnsAccessors.contactName,
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: MakeImportBillsTableCell(
          columnsAccessors.contactName,
          "text",
          "Add name"
        ),
      },
      {
        Header: "Contact Email",
        accessor: columnsAccessors.contactEmail,
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: MakeImportBillsTableCell(
          columnsAccessors.contactEmail,
          "text",
          "Add email"
        ),
      },
      {
        Header: () => (
          <div>
            <span>Deposit Account</span>{" "}
            <CustomTooltip
              title="Letting the recipient decide will prompt your contact to add a bank account and answer your security question."
              placement="top"
              arrow
            >
              <i className="fas fa-info-circle" />
            </CustomTooltip>
          </div>
        ),
        accessor: columnsAccessors.toBankAccountId,
        // eslint-disable-next-line no-unused-vars
        Cell: ToBankAccountCell,
      },
      {
        Header: "Statement",
        accessor: columnsAccessors.statement,
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: MakeImportBillsTableCell(
          columnsAccessors.statement,
          "textarea",
          "Add statement"
        ),
      },
      {
        Header: "Note",
        accessor: columnsAccessors.note,
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: MakeImportBillsTableCell(
          columnsAccessors.note,
          "textarea",
          "Add note to contact"
        ),
      },
      {
        Header: () => (
          <div>
            <span>Amount Due</span>{" "}
            <CustomTooltip
              title="Remaining balance due of the bill total. You can edit this amount to make a partial payment."
              placement="top"
              arrow
            >
              <i className="fas fa-info-circle" />
            </CustomTooltip>
          </div>
        ),
        accessor: columnsAccessors.amount,
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: MakeImportBillsTableCell(
          columnsAccessors.amount,
          "number",
          "Add amount to pay"
        ),
      },
      {
        Header: "Bill Total",
        accessor: columnsAccessors.totalAmount,
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment>
            <div className="font-weight-bold" style={{ lineHeight: "1.2" }}>
              {formattedDollarValue(cell.row.original.totalAmount.value)}
            </div>
          </Fragment>
        ),
      },
    ],
    []
  );

  const filteredTableData = useMemo(
    () =>
      data.filter((row) =>
        filteredBillIds.find((billId) => row.billId.value === billId)
      ),
    [filteredBillIds, allSelected]
  );
  return (
    <>
      <>
        <h2 className="page-title d-inline">
          Select bills to pay{" "}
          <span className="text-muted font-weight-300">|</span>{" "}
          <span className="text-success mr-2">{serviceName}</span>
        </h2>{" "}
        {loading ? (
          <div className="d-inline mb-0">
            <ClipLoader size={15} loading color="#8125D2" />
          </div>
        ) : (
          <>
            {firstConnection || syncInProgress ? (
              <CustomTooltip
                title={`Syncing your ${serviceName} account. You will receive an email confirmation when your bills are ready to pay.`}
                placement="bottom"
                arrow
                open={tooltipSyncingIsOpen}
                onOpen={() => setTooltipSyncingIsOpen(true)}
                onClose={() => setTooltipSyncingIsOpen(false)}
                leaveTouchDelay={10000}
              >
                <Button
                  onClick={() => setTooltipSyncingIsOpen(!tooltipSyncingIsOpen)}
                  variant="contained"
                  style={{ cursor: "default" }}
                  className="btn-link tooltip-button sync-status d-inline mb-1"
                >
                  <ClipLoader size={15} loading color="#8125D2" />
                  <p className="text-primary font-weight-500 d-inline ml-2 mb-0">
                    Syncing
                  </p>
                </Button>
              </CustomTooltip>
            ) : (
              <>
                <p className="text-muted d-inline">
                  – Last sync{" "}
                  {railzConnection?.lastSuccessfulSync
                    ? formatDateTime(railzConnection?.lastSuccessfulSync)
                    : "N/A"}
                </p>
                <CustomTooltip
                  title={`Sync your ${serviceName} account to retrieve bills.`}
                  placement="bottom"
                  arrow
                >
                  <Button
                    className="btn-primary btn-simple mb-0 sp-button"
                    onClick={syncBills}
                  >
                    Sync
                  </Button>
                </CustomTooltip>
              </>
            )}
            {syncInProgress && (
              <CustomTooltip
                title={`Taking too long? Retry syncing your ${serviceName} account to retrieve bills.`}
                placement="bottom"
                arrow
              >
                <Button
                  className="btn-primary btn-simple mb-0 sp-button"
                  onClick={syncBills}
                >
                  Retry sync
                </Button>
              </CustomTooltip>
            )}
          </>
        )}
        <div>
          <p style={{ marginTop: "14px" }} className="d-inline-block">
            Select your {serviceName} bills to pay and fill any missing info.
          </p>
          <div className="float-right d-flex align-items-center mt-2 mb-4">
            <SelectBillsTableSearch
              bills={data}
              setFilteredBills={filterData}
            />
            <CustomTooltip
              title="The total amount you have selected to pay."
              placement="bottom"
              arrow
            >
              <p
                style={{ cursor: "default" }}
                className="float-right font-weight-bold m-0 mr-3"
              >
                Total: {calcBillsPaymentTotal(data)}
              </p>
            </CustomTooltip>
            <Button
              disabled={loading}
              className="btn-primary m-0 text-white sp-button"
              onClick={nextStep}
            >
              Proceed
            </Button>
          </div>
        </div>
        <CardBody className="import-bills-table">
          {/*
            Wait to render table until data is present,
            or else cells will be incorrectly filled with undefined values causing
            false validation errors
          */}
          {filteredTableData && (
            <ReactTable
              loading={loading}
              getData={getData}
              data={filteredTableData}
              columns={columns}
              count={count}
              handleChange={handleChange}
              values={values}
              updateMyData={updateMyData}
              toggleAllSelected={toggleAllSelected}
              filteredBillIds={filteredBillIds}
              responsive
              lastBillIdToggled={lastBillIdToggled}
            />
          )}
        </CardBody>
      </>
    </>
  );
}

export default withApiValue(ImportBillsTable, currentUserOutput);

ImportBillsTable.propTypes = {
  company: PropTypes.object,
};
