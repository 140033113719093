import { matchPath, Route, Switch, useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import React, { Fragment, useEffect, useState } from "react";
import { BulkApprovalSelect } from "./BulkApprovalSelect";
import { ReviewBulkApproval } from "./ReviewBulkApproval";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBan } from "@fortawesome/free-solid-svg-icons";
import { BulkApprovalComplete } from "./BulkApprovalComplete";
import { BulkApprovalFailedPayments } from "./BulkApprovalFailedPayments";
import WarningAlert from "../../../../../../components/sweet_alert/WarningAlert";

export const BulkApproval = () => {
  const history = useHistory();
  const [state] = useSimpleContext();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState();
  const isFirstStep = matchPath(history.location.pathname, {
    path: "/bulk-payment/credit/approve/select",
  });
  const isLastStep = matchPath(history.location.pathname, {
    path: "/bulk-payment/credit/approve/complete",
  });
  const isFailStep = matchPath(history.location.pathname, {
    path: "/bulk-payment/credit/approve/failed-payments",
  });

  useEffect(() => {
    if (!state) {
      history.push("/bulk-payment/credit/approve/select");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  function cancel() {
    WarningAlert(
      () => setAlert(null),
      setAlert,
      <Fragment>
        <Button
          onClick={() => setAlert(null)}
          className="btn-simple btn-primary mt-3"
        >
          Go back
        </Button>
        <Button
          onClick={() => history.push("/admin/payments")}
          color="warning"
          className="btn-primary mt-3 ml-2"
        >
          Yes
        </Button>
      </Fragment>,
      "Exit Page?"
    );
  }

  if (loading) {
    return null;
  } else {
    return (
      <div className="wrapper">
        {alert}
        <div className="main-panel bg-white">
          <div className={"d-flex justify-content-between"}>
            <div
              className={
                !isLastStep && !isFirstStep && !isFailStep ? "" : "invisible"
              }
            >
              <Button
                type="button"
                className="btn btn-link font-weight-600 btn-primary d-flex mt-2"
                onClick={() => history.goBack()}
                color={"primary"}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "8px", fontSize: "18px" }}
                  icon={faAngleLeft}
                />
                Back
              </Button>
            </div>
            <div className={!isLastStep ? "" : "invisible"}>
              <Button
                type="button"
                className="btn btn-link font-weight-600 btn-primary mt-2 d-flex pull-right"
                onClick={cancel}
                color={"danger"}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "8px", fontSize: "18px" }}
                  icon={faBan}
                />
                Exit
              </Button>
            </div>
          </div>
          <Switch>
            <Route
              path="/bulk-payment/credit/approve/select"
              render={() => <BulkApprovalSelect />}
            />
            <Route
              path="/bulk-payment/credit/approve/review"
              render={() => <ReviewBulkApproval />}
            />
            <Route
              path="/bulk-payment/credit/approve/failed-payments"
              render={() => <BulkApprovalFailedPayments />}
            />
            <Route
              path="/bulk-payment/credit/approve/complete"
              render={() => <BulkApprovalComplete />}
            />
          </Switch>
        </div>
      </div>
    );
  }
};

BulkApproval.propTypes = {};
