import {
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_LONG_NAME,
  MAX_LENGTH_NOTE,
  PHONE_ERROR,
  verifyEmail,
  verifyLength,
  verifyMaxTwoDecimal,
  verifyPhone,
  verifyStatementNames,
} from "../../Helpers";

export function validateAmount(values, errors, key = "amount") {
  let amountFloat = parseFloat(values[key]);
  if (values[key]) {
    if (amountFloat > 600000.0) {
      errors[key] = "Max transaction is $600,000.00, ";
    } else if (!verifyMaxTwoDecimal(amountFloat)) {
      errors[key] = "Must be dollar value (ex. $123.45)";
    } else if (amountFloat < 10.0) {
      errors[key] = "Min transaction is $10.00";
    } else {
      delete errors[key];
    }
  }
}

export function validateLongName(values, errors, key = "statement") {
  if (values[key]) {
    if (verifyLength(values[key], MAX_LENGTH_LONG_NAME)) {
      errors[key] = "Max 30 characters";
    } else if (verifyStatementNames(values[key])) {
      errors[key] =
        "Allowed only alpha numeric characters and these symbols... = _ $ . & * ,";
    } else if (!/[a-zA-Z]/.test(values[key])) {
      errors[key] = "Must contain at least 1 letter";
    } else {
      delete errors[key];
    }
  }
}

export function validateDescription(values, errors, key = "description") {
  if (values[key]) {
    if (values[key] && verifyLength(values[key], MAX_LENGTH_DESCRIPTION)) {
      errors[key] = "Max 200 characters";
    } else {
      delete errors[key];
    }
  }
}

export function validateNote(values, errors, key = "note") {
  if (values[key]) {
    if (values[key] && verifyLength(values[key], MAX_LENGTH_NOTE)) {
      errors[key] = "Max 200 characters";
    } else {
      delete errors[key];
    }
  }
}

export function validateEmail(values, errors, key = "email") {
  if (values[key]) {
    if (values[key] && !verifyEmail(values[key])) {
      errors[key] = "Please enter a valid email address";
    } else {
      delete errors[key];
    }
  }
}

export function validatePhone(values, errors, key = "phone") {
  if (values[key]) {
    if (values[key] !== "" && !verifyPhone(values[key])) {
      errors[key] = PHONE_ERROR;
    } else {
      delete errors[key];
    }
  } else {
    delete errors[key];
  }
}
