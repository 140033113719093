import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import useGQL from "../../api_client/UseGQL";
import { updateBankAccountKyc } from "../../api_client/mutations/bankAccount";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { AddBankAccountValidation } from "../../modules/form_validation/AddBankAccountValidation";
import useForm from "../../hooks/UseForms";
import { CustomLoader } from "../loaders/CustomLoader";
import ManualBankForm from "../../views/admin/settings/banks/manual/ManualBankForm";

function LegacyUpdateKYCModal(props) {
  const { values, errors, handleChange, handleSubmit, setValues, setErrors } =
    useForm(submit, AddBankAccountValidation);
  let gqlHooks = useGQL();
  const [loading, setLoading] = useState(false);
  const refs = {
    institutionNumber: useRef(null),
    transitNumber: useRef(null),
    accountNumber: useRef(null),
    title: useRef(null),
    accountType: useRef(null),
    institution: useRef(null),
    holderName: useRef(null),
    holderEmail: useRef(null),
    holderAddress: useRef(null),
    holderAddressPostalCode: useRef(null),
    holderAddressCity: useRef(null),
  };
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setValues(props.bankAccount);
    if (props.bankAccount.missingKyc) {
      let errs = {};
      props.bankAccount.missingKyc.forEach(
        (field) => (errs[field] = "This field is missing or incorrect")
      );
      setErrors(errs);
    }
  }, [props.bankAccount]);

  async function submit() {
    setLoading(true);
    let input = {
      bankAccountId: props.bankAccount.identifier,
      kyc: {},
    };
    let accountNumber = values.accountNumber;
    if (values.accountNumber.includes("*")) {
      delete values.accountNumber;
    }

    if (props.bankAccount.state === "PARTIALLY_READY") {
      props.bankAccount.missingKyc.forEach(
        (field) => (input.kyc[field] = values[field])
      );
    } else {
      Object.keys(refs).forEach((field) =>
        values[field] ? (input.kyc[field] = values[field]) : null
      );
    }
    values.accountNumber = accountNumber;
    let output = {
      ok: true,
    };
    let response = await updateBankAccountKyc(input, output, gqlHooks);
    if (response) {
      if (response.ok) {
        props.success(values);
        setSuccess(true);
      } else if (response.fieldErrors) {
        response.fieldErrors.forEach(
          (error) => (errors[error.field] = error.message)
        );
      }
    }
    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <SweetAlert
          custom
          title={<CustomLoader general={true} />}
          onCancel={() => null}
          onConfirm={() => null}
          showConfirm={false}
          showCancel={false}
        >
          Authorizing...
        </SweetAlert>
      ) : success && props.isOpen ? (
        <SweetAlert
          success
          title={"Successfully Updated"}
          onConfirm={() => props.toggle()}
          onCancel={() => props.toggle()}
          showConfirm={false}
          showCancel={false}
        >
          Details were successfully updated
        </SweetAlert>
      ) : (
        <Modal
          size="lg"
          backdrop
          fade
          centered
          isOpen={props.isOpen}
          toggle={props.toggle}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <Button close onClick={props.toggle} />
              </Col>
            </Row>
            <Col>
              <h4 className="text-center">
                {props.bankAccount.state === "PARTIALLY_READY"
                  ? "Resolve Missing Details"
                  : "Retry Adding Account "}
              </h4>
              <p className="text-center text-muted">
                {props.bankAccount.state === "PARTIALLY_READY"
                  ? "Please add all missing bank account details"
                  : "Please verify all account details are correct"}
              </p>
            </Col>
            <ManualBankForm
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              hideAccountNumber={false}
              refs={refs}
              uneditable={Object.keys(props.bankAccount).filter(
                (k) =>
                  !props.bankAccount.missingKyc.includes(k) &&
                  props.bankAccount.state === "PARTIALLY_READY"
              )}
            />
            <Col>
              <Button
                block
                color="primary"
                type="submit"
                onClick={submit}
                className="pull-right"
              >
                {props.bankAccount.state === "PARTIALLY_READY"
                  ? "Update"
                  : "Submit"}
              </Button>
            </Col>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

LegacyUpdateKYCModal.propTypes = {
  bankAccount: PropTypes.shape({
    missingKyc: PropTypes.array,
    identifier: PropTypes.string,
    state: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
};

export default LegacyUpdateKYCModal;
