import React, { Fragment, useState } from "react";
// import BankSecurity from "../../../assets/img/2fa_page/bank-security.svg";
//import ReceivedMoney from "../../../assets/img/ReceivedMoney.png";
import SendMoney from "../../../assets/img/sendMoney.svg";
function CodeFormSideContent() {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Fragment>
      <div
        className="radial-background overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="w-75 mx-auto mt-5">
          <h1 className="text-white heading-big-4">
            Big, easy <br /> money moves.
          </h1>
          <p className="text-white overflow-hidden sub-heading-big-4">
            Send and receive business payments in minutes. <br />
            <strong>No limits. No paperwork. No headaches.</strong>
          </p>
        </div>

        <div className="overflow-hidden">
          {/* <img className="bank-security" src={BankSecurity} /> */}
        </div>
        <div className="key-login">
          <div className=""></div>
          {/* <img src={ReceivedMoney} alt="Received Money" className="img-1" /> */}
          <img src={SendMoney} alt="Received Money" className="img-2" />
        </div>
        {/* <div className="code-form-side-content">
          <div style={{ width: "100%" }}>
            <div className="auth-logo-small">
              <img src={LogoColor} alt={appName} />
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
}

export default CodeFormSideContent;
