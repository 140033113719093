/* eslint-disable react-hooks/exhaustive-deps,react/prop-types,react/display-name */
import useGQL from "api_client/UseGQL";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Button, CardBody } from "reactstrap";
import ReactTable from "./ReactTable";
import QueryString from "query-string";
import { validApiTokens } from "api_client/queries/tokens";
import PropTypes from "prop-types";
import { CustomTooltip, appName } from "modules/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { apiTokenScopeName } from "variables/ApiTokenScopes";
import ApiTokenCard from "components/cards/ApiTokenCard";
import ApiTokenSkeletonCard from "components/skeleton/ApiTokenSkeletonCard";
import { deleteApiToken } from "api_client/mutations/token";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

function ApiTokensTable(props) {
  const { addToast } = useToasts();
  const gqlHooks = useGQL();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [alertState, setAlert] = useState(null);

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.update) {
      setUpdate(!update);
      history.push({ search: "" });
    }
  });

  const getData = useCallback(async () => {
    setLoading(true);
    let output = {
      scopes: true,
      identifier: true,
      validUntil: true,
      name: true,
      createdAt: true,
    };

    const response = await validApiTokens({}, output, gqlHooks);
    if (response) {
      setData(response.data);
      setCount(response.count);
    }
    setLoading(false);
  }, [update]);

  let sortedDates = data.map((item) => item.validUntil).sort();

  useEffect(() => {
    if (sortedDates[0]) {
      let soonestDeleteMillis = moment(sortedDates[0]).diff(moment());
      if (soonestDeleteMillis > 0) {
        let timeoutId = setTimeout(() => {
          history.push({ search: "update=true" });
        }, soonestDeleteMillis);
        return () => clearTimeout(timeoutId);
      }
    }
  });

  async function deleteItem(identifier) {
    let input = {
      tokenId: identifier,
      immediate: true,
    };
    let output = {
      ok: true,
    };

    const response = await deleteApiToken(input, output, gqlHooks);
    if (response) {
      addToast("Token deleted", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push({ search: "update=true" });
    }
  }

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell }) => <Fragment>{cell.row.original.name}</Fragment>,
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ cell }) => (
          <Fragment>
            {moment(cell.row.original.createdAt).format("MMM D YYYY")}
          </Fragment>
        ),
      },
      {
        Header: "Scopes",
        accessor: "scopes",
        Cell: ({ cell }) => (
          <Fragment>
            {cell.row.original.scopes.map((scope) => {
              return (
                <Badge
                  key={scope}
                  color="default"
                  style={{ minWidth: "80px", marginBottom: "0" }}
                  pill
                >
                  {apiTokenScopeName(scope)}
                </Badge>
              );
            })}
          </Fragment>
        ),
      },
      // Currently not using valid until
      // {
      //   Header: "Valid Until",
      //   accessor: "validUntil",
      //   Cell: ({ cell }) => (
      //     <Fragment>
      //       {cell.row.original.validUntil
      //         ? moment(cell.row.original.validUntil).calendar()
      //         : "-"}
      //     </Fragment>
      //   ),
      // },
      {
        Header: "",
        accessor: "Delete",
        delete: "delete",
        Cell: ({ cell }) => (
          <Fragment>
            <CustomTooltip title="Delete Token" placement="bottom" arrow>
              <Button
                className="btn-link"
                onClick={() => {
                  setAlert(
                    <SweetAlert
                      title="Are you sure?"
                      onConfirm={() => {
                        deleteItem(cell.row.original.identifier);
                        setAlert(null);
                      }}
                      onCancel={() => setAlert(null)}
                      showConfirm={true}
                      showCancel={true}
                      confirmBtnText="Delete"
                    >
                      <p>
                        If you delete this token, the application using it will
                        no longer have access to the {appName} API.
                      </p>
                    </SweetAlert>
                  );
                }}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "1.2rem", marginTop: "5px" }}
                  icon={faTrashAlt}
                />
              </Button>
            </CustomTooltip>
          </Fragment>
        ),
      },
    ],
    []
  );

  return (
    <>
      {alertState}
      <CardBody>
        <ReactTable
          loading={loading}
          getData={getData}
          data={data}
          columns={columns}
          count={count}
          cards={props.cards}
          MobileCard={ApiTokenCard}
          MobileSkeleton={ApiTokenSkeletonCard}
        />
      </CardBody>
    </>
  );
}

// export default withApiValue(ApiTokensTable);
export default ApiTokensTable;

ApiTokensTable.propTypes = {
  cards: PropTypes.bool,
};

ApiTokensTable.defaultProps = {
  cards: false,
};
