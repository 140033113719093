import { PHONE_ERROR, verifyPhone } from "../Helpers";

export function BusinessInformationValidation(values, errors) {
  if (values.name) {
    if (values.name.length > 100) {
      errors.name = "Organization name must be less than 100 characters";
    } else {
      delete errors.name;
    }
  } else {
    delete errors.name;
  }
  if (values.structure) {
    if (values.structure.length > 100) {
      errors.structure = "Business type must be less than 100 characters";
    } else {
      delete errors.structure;
    }
  } else {
    delete errors.structure;
  }
  if (values.industry) {
    if (values.industry.length > 100) {
      errors.industry = "Industry must be less than 100 characters";
    } else {
      delete errors.industry;
    }
  } else {
    delete errors.industry;
  }
  if (values.address) {
    if (values.address.length > 100) {
      errors.address = "Address must be less than 100 characters";
    } else {
      delete errors.address;
    }
  } else {
    delete errors.address;
  }
  if (values.addressCity) {
    if (values.addressCity.length > 100) {
      errors.addressCity = "City must be less than 100 characters";
    } else {
      delete errors.addressCity;
    }
  } else {
    delete errors.addressCity;
  }
  if (values.addressProvince) {
    if (values.addressProvince.length > 100) {
      errors.addressProvince = "Province must be less than 100 characters";
    } else {
      delete errors.addressProvince;
    }
  } else {
    delete errors.addressProvince;
  }
  if (values.addressPostalCode) {
    if (
      !values.addressPostalCode.match(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)
    ) {
      errors.addressPostalCode =
        "Invalid canadian postal code. Must be V8R 6L8 or V8R6L8 or V8R-6L8";
    } else {
      delete errors.addressPostalCode;
    }
  }
  if (values.purpose) {
    if (values.purpose.length > 5000) {
      errors.purpose =
        "Purpose of transactions must be less than 5000 characters";
    } else {
      delete errors.purpose;
    }
  } else {
    delete errors.purpose;
  }
  if (values.incorporationDocumentType) {
    if (values.incorporationDocumentType.length > 100) {
      errors.incorporationDocumentType =
        "Incorporation document type type must be less than 100 characters";
    } else {
      delete errors.incorporationDocumentType;
    }
  } else {
    delete errors.incorporationDocumentType;
  }

  if (values.sourceOfWealth) {
    if (values.sourceOfWealth.length > 100) {
      errors.sourceOfWealth =
        "source of wealth must be less than 100 characters";
    } else {
      delete errors.sourceOfWealth;
    }
  } else {
    delete errors.sourceOfWealth;
  }

  if (values.avgMonthlyVolume) {
    if (isNaN(values.avgMonthlyVolume) || values.avgMonthlyVolume <= 0) {
      errors.avgMonthlyVolume =
        "Average monthly volume must be a positive number";
    } else {
      delete errors.avgMonthlyVolume;
    }
  } else {
    delete errors.avgMonthlyVolume;
  }

  if (values.avgTransactionSize) {
    if (isNaN(values.avgTransactionSize) || values.avgTransactionSize <= 0) {
      errors.avgTransactionSize =
        "Average transaction size must be a positive number";
    } else {
      delete errors.avgTransactionSize;
    }
  } else {
    delete errors.avgTransactionSize;
  }

  if (values.customerSupportContactPhone) {
    if (!verifyPhone(values.customerSupportContactPhone)) {
      errors.customerSupportContactPhone = PHONE_ERROR;
    } else {
      delete errors.customerSupportContactPhone;
    }
  }

  if (values.websiteUrl) {
    if (!values.websiteUrl.startsWith("https://")) {
      errors.websiteUrl =
        "URL must begin with https:// (e.g. https://www.companyname.com).";
    } else {
      try {
        const newUrl = new URL(values.websiteUrl);
        const pattern = /.[a-zA-Z]{2,}$/;

        if (newUrl.pathname !== "/" || values.websiteUrl.endsWith("/")) {
          errors.websiteUrl =
            "Please remove any paths or additional characters after the domain (e.g. Nothing should be after '.com' in https://www.companyname.com )";
        } else if (!pattern.test(values.websiteUrl)) {
          errors.websiteUrl =
            "Please enter a valid URL (e.g. https://www.companyname.com).";
        } else {
          delete errors.websiteUrl;
        }
      } catch (err) {
        errors.websiteUrl =
          "Please enter a valid URL (e.g. https://www.companyname.com).";
      }
    }
  }

  return errors;
}
