import React, { useState } from "react";
import {
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { appName, emailAccounts, orgName } from "modules/Helpers";
import styles from "./../assets/css/tos.module.css";

function TermsOfService(props) {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    // You can implement the logic to save the user&aposs acceptance here
    setAccepted(true);
  };

  return (
    <>
      <div>
        <Modal
          className="add-bank-modal-wrapper"
          backdrop
          fade
          centered
          isOpen={true}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <iframe src="/tos.html" width="900" height="1000"></iframe>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default TermsOfService;
