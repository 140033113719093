import React, { Fragment, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { useToasts } from "react-toast-notifications";
import { contactFormValidation } from "../../../modules/form_validation/ContactFormValidation";
import { createContact } from "../../../api_client/mutations/contacts";
import useGQL from "../../../api_client/UseGQL";
import PropTypes from "prop-types";
import { reportContactCreated } from "../../../modules/analytics";
import TagsSelect from "../../../components/custom_select/TagsSelect";

function CreateContactForm(props) {
  const { values, handleChange, handleSubmit, errors } = useForm(
    submit,
    contactFormValidation
  );
  const { addToast } = useToasts();
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [contactTags, setContactTags] = useState([]);
  let gqlHooks = useGQL();

  async function submit() {
    setLoading(true);
    let tags = contactTags.map((tag) => {
      return tag.label;
    });
    const input = {
      email: values.email,
      name: values.name,
      tags: tags,
      emailInvite: true,
    };
    createContact(input, {}, gqlHooks).then((response) => {
      if (response) {
        addToast("Contact added successfully!", {
          appearance: "success",
          autoDismiss: true,
        });
        reportContactCreated(gqlHooks);
      } else {
        addToast("Something went wrong, could not add contact", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setLoading(false);
      props.toggle();
    });
  }

  function onSelectTags(value) {
    if (value === null) {
      value = [];
    }
    setContactTags(value);
  }

  return (
    <Fragment>
      <div className="content">
        <Row>
          <Col>
            <h4 className="text-center">New Contact</h4>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Label>Name</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="name"
                      innerRef={nameRef}
                      onChange={handleChange}
                      value={values.name || ""}
                      invalid={!!errors.name}
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                  <Label>Email</Label>
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      innerRef={emailRef}
                      onChange={handleChange}
                      value={values.email || ""}
                      invalid={!!errors.email}
                      required
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                  <Label>Tags</Label>
                  <FormGroup>
                    <TagsSelect
                      activeTags={contactTags}
                      onChangeCallback={onSelectTags}
                      placeholder="Type and press enter or select from list"
                    />
                    <FormFeedback>{errors.tags}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Button
                block
                type="submit"
                color="primary"
                disabled={loading || !(values.name && values.email)}
              >
                Add
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default CreateContactForm;

CreateContactForm.propTypes = {
  toggle: PropTypes.func,
};
