import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Label, Row } from "reactstrap";
import TagsSelect from "../custom_select/TagsSelect";
import Select from "react-select";

function ContactsFilters(props) {
  const [tags, setTags] = useState();
  const [state, setState] = useState({
    value: false,
    label: "Active",
  });

  useEffect(() => {
    props.setFilters({
      ...props.filters,
      tag: tags?.label || undefined,
      archived: state?.value,
    });
  }, [tags, state]);

  return (
    <Fragment>
      <Row>
        <Col md={3}>
          <Label className="d-block mt-3">Status</Label>
          <FormGroup>
            <Select
              onChange={(state) => setState(state)}
              className="react-select default"
              classNamePrefix="react-select"
              value={state}
              options={[
                {
                  value: false,
                  label: "Active",
                },
                { value: true, label: "Archived" },
              ]}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <Label className="d-block mt-3">Tags</Label>
          <FormGroup>
            <TagsSelect
              isMulti={false}
              activeTags={tags}
              onChangeCallback={(value) => setTags(value)}
              isClearable
              clear={() => setTags(undefined)}
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
}

export default ContactsFilters;

ContactsFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};
