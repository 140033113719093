import React, { Fragment, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Fade, Row } from "reactstrap";
import { enumValue, INDIVIDUAL, ORGANIZATION } from "../../../modules/Helpers";
import { PulseLoader } from "react-spinners";
import { setCompanyProfile } from "../../../api_client/mutations/companyProfile";
import useGQL from "../../../api_client/UseGQL";
import { trackEvent } from "../../../modules/analytics";
import PropTypes from "prop-types";

import { useHistory } from "react-router";
import { withApiValue } from "../../../modules/WithApiValue";

const config = {
  company: { companyType: true },
};

function OnboardingBusinessType(props) {
  let gqlHooks = useGQL();
  const [companyType, setCompanyType] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCompanyType(props.company.companyType);
  }, []);

  async function submit() {
    setLoading(true);
    trackEvent(
      "onboardingSetCompanyType",
      { companyType: companyType },
      gqlHooks
    );
    const response = await setCompanyProfile(
      { companyType: enumValue(companyType) },
      { company: { companyType: true } },
      gqlHooks
    );
    if (response) {
      props.update(response);
      history.push("/onboarding/profile");
    }
    setLoading(false);
  }

  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Col className="m-auto" style={{ maxWidth: 610 }}>
          <Fade>
            <h2 className="text-center mb-4 font-weight-600">
              What type of business are you signing up?
            </h2>
            <ButtonGroup className="business-type-btn-wrapper">
              <Button
                className="business-type-btn1"
                onClick={() => setCompanyType(ORGANIZATION)}
                active={companyType === ORGANIZATION}
              >
                Organization
                <span className="business-type-description">
                  Select if your business is a legal entity. (E.g. Corporation,
                  Partnership, Nonprofit)
                </span>
              </Button>
              <Button
                className="business-type-btn2"
                onClick={() => setCompanyType(INDIVIDUAL)}
                active={companyType === INDIVIDUAL}
              >
                Sole Proprietorship
                <span className="business-type-description">
                  Select if your business is owned by one individual and is NOT
                  a legal entity.
                </span>
              </Button>
            </ButtonGroup>
            <Button
              onClick={submit}
              block
              color={companyType ? "primary" : "primary"}
              disabled={!companyType}
              className="mt-4"
            >
              {loading ? <PulseLoader color="white" size={10} /> : "Proceed"}
            </Button>
          </Fade>
        </Col>
      </Row>
    </Fragment>
  );
}

export default withApiValue(OnboardingBusinessType, config);
OnboardingBusinessType.propTypes = {
  company: PropTypes.object,
  update: PropTypes.func,
};
