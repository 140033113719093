import { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCreditCardHandler } from "./hooks/creditCardHandler";

/**
 * Use the CSS tab above to style your Element's container.
 */

function useStripeMethod(setErrorMsg) {
  const { createStripeCustomer } = useCreditCardHandler(setErrorMsg);
  const stripe = useStripe();
  const elements = useElements();

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Poppins", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  async function getCustomerCreditCard(
    setLoading,
    setCustomerCreditCard,
    name
  ) {
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Show error to your customer.
      setErrorMsg(result.error.message);
      setLoading(false);
    } else {
      const stripeCcToken = result?.token?.id;
      const response = await createStripeCustomer(stripeCcToken, name);
      setCustomerCreditCard(response?.customerCreditCard);
    }
  }
  return { getCustomerCreditCard, CARD_ELEMENT_OPTIONS, CardElement };
}

export default useStripeMethod;

useStripeMethod.propTypes = {};
