import React, { useEffect, useState } from "react";
import { Nav } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import PropTypes from "prop-types";

export default function BasicTabs(props) {
  const [active, setActive] = useState(props.defaultTab);
  const location = useLocation();

  useEffect(() => {
    if (active === "All") {
      props.changePage(undefined);
    } else {
      props.changePage(active);
    }
  }, [active]);

  useEffect(() => {
    if (location.state) {
      setActive(location.state.active);
    }
  }, []);

  const activeRoute = (name) => {
    return active === name ? "active" : "";
  };

  return (
    <Nav className="table-headers user-table-header pl-1 mb-3 bottom-border">
      {props.pages &&
        props.pages.map((tab, key) => (
          <li key={key} className={activeRoute(`${tab}`) + " nav-link"}>
            <NavLink
              to=""
              onClick={(e) => {
                e.preventDefault();
                setActive(`${tab}`);
              }}
            >
              {tab}
            </NavLink>
          </li>
        ))}
    </Nav>
  );
}

BasicTabs.propTypes = {
  pages: PropTypes.array.isRequired,
  defaultTab: PropTypes.string.isRequired,
  changePage: PropTypes.func.isRequired,
};
