import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ApiTokenScopes, { apiTokenScopeName } from "variables/ApiTokenScopes";
import useFormInline from "hooks/useFormInline";
import useGQL from "api_client/UseGQL";
import { createApiToken } from "api_client/mutations/token";
import { enumValue, appName } from "modules/Helpers";

const apiTokenScopeOptions = {
  create_debits: ApiTokenScopes.CREATE_DEBIT,
  create_scheduled_transaction: ApiTokenScopes.CREATE_SCHEDULED_TRANSACTION,
  query_transaction: ApiTokenScopes.QUERY_TRANSACTION,
  cancel_transaction: ApiTokenScopes.CANCEL_TRANSACTION,
};

function TokenModalBodyCreate(props) {
  let { setLoading, setToken, isLoading, toggle } = props;

  const { values, handleChange, handleSubmit, errors } = useFormInline(
    doSubmit,
    validate,
    validateOne
  );
  let gqlHooks = useGQL();

  function optionsFromValues(values) {
    return Object.keys(apiTokenScopeOptions)
      .map((name) =>
        values[name] == "true" ? apiTokenScopeOptions[name] : null
      )
      .filter((v) => v);
  }

  async function doSubmit() {
    setLoading(true);
    let scopes = optionsFromValues(values).map((v) => enumValue(v));

    let input = {
      name: values.name,
      scopes: scopes,
    };
    let output = {
      id: true,
      token: true,
    };

    const response = await createApiToken(input, output, gqlHooks);

    if (response && response.token) {
      setToken(response.id, response.token);
    }
    setLoading(false);
  }

  function validate(values, errors) {
    let scopes = optionsFromValues(values);

    if (!scopes || !scopes.length) {
      errors.scopes = "You must specify at least one scope";
    } else {
      delete errors.scopes;
    }

    if (!values.name) {
      errors.name = "You must specify a name";
    }

    return errors;
  }

  function validateOne(name, values, errors) {
    switch (name) {
      case "name":
        if (values[name]) {
          delete errors.name;
        } else {
          errors.name = "You must specify a name";
        }
        break;
    }

    if (name in apiTokenScopeOptions || name == "scopes") {
      let scopes = optionsFromValues(values);
      if (!scopes || !scopes.length) {
        errors.scopes = "You must specify at least one scope";
      } else {
        delete errors.scopes;
      }
    }

    return errors;
  }

  return (
    <>
      <ModalHeader>
        <h3>Add API Token</h3>
        <span className="text-default">
          You can create a token which may be used to access the {appName} API.
          <br />
          <br />
          You will only be shown the token value once.
        </span>
      </ModalHeader>
      <Form className="form" onSubmit={handleSubmit}>
        <ModalBody>
          <Col>
            <Label>Scopes *</Label>
            <FormGroup check>
              {[
                Object.keys(apiTokenScopeOptions).map((name) => {
                  let scopeOption = apiTokenScopeOptions[name];
                  return (
                    <div key={`checkitem-${name}`}>
                      <Label className="form-check-label">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          name={name}
                          value={values[name] || false}
                          onChange={(event) => {
                            event.target.value = event.target.checked;
                            handleChange(event);
                          }}
                        />
                        <span className="form-check-sign">
                          <span className="check" />
                        </span>
                        {apiTokenScopeName(scopeOption)}
                      </Label>
                    </div>
                  );
                }),
              ]}
              <Input type="hidden" invalid={!!errors.scopes} />
              <FormFeedback>{errors.scopes}</FormFeedback>
            </FormGroup>

            <Label>Name *</Label>
            <FormGroup>
              <Input
                type="text"
                name="name"
                value={values.name || ""}
                onChange={handleChange}
                invalid={!!errors.name}
              />
              <FormFeedback>{errors.name}</FormFeedback>
            </FormGroup>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isLoading}
            className="btn-link"
            color="default"
            type="button"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            className="btn pull-right"
            color="primary"
            type="submit"
          >
            Create API Token
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
}

export default TokenModalBodyCreate;

TokenModalBodyCreate.propTypes = {
  setLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setToken: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};
