export function OwnersValidation(values, errors) {
  if (values.legalName) {
    if (values.legalName.length > 100) {
      errors.legalName = "Name must be less than 100 characters";
    } else {
      delete errors.legalName;
    }
  } else {
    delete errors.legalName;
  }

  if (values.address) {
    if (values.address.length > 500) {
      errors.address = "Address must be less than 500 characters";
    } else {
      delete errors.address;
    }
  } else {
    delete errors.address;
  }
  if (values.addressPostalCode) {
    if (
      !values.addressPostalCode.match(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)
    ) {
      errors.addressPostalCode =
        "Invalid canadian postal code. Must be V8R 6L8 or V8R6L8 or V8R-6L8";
    } else {
      delete errors.addressPostalCode;
    }
  }
  if (values.addressCity) {
    if (values.addressCity.length > 50) {
      errors.addressCity = "Address City must be less than 50 characters";
    } else {
      delete errors.addressCity;
    }
  } else {
    delete errors.addressCity;
  }
  if (values.occupation) {
    if (values.occupation.length > 50) {
      errors.occupation = "owner occupation must be less than 50 characters";
    } else {
      delete errors.occupation;
    }
  } else {
    delete errors.occupation;
  }
  if (values.percentOwned) {
    const pattern = /^\d+$/;
    if (!pattern.test(values.percentOwned)) {
      errors.percentOwned = "This field must be a whole number.";
    } else {
      delete errors.percentOwned;
    }
  }

  return errors;
}
