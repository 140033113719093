/* eslint react/prop-types: 0 */
import ReactTable from "../../../../../../components/tables/ReactTable";
import React, { useCallback, useMemo, useState } from "react";
import { allTransactions } from "../../../../../../api_client/queries/transactions";
import { makeApproval } from "api_client/mutations/transaction";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  convertToDate,
  CREDIT,
  DEBIT,
  enumValue,
  formattedDollarValue,
  INTERNAL,
} from "../../../../../../modules/Helpers";
import ContactTagBadge from "../../../../../../components/badge/ContactTagBadge";
import useGQL from "../../../../../../api_client/UseGQL";
import { Button } from "reactstrap";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { useHistory } from "react-router-dom";
import { calculateTotalAmount } from "./ReviewBulkApproval";
import { ContentPlaceHolder } from "../../../../../../components/ContentPlaceHolders";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { useAppValue } from "../../../../../../contexts/Context";
import IndeterminateCheckbox from "components/tables/IndeterminateCheckbox";
// eslint-disable-next-line react/display-name
function Popup(props) {
  return <div>{props.props.row.original.approvalNames.join(", ")}</div>;
}
export const BulkApprovalSelect = () => {
  const [context] = useAppValue();
  const [state, setState] = useSimpleContext();
  const [data, setData] = useState(state?.allTransactions || []);
  const [loading, setLoading] = useState(true);
  const [approveDisabled, setApproveDisabled] = useState(false);
  const [alertState, setAlertState] = useState(null);
  let gqlHooks = useGQL();
  const [count, setCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const history = useHistory();
  const requiresApproval = !context.user?.isOwnerOrAdmin;
  const totalAmount = calculateTotalAmount(
    selectedRows?.map((row) => {
      return {
        amount: row.original.amount,
      };
    }) || []
  );
  const getData = useCallback(async ({ pageSize = 1000 }) => {
    await loadTable(pageSize);
  }, []);

  const loadTable = async (pageSize) => {
    if (state?.allTransactions) {
      setLoading(false);
      return;
    }
    setLoading(true);
    let offset = 0;
    const params = {
      limit: pageSize,
      offset: offset,
      states: [enumValue("WAITING_APPROVAL"), enumValue("APPROVED")],
      requiresUserApproval: requiresApproval,
    };
    let output = {
      createdAt: true,
      method: true,
      identifier: true,
      amount: true,
      statement: true,
      description: true,
      padType: true,
      direction: true,
      states: {
        state: true,
        createdAt: true,
      },
      contact: {
        identifier: true,
        email: true,
        name: true,
        tags: true,
      },
      transactionRequestedContactEmail: {
        status: true,
      },
      approvalNames: true,
    };
    const response = await allTransactions(params, output, gqlHooks);
    if (response) {
      setData([...response.data]);
      setCount(response.count);
    }
    setLoading(false);
  };

  const defaultSelection =
    state?.approvedDraftCredits?.reduce((acc, draft) => {
      return { ...acc, [draft.rowId]: true };
    }, {}) || {};

  const submit = () => {
    const approvedDraftCredits = selectedRows.map((row) => {
      const selectedTransaction = data.find(
        (transaction) => transaction.identifier === row.original.identifier
      );
      return {
        ...selectedTransaction,
        rowId: row.id,
      };
    });
    setState({ ...state, approvedDraftCredits, allTransactions: data });
    history.push("/bulk-payment/credit/approve/review");
  };

  const approve = async () => {
    setApproveDisabled(true);
    const selectedTransactions = selectedRows.map((row) => {
      const selectedTransaction = data.find(
        (transaction) => transaction.identifier === row.original.identifier
      );
      return selectedTransaction.identifier;
    });
    const input = {
      transactions: selectedTransactions,
    };
    let output = {
      approvals: {
        user: {
          identifier: true,
          email: true,
        },
        transaction: {
          identifier: true,
        },
      },
    };

    let res = await makeApproval(input, output, gqlHooks);
    setApproveDisabled(false);
    setAlertState(
      <SweetAlert
        success
        title={"Approval Success!"}
        onConfirm={() => setAlertState(null)}
        onCancel={() => setAlertState(null)}
        showConfirm={true}
        confirmBtnText="Ok"
        backdrop={false}
      >
        You have approved the selected transaction(s)
      </SweetAlert>
    );
  };
  const CellComponent = ({ cell }) => {
    const [showPopup, setShowPopup] = useState(false);

    const handleMouseEnter = () => {
      setShowPopup(true);
    };

    const handleMouseLeave = () => {
      setShowPopup(false);
    };

    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ position: "relative", display: "inline-block" }}
      >
        {cell.row.original.approvalNames.length > 1 ? (
          <>
            {cell.row.original.approvalNames[0]}
            <b> + {cell.row.original.approvalNames.length - 1} other</b>
          </>
        ) : (
          cell.row.original.approvalNames[0]
        )}
        {showPopup && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              background: "white",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
              padding: "10px",
              zIndex: 9999,
            }}
          >
            <Popup props={cell} />
          </div>
        )}
      </div>
    );
  };

  let columns = useMemo(
    () => [
      {
        id: "selection",
        accessor: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        Header: "Contact",
        accessor: "contact",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.value && cell.value.name ? cell.value.name : ""}
            </div>
            <div className="text-muted text-sm mb-1">
              {cell.row.original.direction === INTERNAL ? (
                "Internal Transfer"
              ) : (
                <>
                  {cell.value ? cell.value.email : "Pending Payment Acceptance"}
                </>
              )}
            </div>
          </>
        ),
      },
      {
        Header: "Approvals",
        accessor: "approvals",
        Cell: CellComponent,
      },
      {
        Header: "Tags",
        accessor: "none",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.contact === null ? (
              ""
            ) : (
              <div>
                {ContactTagBadge(
                  cell.row.original.contact.tags,
                  cell.row.original.identifier
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Statement",
        accessor: "statement",
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div className="text-muted">{convertToDate(cell.value)}</div>
          </>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        style: { th: { textAlign: "right" } },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <div className="text-right">
            <div className="text-right">
              {cell.row.original.direction === DEBIT ? (
                <div>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    {formattedDollarValue(cell.row.original.amount)}
                  </div>
                  <div className="text-muted text-sm">Receivable</div>
                </div>
              ) : cell.row.original.direction === CREDIT ? (
                <div>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    ({formattedDollarValue(cell.row.original.amount)})
                  </div>
                  <div className="text-muted text-sm">Payable</div>
                </div>
              ) : (
                <div>
                  <div
                    className="font-weight-bold mt-1"
                    style={{ lineHeight: "1.2" }}
                  >
                    {formattedDollarValue(cell.row.original.amount)}
                  </div>
                  <div className="text-muted text-sm">Internal</div>
                </div>
              )}
            </div>
          </div>
        ),
      },
    ],
    [data]
  );

  return (
    <div className={"m-5"}>
      {alertState}
      <div className="d-flex flex-row justify-content-between">
        <h2 className="mb-3 font-weight-600 page-title">
          Pending approval{" "}
          <span className="font-weight-500 text-muted text-xl">
            ({data?.length || 0})
          </span>
        </h2>
      </div>
      <span>Select payments to approve</span>
      <div className={"d-flex flex-row justify-content-end mb-3"}>
        {context.user.company.settings.requiredPaymentApprovals > 1 ? (
          <div className={"d-flex flex-column"}>
            <Button
              disabled={!selectedRows.length || approveDisabled}
              onClick={approve}
              type="button"
              className="btn-primary text-white"
            >
              Approve
            </Button>
          </div>
        ) : (
          ""
        )}
        <div className={"d-flex flex-column"}>
          <Button
            disabled={!selectedRows.length}
            onClick={submit}
            type="button"
            className="btn-primary text-white"
          >
            Review payments
          </Button>
          <span>
            <b>Total: {formattedDollarValue(totalAmount)}</b>
          </span>
        </div>
      </div>
      <ReactTable
        onRowSelect={(rows) => setSelectedRows(rows)}
        selectedRowIds={defaultSelection}
        loading={loading}
        getData={getData}
        data={data}
        columns={columns}
        count={count}
        styles="transaction-table"
        numTd={7}
        // TODO figure out how to get infinite scroll to work with selection
        initialPageSize={500}
        infiniteScroll={false}
        noContentPlaceholder={
          <ContentPlaceHolder
            titleText={"No payments pending your approval."}
            icon={faCheckDouble}
          />
        }
      />
    </div>
  );
};

BulkApprovalSelect.propTypes = {};
