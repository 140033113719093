/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";

function SandwhichMenu(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || "100%";
  const height = props.height || "100%";
  const title = props.title || "menu 8";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <path d="M11,9H1a1,1,0,0,0,0,2H11a1,1,0,0,0,0-2Z" fill={fill} />
        <path d="M11,1H1A1,1,0,0,0,1,3H11a1,1,0,0,0,0-2Z" fill={fill} />
        <path d="M11,5H1A1,1,0,0,0,1,7H11a1,1,0,0,0,0-2Z" />
      </g>
    </svg>
  );
}

export default SandwhichMenu;
