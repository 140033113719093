import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

function unexpectedErrorAlert(
  hideAlert,
  setAlert,
  message = "Please contact support",
  title = "An unexpected error has occurred",
  onConfirm = () => {
    hideAlert();
  },
  confirmBtnText = "Okay, I will contact support"
) {
  hideAlert();
  setAlert(
    <SweetAlert
      danger
      title={title}
      onCancel={() => hideAlert()}
      onConfirm={onConfirm}
      confirmBtnText={confirmBtnText}
      showConfirm={true}
      showCancel={false}
    >
      {message}
    </SweetAlert>
  );
}

export default unexpectedErrorAlert;
