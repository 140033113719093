/* eslint-disable react-hooks/exhaustive-deps,react/prop-types,react/display-name */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CardBody } from "reactstrap";
import PropTypes from "prop-types";
import { allCompanyMembers } from "../../api_client/queries/users";
import useGQL from "../../api_client/UseGQL";
import ReactTable from "./ReactTable";
import UserCard from "../cards/UserCard";
import UserSkeletonCard from "../skeleton/UserSkeletonCard";
import { withApiValue } from "../../modules/WithApiValue";
import QueryString from "query-string";
import { useHistory, useLocation } from "react-router";
import { userPermissionNames } from "../../variables/UserPermissions";
const config = {
  identifier: true,
};

function UserTable(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  let gqlHooks = useGQL();
  const [count, setCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.update) {
      setUpdate(!update);
      history.push({ search: "" });
    }
  }, [location]);

  const getData = useCallback(
    async ({ pageSize }) => {
      setLoading(true);
      let offset = 0;
      const params = {
        limit: pageSize,
        offset: offset,
      };
      let output = {
        permission: true,
        identifier: true,
        canApprove: true,
        member: {
          identifier: true,
          profile: {
            firstName: true,
            lastName: true,
            phone: true,
          },
          email: true,
        },
      };
      const response = await allCompanyMembers(params, output, gqlHooks);
      if (response) {
        setData(response.data);
        setCount(response.count);
      }
      setLoading(false);
    },
    [update]
  );

  let columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell }) => (
          <Fragment>
            <div className="mt-2 mb-2">{cell.row.original.member.email}</div>
          </Fragment>
        ),
      },
      {
        Header: "Permission",
        accessor: "permission",
        Cell: ({ cell }) => (
          <Fragment>
            {userPermissionNames(cell.row.original.permission)}
          </Fragment>
        ),
      },
      {
        Header: "Phone",
        accessor: "phone",
        Cell: ({ cell }) => (
          <Fragment>{cell.row.original.member.profile.phone}</Fragment>
        ),
      },
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: ({ cell }) => (
          <Fragment>{cell.row.original.member.profile.firstName}</Fragment>
        ),
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: ({ cell }) => (
          <Fragment>{cell.row.original.member.profile.lastName}</Fragment>
        ),
      },
      {
        id: "approve",
        accessor: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: "Can Approve payments",
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <Fragment>
            {row.original.canApprove === false ? "No" : "Yes"}
          </Fragment>
        ),
      },
    ],
    []
  );

  return (
    <>
      <CardBody>
        <ReactTable
          loading={loading}
          getData={getData}
          data={data}
          columns={columns}
          count={count}
          cards={props.cards}
          overlayType="user"
          MobileCard={UserCard}
          MobileSkeleton={UserSkeletonCard}
        />
      </CardBody>
    </>
  );
}

export default withApiValue(UserTable, config);

UserTable.propTypes = {
  identifier: PropTypes.string,
  cards: PropTypes.bool,
};

UserTable.defaultProps = {
  cards: false,
};
