import { Route, Switch, useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { BulkDebitDetails } from "./BulkDebitDetails";
import DebitAccountStep from "../../steps/DebitAccountStep";
import { useSimpleContext } from "contexts/SimpleContext";
import { BulkDebitReview } from "./BulkDebitReview";
import { BulkDebitFailedPayments } from "./BulkDebitFailedPayments";
import { BulkDebitComplete } from "./BulkDebitComplete";

export const BulkDebit = () => {
  const history = useHistory();
  const [state] = useSimpleContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!state) {
      history.push("deposit-account", {
        steps: 5,
        currStep: 1,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null;
  } else {
    return (
      <>
        <Switch>
          <Route
            path="/new-payment/debit/bulk/deposit-account"
            render={() => <DebitAccountStep />}
          />
          <Route
            path="/new-payment/debit/bulk/details"
            render={() => <BulkDebitDetails />}
          />
          <Route
            path="/new-payment/debit/bulk/review"
            render={() => <BulkDebitReview />}
          />
          <Route
            path="/new-payment/debit/bulk/failed-payments"
            render={() => <BulkDebitFailedPayments />}
          />
          <Route
            path="/new-payment/debit/bulk/complete"
            render={() => <BulkDebitComplete />}
          />
        </Switch>
      </>
    );
  }
};
