import React from "react";
import { Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import { useHistory } from "react-router";
import {
  CreditCardWithdrawalAccount,
  WithdrawalAccount,
} from "./WithdrawalAccount";

function TransactionPaidFromCard(props) {
  const history = useHistory();
  return (
    <>
      <Row>
        <Col>
          <h4 className="font-weight-600 mt-0 mb-2">
            {props.transaction.direction === "INTERNAL"
              ? "Transfer from"
              : "Paid from"}
          </h4>
        </Col>
      </Row>
      <Card className="details-card shadow-sm border">
        {props.transaction.direction === "DEBIT" ? (
          <>
            <Row className="details-row border-bottom">
              <Col>
                <h5>Contact</h5>
                {props.transaction.contact ? (
                  <p
                    className="text-primary cursor-pointer"
                    onClick={() =>
                      history.push({
                        search: `overlay=true&type=contact&id=${props.transaction.contact.identifier}`,
                      })
                    }
                  >
                    {props.transaction.contact.name ||
                      props.transaction.contact.email}
                  </p>
                ) : (
                  <p className="text-muted font-italic">
                    Pending payment acceptance
                  </p>
                )}
              </Col>
              <Col sm="6">
                <h5>Delivery method</h5>
                <p>
                  {props.transaction.method === "EMAIL"
                    ? props.transaction.contact.email
                    : "Link"}
                </p>
              </Col>
            </Row>
            <Row className="details-row border-bottom">
              <Col>
                {props.transaction.customerCreditCard ? (
                  <CreditCardWithdrawalAccount
                    customerCreditCard={props.transaction.customerCreditCard}
                  />
                ) : (
                  <WithdrawalAccount
                    fromBankAccount={props.transaction.fromBankAccount}
                  />
                )}
              </Col>
            </Row>
          </>
        ) : props.transaction.direction === "CREDIT" ? (
          <>
            <Row className="details-row border-bottom">
              <Col>
                <h5>You</h5>
                <p>{props.transaction.company.name}</p>
              </Col>
            </Row>
            <Row className="details-row border-bottom">
              <Col>
                <WithdrawalAccount
                  fromBankAccount={props.transaction.fromBankAccount}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="details-row">
              <Col>
                <WithdrawalAccount
                  fromBankAccount={props.transaction.fromBankAccount}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>
    </>
  );
}

export default TransactionPaidFromCard;
TransactionPaidFromCard.propTypes = {
  transaction: PropTypes.object,
};
