import React from "react";
import {
  Badge,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import moment from "moment";

export function recurringStatus(recurringPlan) {
  const hasPayerAccount =
    recurringPlan.fromBankAccount || recurringPlan.customerCreditCard;
  let identifier = recurringPlan.identifier;
  identifier = identifier.replace(/-/g, "");
  identifier = identifier.replace(/[0-9]/g, "");

  let width = 80;
  if (recurringPlan.active) {
    // Active
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Active - {`${moment(recurringPlan.start).format("MMM DD, YYYY")}`}
          </PopoverHeader>
          <PopoverBody>
            Recurring plan is active. Next payment will be debited on{" "}
            {`${moment(recurringPlan.nextRun).format("MMM DD, YYYY")}`}
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="success"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Active
        </Badge>
      </React.Fragment>
    );
  } else if (recurringPlan.end && moment() > moment(recurringPlan.end)) {
    // Ended
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Ended - {`${moment(recurringPlan.end).format("MMM DD, YYYY")}`}
          </PopoverHeader>
          <PopoverBody>
            Recurring plan has ended. End date has passed. All payments have
            been initiated.
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="default"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Ended
        </Badge>
      </React.Fragment>
    );
  } else if (moment() > moment(recurringPlan.start) && !hasPayerAccount) {
    // Expired
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Expired - {`${moment(recurringPlan.start).format("MMM DD, YYYY")}`}
          </PopoverHeader>
          <PopoverBody>
            Recurring plan request has expired. You may send your contact
            another one.
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="danger"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Expired
        </Badge>
      </React.Fragment>
    );
  } else if (!hasPayerAccount) {
    // Unauthorized
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>
            Unauthorized -{" "}
            {`${moment(recurringPlan.createdAt).format("MMM DD, YYYY")}`}
          </PopoverHeader>
          <PopoverBody>
            Recurring plan has not been authorized yet. Expires on{" "}
            {`${moment(recurringPlan.start).format("MMM DD, YYYY")}`}
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="info"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Requested
        </Badge>
      </React.Fragment>
    );
  } else if (!recurringPlan.active) {
    // Cancelled
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Cancelled</PopoverHeader>
          <PopoverBody>Recurring plan was cancelled.</PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="danger"
          className="mb-0"
          style={{ width: width, cursor: "default" }}
          pill
        >
          Cancelled
        </Badge>
      </React.Fragment>
    );
  }
}
