import React, { Fragment, useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Col, Row } from "reactstrap";

const DatePickerModal = ({ onChange }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [shouldTriggerChange, setShouldTriggerChange] = useState(false);

  useEffect(() => {
    if (shouldTriggerChange) {
      onChange({ startDate, endDate });
      setShouldTriggerChange(false);
    }
  }, [startDate, endDate, onChange, shouldTriggerChange]);

  const handleDateRangeChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setShouldTriggerChange(true);
  };

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <DatePicker
            className="w-100 form-control"
            selected={startDate}
            onChange={(date) => handleDateRangeChange(date, endDate)}
            selectsStart
            value={startDate}
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            placeholderText="Start Date"
          />
        </Col>
        <Col md={6}>
          <DatePicker
            className="w-100 form-control"
            selected={endDate}
            onChange={(date) => handleDateRangeChange(startDate, date)}
            selectsEnd
            value={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default DatePickerModal;
