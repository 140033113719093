import React from "react";
import { Card } from "reactstrap";

class FlinksComplete extends React.Component {
  render() {
    return (
      <>
        <Card md={4} className="mr-auto ml-auto" style={{ height: "200px" }}>
          <h3 className="text-center mt-5 text-success">
            Bank Connection Complete
          </h3>
        </Card>
      </>
    );
  }
}

export default FlinksComplete;
