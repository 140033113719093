import {
  appName,
  CREDIT_CARD_FEE_RATE,
  CustomTooltip,
  formattedDollarValue,
} from "../../../modules/Helpers";
import { Button } from "reactstrap";
import React, { useState } from "react";
import PropTypes from "prop-types";

export const PlatformFee = ({ amount }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  return (
    <>
      <h5>Platform fee</h5>{" "}
      <CustomTooltip
        title={`${appName} charges a 3.5% platform fee for using this checkout system. This is not a credit card service fee`}
        placement="top"
        arrow
        open={tooltipIsOpen}
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(false)}
        leaveTouchDelay={10000}
      >
        <Button
          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
          variant="contained"
          className="btn-link tooltip-button"
        >
          <i className="fas fa-info-circle" />
        </Button>
      </CustomTooltip>
      <p>{formattedDollarValue(amount * CREDIT_CARD_FEE_RATE)}</p>
    </>
  );
};

PlatformFee.propTypes = {
  amount: PropTypes.string.isRequired,
};
