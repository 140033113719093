import React from "react";
import { Badge } from "reactstrap";

function ContactDetailsTagBadge(tags, identifier) {
  if (identifier) {
    identifier = identifier.replace(/-/g, "");
    identifier = identifier.replace(/" "/g, "");
    identifier = identifier.replace(/[0-9]/g, "");
  } else {
    identifier = "placeholderTextForTag";
  }

  return (
    <React.Fragment>
      {tags.map((tag, key) => (
        <Badge
          key={key}
          id={identifier}
          color="default"
          className="mb-2 mr-1 ml-0"
          pill
        >
          {tag}
        </Badge>
      ))}
    </React.Fragment>
  );
}

export default ContactDetailsTagBadge;

ContactDetailsTagBadge.propTypes = {};
