import SweetAlert from "react-bootstrap-sweetalert";
import React from "react";
import { domains } from "modules/Helpers";

function MicrodepositAlert(
  hideAlert,
  setAlert,
  message = "We've sent 2 micro-deposits to confirm ownership of the account. You will receive an email with further steps within the next business day.",
  title = "Account Verification In Progress"
) {
  hideAlert();
  setAlert(
    <SweetAlert
      success
      title={title}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      showConfirm={false}
      showCancel={false}
    >
      {message}{" "}
      <a
        href={`https://${domains.HELP}/how-to-verify-a-bank-account-with-micro-deposits`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </SweetAlert>
  );
}

export default MicrodepositAlert;
