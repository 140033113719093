import React, { Fragment } from "react";
import { Navbar } from "reactstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import { CustomLoader } from "./CustomLoader";

export const AdminLoader = (
  <Fragment>
    <div className="desktop">
      <Navbar
        color="white"
        expand="md"
        className="admin-navbar sticky-top justify-content-between"
      >
        <div className="row">
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
          <Skeleton
            className="ml-3"
            animation="wave"
            variant="text"
            width={75}
            height={40}
          />
          <Skeleton
            className="ml-3"
            animation="wave"
            variant="text"
            width={75}
            height={40}
          />
          <Skeleton
            className="ml-3"
            animation="wave"
            variant="text"
            width={75}
            height={40}
          />
          <Skeleton
            className="ml-3"
            animation="wave"
            variant="text"
            width={75}
            height={40}
          />
        </div>
        <div className="row">
          <Skeleton
            className="ml-3"
            animation="wave"
            variant="text"
            width={75}
            height={40}
          />
          <Skeleton
            className="ml-3 mt-2"
            animation="wave"
            variant="circle"
            width={25}
            height={25}
          />
          <Skeleton
            className="ml-3 mt-2"
            animation="wave"
            variant="circle"
            width={25}
            height={25}
          />
          <Skeleton
            className="ml-3 mt-2"
            animation="wave"
            variant="circle"
            width={25}
            height={25}
          />
          <Skeleton
            className="ml-3 mt-1"
            animation="wave"
            variant="text"
            width={75}
            height={30}
          />
        </div>
      </Navbar>
      <CustomLoader general={true} />
    </div>
    <div className="mobile">
      <Navbar
        color="white"
        expand="md"
        className="admin-navbar sticky-top justify-content-between"
      >
        <div>
          <Skeleton
            className=""
            animation="wave"
            variant="circle"
            width={35}
            height={35}
          />
        </div>
        <div>
          <Skeleton
            className="mt-1"
            animation="wave"
            variant="text"
            width={25}
            height={5}
          />
          <Skeleton
            className="mt-1"
            animation="wave"
            variant="text"
            width={25}
            height={5}
          />
          <Skeleton
            className="mt-1"
            animation="wave"
            variant="text"
            width={25}
            height={5}
          />
        </div>
      </Navbar>
      <CustomLoader general={true} />
    </div>
  </Fragment>
);
