/* eslint-disable react/prop-types */
import React from "react";

import { useSimpleContext } from "../contexts/SimpleContext";
import { PageNotAvailableError } from "../modules/error_wrappers/PageNotAvailableError";

export function BankTransfersGateway(props) {
  const [state] = useSimpleContext();
  const bankTransfersEnabled =
    state.transaction?.company.settings.eftEnabled ||
    state.recurringPlan?.company.settings.eftEnabled;
  if (!bankTransfersEnabled) {
    return (
      <PageNotAvailableError errorBodyText="Sorry, bank transfers are not enabled for this transaction." />
    );
  }

  return props.children;
}
