import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Card } from "reactstrap";
import { getBankIcon } from "../../modules/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import StatusBadgeBankAccount from "../badge/StatusBadgeBankAccount";

function BankCard(props) {
  const history = useHistory();

  if (!props.item) {
    return null;
  }

  return (
    <Fragment>
      <Card
        className="t-card border shadow-sm border"
        onClick={() =>
          history.push({
            search: `?overlay=true&type=bank&id=${props.item.identifier}`,
          })
        }
      >
        <div className="t-card-row align-items-center">
          <div className="bank-logo ">
            {getBankIcon(props.item.institution) ? (
              <img src={getBankIcon(props.item.institution)} alt="" />
            ) : (
              <FontAwesomeIcon icon={faUniversity} />
            )}
          </div>
          <b>{props.item.institution}</b>
          <div className="ml-auto">{StatusBadgeBankAccount(props.item)}</div>
        </div>
        <div className="t-card-row align-items-end">
          <div>
            <span>{props.item.title}</span>
            <p>
              {props.item.institutionNumber} {props.item.transitNumber}{" "}
              {props.item.accountNumber}
            </p>
          </div>
          {props.item.billing && (
            <div className="ml-auto">
              <span className="text-primary">Billing account</span>
            </div>
          )}
        </div>
      </Card>
    </Fragment>
  );
}

export default BankCard;

BankCard.propTypes = {
  item: PropTypes.object,
};
