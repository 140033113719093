import React, { Fragment } from "react";
import { Row } from "reactstrap";
import Verification from "./Verification";

function OnboardingVerifyIdentity() {
  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Verification />
      </Row>
    </Fragment>
  );
}

export default OnboardingVerifyIdentity;

OnboardingVerifyIdentity.propTypes = {};
