/* eslint-disable react/prop-types */
import React from "react";

function FlinksCompleteCheck(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || "100%";
  const height = props.height || "100%";
  const title = props.title || "c check 2";

  return (
    // <svg
    //   height={height}
    //   width={width}
    //   viewBox="0 0 16 16"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <title>{title}</title>
    //   <g fill={secondaryfill}>
    //     <path
    //       d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"
    //       fill={fill}
    //     />
    //   </g>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 79 79"
      fill="none"
    >
      <path
        d="M39.6125 0.294922C61.3235 0.294922 78.8896 17.8609 78.8896 39.5718C78.8896 61.2828 61.3235 78.849 39.6125 78.849C17.9016 78.849 0.335449 61.2828 0.335449 39.5718C0.335449 17.8609 18.099 0.294922 39.6125 0.294922Z"
        fill="#93C02D"
      />
      <path
        d="M57.7713 25.5586L30.1391 53.1907C29.547 53.7828 28.5602 53.5855 28.1654 52.9934L21.0601 41.5457"
        stroke="white"
        strokeWidth="6.90803"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FlinksCompleteCheck;
