import SweetAlert from "react-bootstrap-sweetalert";
import React from "react";

function OnlineBankLoginAlert(
  hideAlert,
  setAlert,
  message = "Please contact support",
  title = "Action required"
) {
  hideAlert();
  setAlert(
    <SweetAlert
      warning
      title={title}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      showConfirm={true}
      showCancel={false}
    >
      {message}
    </SweetAlert>
  );
}

export default OnlineBankLoginAlert;
