import { host } from "./client";

export async function callGQL(query, auth) {
  let response = await fetch(host, {
    method: "POST",
    headers: {
      Authorization: auth,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      query: query,
    }),
  });
  return await response.json();
}
