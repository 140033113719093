import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import ImageUpload from "../../../../components/custom_upload/ImageUpload";
import useForm from "../../../../hooks/UseForms";
import { companyNameValidation } from "../../../../modules/form_validation/CompanyValidation";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../../api_client/UseGQL";
import { setCompanyProfile } from "../../../../api_client/mutations/companyProfile";
import { CustomTooltip } from "../../../../modules/Helpers";
import { withApiValue } from "../../../../modules/WithApiValue";

const config = {
  company: {
    displayImage: true,
    name: true,
    fullName: true,
  },
};

function EditCompany(props) {
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    submit,
    companyNameValidation
  );
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const [tooltipStatementIsOpen, setTooltipStatementIsOpen] = useState(false);
  const [tooltipPublicNameIsOpen, setTooltipPublicNameIsOpen] = useState(false);

  useEffect(() => {
    setValues(props.company);
  }, []);

  async function submit() {
    setLoading(true);
    let output = {
      company: {
        name: true,
        fullName: true,
      },
    };
    const response = await setCompanyProfile(
      { name: values.name, fullName: values.fullName },
      output,
      gqlHooks
    );
    if (response) {
      props.update(response);
      addToast("Business name updated", {
        appearance: "success",
        autoDismiss: true,
      });
    }
    setLoading(false);
  }

  return (
    <>
      <div className="content">
        <Row className="mr-0 ml-0">
          <Col lg="6" className="d-flex flex-grow-1">
            <Card>
              <Form className="form" onSubmit={handleSubmit}>
                <CardBody>
                  <Label className="mt-2">
                    Business name statement descriptor
                  </Label>
                  <CustomTooltip
                    title=" Your business name statement descriptor is used on receipts
                  and bank statements (must be less than 15 characters)."
                    placement="top"
                    arrow
                    open={tooltipStatementIsOpen}
                    onOpen={() => setTooltipStatementIsOpen(true)}
                    onClose={() => setTooltipStatementIsOpen(false)}
                    leaveTouchDelay={10000}
                  >
                    <Button
                      onClick={() =>
                        setTooltipStatementIsOpen(!tooltipStatementIsOpen)
                      }
                      variant="contained"
                      className="btn-link tooltip-button"
                    >
                      <i className="fas fa-info-circle" />
                    </Button>
                  </CustomTooltip>
                  <FormGroup>
                    <Input
                      name="name"
                      type="text"
                      value={values.name || ""}
                      invalid={!!errors.name}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                  <Label>Public business name</Label>
                  <CustomTooltip
                    title="This name will be used in emails and content displayed to you, your customers and your suppliers."
                    placement="top"
                    arrow
                    open={tooltipPublicNameIsOpen}
                    onOpen={() => setTooltipPublicNameIsOpen(true)}
                    onClose={() => setTooltipPublicNameIsOpen(false)}
                    leaveTouchDelay={10000}
                  >
                    <Button
                      onClick={() =>
                        setTooltipPublicNameIsOpen(!tooltipPublicNameIsOpen)
                      }
                      variant="contained"
                      className="btn-link tooltip-button"
                    >
                      <i className="fas fa-info-circle" />
                    </Button>
                  </CustomTooltip>
                  <FormGroup>
                    <Input
                      name="fullName"
                      type="text"
                      value={values.fullName || ""}
                      invalid={!!errors.fullName}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.fullName}</FormFeedback>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    Update Name
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
          <Col lg="4">
            <ImageUpload companyImage={props.company?.displayImage} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default withApiValue(EditCompany, config);

EditCompany.propTypes = {
  company: PropTypes.object,
  update: PropTypes.func,
};
