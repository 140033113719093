import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function allRecurringPlans(input, output, hooks) {
  const query = buildQuery(input, output, "allRecurringPlans", true);
  return callQuery(query, hooks);
}

allRecurringPlans.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function publicRecurringPlan(input, output, hooks) {
  const query = buildQuery(input, output, "publicRecurringPlan", false);
  return callQuery(query, hooks);
}

publicRecurringPlan.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function getRecurringPlan(input, output, hooks) {
  const query = buildQuery(input, output, "recurringPlan", false);
  return callQuery(query, hooks);
}
