import React, { Fragment } from "react";
import ContactTagsTable from "../../../../components/tables/ContactTagsTable";

function ContactTags() {
  return (
    <Fragment>
      <ContactTagsTable />
    </Fragment>
  );
}

export default ContactTags;

ContactTags.propTypes = {};
