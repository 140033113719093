import React, { useState, useEffect } from "react";
import { updateUser } from "modules/Helpers";
import {
  Button,
  CardBody,
  CardFooter,
  Label,
  Form,
  Col,
  Row,
} from "reactstrap";
import { useAppValue } from "contexts/Context";
export default function EditProfileCard() {
  let [count, setCount] = useState(0);
  const [context, dispatch] = useAppValue();
  useEffect(() => {
    setCount(context.user.company.settings.requiredPaymentApprovals);
  }, [context.user]);
  function incrementCount() {
    if (count >= context.user.numberOfValidApprovers) {
      setCount(context.user.numberOfValidApprovers);
    } else {
      count = count + 1;
      const newUser = updateUser(context, count);
      dispatch({
        type: "setUser",
        user: newUser,
      });
      setCount(count);
    }
  }
  function decrementCount() {
    if (count <= 1) {
      setCount(1);
    } else {
      count = count - 1;
      const newUser = updateUser(context, count);
      dispatch({
        type: "setUser",
        user: newUser,
      });
      setCount(count);
    }
  }
  return (
    <>
      <Form className="form">
        <CardBody>
          <h3>Approvals</h3>
          <h4>Required Payment Approvals</h4>
          <Label>Enter Amount</Label>
          <Row className="count-row">
            <Col>
              <Button color="primary" onClick={incrementCount}>
                +
              </Button>
            </Col>
            <Col className="count-col text-center">{count}</Col>
            <Col>
              <Button color="primary" onClick={decrementCount}>
                -
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Form>
    </>
  );
}
