import { Button, Col } from "reactstrap";
import { CustomTooltip, initiatedDay } from "../../modules/Helpers";
import React from "react";
import PropTypes, { string } from "prop-types";

const INITIATE = "initiate";
const RETRY = "retry";

function InitiateDateInfo(props) {
  let { type, isOpen, setOpen } = props;

  return (
    <Col>
      <span>{type === INITIATE ? "Initiated" : "Retry"} date</span>
      <CustomTooltip
        title={`Payments ${
          type === INITIATE ? "initiated" : "retried"
        } after 3:00pm PST will initiate the next business day`}
        placement="top"
        arrow
        open={isOpen}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        leaveTouchDelay={10000}
      >
        <Button
          onClick={() => setOpen(!isOpen)}
          variant="contained"
          className="btn-link tooltip-button"
        >
          <i className="fas fa-info-circle" />
        </Button>
      </CustomTooltip>
      <h5>{initiatedDay()}</h5>
    </Col>
  );
}

export { InitiateDateInfo as default, INITIATE, RETRY };

InitiateDateInfo.propTypes = {
  type: PropTypes.oneOf(string[(INITIATE, RETRY)]),
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
};
