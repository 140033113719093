import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { convertToDate, CustomTooltip } from "../../modules/Helpers";
import ContactDetailsTagBadge from "../badge/ContactDetailsTagBadge";

function ContactInfoCard(props) {
  return (
    <Card className="details-card shadow-sm border mt-4">
      <Row className="details-row border-bottom">
        <Col>
          <h5>Email</h5>
          <p>{props.contact.email}</p>
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col className="details-row-button-wrapper">
          <h5 className="mb-1">Tags</h5>
          <p className="mb-2">
            {ContactDetailsTagBadge(
              props.contact.tags,
              props.contact.identifier
            )}
          </p>
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          <h5>Contact id</h5>
        </Col>
        <Col>
          <div>
            <CopyToClipboard
              onCopy={() => props.copyIdToClipboard()}
              text={props.contact.identifier}
            >
              <Button className="btn-link details-row-button">Copy</Button>
            </CopyToClipboard>
          </div>
        </Col>
        <Col sm="12">
          <p>
            {" "}
            <CustomTooltip title="Click to copy" placement="top" arrow>
              <CopyToClipboard
                onCopy={() => props.copyIdToClipboard()}
                text={props.contact.identifier}
              >
                <span style={{ cursor: "pointer" }}>
                  {props.contact.identifier}
                </span>
              </CopyToClipboard>
            </CustomTooltip>
          </p>
        </Col>
      </Row>
      <Row className="details-row border-bottom">
        <Col>
          <h5>Date created</h5>
          <p>{convertToDate(props.contact.createdAt)}</p>
        </Col>
      </Row>
    </Card>
  );
}

export default ContactInfoCard;
ContactInfoCard.propTypes = {
  contact: PropTypes.object,
  copyIdToClipboard: PropTypes.func,
};
