import React, { useContext, useState } from "react";

export const SimpleContext = React.createContext();

// eslint-disable-next-line react/prop-types
export const SimpleContextProvider = ({ children }) => {
  const [state, setState] = useState();
  const store = [state, setState];
  return (
    <SimpleContext.Provider value={store}>{children}</SimpleContext.Provider>
  );
};

export const useSimpleContext = () => useContext(SimpleContext);
