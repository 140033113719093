import { Row } from "reactstrap";
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";
import InitiateDateInfo, { RETRY } from "../generic_details/InitiateDateInfo";
import DepositDateInfo from "../generic_details/DepositDateInfo";

function TransactionRetryModal(props) {
  let { doRetry, setAlertState } = props;

  const [tooltipInitiatedIsOpen, setTooltipInitiatedIsOpen] = useState(false);
  const [tooltipDepositIsOpen, setTooltipDepositIsOpen] = useState(false);

  return (
    <SweetAlert
      onConfirm={() => doRetry()}
      onCancel={() => setAlertState(null)}
      title="Confirm Transaction Retry"
      showCancel
      showConfirm
      confirmBtnText="Retry"
      customClass="shadow"
    >
      Please ensure sufficient funds are available in the withdrawal account
      before continuing.
      <hr />
      <Row>
        <InitiateDateInfo
          type={RETRY}
          isOpen={tooltipInitiatedIsOpen}
          setOpen={setTooltipInitiatedIsOpen}
        />
      </Row>
      <Row>
        <DepositDateInfo
          isOpen={tooltipDepositIsOpen}
          setOpen={setTooltipDepositIsOpen}
        />
      </Row>
    </SweetAlert>
  );
}

export default TransactionRetryModal;

TransactionRetryModal.propTypes = {
  doRetry: PropTypes.func,
  setAlertState: PropTypes.func,
};
