import React, { Fragment, useEffect, useState } from "react";
import { usePagination, useRowSelect, useTable } from "react-table";
import { Card, CardBody, Fade, Table } from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Waypoint } from "react-waypoint";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { breakPoints } from "../../modules/Helpers";
import Skeleton from "@material-ui/lab/Skeleton";
import FabUpButton from "../buttons/FabUpButton";
import { baseUrl } from "../../modules/Helpers";

function ReactTable({
  columns,
  data,
  getData,
  loading,
  count,
  detailRoute,
  styles,
  MobileCard,
  MobileSkeleton,
  settingsRoute,
  responsive = false,
  overlayType,
  button = false,
  cards,
  numTd,
  updateMyData,
  filteredBillIds,
  onRowSelect,
  lastBillIdToggled,
  selectedRowIds = {},
  initialPageSize = 20,
  infiniteScroll = true,
  noContentPlaceholder = undefined,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: initialPageSize, selectedRowIds },
      manualPagination: true,
      updateMyData,
      filteredBillIds,
      lastBillIdToggled,
    },
    usePagination,
    useRowSelect
  );
  const history = useHistory();
  const screenSize = useWindowDimensions();
  const [infScrollLoading, setInfScrollLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    onRowSelect?.(selectedFlatRows);
  }, [onRowSelect, selectedFlatRows, selectedRowIds]);

  useEffect(() => {
    if (!MobileCard || !cards) {
      window.addEventListener("scroll", function () {
        if (window.scrollY === 0 && infiniteScroll) {
          setShowButton(false);
          if (pageSize > 60) {
            setPageSize(60);
          }
        } else {
          setShowButton(true);
        }
      });
    }
  }, [pageSize]);

  useEffect(() => {
    getData?.({ pageSize });
  }, [getData, pageSize]);

  useEffect(() => {
    if (!loading) {
      setInfScrollLoading(false);
    }
  }, [loading]);

  function handleBottomScroll() {
    if (!infiniteScroll) {
      return;
    }
    if (pageSize < count) {
      setPageSize(pageSize + 5);
    }
  }

  function handleBottomScrollTable() {
    if (!infiniteScroll) {
      return;
    }
    if (pageSize < count) {
      setInfScrollLoading(true);
      setPageSize(pageSize + 20);
    }
  }

  function createSkeleton() {
    const LoadSkeleton = [];
    for (let i = 0; i < 5; i++) {
      LoadSkeleton.push(
        <Fragment key={i}>
          <MobileSkeleton />
        </Fragment>
      );
    }
    return LoadSkeleton;
  }

  function createTableSkeleton() {
    const TableRowSkeleton = [];
    const TableRow = [];
    for (let i = 0; i < numTd; i++) {
      TableRow.push(
        <td key={i}>
          <Skeleton height={41} />
        </td>
      );
    }
    for (let i = 0; i < 20; i++) {
      TableRowSkeleton.push(<tr key={i}>{TableRow.map((td) => td)}</tr>);
    }
    return TableRowSkeleton;
  }

  if (!data.length && noContentPlaceholder) {
    return noContentPlaceholder;
  }

  if (cards) {
    return (
      <div>
        {data.map((item) => (
          <Fade key={item.identifier}>
            <MobileCard item={item} />
          </Fade>
        ))}
        {loading && createSkeleton()}
        {count > 10 && <Waypoint onEnter={() => handleBottomScroll} />}
      </div>
    );
  } else {
    return (
      <Fragment>
        <Card id="table" className={MobileCard && "desktop shadow-sm border"}>
          {pageSize > 60 && showButton && <FabUpButton />}
          <CardBody>
            <Fade>
              <Table
                responsive={screenSize.width < breakPoints.xl || responsive}
                hover
                {...getTableProps()}
                className={styles}
              >
                <thead>
                  {headerGroups.map((headerGroup, key) => (
                    <tr key={key} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          key={key}
                          {...column.getHeaderProps()}
                          className="font-weight-600 text-dark"
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        key={i}
                        className={`${overlayType && "cursor-pointer"}`}
                        {...row.getRowProps()}
                        onClick={(event) => {
                          const clickedCellIndex =
                            event.target.parentNode.innerText;
                          if (button) {
                            // add more conditionals here if you want this behaviour for other buttons
                            if (clickedCellIndex === "View") {
                              window.open(
                                baseUrl +
                                  "/payment/pay/" +
                                  row.original.identifier,
                                "_blank"
                              );
                            }
                          }
                          if (clickedCellIndex !== "View" && overlayType) {
                            if (settingsRoute) {
                              history.push(
                                `${detailRoute}?${row.original.identifier}${settingsRoute}`
                              );
                            } else {
                              history.push({
                                search: `?overlay=true&type=${overlayType}&id=${
                                  row.original.identifier ||
                                  row.original.member.identifier
                                }`,
                              });
                            }
                          }
                        }}
                      >
                        {row.cells.map((cell, key) => {
                          return (
                            <td key={key} {...cell.getCellProps()}>
                              {loading && !infScrollLoading ? (
                                <Skeleton height={41} />
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                  {infScrollLoading && createTableSkeleton()}
                </tbody>
              </Table>
            </Fade>
            {count > 20 &&
              !infScrollLoading &&
              !loading &&
              !(screenSize.width < breakPoints.md) && (
                <Waypoint onEnter={() => handleBottomScrollTable()} />
              )}
          </CardBody>
        </Card>
        {screenSize.width < breakPoints.md && MobileCard && (
          <div>
            {data.map((item) => (
              <Fade key={item.identifier}>
                <MobileCard key={item.identifier} item={item} />
              </Fade>
            ))}
            {loading && createSkeleton()}
            {count > 10 && <Waypoint onEnter={() => handleBottomScroll()} />}
          </div>
        )}
      </Fragment>
    );
  }
}

export default ReactTable;

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getData: PropTypes.func,
  resetSelectedRows: PropTypes.bool,
  setResetSelectedRows: PropTypes.func,
  loading: PropTypes.bool,
  count: PropTypes.number,
  detailRoute: PropTypes.string,
  hidePagination: PropTypes.bool,
  styles: PropTypes.string,
  MobileCard: PropTypes.func,
  MobileSkeleton: PropTypes.func,
  settingsRoute: PropTypes.string,
  responsive: PropTypes.bool,
  overlayType: PropTypes.string,
  cards: PropTypes.bool,
  numTd: PropTypes.number,
  updateMyData: PropTypes.func,
  toggleAllSelected: PropTypes.func,
  filteredBillIds: PropTypes.func,
  onRowSelected: PropTypes.func,
  lastBillIdToggled: PropTypes.func,
  selectedRowIds: PropTypes.func,
  onRowSelect: PropTypes.func,
  initialPageSize: PropTypes.number,
  infiniteScroll: PropTypes.bool,
  noContentPlaceholder: PropTypes.element,
};
