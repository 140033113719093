import React, { Fragment, useEffect, useState } from "react";
import OnboardingProgressBar from "../progress-bars/OnboardingProgressBar";
import LogoWithText from "../../assets/img/LogoWithText.png";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";
import { ORGANIZATION, appName } from "../../modules/Helpers";
import { withApiValue } from "../../modules/WithApiValue";

const config = {
  kyc: { updateRequired: true, resubmitted: true },
  company: { companyType: true, kyc: { updateRequired: true } },
};

function OnboardingNavbav(props) {
  const [updateStatus, setUpdateStatus] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setTimeout(function () {
      setDisabled(false);
    }, 500);
  }, [location.pathname]);

  useEffect(() => {
    if (
      props.kyc.updateRequired ||
      props.company.kyc.updateRequired ||
      props.kyc.resubmitted
    ) {
      setUpdateStatus(true);
    }
  }, [props.company, props.kyc]);

  function skip() {
    switch (location.pathname) {
      case "/onboarding/concierge":
        history.push("/onboarding/business-type");
        break;
      case "/onboarding/business-type":
        history.push("/onboarding/profile");
        break;
      case "/onboarding/profile":
        props.company.companyType === ORGANIZATION
          ? history.push("/onboarding/organization-legal")
          : history.push("/onboarding/individual-legal");
        break;
      case "/onboarding/individual-legal":
        history.push("/onboarding/organization-owners");
        break;
      case "/onboarding/organization-legal":
        history.push("/onboarding/organization-owners");
        break;
      case "/onboarding/organization-owners":
        history.push("/onboarding/identity");
        break;
      case "/onboarding/identity":
        history.push("/onboarding/add-bank");
        break;
      case "/onboarding/add-bank":
        props.company.companyType === ORGANIZATION
          ? history.push("/onboarding/organization-documents")
          : history.push("/onboarding/security");
        break;
      case "/onboarding/organization-documents":
        history.push("/onboarding/security");
        break;
      case "/onboarding/security":
        history.push("/onboarding/complete");
        break;
    }
  }

  function back() {
    switch (location.pathname) {
      case "/onboarding/business-type":
        history.push("/onboarding/concierge");
        break;
      case "/onboarding/profile":
        history.push("/onboarding/business-type");
        break;
      case "/onboarding/individual-legal":
        history.push("/onboarding/profile");
        break;
      case "/onboarding/organization-legal":
        history.push("/onboarding/profile");
        break;
      case "/onboarding/organization-owners":
        history.push("/onboarding/organization-legal");
        break;
      case "/onboarding/identity":
        props.company.companyType === ORGANIZATION
          ? history.push("/onboarding/organization-owners")
          : history.push("/onboarding/individual-legal");
        break;
      case "/onboarding/add-bank":
        history.push("/onboarding/identity");
        break;
      case "/onboarding/organization-documents":
        updateStatus
          ? history.push("/onboarding/identity")
          : history.push("/onboarding/add-bank");
        break;
      case "/onboarding/security":
        props.company.companyType === ORGANIZATION
          ? history.push("/onboarding/organization-documents")
          : history.push("/onboarding/add-bank");
        break;
      case "/onboarding/complete":
        updateStatus
          ? props.company.companyType === ORGANIZATION
            ? history.push("/onboarding/organization-documents")
            : history.push("/onboarding/identity")
          : history.push("/onboarding/security");
        break;
    }
  }

  return (
    <Fragment>
      <OnboardingProgressBar companyType={props.company.companyType} />
      <div className="d-flex justify-content-between">
        <div
          className={`${
            location.pathname === "/onboarding/concierge" && "invisible d-none"
          }`}
        >
          <Button
            type="button"
            className="btn btn-link font-weight-600 btn-primary d-flex mt-2"
            onClick={() => {
              setDisabled(true);
              back();
            }}
            color={"links"}
            disabled={disabled}
          >
            <FontAwesomeIcon
              style={{ marginRight: "8px", fontSize: "18px" }}
              icon={faAngleLeft}
            />
            Back
          </Button>
        </div>
        <div
          className={`${
            location.pathname !== "/onboarding/concierge" && "invisible d-none"
          }`}
        >
          <Button
            type="button"
            className="btn btn-link font-weight-600 btn-primary d-flex mt-2"
            onClick={() => {
              setDisabled(true);
              history.push("/admin/payments");
            }}
            color={"primary"}
            disabled={disabled}
          >
            <FontAwesomeIcon
              style={{ marginRight: "8px", fontSize: "18px" }}
              icon={faAngleLeft}
            />
            Dashboard
          </Button>
        </div>
        <div className="pt-3" style={{ maxWidth: "150px" }}>
          <img src={LogoWithText} alt={`${appName} Logo`} />
        </div>
        <div
          className={`${
            location.pathname === "/onboarding/complete" && "invisible"
          }`}
        >
          {!updateStatus ? (
            <Button
              type="button"
              className="btn btn-link font-weight-600 btn-primary d-flex mt-2"
              onClick={() => {
                setDisabled(true);
                skip();
              }}
              color={"links"}
              disabled={disabled}
            >
              {location.pathname === "/onboarding/concierge"
                ? "I got this"
                : "Skip"}
              <FontAwesomeIcon
                style={{ marginLeft: "8px", fontSize: "18px" }}
                icon={faAngleRight}
              />
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default withApiValue(OnboardingNavbav, config, () => null);

OnboardingNavbav.propTypes = {
  company: PropTypes.object,
  kyc: PropTypes.object,
};
