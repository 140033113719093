import React, { Fragment, useEffect } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import useForm from "../../hooks/UseForms";
import PropTypes from "prop-types";
import { customFilter } from "../../modules/Helpers";
import SearchIcon from "../../assets/icons/SearchIcon";

function SelectBillsTableSearch(props) {
  const { values, setValues, handleChange } = useForm(
    () => null,
    () => null
  );

  useEffect(() => {
    if (values.search !== undefined) {
      const timeoutId = setTimeout(() => {
        if (props.bills.length > 0) {
          let billIds = [];
          props.bills.map((bill) => {
            if (
              customFilter(
                {
                  data: {
                    name: bill.contactName.value,
                    email: bill.contactEmail.value,
                  },
                },
                values.search
              )
            ) {
              billIds.push(bill.billId.value);
            }
          });
          props.setFilteredBills(billIds);
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [values.search]);

  return (
    <Fragment>
      <div className="d-flex flex-grow-1 custom-search-container">
        <InputGroup
          className={`input-group-focus custom-search-input rounded mt-0 mb-0 mr-3`}
        >
          <InputGroupAddon
            addonType="prepend"
            className="custom-search-input mt-0 rounded"
          >
            <InputGroupText className="custom-search-input">
              <SearchIcon
                fill={"gray"}
                width={15}
                height={15}
                strokewidth={6}
              />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            name="search"
            value={values.search || ""}
            onChange={handleChange}
            placeholder="Search by contact..."
            className="custom-search-input mt-0"
          />
          <InputGroupAddon
            onClick={() => setValues({ search: "" })}
            addonType="append"
            className={`custom-search-input mt-0 append-clear-container ${
              (values[props.bills.contactName] === "" || !values.search) &&
              "hide-close"
            }`}
          >
            <i className="tim-icons icon-simple-remove" />
          </InputGroupAddon>
        </InputGroup>
      </div>
    </Fragment>
  );
}

export default SelectBillsTableSearch;

SelectBillsTableSearch.propTypes = {
  bills: PropTypes.array.isRequired,
  setFilteredBills: PropTypes.func.isRequired,
};
