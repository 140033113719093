/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from "react";
import { Col, Fade, Row } from "reactstrap";
import VerificationImage from "../../../assets/img/verification/FaceVerificationIcon.png";
import VerifyButton from "@passbase/button/react";
import { useToasts } from "react-toast-notifications";
import { reportVerificationComplete } from "../../../modules/analytics";
import { updatePassbaseReferenceId } from "../../../api_client/mutations/verification";
import useGQL from "../../../api_client/UseGQL";
import { publicUser } from "../../../api_client/queries/user";
import { CustomLoader } from "../../../components/loaders/CustomLoader";
import success from "../../../assets/img/onboarding/VerificationComplete.svg";
import { appName, domains } from "../../../modules/Helpers";

function RetryPassbase(props) {
  const { addToast } = useToasts();

  let gqlHooks = useGQL();
  const [loading, setLoading] = useState(true);
  const [currUser, setCurrUser] = useState({});
  const [passbaseSubmitted, setPassbaseSubmitted] = useState(false);

  useEffect(() => {
    getPublicUser();
  }, []);

  function getPublicUser() {
    const { userId } = props.match.params;
    const input = {
      userId: userId,
    };

    const output = {
      identifier: true,
      email: true,
      isActive: true,
      createdAt: true,
      kycApproved: true,
    };
    publicUser(input, output, gqlHooks).then((response) => {
      if (response) {
        if (response.data.email) {
          setCurrUser(response.data);
          setLoading(false);
        }
      }
    });
  }

  function onFinish(referenceId) {
    const { userId, previousReferenceId } = props.match.params;
    setLoading(true);
    updatePassbaseReferenceId(
      {
        referenceId: referenceId,
        userId: userId,
        previousReferenceId: previousReferenceId,
      },
      { ok: true },
      gqlHooks
    ).then((response) => {
      if (response) {
        if (response.ok) {
          getPublicUser();
        }
        setPassbaseSubmitted(true);
      }
      setLoading(false);
    });
    reportVerificationComplete(gqlHooks);
  }

  function onError(errorCode) {
    if (errorCode === "CANCELLED_BY_USER") {
      addToast(
        `Please try again. If you are experiencing issues, contact ${appName} support`,
        {
          appearance: "info",
          autoDismiss: true,
        }
      );
    } else {
      addToast(
        `Please try again. If you are experiencing issues, contact ${appName} support`,
        {
          appearance: "info",
          autoDismiss: true,
        }
      );
    }
  }

  if (loading) {
    return (
      <Fragment>
        <CustomLoader size={25} />
      </Fragment>
    );
  } else if (currUser.kycApproved === false && !passbaseSubmitted) {
    return (
      <Fragment>
        {" "}
        <Col className="m-auto text-center" style={{ maxWidth: 525 }}>
          <Fade>
            <div className="d-flex justify-content-center">
              <img
                width={100}
                height={100}
                src={VerificationImage}
                alt={appName}
                className="mb-3"
              />
            </div>
            <h2 className="text-center mb-2 font-weight-600">
              Verify your identity
            </h2>
            <p className="text-muted">
              Please have a <b>government-issued ID</b> ready to complete this
              step (e.g. passport or driver&rsquo;s license). This process will
              take <b>about 5 minutes</b> and help us build a secure system
              together.{" "}
              <a
                href={`https://${domains.HELP}/why-does-mazumago-need-this-information`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </p>
            <Row className="justify-content-center mt-4">
              <VerifyButton
                apiKey={
                  process.env.REACT_APP_PASSBASE
                    ? process.env.REACT_APP_PASSBASE
                    : "hWETqYzwQxNWk3WzLmJ5ht98ntdH3d3ZyDmAxDjtxjGrUCfencC97cqAYTIVSbYX"
                }
                onFinish={onFinish}
                onError={onError}
                onStart={() => {}}
                prefillAttributes={{ email: currUser.email }}
              />
            </Row>
          </Fade>
        </Col>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Col className="m-auto text-center" style={{ maxWidth: 525 }}>
          <Fade>
            <div className="text-center">
              <img
                src={success}
                alt="Identity Verification Submitted"
                width="100"
                className="m-auto d-block"
              />
              <h2 className="text-center mt-3 mb-2 font-weight-600">
                Identity Verification Submitted!
              </h2>
              <p className="text-muted">
                Thank you for taking the time to verify your identity. You can
                now close this page and continue with your previous session.
              </p>
            </div>
          </Fade>
        </Col>
      </Fragment>
    );
  }
}

export default RetryPassbase;
