import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export function mixpanelEvent(input, output, hooks) {
  const query = buildMutation(input, output, "mixpanelEvent");
  return callMutation(query, hooks);
}

mixpanelEvent.propTypes = {
  input: PropTypes.shape({
    eventName: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
