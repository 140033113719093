import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function allContacts(input, output, hooks) {
  const query = buildQuery(input, output, "allContacts", true);
  return callQuery(query, hooks);
}

allContacts.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function getContact(input, output, hooks) {
  const query = buildQuery(input, output, "contact", false);
  return callQuery(query, hooks);
}

getContact.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function publicContact(input, output, hooks) {
  const query = buildQuery(input, output, "publicContact");
  return callQuery(query, hooks);
}

publicContact.propTypes = {
  input: PropTypes.shape({
    contactId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function allContactTags(input, output, hooks) {
  const query = buildQuery(input, output, "allContactTags");
  return callQuery(query, hooks);
}

allContactTags.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};
