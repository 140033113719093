import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Label, Row } from "reactstrap";
import TagsSelect from "../custom_select/TagsSelect";
import Select from "react-select";

function RecurringPlansFilters(props) {
  const [status, setStatus] = useState(undefined);
  const [tags, setTags] = useState();

  useEffect(() => {
    props.setFilters({
      ...props.filters,
      contactTag: tags?.label || undefined,
    });
  }, [tags]);

  useEffect(() => {
    props.setFilters({
      ...props.filters,
      active: status?.value || undefined,
    });
  }, [status]);

  return (
    <Fragment>
      <Row>
        <Col md={3}>
          <Label>Tags</Label>
          <FormGroup>
            <TagsSelect
              isMulti={false}
              activeTags={tags}
              onChangeCallback={(value) => setTags(value)}
              isClearable
              clear={() => setTags(undefined)}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <Label>Status</Label>
          <Select
            isClearable
            className="react-select default"
            classNamePrefix="react-select"
            onChange={(value) => setStatus(value)}
            value={status}
            options={[
              {
                value: undefined,
                label: "All",
              },
              { label: "Active", value: true },
              { label: "Expire/Cancelled", value: false },
            ]}
            clear={() =>
              setStatus({
                value: undefined,
                label: "",
              })
            }
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default RecurringPlansFilters;

RecurringPlansFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};
