import React, { Fragment } from "react";
import { Col } from "reactstrap";
import SecurityIcon from "../../assets/icons/SecurityIcon";
import { appName, domains } from "../../modules/Helpers";

function CheckoutSecurityBanner() {
  return (
    <Fragment>
      <hr />
      <Col sm="12" className="text-center pt-1 pb-4">
        <SecurityIcon
          className="pull-right"
          fill="#8125D2"
          width={25}
          height={25}
        />
        <span className="ml-2 font-weight-700 text-sm">
          {appName} secure 256-bit SSL encrypted payments
        </span>
        <small className="pt-2 pb-1 text-muted ml-1">
          <br />
          Visit{" "}
          <a
            href={`https://${domains.PRIMARY}/security`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {domains.PRIMARY}/security
          </a>{" "}
          to learn about our security standards.
        </small>
        <br />
        <br />
      </Col>
    </Fragment>
  );
}

export default CheckoutSecurityBanner;

CheckoutSecurityBanner.propTypes = {};
