import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PropTypes from "prop-types";
import useForm from "../../../../hooks/UseForms";
import { registerFormValidation } from "../../../../modules/form_validation/RegisterFormValidation";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import useGQL from "../../../../api_client/UseGQL";
import { createCompanyInvite } from "../../../../api_client/mutations/companyProfile";
import successAlert from "../../../../components/sweet_alert/SuccessAlert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UserPermissions from "../../../../variables/UserPermissions";
import { enumValue } from "../../../../modules/Helpers";
import PermissionsTooltip from "../../../../components/tooltips/PermissionsTooltip";

function AddUserModal(props) {
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    submit,
    registerFormValidation
  );
  const [permissionsTooltipOpen, setPermissionsTooltipOpen] = useState(false);
  let history = useHistory();
  const [state, setState] = useState({
    permission: 0,
    code: "",
    loading: false,
    isOpen: false,
  });
  const { addToast } = useToasts();
  let gqlHooks = useGQL();

  async function submit() {
    setState((state) => ({ ...state, loading: true }));
    let input = {
      email: values.email,
      permission: enumValue(values.permission || UserPermissions.ADMIN),
    };
    let output = {
      companyInvite: {
        identifier: true,
        securityPin: true,
      },
    };
    const response = await createCompanyInvite(input, output, gqlHooks);
    if (response) {
      if (response.companyInvite) {
        successAlert(
          () => props.setAlert(null),
          props.setAlert,
          <Fragment>
            <h3 className="ml-auto text-center">
              {response.companyInvite.securityPin}
            </h3>
            <CopyToClipboard text={response.companyInvite.securityPin}>
              <Button
                color="primary"
                className="btn-simple"
                size="md"
                block
                onClick={() => {
                  copyToClipboard();
                  props.setAlert(null);
                }}
              >
                COPY {response.companyInvite.securityPin}
              </Button>
            </CopyToClipboard>
          </Fragment>,
          "User invited, please send them this code"
        );
        setValues({});
        setState((state) => ({
          ...state,
          code: response.companyInvite.securityPin,
        }));
        props.toggle();
      } else {
        addToast("User added", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    }
    setState((state) => ({ ...state, loading: false }));
  }

  const copyToClipboard = () => {
    addToast("Copied to clipboard, send to your employee", {
      appearance: "success",
      autoDismiss: true,
    });
    history.push("/admin/settings#users", {
      active: "#invites",
    });
  };

  return (
    <>
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader>
          <h3>Add user</h3>
          <span className="text-default">
            We will generate an access code and send this user an email to
            register with your company.
            <br />
            <br />
            You will have to send them the access code.
          </span>
        </ModalHeader>
        <Form className="form" onSubmit={handleSubmit}>
          <ModalBody>
            <Col>
              <Label>User Email *</Label>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  value={values.email || ""}
                  required
                  onChange={handleChange}
                  invalid={!!errors.email}
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>
              <Label>Permission *</Label>
              <PermissionsTooltip
                permissionsTooltipOpen={permissionsTooltipOpen}
                setPermissionsTooltipOpen={setPermissionsTooltipOpen}
              />
              <FormGroup className="radio-form">
                <div>
                  <Input
                    type="radio"
                    name="permission"
                    value={UserPermissions.OWNER}
                    checked={
                      (values.permission || UserPermissions.ADMIN) ===
                      UserPermissions.OWNER
                    }
                    onChange={handleChange}
                  />
                  Owner
                </div>
                <div>
                  <Input
                    type="radio"
                    name="permission"
                    value={UserPermissions.ADMIN}
                    checked={
                      (values.permission || UserPermissions.ADMIN) ===
                      UserPermissions.ADMIN
                    }
                    onChange={handleChange}
                  />
                  Admin (default)
                </div>
                <div>
                  <Input
                    type="radio"
                    name="permission"
                    value={UserPermissions.USER}
                    checked={
                      (values.permission || UserPermissions.ADMIN) ===
                      UserPermissions.USER
                    }
                    onChange={handleChange}
                  />
                  User
                </div>
              </FormGroup>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={state.loading}
              className="btn-link"
              color="default"
              type="button"
              onClick={props.toggle}
            >
              Cancel
            </Button>
            <Button
              disabled={state.loading}
              className="btn pull-right"
              color="primary"
              type="submit"
            >
              Add User
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default AddUserModal;

AddUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};
