import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function allCompanyNotifications(input, output, hooks) {
  const query = buildQuery(input, output, "allCompanyNotifications", true);
  return callQuery(query, hooks);
}

allCompanyNotifications.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function unreadNotifications(input, output, hooks) {
  const query = buildQuery(input, output, "unreadNotifications");
  return callQuery(query, hooks);
}

unreadNotifications.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};
