/* eslint-disable react-hooks/exhaustive-deps,react/prop-types,react/display-name */
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Button, CardBody } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import { allCompanyInvites } from "../../api_client/queries/users";
import useGQL from "../../api_client/UseGQL";
import { deleteCompanyInvite } from "../../api_client/mutations/companyProfile";
import ReactTable from "./ReactTable";
import InviteCard from "../cards/InviteCard";
import TransactionCardSkeleton from "../skeleton/TransactionCardSkeleton";
import { userPermissionNames } from "../../variables/UserPermissions";

function InviteTable(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState(null);
  const { addToast } = useToasts();
  let gqlHooks = useGQL();
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const getData = useCallback(
    async ({ pageSize }) => {
      if (!props.refresh || refresh) {
        setLoading(true);
        let offset = 0;
        const params = {
          limit: pageSize,
          offset: offset,
        };
        let output = {
          identifier: true,
          securityPin: true,
          email: true,
          permission: true,
        };
        const response = await allCompanyInvites(params, output, gqlHooks);
        if (response) {
          setData(response.data);
          setCount(response.count);
        }
        setLoading(false);
        setRefresh(false);
      }
    },
    [props.refresh, refresh]
  );

  function confirmDelete(identifier, email) {
    let title = `Are you sure you want to delete invite to ${email}?`;
    setAlertState(
      <SweetAlert
        warning
        onConfirm={() => deleteInvite(identifier)}
        onCancel={() => setAlertState(null)}
        showConfirm={true}
        showCancel={true}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      >
        <span className="font-weight-600">{title}</span>
      </SweetAlert>
    );
  }

  const deleteInvite = async (identifier) => {
    setAlertState(null);
    let input = {
      companyInviteId: identifier,
    };
    let output = {
      ok: true,
    };
    const response = await deleteCompanyInvite(input, output, gqlHooks);
    if (response) {
      setRefresh(true);
      addToast("Invite deleted", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };

  const copyToClipboard = () => {
    addToast("Copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  let columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell }) => (
          <Fragment>
            <div className="mt-2 mb-2">{cell.row.original.email}</div>
          </Fragment>
        ),
      },
      {
        Header: "Permission",
        accessor: "permission",
        Cell: ({ cell }) => (
          <Fragment>
            {userPermissionNames(cell.row.original.permission)}
          </Fragment>
        ),
      },
      {
        Header: "Access Code",
        accessor: "securityPin",
        Cell: ({ cell }) => (
          <Fragment>
            <CopyToClipboard text={cell.row.original.securityPin}>
              <Button className="btn-link p-0" onClick={copyToClipboard}>
                <i className="tim-icons icon-lock-circle" />{" "}
                {cell.row.original.securityPin}
              </Button>
            </CopyToClipboard>
          </Fragment>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <Fragment>
            <Button
              onClick={() =>
                confirmDelete(
                  cell.row.original.identifier,
                  cell.row.original.email
                )
              }
              color="danger"
              className="p-0 btn-link"
              size="sm"
            >
              Delete <i className="tim-icons icon-simple-remove" />
            </Button>
          </Fragment>
        ),
      },
    ],
    []
  );

  return (
    <>
      {alertState}
      <CardBody>
        <ReactTable
          loading={loading}
          getData={getData}
          data={data}
          columns={columns}
          count={count}
          MobileCard={(props) => (
            <InviteCard
              {...props}
              removeInvite={(identifier, email) =>
                confirmDelete(identifier, email)
              }
            />
          )}
          MobileSkeleton={TransactionCardSkeleton}
        />
      </CardBody>
    </>
  );
}

export default InviteTable;

InviteTable.propTypes = {};

InviteTable.defaultProps = {};
