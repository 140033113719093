import { useEffect, useState } from "react";
import { currentUser } from "../api_client/queries/user";
import useGQL from "../api_client/UseGQL";
import { useAppValue } from "../contexts/Context";

function useApiValue(data = null) {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(true);
  const gqlHooks = useGQL();
  const [context, dispatch] = useAppValue();

  useEffect(() => {
    if (data !== null) {
      let stateOutput = { ...data };
      currentUser({}, stateOutput, gqlHooks).then((response) => {
        if (response) {
          setValues(response.data);
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(context.updates).length !== 0) {
      let tempVal = {};
      Object.keys(values).forEach(function (key) {
        if (context.updates[key]) {
          tempVal[key] = context.updates[key];
          setValues({ ...values, [key]: { ...values[key], ...tempVal[key] } });
        }
      });
    }
  }, [context.updates]);

  function update(newData) {
    dispatch({ type: "setUpdates", updates: newData });
  }

  return { values, update, loading, setLoading };
}

export default useApiValue;
