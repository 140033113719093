import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPencil,
  faTimes,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  changeMemberPermissions,
  deleteCompanyMember,
} from "../../../../api_client/mutations/companyProfile";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../../api_client/UseGQL";
import { allCompanyMembers } from "../../../../api_client/queries/users";
import { useHistory } from "react-router";
import { CustomTooltip, enumValue } from "../../../../modules/Helpers";
import { withApiValue } from "../../../../modules/WithApiValue";
import { userPermissionNames } from "../../../../variables/UserPermissions";
import ChangeUserPermissionModal from "../../../../components/modals/ChangeUserPermissionModal";
import { useAppValue } from "contexts/Context";

const config = {
  identifier: true,
};

function UserDetails(props) {
  const { addToast } = useToasts();
  const [alertState, setAlertState] = useState(null);
  let gqlHooks = useGQL();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [user, setUser] = useState();
  const [context, dispatch] = useAppValue();
  // const {changingPermission, setChangingPermission} = useState();
  function getMemberInfo() {
    setLoading(true);
    props.setBackdropClick(true);
    const params = {
      limit: 999,
      offset: 0,
    };
    let output = {
      permission: true,
      canApprove: true,
      member: {
        identifier: true,
        profile: {
          firstName: true,
          lastName: true,
          phone: true,
        },
        email: true,
      },
    };
    allCompanyMembers(params, output, gqlHooks).then((response) => {
      if (response && response.data) {
        setUser(
          response.data.filter(
            (member) => member.member.identifier === props.id
          )[0]
        );
        setLoading(false);
        props.setBackdropClick(false);
      }
    });
  }

  useEffect(() => {
    if (props.id) {
      getMemberInfo();
    } else {
      history.push({ search: "" });
    }
  }, []);

  function confirmDelete() {
    let title = `Are you sure you want to delete user ${user.member.email}?`;
    setAlertState(
      <SweetAlert
        warning
        title={title}
        onConfirm={() => removeEmployee(user.member.identifier)}
        onCancel={() => setAlertState(null)}
        showConfirm={true}
        showCancel={true}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      />
    );
  }

  async function removeEmployee(identifier) {
    setAlertState(null);
    setLoading(true);
    props.setBackdropClick(true);
    let input = {
      companyMemberId: identifier,
    };
    let output = {
      ok: true,
    };
    const response = await deleteCompanyMember(input, output, gqlHooks);
    if (response) {
      addToast("User deleted", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push({ search: "update=true" });
    }
    setLoading(false);
    props.setBackdropClick(false);
  }

  function editPermission() {
    setAlertState(
      <ChangeUserPermissionModal
        currentPermission={user?.permission}
        savePermission={savePermission}
      />
    );
  }

  async function savePermission(permission, canApprove) {
    if (permission && user) {
      setAlertState(null);
      setLoading(true);
      props.setBackdropClick(true);
      let input = {
        companyMemberId: user?.member.identifier,
        permission: enumValue(permission),
        canApprove: canApprove,
      };
      let output = {
        ok: true,
      };
      const response = await changeMemberPermissions(input, output, gqlHooks);
      if (response && response.ok) {
        addToast("Permissions changed", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push({ search: "update=true" });
      }
      setLoading(false);
      props.setBackdropClick(false);
    } else if (user) {
      setAlertState(null);
      setLoading(true);
      props.setBackdropClick(true);
      let input = {
        companyMemberId: user?.member.identifier,
        permission: enumValue(user.permission),
        canApprove: canApprove,
      };
      console.log(input);
      let output = {
        ok: true,
      };
      const response = await changeMemberPermissions(input, output, gqlHooks);
      if (response && response.ok) {
        addToast("Permissions changed", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push({ search: "update=true" });
      }
      setLoading(false);
      props.setBackdropClick(false);
    } else {
      setAlertState(null);
    }
  }

  if (loading) {
    return null;
  }

  return (
    <>
      {alertState}
      <div className="content">
        <Row>
          <Col>
            <p className="text-muted mb-0 mt-2">
              {userPermissionNames(user?.permission)}
            </p>
          </Col>
          <Col>
            <Button
              className="btn-link details-close-btn"
              disabled={loading}
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <UncontrolledDropdown className="pull-right">
              <div className="details-action-wrapper">
                <DropdownToggle className="details-more-btn" nav>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </DropdownToggle>
              </div>
              <DropdownMenu
                right
                className="custom-dropdown-menu details-actions-dropdown"
              >
                {props.identifier !== user?.member.identifier ? (
                  <DropdownItem className="text-danger" onClick={confirmDelete}>
                    <span className="d-inline-block font-weight-600 text-danger">
                      Delete
                    </span>
                  </DropdownItem>
                ) : (
                  <DropdownItem disabled>
                    <span className="d-inline-block font-weight-600 text-gray">
                      Delete
                    </span>
                    <CustomTooltip
                      title="Cannot delete yourself"
                      placement="top"
                      arrow
                    >
                      <i className="fas fa-info-circle ml-2" />
                    </CustomTooltip>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <FontAwesomeIcon
              style={{ fontSize: "30px" }}
              className="pull-left mr-2"
              icon={faUserCircle}
            />
            <div className="pull-left">
              <h3 className="font-weight-600 d-inline-block mb-0">
                {user?.member?.profile.firstName}{" "}
                {user?.member?.profile.lastName}
              </h3>
            </div>
          </Col>
        </Row>
        <Card className="details-card shadow-sm border mt-4">
          <Row className="details-row border-bottom">
            <Col>
              <h5>Email address</h5>
              <p>{user?.member.email}</p>
            </Col>
          </Row>
          <Row className="details-row border-bottom">
            <Col>
              <h5>Phone number</h5>
              <p>{user?.member.profile.phone}</p>
            </Col>
          </Row>
          <Row className="details-row border-bottom">
            <Col>
              <h5>Permission</h5>
              <p>{userPermissionNames(user?.permission)}</p>
            </Col>
            <Col className="col-1">
              <Button
                className="btn-link details-edit-btn"
                onClick={editPermission}
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            </Col>
          </Row>
          {context.user.isOwner ? (
            <Row className="details-row border-bottom">
              <Col>
                <h5>Can approve payments</h5>
                <>
                  <input
                    type="checkbox"
                    defaultChecked={user.canApprove}
                    onChange={() => savePermission(null, !user.canApprove)}
                  />
                </>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Card>
      </div>
    </>
  );
}

export default withApiValue(UserDetails, config);
UserDetails.propTypes = {
  id: PropTypes.string.isRequired,
  identifier: PropTypes.string,
  toggle: PropTypes.func,
  setBackdropClick: PropTypes.func,
};
