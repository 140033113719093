import SweetAlert from "react-bootstrap-sweetalert";
import React, { useState } from "react";
import { Col, Input, Row } from "reactstrap";
import UserPermissions from "../../variables/UserPermissions";
import PropTypes from "prop-types";
import PermissionsTooltip from "../tooltips/PermissionsTooltip";

function ChangeUserPermissionModal(props) {
  const { currentPermission, savePermission } = props;
  const [permission, setPermission] = useState(currentPermission);
  const [permissionsTooltipOpen, setPermissionsTooltipOpen] = useState(false);
  return (
    <SweetAlert
      onConfirm={() => savePermission(permission)}
      onCancel={() => savePermission()}
      title="Change User Permission"
      showConfirm
      showCancel
      confirmBtnText="Save"
      customClass="shadow"
    >
      Set the selected member&lsquo;s permission level.{" "}
      <PermissionsTooltip
        permissionsTooltipOpen={permissionsTooltipOpen}
        setPermissionsTooltipOpen={setPermissionsTooltipOpen}
      />
      <br />
      <br />
      <Row className="justify-content-start text-justify">
        <Col className="modal-body-radio">
          <div>
            <Input
              type="radio"
              name="permission"
              value={UserPermissions.OWNER}
              checked={permission === UserPermissions.OWNER}
              onChange={() => setPermission(UserPermissions.OWNER)}
            />
            Owner
          </div>
          <div>
            <Input
              type="radio"
              name="permission"
              value={UserPermissions.ADMIN}
              checked={permission === UserPermissions.ADMIN}
              onChange={() => setPermission(UserPermissions.ADMIN)}
            />
            Admin
          </div>
          <div>
            <Input
              type="radio"
              name="permission"
              value={UserPermissions.USER}
              checked={permission === UserPermissions.USER}
              onChange={() => setPermission(UserPermissions.USER)}
            />
            User
          </div>
        </Col>
      </Row>
    </SweetAlert>
  );
}

export default ChangeUserPermissionModal;

ChangeUserPermissionModal.propTypes = {
  currentPermission: PropTypes.string,
  savePermission: PropTypes.func,
};
