import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

function confirmRoutingAlert(
  setAlert,
  submit,
  accountNumber,
  setErrors,
  accountNumberRef
) {
  var verifyAccountNumber = "";

  function verifySubmit() {
    if (verifyAccountNumber !== accountNumber) {
      setErrors({ accountNumber: "Account numbers do not match" });
      accountNumberRef.current.focus();
      setAlert();
    } else {
      submit();
    }
  }

  const handleVerifyChange = (event) => {
    verifyAccountNumber = event.target.value;
  };

  setAlert(null);
  setAlert(
    <SweetAlert
      hideOverlay
      title="Verify Account Number"
      onCancel={() => setAlert()}
      onConfirm={() => verifySubmit()}
      showCancel
      showConfirm
      confirmBtnText="Verify"
      customClass="shadow"
    >
      {(renderProps) => (
        <form onSubmit={() => verifySubmit()}>
          Re-enter the account number
          <hr />
          <input
            type={"text"}
            className="form-control"
            onChange={handleVerifyChange}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <hr />
        </form>
      )}
    </SweetAlert>,
    true
  );
}

export default confirmRoutingAlert;
