import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function allCompanyMembers(input, output, hooks) {
  const query = buildQuery(input, output, "allCompanyMembers", true);
  return callQuery(query, hooks);
}

allCompanyMembers.propTypes = {
  input: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function allCompanyInvites(input, output, hooks) {
  const query = buildQuery(input, output, "allCompanyInvites", true);
  return callQuery(query, hooks);
}

allCompanyInvites.propTypes = {
  input: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  output: PropTypes.element.isRequired,
};
