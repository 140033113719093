import React, { Fragment } from "react";
import { Button, Col, Fade, Row } from "reactstrap";
import useGQL from "../../../api_client/UseGQL";
import { useHistory } from "react-router";
import success from "../../../assets/img/onboarding/SetupCompleteUpdated.svg";
import { reportOnboardingComplete } from "../../../modules/analytics";

function OnboardingComplete() {
  let gqlHooks = useGQL();
  let history = useHistory();

  function updateState() {
    localStorage.removeItem("legalInformation");
    localStorage.removeItem("ownersSubmitted");
    localStorage.removeItem("verificationSubmitted");
    localStorage.removeItem("documentsUploaded");
    reportOnboardingComplete(gqlHooks);
    history.push("/admin/payments");
  }

  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Col className="m-auto" style={{ maxWidth: 470 }}>
          <Fade>
            <img
              src={success}
              alt="Setup Complete"
              width="100"
              height="100"
              className="m-auto d-block"
            />
            <h2 className="text-center mt-3 mb-2 font-weight-600">
              Setup complete!
            </h2>
            <p className="text-muted text-center mb-4">
              Thank you for taking the time to get setup!
            </p>
            <Button
              onClick={updateState}
              color={"primary"}
              className="m-auto d-block"
            >
              View dashboard
            </Button>
          </Fade>
        </Col>
      </Row>
    </Fragment>
  );
}

export default OnboardingComplete;

OnboardingComplete.propTypes = {};
