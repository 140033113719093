import React, { useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { getBankIcon } from "../../modules/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert";
import useGQL from "../../api_client/UseGQL";
import { removeBankAccountFromContact } from "../../api_client/mutations/contacts";
import { useToasts } from "react-toast-notifications";
import NewContactBankAccount from "views/admin/contacts/NewContactBankAccount";

function ContactBankAccountCard(props) {
  const { contact } = props;
  const { addToast } = useToasts();
  const [alertState, setAlertState] = useState(null);
  const [, setLoading] = useState(true);
  const gqlHooks = useGQL();

  async function deleteAccount(bankAccountId) {
    setAlertState(null);
    setLoading(true);
    props.setBackdropClick(true);

    let input = {
      bankAccountId: bankAccountId,
      contactId: contact.identifier,
    };
    let output = {
      ok: true,
    };
    console.log("Delete account: ", input);
    const response = await removeBankAccountFromContact(
      input,
      output,
      gqlHooks
    );

    props.setBackdropClick(false);

    if (response) {
      addToast("Account Removed", {
        appearance: "success",
        autoDismiss: true,
      });
      setLoading(false);
      props.onBankAccountDeleted(bankAccountId);
    }
  }

  function confirmDelete(bankAccountId) {
    setAlertState(
      <SweetAlert
        onConfirm={() => deleteAccount(bankAccountId)}
        onCancel={() => setAlertState(null)}
        title="Confirm Account Deletion"
        showCancel
        showConfirm
        customClass="shadow"
      >
        Deleting this account will remove it from your dashboard.
      </SweetAlert>
    );
  }

  function addBankAccount() {
    setAlertState(
      <SweetAlert title={""} showConfirm={false} onConfirm={() => {}}>
        <Row style={{ marginTop: -30 }}>
          <Col sm="12">
            <Button close onClick={() => setAlertState(null)} />
          </Col>
        </Row>
        <h2 className="text-center">Add a bank account</h2>
        <NewContactBankAccount
          done={() => {
            setAlertState(null);
            props.onBankAccountAdded();
          }}
          contact={contact}
        />
      </SweetAlert>
    );
  }

  return (
    <>
      {alertState}
      <Row>
        <Col>
          <h4 className="font-weight-600 mt-0 ">Bank accounts</h4>
        </Col>
        <Col>
          <Button
            className="btn-link details-add-btn"
            onClick={() => addBankAccount()}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      {contact.bankAccounts && contact.bankAccounts.length ? (
        <>
          {contact.bankAccounts.map((bankAccount) => (
            <Card
              className="details-card shadow-sm border mb-3"
              key={bankAccount.identifier}
            >
              <Row className="details-row">
                <Col className="col-10">
                  <div className="bank-logo">
                    {contact.bankAccounts ? (
                      <img
                        src={getBankIcon(bankAccount.institution)}
                        alt="Bank Logo"
                      />
                    ) : (
                      <FontAwesomeIcon icon={faUniversity} />
                    )}
                  </div>
                  <h5>Account</h5>
                  <p>
                    {bankAccount && bankAccount.state !== "PROCESSING" ? (
                      `${bankAccount.institution} - ${bankAccount.title} - ${bankAccount.accountNumber}`
                    ) : (
                      <span className="text-muted font-italic">
                        Pending authorization
                      </span>
                    )}
                  </p>
                </Col>
                <Col className="col-2">
                  <Button
                    className="btn-link details-close-btn"
                    onClick={() => confirmDelete(bankAccount.identifier)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Col>
              </Row>
            </Card>
          ))}
        </>
      ) : (
        <Card className="details-card shadow-sm border mb-3">
          <Row className="details-row">
            <Col>
              <div className="bank-logo">
                <FontAwesomeIcon icon={faUniversity} />
              </div>
              <h5>Account</h5>
              <p className="text-muted font-italic">Pending authorization</p>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}

export default ContactBankAccountCard;
ContactBankAccountCard.propTypes = {
  contact: PropTypes.object,
  onBankAccountDeleted: PropTypes.func,
  onBankAccountAdded: PropTypes.func,
  setBackdropClick: PropTypes.func,
};
