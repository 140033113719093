/* eslint-disable react/prop-types */
import React from "react";
import { Redirect } from "react-router-dom";
import useApiValue from "../hooks/useApiValue";
import { CustomLoader } from "../components/loaders/CustomLoader";
import { useToasts } from "react-toast-notifications";

function ValidatedGateway(props) {
  const { addToast } = useToasts();
  const { values, loading } = useApiValue({
    profile: { firstName: true, lastName: true },
    company: {
      validated: true,
      billingAccount: { identifier: true },
      kyc: { submitted: true },
      settings: { securityQuestion: true, useSecurityQuestion: true },
    },
    kyc: { submitted: true, validated: true },
  });

  if (loading || !values.company || !values.profile || !values.kyc) {
    return <CustomLoader general={true} />;
  } else if (
    !values.company.validated &&
    (!values.profile?.firstName ||
      !values.profile?.lastName ||
      !values.company?.kyc?.submitted ||
      !values.company?.settings?.securityQuestion ||
      !values.kyc?.submitted)
  ) {
    addToast("Please complete onboarding", {
      appearance: "warning",
      autoDismiss: true,
    });
    return <Redirect from="/" to={`/onboarding`} />;
  } else if (!values.company?.validated || !values.kyc?.validated) {
    addToast(
      "You account is currently under review, please contact support if you do not hear from us within 1 business day",
      {
        appearance: "warning",
        autoDismiss: true,
      }
    );
    return <Redirect from="/" to={`/admin/payments`} />;
  } else if (!values.company?.billingAccount?.identifier) {
    addToast("Please specify a billing account", {
      appearance: "warning",
      autoDismiss: true,
    });
    return <Redirect from="/" to={`/admin/settings#banks`} />;
  } else {
    return props.children;
  }
}

export default ValidatedGateway;
