import { domains, emailAccounts } from "modules/Helpers";
import React from "react";
import {
  Badge,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

function StatusBadgeBankAccount(bankAccount) {
  // Need to remove number and dashes from identifier in order for the dom call to work
  let identifier = bankAccount.identifier;

  identifier = identifier.replace(/-/g, "");
  identifier = identifier.replace(/[0-9]/g, "");

  let width = 120;

  // Statuses
  let authorized = bankAccount.state === "READY";
  let confirm = bankAccount.state === "CONFIRM_MICRODEPOSIT";
  let error =
    bankAccount.state === "NOT_READY" ||
    bankAccount.state === "PROCESSING_ERROR" ||
    bankAccount.state === "DEVELOPER_ERROR";
  let failedMicroDeposit = bankAccount.state === "FAILED_MICRODEPOSIT";
  let missingDetails = bankAccount.state === "PARTIALLY_READY";
  let pendingFlinks = bankAccount.state === "PROCESSING";
  let pendingMicroDeposit = bankAccount.state === "PENDING_MICRODEPOSIT";
  let padSigned = bankAccount.variableDebitPad;

  if (authorized && padSigned) {
    // Micro-deposit amounts confirmed, ready for transactions
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Authorized</PopoverHeader>
          <PopoverBody>
            Bank account is authorized to send and receive payments
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="success"
          className="mb-0 bank-account-status-badge"
          style={{ width: width }}
          pill
        >
          Authorized
        </Badge>
      </React.Fragment>
    );
  } else if (authorized && !padSigned) {
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Needs Signature</PopoverHeader>
          <PopoverBody>
            Bank account pre-authorized-debit agreement needs to be signed
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="warning"
          className="mb-0 bank-account-status-badge"
          style={{ width: width }}
          pill
        >
          Needs Signature
        </Badge>
      </React.Fragment>
    );
  } else if (confirm) {
    // Micro-deposit sent to account, ready to enter amounts
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Ready to Confirm</PopoverHeader>
          <PopoverBody>
            Please confirm the micro-deposit amounts sent to your bank account
            by selecting the “Confirm micro-deposits” action.{" "}
            <a
              href={`https://${domains.HELP}/how-to-verify-a-bank-account-with-micro-deposits`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="info"
          className="mb-0 bank-account-status-badge"
          style={{ width: width }}
          pill
        >
          Ready to Confirm
        </Badge>
      </React.Fragment>
    );
  } else if (error) {
    // Account error
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Error</PopoverHeader>
          <PopoverBody>
            Error connecting your bank account, please contact{" "}
            <a href={`mailto:${emailAccounts.SUPPORT}`}>
              {emailAccounts.SUPPORT}
            </a>
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="warning"
          className="mb-0 bank-account-status-badge"
          style={{ width: width }}
          pill
        >
          Error
        </Badge>
      </React.Fragment>
    );
  } else if (failedMicroDeposit) {
    // Micro-deposit failed to send to account, ready to update account info
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Failed</PopoverHeader>
          <PopoverBody>
            Failed to deposit micro-deposits into your bank account, please
            retry adding your account by selecting the “Retry adding account”
            action.{" "}
            <a
              href={`https://${domains.HELP}/how-to-verify-a-bank-account-with-micro-deposits`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="warning"
          className="mb-0 bank-account-status-badge"
          style={{ width: width }}
          pill
        >
          Failed
        </Badge>
      </React.Fragment>
    );
  } else if (missingDetails) {
    // Missing bank account info
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Missing Details</PopoverHeader>
          <PopoverBody>
            Please add your missing bank account details by selecting the
            “Resolve missing details” action
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="default"
          className="mb-0 bank-account-status-badge"
          style={{ width: width }}
          pill
        >
          Missing Details
        </Badge>
      </React.Fragment>
    );
  } else if (pendingFlinks) {
    // Flinks gathering account data
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Pending</PopoverHeader>
          <PopoverBody>
            Currently gathering bank account data, please try refreshing the
            page
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="default"
          className="mb-0 bank-account-status-badge"
          style={{ width: width }}
          pill
        >
          Pending
        </Badge>
      </React.Fragment>
    );
  } else if (pendingMicroDeposit) {
    // Micro-deposit not yet sent to account
    return (
      <React.Fragment>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={identifier}
          style={{ cursor: "default" }}
        >
          <PopoverHeader>Pending</PopoverHeader>
          <PopoverBody>
            We&apos;ve sent 2 micro-deposits to confirm ownership of the
            account. You will receive an email with further steps within the
            next business day.{" "}
            <a
              href={`https://${domains.HELP}/how-to-verify-a-bank-account-with-micro-deposits`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </PopoverBody>
        </UncontrolledPopover>
        <Badge
          id={identifier}
          color="default"
          className="mb-0 bank-account-status-badge"
          style={{ width: width }}
          pill
        >
          Pending
        </Badge>
      </React.Fragment>
    );
  }
}

export default StatusBadgeBankAccount;

StatusBadgeBankAccount.propTypes = {};
