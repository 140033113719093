import React, { useState, useEffect } from "react";
import EditApprovalsCard from "./EditApprovalsCard";
import NoteToContactCard from "./NoteToContactCard";
import { Col, Row, Button } from "reactstrap";
import { useAppValue } from "contexts/Context";
import { setCompanySettings } from "api_client/mutations/companyProfile";
import useGQL from "api_client/UseGQL";
import { useToasts } from "react-toast-notifications";
import useForm from "hooks/UseForms";
function Configuration() {
  let errors = useForm(handleSubmit);
  const [loading, setLoading] = useState(false);
  const [context, dispatch] = useAppValue();
  const gqlHooks = useGQL();
  const { addToast } = useToasts();
  async function handleSubmit() {
    setLoading(true);
    const output = {
      settings: {
        notesRequired: true,
        notesAlias: true,
      },
    };
    const input = {
      notesAlias: context.user.company.settings.notesAlias
        ? context.user.company.settings.notesAlias
        : "",
      notesRequired: context.user.company.settings.notesRequired,
      requiredPaymentApprovals:
        context.user.company.settings.requiredPaymentApprovals,
    };
    const response = await setCompanySettings(input, output, gqlHooks);
    if (response) {
      context.user.company.settings = {
        ...context.user.company.settings,
        ...response.settings,
      };
      dispatch({
        type: "setUser",
        user: context.user,
      });

      addToast("Configuration Saved", {
        appearance: "success",
        autoDismiss: true,
      });
    }

    setLoading(false);
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="4">
            <NoteToContactCard props={errors} />
          </Col>
          {context.user.isOwner ? (
            <Col md="4">
              <EditApprovalsCard />
            </Col>
          ) : (
            ""
          )}
        </Row>
        <div className="save-button-container">
          <Button
            block
            color="primary"
            className="small-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

export default Configuration;

Configuration.propTypes = {};
