import React, { Fragment, useState } from "react";
import LogoWithText from "../assets/img/LogoWithText-Light.png";
// import LostPageImage from "../assets/img/LostPage.svg";
import PopOutOverlay from "./overlays/PopOutOverlay";
import AuthSupportForm from "./forms/AuthSupportForm";
import { Button, Fade } from "reactstrap";
import { useHistory } from "react-router";
import useExitPrompt from "../hooks/useExitPrompt";
import { appName, domains } from "../modules/Helpers";

function LostPage() {
  const [overlayDetails, setOverlayDetails] = useState(false);
  const history = useHistory();
  useExitPrompt(false);

  return (
    <Fragment>
      <div className="bg-white auth-wrapper">
        <div className="d-flex flex-row">
          <PopOutOverlay
            isOpen={overlayDetails}
            toggle={() => {
              setOverlayDetails(!overlayDetails);
            }}
          >
            <AuthSupportForm
              toggleSupport={() => setOverlayDetails(!overlayDetails)}
            />
          </PopOutOverlay>
          <div className="flex-column d-flex auth-content flex-grow-1 bg-dark">
            <div className="d-flex flex-row">
              <div
                className="mr-auto mb-3 cursor-pointer"
                style={{ maxWidth: "150px" }}
                onClick={() =>
                  window.open(`https://${domains.PRIMARY}/`, "_self")
                }
              >
                <img src={LogoWithText} alt={`${appName} Logo`} />
              </div>
              <Button
                onClick={() => history.push("/auth/login")}
                className="border-white text-white btn-simple"
              >
                Login
              </Button>
            </div>
            <div className="d-flex justify-content-center flex-grow-1 flex-column">
              <h2 className="text-white text-center">Are you lost?</h2>
              <div className="ml-auto mr-auto">
                <span className="desktop text-white">Need some help?</span>
                <Button
                  onClick={() => setOverlayDetails(!overlayDetails)}
                  className="sp-button btn-primary mr-3"
                >
                  Talk to support
                </Button>
              </div>
              <Fade className="justify-content-center d-flex">
                {/* <img
                  className="w-75 ml-auto mr-auto"
                  src={LostPageImage}
                  alt={"Sorry"}
                /> */}
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LostPage;

LostPage.propTypes = {};
