import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PAD_TYPE_OPTIONS } from "../../../../../modules/Helpers";
import Select from "react-select";

function ManualContactBankForm(props) {
  const [accountType, setAccountType] = useState(PAD_TYPE_OPTIONS[0]);

  useEffect(() => {
    Object.keys(props.refs)
      .reverse()
      .forEach((key) => {
        if (!props.uneditable.includes(key)) {
          try {
            props.refs[key].current.focus();
          } catch {
            console.log("no ref for this");
          }
        }
      });
  }, []);

  useEffect(() => {
    props.setValues({ ...props.values, accountType: accountType.value });
  }, [accountType.value]);

  return (
    <Fragment>
      <Row className="m-0">
        <Col md="4" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">Institution #</Label>
          <FormGroup>
            <Input
              name="institutionNumber"
              type="text"
              innerRef={props.refs.institutionNumber}
              value={props.values.institutionNumber || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.institutionNumber}
              required
              readOnly={props.uneditable.includes("institutionNumber")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.institutionNumber}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="4" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">Transit #</Label>
          <FormGroup>
            <Input
              name="transitNumber"
              type="text"
              innerRef={props.refs.transitNumber}
              value={props.values.transitNumber || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.transitNumber}
              required
              readOnly={props.uneditable.includes("transitNumber")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.transitNumber}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="4" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">Account #</Label>
          <FormGroup>
            <Input
              name="accountNumber"
              type={props.hideAccountNumber ? "password" : "text"}
              innerRef={props.refs.accountNumber}
              value={props.values.accountNumber || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.accountNumber}
              required
              readOnly={props.uneditable.includes("accountNumber")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.accountNumber}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row className="m-0 justify-content-center">
        <Col md="6" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">Institution name</Label>
          <FormGroup>
            <Input
              name="institution"
              type="text"
              innerRef={props.refs.institution}
              value={props.values.institution || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.institution}
              required
              readOnly={props.uneditable.includes("institution")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.institution}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">Account type</Label>
          <FormGroup>
            <Select
              onChange={(option) => setAccountType(option)}
              className="react-select default"
              classNamePrefix="react-select"
              name="accountType"
              value={accountType}
              options={PAD_TYPE_OPTIONS}
              readOnly={props.uneditable.includes("accountType")}
            />
          </FormGroup>
        </Col>
        <Col md="6" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">Account holder name</Label>
          <FormGroup>
            <Input
              name="holderName"
              type="text"
              innerRef={props.refs.holderName}
              value={props.values.holderName || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.holderName}
              required
              readOnly={props.uneditable.includes("holderName")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.holderName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">Account holder email</Label>
          <FormGroup>
            <Input
              name="holderEmail"
              type="email"
              innerRef={props.refs.holderEmail}
              value={props.values.holderEmail || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.holderEmail}
              required
              readOnly={props.uneditable.includes("holderEmail")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.holderEmail}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">
            Account holder address
          </Label>
          <FormGroup>
            <Input
              name="holderAddress"
              type="text"
              innerRef={props.refs.holderAddress}
              value={props.values.holderAddress || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.holderAddress}
              required
              readOnly={props.uneditable.includes("holderAddress")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.holderAddress}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">Account holder city</Label>
          <FormGroup>
            <Input
              name="holderAddressCity"
              type="text"
              innerRef={props.refs.holderAddressCity}
              value={props.values.holderAddressCity || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.holderAddressCity}
              required
              readOnly={props.uneditable.includes("holderAddressCity")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.holderAddressCity}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6" className="manual-bank-form-col">
          <Label className="manual-bank-form-label">
            Account holder postal code
          </Label>
          <FormGroup>
            <Input
              name="holderAddressPostalCode"
              type="text"
              innerRef={props.refs.holderAddressPostalCode}
              value={props.values.holderAddressPostalCode || ""}
              onChange={props.handleChange}
              invalid={!!props.errors.holderAddressPostalCode}
              required
              readOnly={props.uneditable.includes("holderAddressPostalCode")}
              className="manual-bank-input"
            />
            <FormFeedback>{props.errors.holderAddressPostalCode}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
}

export default ManualContactBankForm;

ManualContactBankForm.defaultProps = {
  uneditable: [],
};

ManualContactBankForm.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  hideAccountNumber: PropTypes.bool.isRequired,
  refs: PropTypes.shape({
    institutionNumber: PropTypes.object,
    transitNumber: PropTypes.object,
    accountNumber: PropTypes.object,
    institution: PropTypes.object,
    accountType: PropTypes.object,
    holderName: PropTypes.object,
    holderEmail: PropTypes.object,
    holderAddress: PropTypes.object,
    holderAddressPostalCode: PropTypes.object,
    holderAddressCity: PropTypes.object,
  }),
  uneditable: PropTypes.array,
};
