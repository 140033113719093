import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import useHubspotForm from "../../hooks/useHubspotForm";
import useForm from "../../hooks/UseForms";
import { registerFormValidation } from "../../modules/form_validation/RegisterFormValidation";
import { useHistory, useLocation } from "react-router";
import { withApiValue } from "../../modules/WithApiValue";
import QueryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import successAlert from "../sweet_alert/SuccessAlert";
import useGQL from "../../api_client/UseGQL";

const config = {
  profile: { firstName: true, lastName: true, phone: true },
  email: true,
};

function SupportForm(props) {
  const history = useHistory();
  const { data, isLoading, handleHubspotSubmit } = useHubspotForm({
    portalId: "6217266",
    formId: "d49a4d42-cc4a-46c8-99bb-23197d9b2125",
  });
  const { values, setValues, handleChange, handleSubmit, errors } = useForm(
    submit,
    registerFormValidation
  );
  const location = useLocation();
  let gqlHooks = useGQL(true);

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (data && data.status === 200) {
      successAlert(
        () => gqlHooks.context.setAlert(null),
        gqlHooks.context.setAlert,
        "Ticket submitted a team member will contact you shortly"
      );
      history.push({ search: "" });
    }
    setValues({
      email: props.email,
      firstName: props.profile.firstName,
      lastName: props.profile.lastName,
      phone: props.profile.phone,
      transactionId: search.transactionId || search.recurringPlanId,
      subject: search.transactionId
        ? "Payment issue"
        : search.recurringPlanId
        ? "Recurring plan issue"
        : "",
    });
  }, [data]);

  function submit() {
    handleHubspotSubmit({
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      phone: values.phone,
      "TICKET.subject": values.subject || "none",
      "TICKET.content": values.description || "none",
      "TICKET.transaction_id": values.transactionId || "none",
      "TICKET.what_troubleshooting_have_you_tried_": values.info || "none",
    });
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Button
            className="btn-link details-close-btn"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <h3 className="font-weight-600 mt-1 mb-4">Support ticket</h3>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Label>First name</Label>
            <FormGroup>
              <Input
                name="firstName"
                type="text"
                value={values.firstName || ""}
                onChange={handleChange}
                invalid={!!errors.firstName}
                required
              />
            </FormGroup>
          </Col>
          <Col>
            <Label>Last name</Label>
            <FormGroup>
              <Input
                name="lastName"
                type="text"
                value={values.lastName || ""}
                onChange={handleChange}
                invalid={!!errors.lastName}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Label>Email</Label>
        <FormGroup>
          <Input
            name="email"
            type="email"
            value={values.email || ""}
            onChange={handleChange}
            invalid={!!errors.email}
            required
          />
        </FormGroup>
        <Label>Phone number</Label>
        <FormGroup>
          <Input
            type="tel"
            name="phone"
            value={values.phone || ""}
            invalid={!!errors.phone}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Label>Subject</Label>
        <FormGroup>
          <Input
            name="subject"
            type="text"
            value={values.subject || ""}
            onChange={handleChange}
            invalid={!!errors.subject}
            required
          />
        </FormGroup>
        <Label>
          {location.pathname === "/admin/payments" ? " Payment id" : " Plan id"}{" "}
          <span className="text-muted text-sm">(optional)</span>
        </Label>
        <FormGroup>
          <Input
            name="transactionId"
            type="text"
            value={values.transactionId || ""}
            onChange={handleChange}
            invalid={!!errors.transactionId}
          />
        </FormGroup>
        <Label>Description</Label>
        <FormGroup>
          <Input
            name="description"
            type="textarea"
            value={values.description || ""}
            onChange={handleChange}
            invalid={!!errors.description}
            required
          />
        </FormGroup>
        <Label>
          What troubleshooting have you tried so far?{" "}
          <span className="text-muted text-sm">(optional)</span>
        </Label>
        <FormGroup>
          <Input
            name="info"
            type="textarea"
            value={values.info || ""}
            onChange={handleChange}
            invalid={!!errors.info}
          />
        </FormGroup>
        <Button
          disabled={isLoading}
          type="submit"
          className="btn-primary"
          block
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );
}

export default withApiValue(SupportForm, config);

SupportForm.propTypes = {
  ticketInfo: PropTypes.object,
  email: PropTypes.string,
  profile: PropTypes.object,
};
