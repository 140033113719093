import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faLink,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl, CustomTooltip } from "../../../../modules/Helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";

function RecurringPlanActionsDesktop(props) {
  return (
    <>
      <div className="details-action-wrapper">
        <>
          <CustomTooltip title="Get help" placement="bottom" arrow>
            <Button onClick={props.ticket} className="btn-link">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Button>
          </CustomTooltip>

          {document.queryCommandSupported("copy") && (
            <CopyToClipboard
              text={`${baseUrl}/payment/recurring/accept/${props.recurringPlan.identifier}`}
              onCopy={props.copyPlanLink}
            >
              <CustomTooltip
                title=" Copy payment plan link"
                placement="bottom"
                arrow
              >
                <Button className="btn-link">
                  <FontAwesomeIcon icon={faLink} />
                </Button>
              </CustomTooltip>
            </CopyToClipboard>
          )}
        </>
        {props.recurringPlan.active && (
          <>
            <CustomTooltip title="Cancel payment plan" placement="bottom" arrow>
              <Button
                onClick={props.cancelPlan}
                className="btn-link text-danger"
              >
                <FontAwesomeIcon icon={faBan} />
              </Button>
            </CustomTooltip>
          </>
        )}
      </div>
    </>
  );
}

export default RecurringPlanActionsDesktop;
RecurringPlanActionsDesktop.propTypes = {
  recurringPlan: PropTypes.object,
  copyPlanLink: PropTypes.func,
  cancelPlan: PropTypes.func,
  ticket: PropTypes.func,
};
