import React, { useContext, useReducer } from "react";
import Cookies from "js-cookie";
import { redirectReducer } from "./reducers/redirectReducer";
import { userReducer } from "./reducers/userReducer";

export const StateContext = React.createContext();

export const initialState = () => {
  let user = undefined;
  if (Cookies.get("user")) {
    user = JSON.parse(Cookies.get("user"));
  }
  return {
    redirectURL: "",
    user: user,
    alert: null,
    showOnboarding: false,
    transactionCount: 0,
    draftCount: 0,
    updates: {},
  };
};

// eslint-disable-next-line react/prop-types
export const ContextProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useAppValue = () => useContext(StateContext);
export const mainReducer = (
  {
    redirectURL,
    user,
    alert,
    showOnboarding,
    transactionCount,
    draftCount,
    allDraftCount,
    updates,
  },
  action
) => ({
  redirectURL: redirectReducer(redirectURL, action),
  user: userReducer(user, action),
  alert: alertReducer(alert, action),
  showOnboarding: onboardingReducer(showOnboarding, action),
  transactionCount: transactionCountReducer(transactionCount, action),
  draftCount: draftCountReducer(draftCount, action),
  allDraftCount: allDraftCountReducer(allDraftCount, action),
  updates: updatesReducer(updates, action),
});

function alertReducer(state, action) {
  if (action.type === "setAlert") {
    return action.alert;
  } else {
    return state;
  }
}

function onboardingReducer(state, action) {
  if (action.type === "setShowOnboarding") {
    return action.showOnboarding;
  } else {
    return state;
  }
}

function transactionCountReducer(state, action) {
  if (action.type === "setTransactionCount") {
    return action.transactionCount;
  } else {
    return state;
  }
}

function draftCountReducer(state, action) {
  if (action.type === "setDraftCount") {
    return action.draftCount;
  } else {
    return state;
  }
}

function allDraftCountReducer(state, action) {
  if (action.type === "setAllDraftCount") {
    return action.allDraftCount;
  } else {
    return state;
  }
}

function updatesReducer(state, action) {
  if (action.type === "setUpdates") {
    return action.updates;
  } else {
    return state;
  }
}
