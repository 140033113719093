const TransactionStates = {
  PROCESSING: 0,
  COMPLETED: 1,
  FAILED: 2,
  INITIATED: 3,
  PULLED: 4,
  PUSHED: 5,
  ON_HOLD: 6,
  REQUESTED: 7,
  CANCELLED: 8,
  PENDING: 9,
  RETURNED: 10,
  RETURNING: 11,
  REROUTED: 12,
  RETRYING: 13,
  WAITING_APPROVAL: 14,
  DRAFT: 15,
  EMAIL_ERROR: 16,
  UNAUTHORIZED: 17,
  PAID: 18,
  APPROVED: 19,
  RESUMED: 20,
};

export default TransactionStates;
