import React, { useState } from "react";
import PropTypes from "prop-types";
import { Badge, Button, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { apiTokenScopeName } from "variables/ApiTokenScopes";
import { useToasts } from "react-toast-notifications";
import { deleteApiToken } from "api_client/mutations/token";
import useGQL from "api_client/UseGQL";
import { useHistory } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { appName } from "../../modules/Helpers";

function ApiTokenCard(props) {
  const { addToast } = useToasts();
  const gqlHooks = useGQL();
  const history = useHistory();
  const [alertState, setAlert] = useState(null);

  let { name, identifier, scopes, createdAt } = props.item;

  async function deleteItem() {
    let input = {
      tokenId: identifier,
      immediate: true,
    };
    let output = {
      ok: true,
    };

    const response = await deleteApiToken(input, output, gqlHooks);
    if (response) {
      addToast("Token deleted", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push({ search: "update=true" });
    }
  }

  return (
    <>
      {alertState}
      <Card className="t-card shadow-sm border">
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <div className="font-weight-bold">{name}</div>
          </div>
          <div className="flex-column">
            <Button
              className="btn-link no-vert-padding"
              onClick={() => {
                setAlert(
                  <SweetAlert
                    title="Are you sure?"
                    onConfirm={() => {
                      deleteItem();
                      setAlert(null);
                    }}
                    onCancel={() => setAlert(null)}
                    showConfirm={true}
                    showCancel={true}
                    confirmBtnText="Delete"
                  >
                    <p>
                      If you delete this token, the application using it will no
                      longer have access to the {appName} API.
                    </p>
                  </SweetAlert>
                );
              }}
            >
              <FontAwesomeIcon
                style={{ fontSize: "1.2rem" }}
                icon={faTrashAlt}
              />
            </Button>
          </div>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <div className="text-muted">
              Created {moment(createdAt).format("MMM D YYYY")}
            </div>
            <div>
              {scopes.map((scope) => {
                return (
                  <Badge
                    key={scope}
                    color="default"
                    style={{ minWidth: "80px", marginBottom: "0" }}
                    pill
                  >
                    {apiTokenScopeName(scope)}
                  </Badge>
                );
              })}
            </div>
            {/* {validUntil && (
              <div className="text-warning">
                Expires {moment(validUntil).calendar()}
              </div>
            )} */}
          </div>
        </div>
      </Card>
    </>
  );
}

export default ApiTokenCard;

ApiTokenCard.propTypes = {
  item: PropTypes.object,
};
