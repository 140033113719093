import { baseUrl, emailAccounts } from "modules/Helpers";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  CardText,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useHubspotForm from "hooks/useHubspotForm";
import { registerFormValidation } from "modules/form_validation/RegisterFormValidation";
import useForm from "hooks/UseForms";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function AdminError(props) {
  const url = window.location.href;
  const uuidMatch = url.match(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
  );
  const uuid = uuidMatch ? uuidMatch[0] : "UUID not found";
  console.log(uuid);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    description: "",
    info: "",
  });

  const { addToast } = useToasts();
  const { data, isLoading, handleHubspotSubmit } = useHubspotForm({
    portalId: "6217266",
    formId: "d49a4d42-cc4a-46c8-99bb-23197d9b2125",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }
  useEffect(() => {
    console.log(values);
  }, [values]);

  function submit(event) {
    event.preventDefault();
    window.onbeforeunload = null;

    handleHubspotSubmit({
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      phone: values.phone,
      "TICKET.subject": values.subject || "none",
      "TICKET.content": values.description || "none",
      "TICKET.what_troubleshooting_have_you_tried_": values.info || "none",
      "TICKET.transaction_id": uuid,
    });

    addToast(
      "Thank you, we will be in touch. You will be redirected in 10 seconds",
      {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      }
    );

    values.firstName = "";
    values.lastName = "";
    values.email = "";
    values.phone = "";
    values.subject = "";
    values.description = "";
    values.info = "";

    setTimeout(() => {
      window.location.href = `${baseUrl}/admin`;
    }, 10000);
  }

  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Card className="card-lock card-white text-center">
              <CardBody>
                <CardTitle tag="h2">
                  Ooops, there appears to be a problem. Please contact support.
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Row>
            <Col>
              <h3 className="font-weight-600 mt-1 mb-4">Support ticket</h3>
            </Col>
          </Row>
          <Form onSubmit={submit}>
            <Row>
              <Col>
                <Label>First name</Label>
                <FormGroup>
                  <Input
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col>
                <Label>Last name</Label>
                <FormGroup>
                  <Input
                    name="lastName"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Label>Email</Label>
            <FormGroup>
              <Input
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <Label>Phone number</Label>
            <FormGroup>
              <Input
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <Label>Subject</Label>
            <FormGroup>
              <Input
                name="subject"
                type="text"
                value={values.subject}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <Label>Description</Label>
            <FormGroup>
              <Input
                name="description"
                type="textarea"
                value={values.description}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <Label>
              What troubleshooting have you tried so far?{" "}
              <span className="text-muted text-sm">(optional)</span>
            </Label>
            <FormGroup>
              <Input
                name="info"
                type="textarea"
                value={values.info}
                onChange={handleChange}
              />
            </FormGroup>
            <Button type="submit" className="btn-primary" block>
              Submit
            </Button>
          </Form>
        </Container>
      </div>
    </>
  );
}

export default AdminError;
