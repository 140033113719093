import {
  MAX_LENGTH_SHORT_NAME,
  verifyLength,
  verifyStatementNames,
} from "../Helpers";

export function companyNameValidation(values, errors) {
  if (values.companyName) {
    if (
      verifyStatementNames(values.companyName) ||
      verifyLength(values.companyName, MAX_LENGTH_SHORT_NAME)
    ) {
      errors.companyName =
        "Company name must be less than 15 characters and ony use valid characters";
    } else {
      delete errors.companyName;
    }
  }

  if (values.name) {
    if (
      verifyStatementNames(values.name) ||
      verifyLength(values.name, MAX_LENGTH_SHORT_NAME)
    ) {
      errors.name =
        "Company name must be less than 15 characters and ony use valid characters";
    } else {
      delete errors.name;
    }
  }
  return errors;
}
