import { appName } from "modules/Helpers";
import React, { Fragment, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import SavedBankInfoModal from "./SavedBankInfoModal";

function GetSavedBadge() {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <SavedBankInfoModal
        open={open}
        toggle={() => setOpen(!open)}
        type="get"
      />
      <Container className="payment-secondary-action">
        <Row>
          <Col md="8" lg="8" className="m-auto text-center">
            <span className="text-muted">
              Already paid someone with {appName}?
            </span>
            <Button
              onClick={() => setOpen(true)}
              className="btn-lg btn-simple btn-primary bg-white mb-0"
              block
            >
              Get my saved payment details
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default GetSavedBadge;

GetSavedBadge.propTypes = {};
