/* eslint-disable react/prop-types */
import React from "react";
import debitLink from "assets/img/banks/debitLink.svg";

function LinkIcon(props) {
  //const fill = props.fill || "currentColor";
  //const secondaryfill = props.secondaryfill || fill;
  //const strokewidth = props.strokewidth || 4;
  const width = props.width || "100%";
  const height = props.height || "100%";
  //const title = props.title || "link";

  return (
    // <svg
    //   height={height}
    //   width={width}
    //   viewBox="0 0 48 48"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <title>{title}</title>
    //   <g
    //     fill={secondaryfill}
    //     stroke={secondaryfill}
    //     strokeLinecap="square"
    //     strokeWidth={strokewidth}
    //   >
    //     <path
    //       d="M23.447,18.9a9.965,9.965,0,0,1,3.417,2.24h0a10,10,0,0,1,0,14.142l-6.793,6.793a10,10,0,0,1-14.142,0h0a10,10,0,0,1,0-14.142l6.793-6.793"
    //       fill="none"
    //     />
    //     <path
    //       d="M24.553,29.1a9.965,9.965,0,0,1-3.417-2.24h0a10,10,0,0,1,0-14.142l6.793-6.793a10,10,0,0,1,14.142,0h0a10,10,0,0,1,0,14.142l-6.793,6.793"
    //       fill="none"
    //       stroke={fill}
    //     />
    //   </g>
    // </svg>
    <img src={debitLink} height={height} width={width} />
  );
}

export default LinkIcon;
