import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";
import EditProfileCard from "./EditProfileCard";
import ChangePasswordCard from "./ChangePasswordCard";

function Profile() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="4">
            <EditProfileCard />
          </Col>
          <Col md="4">
            <ChangePasswordCard />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Profile;
