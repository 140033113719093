import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function allTransactions(input, output, hooks) {
  const query = buildQuery(input, output, "allTransactions", true);
  return callQuery(query, hooks);
}

allTransactions.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function publicTransaction(input, output, hooks) {
  const query = buildQuery(input, output, "publicTransaction", false);
  return callQuery(query, hooks);
}

publicTransaction.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function transactionDetail(input, output, hooks) {
  const query = buildQuery(input, output, "transaction");
  return callQuery(query, hooks);
}

export function allPublicTransactions(input, output, hooks) {
  const query = buildQuery(input, output, "allPublicTransactions");
  return callQuery(query, hooks);
}

allTransactions.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};
