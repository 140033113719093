import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import {
  convertToDate,
  CREDIT,
  DEBIT,
  formatDate,
  formattedDollarValue,
  getCurrentState,
  INTERNAL,
} from "../../modules/Helpers";
import { statusPill } from "../badge/StatusBadge";
import ContactTagBadge from "../badge/ContactTagBadge";
import { useHistory } from "react-router";
import { statusPillEmail } from "../badge/StatusBadgeEmail";

function TransactionCard(props) {
  const history = useHistory();
  const showEmailStatus =
    props.item?.transactionRequestedContactEmail?.status &&
    props.item?.states[0].state === 7;
  if (!props.item) {
    return null;
  }
  return (
    <Fragment>
      <Card
        className="t-card"
        onClick={() =>
          history.push({
            search: `?overlay=true&type=payment&id=${props.item.identifier}`,
          })
        }
      >
        <div className="t-card-row">
          <span className="text-primary text-sm font-weight-600">
            {props.item.direction === CREDIT && "Payable"}
            {props.item.direction === INTERNAL && "Internal"}
            {props.item.direction === DEBIT && "Receivable"}
          </span>
          <div className="t-card-row ml-auto">
            <h4 className="mr-2 mb-0">
              {props.item.direction === CREDIT &&
                `(${formattedDollarValue(props.item.amount)})`}
              {props.item.direction === INTERNAL &&
                formattedDollarValue(props.item.amount)}
              {props.item.direction === DEBIT &&
                formattedDollarValue(props.item.amount)}
            </h4>
            <div>
              {showEmailStatus ? (
                <>
                  {statusPillEmail(
                    props.item?.transactionRequestedContactEmail?.status,
                    props.item
                  )}
                </>
              ) : (
                <>
                  {statusPill(
                    getCurrentState(props.item?.states).state,
                    props.item
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <div className="text-muted text-sm">
              {props.item?.direction === INTERNAL ? (
                "Internal Transfer"
              ) : (
                <Fragment>
                  <div className="font-weight-bold">
                    {props.item?.contact?.name?.substring(0, 20)}
                  </div>
                  {props.item?.contact?.email
                    ? props.item.statement?.substring(0, 20)
                    : "Pending Payment Acceptance"}
                </Fragment>
              )}
            </div>
          </div>
          <div className="ml-1 mt-2">
            {props.item?.contact &&
              ContactTagBadge(
                props.item?.contact?.tags,
                props.item?.identifier
              )}
          </div>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <span className="text-sm font-weight-600">Date Created</span>
            <br />
            <span className="text-muted text-sm">
              {convertToDate(props.item?.createdAt)}
            </span>
          </div>
          {}
          <div className="flex-column text-right">
            <span className="text-sm font-weight-600">
              {props.item.states[0].state === 1
                ? "Deposited"
                : "Estimated Deposit"}
            </span>
            <br />
            <span className="text-muted text-sm">
              {props.item.states[0].state === 7 ? (
                <span className="text-muted">Pending</span>
              ) : (
                formatDate(props.item.estDepositDate)
              )}
            </span>
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

export default TransactionCard;

TransactionCard.propTypes = {
  item: PropTypes.object,
};
