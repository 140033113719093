import { FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import React, { useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes, { string } from "prop-types";
import InitiateDateInfo, { RETRY } from "../generic_details/InitiateDateInfo";
import DepositDateInfo, {
  REROUTE as DEPOSIT_REROUTE,
  RETRY as DEPOSIT_RETRY,
} from "../generic_details/DepositDateInfo";
import useForm from "../../hooks/UseForms";
import { AddBankAccountValidation } from "../../modules/form_validation/AddBankAccountValidation";

const REROUTE = "reRoute";
const RESOURCE = "reSource";

function TransactionReRouteSourceModal(props) {
  let { doAction, setAlertState, type } = props;

  const [tooltipInitiatedIsOpen, setTooltipInitiatedIsOpen] = useState(false);
  const [tooltipDepositIsOpen, setTooltipDepositIsOpen] = useState(false);

  const validateBankAccountInfo = (values, errors) => {
    let { institutionNumber, accountNumber, transitNumber } =
      AddBankAccountValidation(values, errors);
    return { institutionNumber, accountNumber, transitNumber };
  };

  const refs = {
    institutionNumber: useRef(null),
    transitNumber: useRef(null),
    accountNumber: useRef(null),
  };
  const { values, handleChange, errors, setErrors } = useForm(
    confirm,
    validateBankAccountInfo
  );

  function confirm() {
    let errs = {};
    let err = false;
    Object.keys(refs)
      .reverse()
      .forEach(function (key) {
        if (refs[key].current && (!values[key] || values[key] === "")) {
          err = true;
          errs[key] = "This field is required";
          refs[key].current.focus();
        }
      });
    setErrors(errs);
    if (!err) {
      doAction(values);
    }
  }

  return (
    <SweetAlert
      onConfirm={confirm}
      onCancel={() => setAlertState(null)}
      title="Update Contact Bank Details"
      showCancel
      showConfirm
      confirmBtnText="Retry"
      customClass="shadow"
    >
      The bank details your contact provided appear to be incorrect. Re-enter
      them below to retry the transaction.
      <hr />
      <Label>Institution #</Label>
      <FormGroup>
        <Input
          aria-label="Institution number"
          name="institutionNumber"
          type="text"
          innerRef={refs.institutionNumber}
          value={values.institutionNumber || ""}
          onChange={handleChange}
          invalid={!!errors.institutionNumber}
          required
        />
        <FormFeedback>{errors.institutionNumber}</FormFeedback>
      </FormGroup>
      <Label>Transit #</Label>
      <FormGroup>
        <Input
          aria-label="Transit number"
          name="transitNumber"
          type="text"
          innerRef={refs.transitNumber}
          value={values.transitNumber}
          onChange={handleChange}
          invalid={!!errors.transitNumber}
          required
        />
        <FormFeedback>{errors.transitNumber}</FormFeedback>
      </FormGroup>
      <Label>Account #</Label>
      <FormGroup>
        <Input
          aria-label="Account number"
          name="accountNumber"
          type="text"
          innerRef={refs.accountNumber}
          value={values.accountNumber}
          onChange={handleChange}
          invalid={!!errors.accountNumber}
          required
        />
        <FormFeedback>{errors.accountNumber}</FormFeedback>
      </FormGroup>
      <hr />
      <Row>
        <InitiateDateInfo
          type={RETRY}
          isOpen={tooltipInitiatedIsOpen}
          setOpen={setTooltipInitiatedIsOpen}
        />
      </Row>
      <Row>
        <DepositDateInfo
          type={type === RESOURCE ? DEPOSIT_RETRY : DEPOSIT_REROUTE}
          isOpen={tooltipDepositIsOpen}
          setOpen={setTooltipDepositIsOpen}
        />
      </Row>
    </SweetAlert>
  );
}

export default TransactionReRouteSourceModal;

TransactionReRouteSourceModal.propTypes = {
  doAction: PropTypes.func,
  setAlertState: PropTypes.func,
  type: PropTypes.oneOf(string[(RESOURCE, REROUTE)]),
};
