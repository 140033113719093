import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { registerInviteFormValidation } from "../../../modules/form_validation/RegisterInviteFormValidation";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import useGQL from "../../../api_client/UseGQL";
import { signupFromInvite } from "../../../api_client/mutations/auth";
import LogoColor from "../../../assets/img/logo-colour.png";
import { appName, domains } from "../../../modules/Helpers";
import { reportSignUp } from "../../../modules/analytics";
import { Link } from "react-router-dom";
function RegisterInviteForm(props) {
  const { values, setValues, handleChange, handleSubmit, errors } = useForm(
    submit,
    registerInviteFormValidation
  );
  const [loading, setLoading] = useState(false);
  const refs = {
    password: useRef(null),
    phone: useRef(null),
    securityPin: useRef(null),
  };
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();

  useEffect(() => {
    const token = props.match.params.token;
    setValues({ authToken: token });
    refs.password.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function submit() {
    setLoading(true);
    let output = {
      loginToken: true,
      user: {
        identifier: true,
      },
    };
    let response = await signupFromInvite(values, output, gqlHooks);
    setLoading(false);
    if (!response) {
      return;
    } else if (!response.loginToken) {
      addToast("Something went wrong please contact support", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    reportSignUp(false);

    addToast("Account created! Login code has been sent", {
      appearance: "success",
      autoDismiss: true,
    });
    history.push("/auth/login-code/", {
      token: response.loginToken,
    });
  }

  return (
    <>
      <div className="text-center ">
        <div className="mr-auto ml-auto mb-3 auth-logo">
          <img src={LogoColor} width={50} alt={appName} />
        </div>
        <h2 className="font-weight-bold register-header mb-2">
          Welcome To No Limit Payments!
        </h2>
        <p className="text-muted mb-3">
          Already have an account? <NavLink to="/auth/login/">Login</NavLink>
        </p>
      </div>
      <Col md="7" className="ml-auto mr-auto">
        <Form className="form" onSubmit={handleSubmit}>
          <Label>Create password</Label>
          <FormGroup>
            <Input
              innerRef={refs.password}
              type="password"
              name="password"
              id="password"
              value={values.password || ""}
              invalid={!!errors.password}
              onChange={handleChange}
              autoComplete="off"
              required
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>
          <Label>Phone number</Label>
          <FormGroup>
            <Input
              type="tel"
              name="phone"
              id="phone"
              innerRef={refs.phone}
              value={values.phone || ""}
              invalid={!!errors.phone}
              onChange={handleChange}
              required
            />
            <FormText>
              Must be a cell number. We will text verification codes to this
              number.
            </FormText>
            <FormFeedback>{errors.phone}</FormFeedback>
          </FormGroup>
          <Label>Access code</Label>
          <FormGroup>
            <Input
              name="securityPin"
              type="text"
              id="securityPin"
              innerRef={refs.securityPin}
              value={values.securityPin || ""}
              invalid={!!errors.securityPin}
              onChange={handleChange}
              required
            />
            <FormFeedback>{errors.securityPin}</FormFeedback>
            <FormText>
              The company who invited you must provide you with an access code.
            </FormText>
          </FormGroup>
          <Button
            disabled={loading}
            size="md"
            type="submit"
            color="primary"
            block
            className="mt-4"
          >
            Sign up free
          </Button>
          <div className="text-sm text-center">
            <span className="text-muted">
              By clicking the Sign up free button, you agree to our{" "}
              {/* <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://${domains.PRIMARY}/terms-of-service`}
              >
                Terms of Service & Privacy Policy.
              </a>{" "} */}
              ]<Link to="/tos">Terms of Service & Privacy Policy.</Link>
            </span>
          </div>
        </Form>
      </Col>
    </>
  );
}

export default RegisterInviteForm;

RegisterInviteForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};
