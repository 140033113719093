import React, { useState, useEffect } from "react";

import {
  Button,
  CardBody,
  CardFooter,
  Col,
  Input,
  Form,
  Label,
  FormGroup,
} from "reactstrap";
import useForm from "../../../../hooks/UseForms";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../../api_client/UseGQL";
import { setCompanySettings } from "../../../../api_client/mutations/companyProfile";
import { useAppValue } from "contexts/Context";
import { useSimpleContext } from "contexts/SimpleContext";
import { updateUser } from "modules/Helpers";
const wrapFixCheckEvent = (func) => {
  return (event) => {
    event.target = { name: event.target.name, value: event.target.checked };
    return func(event);
  };
};

export default function NoteToContactCard(props) {
  let [errors, setErrors] = useState(props.props.errors);
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const [context, dispatch] = useAppValue();
  const [values, setValues] = useState(context.user.company.settings);
  useEffect(() => {
    setValues(context.user.company.settings);
    setErrors(props.props.errors);
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update the state based on the input name
    if (name === "notesAlias") {
      const newUser = updateUser(context, null, event.target.value, null);
      setValues((prevValues) => ({
        ...prevValues,
        notesAlias: value,
      }));
      dispatch({
        type: "setUser",
        user: newUser,
      });
    } else if (name === "notesRequired") {
      const newUser = updateUser(context, null, undefined, event.target.value);
      setValues((prevValues) => ({
        ...prevValues,
        notesRequired: event.target.value,
      }));
      dispatch({
        type: "setUser",
        user: newUser,
      });
    }
  };
  return (
    <>
      <Form className="form">
        <CardBody>
          <Col>
            <h3>Fields</h3>
            <h4>Note to Contact</h4>
            <Label>Display Name</Label>
            <FormGroup>
              <Input
                name="notesAlias"
                value={values.notesAlias || ""}
                type="text"
                invalid={!!errors.notesAlias}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup check>
              <Label className="form-check-label">
                <Input
                  name="notesRequired"
                  className="form-check-input"
                  type="checkbox"
                  checked={values.notesRequired}
                  onChange={wrapFixCheckEvent(handleChange)}
                  disabled={loading}
                />
                <span className="form-check-sign">
                  <span className="check" />
                </span>
                <Label>Required</Label>
              </Label>
            </FormGroup>
          </Col>
        </CardBody>
      </Form>
    </>
  );
}
