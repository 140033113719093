import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import {
  convertToDate,
  formattedDollarValue,
  FREQUENCY_OPTIONS,
} from "../../modules/Helpers";
import ContactTagBadge from "../badge/ContactTagBadge";
import { recurringStatus } from "../badge/StatusBadgeRecurring";
import { useHistory } from "react-router";

function RecurringPlanCard(props) {
  const history = useHistory();
  return (
    <Fragment>
      <Card
        className="t-card"
        onClick={() =>
          history.push({
            search: `?overlay=true&type=recurring&id=${props.item.identifier}`,
          })
        }
      >
        <div className="t-card-row">
          <span className="text-primary text-sm font-weight-600">
            Receivable
          </span>
          <div className="t-card-row ml-auto">
            <h4 className="mr-2 mb-0">
              {formattedDollarValue(props.item.amount)}{" "}
              <span className="text-muted text-sm">
                /{" "}
                {FREQUENCY_OPTIONS.find((frequency) => {
                  return frequency.value === props.item.frequency;
                })
                  .label.slice(0, -2)
                  .toLowerCase()}
              </span>
            </h4>
            <div>{recurringStatus(props.item)}</div>
          </div>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <div className="font-weight-bold">
              {props.item?.contact?.name?.substring(0, 20) ||
                props.item?.contact?.email?.substring(0, 20)}
            </div>
            <div className="text-muted">
              {props.item?.statement.substring(0, 20)}
            </div>
          </div>
          <div className="ml-1 mt-2">
            {props.item?.contact &&
              ContactTagBadge(
                props.item?.contact?.tags,
                props.item?.identifier
              )}
          </div>
        </div>
        <div className="t-card-row justify-content-between">
          <div className="flex-column">
            <span className="text-sm font-weight-600">Start</span>
            <br />
            <span className="text-muted text-sm">
              {convertToDate(props.item?.start)}
            </span>
          </div>
          <div className="flex-column text-right">
            <span className="text-sm font-weight-600">Next</span>
            <br />
            <span className="text-muted text-sm">
              {convertToDate(props.item?.nextRun)}
            </span>
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

export default RecurringPlanCard;

RecurringPlanCard.propTypes = {
  item: PropTypes.object,
};
