import React, { Fragment, useState } from "react";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { Button, Col, Row } from "reactstrap";
import { calcBillsPaymentTotal } from "../../../../../../modules/Helpers";
import SuccessIcon from "react-bootstrap-sweetalert/dist/components/SuccessIcon";
import CreateAdditionalPaymentDropdown from "../../../../../../components/dropdowns/CreateAdditionalPaymentDropdown";
import { withApiValue } from "../../../../../../modules/WithApiValue";
import PropTypes from "prop-types";
import ReviewBillsOverlay from "../../../../../../components/overlays/ReviewBillsOverlay";

const config = {
  company: {
    railzConnection: {
      serviceName: true,
    },
  },
};

function CreditPaymentSuccessStep(props) {
  const history = useHistory();
  const [state] = useSimpleContext();
  const [reviewBillsOverlay, setReviewBillsOverlay] = useState(false);

  return (
    <Fragment>
      <ReviewBillsOverlay
        isOpen={reviewBillsOverlay}
        toggle={() => setReviewBillsOverlay(!reviewBillsOverlay)}
      />
      <SuccessIcon />
      <h2 className="text-center">Payment sent</h2>
      <div className="new-payment-inner-content text-center pt-0">
        <span>
          You sent a one time payment of{" "}
          <b>{calcBillsPaymentTotal(state.bills)}</b> settling{" "}
          <b>
            {state.bills.length} bill
            {state.bills.length > 1 && "s"}
          </b>
          . The payee
          {state.bills.length > 1 && "s"} will receive an email confirmation of
          this payment.
        </span>
        <div
          style={{ background: "#fff8d2", borderLeft: "8px solid #ffe664" }}
          className="text-left p-3 mt-4"
        >
          <h5 className="mb-1">Tip</h5>
          <p>
            Don&apos;t forget to mark these bills as{" "}
            <span className="font-italic font-weight-500">paid</span> in your{" "}
            {props.company.railzConnection.serviceName.capitalize()} account.
          </p>
        </div>
        <Button
          onClick={() => setReviewBillsOverlay(!reviewBillsOverlay)}
          block
          className="btn-primary btn-simple mt-4"
        >
          View bill{state.bills.length > 1 && "s"} summary
        </Button>
        <Button
          onClick={() => history.push("/admin/payments")}
          block
          className="btn-primary mt-3"
        >
          All done, return to dashboard
        </Button>
        <Row className="mt-3">
          <Col xs="5" className="ml-auto pl-0">
            <hr />
          </Col>
          <Col xs="1">
            <span>or</span>
          </Col>
          <Col xs="5" className="mr-auto pr-0">
            <hr />
          </Col>
        </Row>
        <CreateAdditionalPaymentDropdown />
      </div>
    </Fragment>
  );
}

export default withApiValue(CreditPaymentSuccessStep, config);

CreditPaymentSuccessStep.propTypes = {
  company: PropTypes.object,
};
