import React, { Fragment, useEffect, useState } from "react";
import useExitPrompt from "../../hooks/useExitPrompt";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import useGQL from "../../api_client/UseGQL";
import { useSimpleContext } from "../../contexts/SimpleContext";
import * as Sentry from "@sentry/browser";
import { publicRecurringPlan } from "../../api_client/queries/recurringPlans";
import { trackEvent } from "../../modules/analytics";
import PaymentNavbar from "../../components/navbars/PaymentNavbar";
import AcceptRecurring from "./AcceptRecurring";
import PopOutOverlay from "../../components/overlays/PopOutOverlay";
import CheckoutSupportForm from "../../components/forms/CheckoutSupportForm";

function RecurringPayment() {
  const [, setShowExitPrompt] = useExitPrompt(true);
  const { params } = useRouteMatch();
  const gqlHooks = useGQL();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [overlayDetails, setOverlayDetails] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    try {
      Sentry.configureScope((scope) =>
        scope.setTransactionName("Checkout Recurring")
      );
    } catch (e) {
      console.log("sentry not loaded");
    }
  }, []);

  useEffect(() => {
    setShowExitPrompt(true);
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  useEffect(() => {
    const recurringIdentifier = params.identifier;
    let input = { recurringPlanId: recurringIdentifier };
    let output = {
      identifier: true,
      active: true,
      start: true,
      end: true,
      nextRun: true,
      expired: true,
      amount: true,
      statement: true,
      frequency: true,
      description: true,
      contact: {
        identifier: true,
        email: true,
      },
      transactions: {
        createdAt: true,
        identifier: true,
        amount: true,
        statement: true,
        description: true,
        padType: true,
        direction: true,
        estDepositDate: true,
        states: {
          state: true,
          createdAt: true,
        },
      },
      fromBankAccount: {
        institution: true,
        title: true,
        accountNumber: true,
      },
      customerCreditCard: {
        brand: true,
        last4: true,
      },
      toBankAccount: {
        institution: true,
        title: true,
        accountNumber: true,
        convergeMerchantAccount: {
          merchantId: true,
          pin: true,
          apiUser: {
            userId: true,
          },
        },
      },
      company: {
        name: true,
        displayImage: true,
        identifier: true,
        settings: {
          ccEnabled: true,
          eftEnabled: true,
        },
        isUsingConverge: true,
      },
    };
    publicRecurringPlan(input, output, gqlHooks).then((response) => {
      if (response) {
        if (response.data) {
          trackEvent(
            "LoadedRecurringPlan",
            {
              requestId: recurringIdentifier,
              companyId: response.data.company.identifier,
            },
            gqlHooks
          );
          setState({ recurringPlan: response.data });
        } else {
          history.push("/lost");
        }
      } else {
        history.push("/lost");
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (state?.recurringPlan) {
      // todo handle expired case, should show some sort of prompt to say they cant use it
      if (state.recurringPlan.active) {
        history.push({ search: "active=true" });
      }
    }
  }, [state]);

  if (loading) {
    return null;
  } else {
    return (
      <Fragment>
        <div className="payment-wrapper section-to-print">
          <PaymentNavbar
            companyLogo={state?.recurringPlan.company.displayImage}
            companyName={state?.recurringPlan.company.fullName}
            summaryText={`Recurring payment to ${
              state?.recurringPlan.company.fullName ||
              state?.recurringPlan.company.name
            }`}
            amount={state?.recurringPlan.amount}
            frequencyText={`/${state?.recurringPlan.frequency.slice(0, -2)}`}
            toggleSupport={() => setOverlayDetails(!overlayDetails)}
          />
          <PopOutOverlay
            isOpen={overlayDetails}
            toggle={() => {
              setOverlayDetails(!overlayDetails);
            }}
          >
            <CheckoutSupportForm
              toggleSupport={() => setOverlayDetails(!overlayDetails)}
            />
          </PopOutOverlay>
          <div className="payment-content">
            <Switch>
              <Route
                path="/payment/recurring/:identifier"
                render={() => <AcceptRecurring loading={loading} />}
              />
            </Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default RecurringPayment;

RecurringPayment.propTypes = {};
