import PropTypes from "prop-types";
import { buildQuery, callQuery } from "../query";

export function currentUser(input, output, hooks) {
  const query = buildQuery(input, output, "currentUser");
  return callQuery(query, hooks);
}

currentUser.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};

export function publicUser(input, output, hooks) {
  const query = buildQuery(input, output, "publicUser");
  return callQuery(query, hooks);
}

publicUser.propTypes = {
  input: PropTypes.shape({}).isRequired,
  output: PropTypes.element.isRequired,
};
