// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useGQLContext } from "../api_client/client";

function TokenStateGateway(props) {
  const [, dispatchGQL] = useGQLContext();

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      checkCookies();
    });
  }, []);

  function checkCookies() {
    if (Cookies.get("token") === undefined) {
      dispatchGQL({
        type: "logout",
      });
    }
  }

  return props.children;
}

export default TokenStateGateway;
