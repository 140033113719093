import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useSimpleContext } from "../../../../../contexts/SimpleContext";
import { Button, Col, Row } from "reactstrap";
import { formattedDollarValue } from "../../../../../modules/Helpers";
import SuccessIcon from "react-bootstrap-sweetalert/dist/components/SuccessIcon";
import CreateAdditionalPaymentDropdown from "../../../../../components/dropdowns/CreateAdditionalPaymentDropdown";

function InternalSuccessStep() {
  const history = useHistory();
  const [state] = useSimpleContext();

  return (
    <Fragment>
      <SuccessIcon />
      <h2 className="text-center">Internal transfer created</h2>
      <div className="new-payment-inner-content text-center pt-0">
        <span>
          You transferred <b>{formattedDollarValue(state.amount)}</b> from{" "}
          <b>{state.fromBankAccount.label}</b> to{" "}
          <b>{state.toBankAccount.label}</b>.
        </span>
        <Button
          onClick={() => history.push("/admin/payments")}
          block
          className="btn-primary mt-4"
        >
          All done, return to dashboard
        </Button>
        <Row className="mt-3">
          <Col xs="5" className="ml-auto pl-0">
            <hr />
          </Col>
          <Col xs="1">
            <span>or</span>
          </Col>
          <Col xs="5" className="mr-auto pr-0">
            <hr />
          </Col>
        </Row>
        <CreateAdditionalPaymentDropdown />
      </div>
    </Fragment>
  );
}

export default InternalSuccessStep;

InternalSuccessStep.propTypes = {};
