import { useEffect, useState } from "react";
import { useGQLContext } from "../api_client/client";
import { useApiFieldErrors } from "../api_client/UseGQL";

const useForm = (callback = () => null, validate = () => null) => {
  const [gqlContext, dispatch] = useGQLContext();
  const [values, setValues] = useState({});
  const [formValidationErrors, setErrors] = useState({});
  const apiErrors = useApiFieldErrors(values);
  const [waitErrors, setWaitErrors] = useState({});

  let errors = { ...formValidationErrors, ...apiErrors };

  useEffect(() => {
    if (
      gqlContext.fieldErrors &&
      Object.entries(values).length === 0 &&
      Object.entries(gqlContext.fieldErrors).length !== 0
    ) {
      dispatch({
        type: "setFieldErrors",
        fieldErrors: {},
      });
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => setWaitErrors(errors), 500);
    return () => clearTimeout(timeoutId);
  }, [values]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    if (Object.keys(errors).length === 0) {
      callback();
    }
  };

  const handleEventValue = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleEventApiErrors = (event) => {
    if (apiErrors[event.target.name]) {
      delete apiErrors[event.target.name];
      dispatch({
        type: "setFieldErrors",
        fieldErrors: apiErrors,
      });
    }
  };

  const validateForm = (name, value, billId) => {
    setErrors(validate({ [name]: value }, formValidationErrors, billId));
  };

  const handleChange = (event) => {
    event.persist();
    handleEventValue(event);
    handleEventApiErrors(event);
    if (event.target.value.length === 0) {
      let errors = formValidationErrors;
      if (errors && errors[event.target.name]) delete errors[event.target.name];
      setErrors(errors);
    } else {
      validateForm(event.target.name, event.target.value);
    }
  };

  const handleTableFieldChange = (event) => {
    event.persist();
    handleEventValue(event);
    validateForm(event.target.name, event.target.value);
  };

  return {
    handleChange,
    handleTableFieldChange,
    handleSubmit,
    validateForm,
    setErrors,
    values,
    setValues,
    errors,
    waitErrors,
  };
};

export default useForm;
