import React, { Fragment, useEffect, useRef, useState } from "react";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";

import { NavLink, useHistory } from "react-router-dom";
import useForm from "../../../hooks/UseForms";
import { registerFormValidation } from "../../../modules/form_validation/RegisterFormValidation";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../api_client/UseGQL";
import { signup } from "../../../api_client/mutations/auth";
import { cleanCompanyName, appName, domains } from "../../../modules/Helpers";
import LogoColor from "../../../assets/img/logo-colour.png";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HubspotRefferalForm from "../../../components/hubspot/HubspotRefferalForm";
import { reportSignUp } from "../../../modules/analytics";
import { Link } from "react-router-dom";
// import HubspotRefferalForm from "../../../components/hubspot/HubspotRefferalForm";

function RegisterForm() {
  const { values, handleChange, handleSubmit, errors } = useForm(
    submit,
    registerFormValidation
  );
  const refs = {
    email: useRef(null),
    password: useRef(null),
    phone: useRef(null),
    companyName: useRef(null),
    referrer: useRef(null),
  };
  const { addToast } = useToasts();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  let gqlHooks = useGQL();
  const [verifyBusiness, setVerifyBusiness] = useState("initial");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function submit() {
    setLoading(true);

    if (values.email && values.email.endsWith("candassociates.com")) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
      addToast("Something went wrong please, contact support", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    let companyName = cleanCompanyName(values.email);
    let input = {
      ...values,
      companyName: companyName,
    };
    let output = {
      loginToken: true,
      user: {
        identifier: true,
      },
    };
    let response = await signup(input, output, gqlHooks);
    setLoading(false);
    if (!response) {
      return;
    } else if (!response.loginToken) {
      addToast("Something went wrong, please contact support", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    reportSignUp(true);
    addToast("Account created! Login code has been sent", {
      appearance: "success",
      autoDismiss: true,
    });
    history.push("/auth/login-code/", {
      token: response.loginToken,
    });
  }

  if (verifyBusiness === "business") {
    return (
      <>
        <div className="text-center ">
          <div className="mr-auto ml-auto mb-3 auth-logo">
            <img src={LogoColor} width={50} alt={appName} />
          </div>
          <h2 className="font-weight-bold register-header mb-2">
            Welcome To No Limit Payments!
          </h2>
          <p className="text-muted mb-3">
            Already have an account? <NavLink to="/auth/login/">Login</NavLink>
          </p>
        </div>
        <Col md="7" className="ml-auto mr-auto">
          <Form role="form" onSubmit={handleSubmit}>
            <Label>Business email address</Label>
            <FormGroup>
              <Input
                name="email"
                type="email"
                innerRef={refs.email}
                value={values.email || ""}
                onChange={handleChange}
                invalid={!!errors.email}
                required
              />
              <FormFeedback>This email address is already in use</FormFeedback>
            </FormGroup>
            <Label>Cell phone number</Label>
            <FormGroup>
              <Input
                innerRef={refs.phone}
                type="tel"
                name="phone"
                value={values.phone || ""}
                invalid={!!errors.phone}
                onChange={handleChange}
                required
              />
              <FormText>
                Must be a cell number. We will text verification codes to this
                number.
              </FormText>
              <FormFeedback>{errors.phone}</FormFeedback>
            </FormGroup>
            <Label>Create password</Label>
            <FormGroup>
              <Input
                innerRef={refs.password}
                type="password"
                name="password"
                value={values.password || ""}
                invalid={!!errors.password}
                onChange={handleChange}
                required
                autoComplete="off"
              />
              <FormFeedback>{errors.password}</FormFeedback>
            </FormGroup>
            <Button
              disabled={loading}
              block
              type="submit"
              color="primary"
              size="md"
              className="mt-4"
            >
              Sign up free
            </Button>
            <div className="text-sm text-center">
              <span className="text-muted">
                By clicking the Sign up free button, you agree to our{" "}
                {/* <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://${domains.PRIMARY}/terms-of-service`}
                >
                  Terms of Service & Privacy Policy.
                </a>{" "} */}
                <Link to="/tos">Terms of Service & Privacy Policy.</Link>
              </span>
            </div>
          </Form>
        </Col>
      </>
    );
  } else if (verifyBusiness === "personal") {
    return (
      <div className="text-center">
        <div className="mr-auto ml-auto mb-3">
          <FontAwesomeIcon
            icon={faCircleXmark}
            size={"5x"}
            color={"var(--danger)"}
          />
        </div>
        <h2 className="font-weight-bold register-header mb-2">
          Sorry! {appName} is available for businesses only.
        </h2>
        <br />
        <p className="text-muted mb-3">
          Know a business that could benefit from using {appName}?
        </p>
        <Button
          className="btn-primary mt-3"
          onClick={() => setVerifyBusiness("refer")}
        >
          Refer a business
        </Button>
        <Button
          className="btn-simple btn-primary mt-3 ml-2"
          onClick={() => setVerifyBusiness("initial")}
        >
          Go back
        </Button>
      </div>
    );
  } else if (verifyBusiness === "refer") {
    return (
      <div className="text-center">
        <div className="mr-auto ml-auto mb-3 auth-logo">
          <img src={LogoColor} width={50} alt={appName} />
        </div>
        <h2 className="font-weight-bold register-header mb-2">
          Who would you like to refer?
        </h2>
        <br />
        <HubspotRefferalForm back={() => setVerifyBusiness("initial")} />
      </div>
    );
  } else {
    return (
      <Fragment>
        <div className="text-center">
          <div className="mr-auto ml-auto mb-3 auth-logo">
            <img src={LogoColor} width={50} alt={appName} />
          </div>
          <h2 className="font-weight-bold register-header mb-2">
            Are you signing up as a business?
          </h2>
          <p className="text-muted mb-3">
            Already have an account? <NavLink to="/auth/login/">Login</NavLink>
          </p>
        </div>
        <Col lg="6" className="ml-auto mr-auto">
          <ButtonGroup className="business-type-btn-wrapper">
            <Button
              className="business-type-btn1"
              onClick={() => setVerifyBusiness("business")}
            >
              Business
            </Button>
            <Button
              className="business-type-btn2"
              onClick={() => setVerifyBusiness("personal")}
            >
              Personal
            </Button>
          </ButtonGroup>
        </Col>
      </Fragment>
    );
  }
}

export default RegisterForm;
