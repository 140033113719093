/* eslint-disable react/prop-types,react/display-name */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { formatDate } from "../../modules/Helpers";
import PropTypes from "prop-types";
import useGQL from "../../api_client/UseGQL";
import { allContacts } from "../../api_client/queries/contacts";
import ReactTable from "./ReactTable";
import ContactTagBadge from "../badge/ContactTagBadge";
import ContactsCard from "../cards/ContactsCard";
import ContactsSkeletonCard from "../skeleton/ContactsSkeletonCard";
import QueryString from "query-string";
import { useLocation } from "react-router";

function ContactsTable(props) {
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let gqlHooks = useGQL();
  const [update, setUpdate] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.update) {
      setUpdate(!update);
    }
  }, [location]);

  const getData = useCallback(
    async ({ pageSize }) => {
      if (!props.refresh) {
        setLoading(true);
        let offset = 0;
        const params = {
          ...props.filters,
          limit: pageSize,
          offset: offset,
        };
        let output = {
          identifier: true,
          name: true,
          createdAt: true,
          email: true,
          contactType: true,
          tags: true,
          bankAccounts: {
            identifier: true,
            state: true,
          },
        };
        const response = await allContacts(params, output, gqlHooks);
        if (response) {
          setData(response.data);
          setCount(response.count);
          props.getNumContacts(response.count);
        }
        setLoading(false);
      }
    },
    [props.filters, props.refresh, update]
  );

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell }) => (
          <Fragment>
            <div className="font-weight-bold mt-2 mb-2">
              {cell.row.original.name === null ? (
                <h5 className="text-muted font-italic">Name Unassigned</h5>
              ) : (
                cell.row.original.name
              )}
            </div>
          </Fragment>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ cell }) => (
          <Fragment>
            {ContactTagBadge(
              cell.row.original.tags,
              cell.row.original.identifier
            )}
          </Fragment>
        ),
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        Cell: ({ cell }) => (
          <Fragment>
            <div className="text-muted">
              {formatDate(cell.row.original.createdAt)}
            </div>
          </Fragment>
        ),
      },
    ],

    []
  );

  return (
    <>
      <ReactTable
        loading={loading}
        getData={getData}
        data={data}
        columns={columns}
        count={count}
        overlayType="contact"
        MobileCard={ContactsCard}
        MobileSkeleton={ContactsSkeletonCard}
        numTd={4}
      />
    </>
  );
}

export default ContactsTable;

ContactsTable.propTypes = {
  filters: PropTypes.object.isRequired,
  getNumContacts: PropTypes.func,
};
