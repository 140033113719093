import React, { Fragment, useEffect, useState } from "react";
import { withApiValue } from "../../../../../../modules/WithApiValue";
import PropTypes from "prop-types";
import QueryString from "query-string";
import { CustomLoader } from "../../../../../../components/loaders/CustomLoader";
import { useScript } from "../../../../../../hooks/useScript";

const config = {
  company: {
    identifier: true,
  },
};

const RailzConnectWrapper = ({ loaded }) => {
  return (
    <div className="m-auto">
      <div id="railz-connect">{!loaded && <CustomLoader />}</div>
    </div>
  );
};
RailzConnectWrapper.propTypes = {
  loaded: PropTypes.bool,
};

function CreditConnectIntegrationStep(props) {
  const url = window.location.origin;
  const queryParams = QueryString.stringify({
    from: "railzConnect",
  });
  const [loaded, setLoaded] = useState(false);
  const status = useScript("https://connect.railz.ai/v1/railz-connect.js");

  useEffect(() => {
    if ((status === "ready" || status === "idle") && !loaded) {
      const RailzConnect = new window.RailzConnect();
      RailzConnect.mount({
        parentElement: document.getElementById("railz-connect"),
        widgetId: "wid_prod_21d06cf2-cf79-4295-9d82-4fa2d850d145",
        businessName: `${props.company.identifier}`,
        redirectUrl: `${url}/admin/payments?${queryParams}`,
        serviceFilterEnabled: false,
      });
      setLoaded(true);
    }
  }, [status]);

  return (
    <Fragment>
      <RailzConnectWrapper loaded={loaded} />
    </Fragment>
  );
}

export default withApiValue(CreditConnectIntegrationStep, config);

CreditConnectIntegrationStep.propTypes = {
  company: PropTypes.object,
};
