import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function PopOutOverlay(props) {
  return (
    <Fragment>
      <Dialog
        fullScreen
        disableBackdropClick={props.disableBackdropClick}
        className={!props.fullscreen && "pop-out-overlay"}
        open={props.isOpen}
        onClose={props.toggle}
        TransitionComponent={Transition}
      >
        <div className="p-3">{props.children}</div>
      </Dialog>
    </Fragment>
  );
}

export default PopOutOverlay;

PopOutOverlay.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.object,
  fullscreen: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
};
