import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { camelToSentenceCase } from "../../modules/Helpers";

function FieldGenerator(props) {
  return (
    <Fragment>
      {props.fields.map((field, key) => (
        <Fragment key={key}>
          <div className={props.white && "white-form"}>
            <Label>{camelToSentenceCase(field)}</Label>
            <FormGroup>
              <Input
                name={field}
                type="text"
                value={props.values[field] || ""}
                onChange={props.handleChange}
                invalid={!!props.errors[field]}
                required
              />
              <FormFeedback>{props.errors[field]}</FormFeedback>
            </FormGroup>
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
}

export default FieldGenerator;

FieldGenerator.propTypes = {
  fields: PropTypes.array.isRequired,
  classes: PropTypes.string,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  white: PropTypes.bool,
};

FieldGenerator.defaultProps = {
  fields: [],
};
