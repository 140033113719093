/* eslint-disable react/prop-types */
import React from "react";

function SearchIcon(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || "100%";
  const height = props.height || "100%";
  const title = props.title || "SearchIcon";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap="square"
        strokeWidth={strokewidth}
      >
        <line fill="none" x1="44" x2="31.314" y1="44" y2="31.314" />
        <circle cx="20" cy="20" fill="none" r="16" stroke={fill} />
      </g>
    </svg>
  );
}

export default SearchIcon;
