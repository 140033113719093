import React, { Fragment, useEffect, useState } from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";
import { CustomLoader } from "../components/loaders/CustomLoader";
import OnlineBankLoginAlert from "../components/sweet_alert/OnlineBankLoginAlert";
import { appName, baseUrl, domains, ENV, PRODUCTION } from "./Helpers";

function FlinksIframe(props) {
  const [loading, setLoading] = useState(true);
  const [redirectAlert, setRedirectAlert] = useState(null);

  const hideSpinner = () => {
    setLoading(false);
  };

  useEffect(() => {
    window.addEventListener("message", handleFrameTasks);
    return () => {
      window.removeEventListener("message", handleFrameTasks);
    };
  }, []);

  const handleFrameTasks = (e) => {
    let setLoginId = props.setLoginId;
    let setAccountId = props.setAccountId;
    if (e.data.step === "REDIRECT") {
      setLoginId(e.data);
    }
    if (e.data.step === "ACCOUNT_SELECTED") {
      setAccountId(e.data);
    }
    if (e.data.step === "RETRY_COUNT" && e.data.count > 1) {
      OnlineBankLoginAlert(
        () => setRedirectAlert(null),
        setRedirectAlert,
        <Fragment>
          <h4>
            Not all bank accounts are supported online, please try entering your
            routing information with our <b>Digital Cheque</b> option.
          </h4>
        </Fragment>,
        "Trouble logging in?"
      );
    }
  };

  return (
    <React.Fragment>
      {redirectAlert}
      {loading ? (
        <div className="add-bank-flinks-loader">
          <Col>
            <CustomLoader />
          </Col>
        </div>
      ) : null}
      <iframe
        key={props.reloadState}
        title="verifyBank"
        className={props.page}
        src={
          `https://${domains.FLINKS_PRIV}/v2/?` +
          `${ENV === PRODUCTION ? "" : "demo=true"}&institutionsLocation=ca` +
          `&withTransactions=false&headerEnable=false&customerName=${appName}` +
          `&consentEnable=true&features=AccountNumber%2CAccountInfo` +
          `&redirectUrl=${encodeURIComponent(baseUrl)}%2Fbank-connected` +
          `&innerRedirect=true&accountSelectorEnable=true&` +
          `showAllOperationsAccounts=true&accountSelectorCurrency=cad` +
          `&eftEligibleRatio=0.5&institutionFilterEnable=true`
        }
        onLoad={hideSpinner}
      />
    </React.Fragment>
  );
}
export default FlinksIframe;

FlinksIframe.propTypes = {
  setLoginId: PropTypes.func.isRequired,
  setAccountId: PropTypes.func.isRequired,
  page: PropTypes.string,
  reloadState: PropTypes.number,
  redirectFunc: PropTypes.func.isRequired,
};
