import React, { Fragment, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import PaymentDetailsStep from "./steps/PaymentDetailsStep";
import { useSimpleContext } from "../../contexts/SimpleContext";
import BankTransferMethod from "./methods/BankTransferMethod";
import PaymentReviewStep from "./steps/PaymentReviewStep";
import PaymentSuccessStep from "./steps/PaymentSuccessStep";
import PaymentTrackStep from "./steps/PaymentTrackStep";
import TrustFooter from "../../components/footers/TrustFooter";
import CheckoutSecurityBanner from "../../components/generic_details/CheckoutSecurityBanner";

function AcceptCredit() {
  const history = useHistory();
  const [state] = useSimpleContext();
  const location = useLocation();

  useEffect(() => {
    if (
      state.transaction.states[0].state !== 7 &&
      !location.pathname.includes("success")
    ) {
      history.push(`/payment/credit/${state.transaction.identifier}/track`);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (state.transaction.states[0].state === 7) {
      history.push(`/payment/credit/${state.transaction.identifier}/details`);
    } else {
      history.push(`/payment/credit/${state.transaction.identifier}/track`);
    }
  }, []);

  return (
    <Fragment>
      <div className="card border">
        <Switch>
          <Route
            path="/payment/credit/:identifier/details"
            render={() => <PaymentDetailsStep />}
          />
          <Route
            path="/payment/credit/:identifier/bank-transfer"
            render={() => <BankTransferMethod />}
          />
          <Route
            path="/payment/credit/:identifier/review"
            render={() => <PaymentReviewStep />}
          />
          <Route
            path="/payment/credit/:identifier/success"
            render={() => <PaymentSuccessStep />}
          />
          <Route
            path="/payment/credit/:identifier/track"
            render={() => <PaymentTrackStep />}
          />
          <Redirect
            from={"/payment/credit/:identifier"}
            to={"/payment/credit/:identifier/details"}
          />
        </Switch>
        <CheckoutSecurityBanner />
      </div>
      <TrustFooter />
    </Fragment>
  );
}

export default AcceptCredit;

AcceptCredit.propTypes = {};
